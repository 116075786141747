<template>
  <div class="welcome">
    <v-layout column>
      <v-flex xs12>
        <v-app-bar elevate-on-scroll color="primary" app>
          <v-container>
            <v-row align="center">
              <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
                class="hidden-md-and-up white--text"
              ></v-app-bar-nav-icon>

              <v-img max-width="200" src="img/UNHCR-logo.png"> </v-img>

              <v-spacer></v-spacer>
              <v-btn
                depressed
                class="white primary--text mr-5 hidden-sm-and-down text-none"
                @click="changeLoginCardState"
                >Login</v-btn
              >
            </v-row>
          </v-container>
        </v-app-bar>
        <v-navigation-drawer temporary app v-model="drawer">
          <v-list dense nav>
            <v-list-item @click="changeLoginCardState" link>
              <v-list-item-icon>
                <v-icon>mdi-lock-open-variant</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Sign In</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 class="header">
        <v-img
          dark
          height="700"
          src="img/images/kids-happy.jpg"
        >
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-container>
                  <h1 class="display-2 mb-4">Accurate Data.</h1>
                  <h4 class="display-2">Improved learning</h4>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-flex>
    </v-layout>
    
    <v-container>
      <v-layout column>
        <v-flex xs12 class="header mt-5">
          <div class="pa-5">
            <v-layout row wrap>
              <v-flex xs12 md6>
                <div align="center">
                  <v-img src="img/images/P2PD3B1.png"> </v-img>
                </div>
              </v-flex>
              <v-flex xs12 md6>
                <v-container fill-height fluid>
                  <v-row align="center" justify="center">
                    <v-col class="text-center"  cols="12">
                      <div class="headline font-weight-black">Education Data</div>
                      <div class="subtitle-1">Unhcr- EMIS is an Education Management</div>
                      <div class="subtitle-1">Information System designed to manage education</div>
                      <div class="subtitle-1">systems in Kakuma Refugee Camp.</div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs12 md6 class="mt-9 white--text">
              <div class="primary mx-3">
                <v-container fill-height fluid >
                  <v-row align="center" class="my-15" justify="center" >
                    <v-col class="text-center" cols="12" >
                      <div class="headline font-weight-black">Student Data</div>
                      <div class="subtitle-1">Collect and report data on</div>
                      <div class="subtitle-1">student attendance, infrastructure,</div>
                      <div class="subtitle-1">and progress.</div>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-flex>
            <v-flex xs12 md6 class="mt-9">
              <v-img class="mx-3" height="284" src="img/images/ben-white-83tkHLPgg2Q-unsplash.jpg"> </v-img>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs12 md6 class="mt-12">
              <v-img class="mx-3" height="284" src="img/images/group-african-kids-paying-attention-class.jpg"> </v-img>
            </v-flex>
            <v-flex xs12 md6 class="mt-12">
              <div class="header mx-3">
                <v-container fill-height fluid >
                  <v-row align="center" class="my-15" justify="center" >
                    <v-col class="text-center" cols="12" >
                      <div class="headline font-weight-black">Teacher Data</div>
                      <div class="subtitle-1">Collect and manage data on</div>
                      <div class="subtitle-1">teacher qualifications and</div>
                      <div class="subtitle-1">attendance.</div>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 class="header mt-9">
          <div>
            <v-img
              dark
              height="400"
              src="img/images/emmanuel-ikwuegbu-m4h6qtdogMk-unsplash.jpg"
            >
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col class="text-center" cols="12">
                    <v-container>
                      <v-layout row wrap class="mt-10">
                        <v-flex xs12 md6>
                          <div class="headline font-weight-black">Institutional Data</div>
                          <div class="subtitle-1">Manage data on educational</div>
                          <div class="subtitle-1">institutions to optimize resources</div>
                          <div class="subtitle-1">and services.</div>
                        </v-flex>
                        <v-flex xs12 md6>
                          
                        </v-flex>
                      </v-layout>
                      
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </div>
        </v-flex>
        <v-flex xs12 class="header mt-7">
          <div>
            <v-img
              dark
              height="400"
              src="img/images/zach-vessels-_P9fwHnwhcA-unsplash.jpg"
            >
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col class="text-center" cols="12">
                    <v-container>
                      <div class="display-1 font-weight-black">Who is using UNHCR-EMIS?</div>
                      <v-layout row wrap class="mt-10">
                        <v-flex xs12 md3>
                          <v-layout column>
                            <v-flex xs12>
                              <div class="display-2 font-weight-light">800</div>
                            </v-flex>
                            <v-flex xs12>
                              <div class="title">Students</div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md3>
                          <v-layout column>
                            <v-flex xs12>
                              <div class="display-2 font-weight-light">120</div>
                            </v-flex>
                            <v-flex xs12>
                              <div class="title">Staff</div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md3>
                          <v-layout column>
                            <v-flex xs12>
                              <div class="display-2 font-weight-light">44</div>
                            </v-flex>
                            <v-flex xs12>
                              <div class="title">Institutions</div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md3>
                          <v-layout column>
                            <v-flex xs12>
                              <div class="display-2 font-weight-light">12</div>
                            </v-flex>
                            <v-flex xs12>
                              <div class="title">Locations</div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    
    <AuthPopUp v-model="loginCardState" />
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    AuthPopUp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,
      bg: "primary",
      avSize: "transparent",
      avCard: "transparent",
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions(["changeLoginCardState"]),
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.bg = "primary";
        this.avCard = "#D2A354";
        this.avSize = 270;
      } else {
        this.bg = "primary";
        this.avCard = "transparent";
        this.avSize = 372;
      }
    },
  },
  computed: {
    ...mapGetters(["loginCardState"]),
  },
};
</script>
