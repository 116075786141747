<template>
  <div class="users" v-if="$can('user_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ $t(message) }}</span>
    </v-snackbar>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <div class="title">{{ $t("usersMsg") }}</div>
        </v-flex>
        <div v-if="view == 'default'">
          <v-flex xs12 class="mt-5 mb-2">
            <v-card elevation="0" class="header">
              <v-layout row wrap>
                <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                <v-flex xs12 md4>
                  <v-text-field
                    outlined
                    rounded
                    dense
                    :label="$t('searchMsg')"
                    append-icon="mdi-undo-variant"
                    @click:append="resetSearch()"
                    v-on:keyup.enter="search"
                    v-model="searchTerm"
                    @input="enableSearch()"
                    class="background search_field my-3 mx-5"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <!-- <div class="pa-1">
                    <v-btn
                      class="secondary text-none"
                      depressed
                      block
                      @click="search"
                      :loading="searchLoader"
                      :disabled="searchButtonDisabled"
                    >
                      {{ $t("searchMsg") }}
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </div> -->
                  <div align="center" class="my-4">
                    <v-btn
                      rounded
                      depressed
                      class="white--text universal text-none"
                      @click="changeView('create')"
                      v-if="$can('user_create')"
                    >
                      {{ $t("addUserMsg") }}
                      <v-icon right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <div v-if="users.length == 0">
              <v-card elevation="0">
                <v-layout row wrap>
                  <v-flex xs12 md1>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          <v-icon large class="primary--text">
                            mdi-alert-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md11>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          {{ $t("noUserFoundMsg") }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-card>
            </div>
            <div v-else>
              <v-progress-linear
                v-if="userLoader"
                height="1"
                indeterminate
                color="primary"
              ></v-progress-linear>
              <div class="hidden-sm-and-down">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="header">
                      <tr>
                        <th class="text-left">{{ $t("nameMsg") }}</th>
                        <th class="text-left">{{ $t("emailMsg") }}</th>
                        <th class="text-left">{{ $t("dateAddedMsg") }}</th>
                        <th class="text-left">{{ $t("roleMsg") }}</th>
                        <th class="text-left">{{ $t("accountActiveMsg") }}</th>
                        <th class="text-right">{{ $t("actionsMsg") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in users" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.email }}</td>
                        <td>
                          {{ item.created_at | moment("DD/MM/YYYY - hh:m a") }}
                        </td>
                        <td>
                          <div v-if="item.roles.length != 0">
                            {{ item.roles[0].name }}
                          </div>
                          <div v-else>{{ $t("noneMsg") }}</div>
                        </td>
                        <td>
                          <div v-if="item.active == 1">{{ $t("yesMsg") }}</div>
                          <div v-else>{{ $t("noMsg") }}</div>
                        </td>
                        <td>
                          <div align="right">
                            <!-- <v-btn
                              icon
                              class="button mr-1 universal--text"
                              @click="showUser(item)"
                              v-if="$can('user_view')"
                              >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn> -->
                            <v-tooltip top v-if="$can('user_edit')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  class="button mr-1 success--text"
                                  @click="editUser(item)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("editMsg") }}</span>
                            </v-tooltip>

                            <v-tooltip top v-if="$can('user_archive')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  class="button mr-1 error--text"
                                  @click="deleteUser(item)"
                                  :loading="loading && userIndex == item.id"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("deleteMsg") }}</span>
                            </v-tooltip>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="hidden-md-and-up">
                <v-layout column>
                  <template v-for="(user, index) in users">
                    <div :key="index">
                      <v-flex xs12 class="mb-2">
                        <v-card elevation="0">
                          <div class="pa-5">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <div class="title primary--text">
                                      <b>{{ user.name }}</b>
                                    </div>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn
                                      depressed
                                      class="red--text"
                                      :loading="loading && userIndex == user.id"
                                      icon
                                      @click="deleteUser(user)"
                                      v-if="$can('user_archive')"
                                    >
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-divider class="mt-2"></v-divider>
                              <v-flex xs12 class="mt-1">
                                <v-layout column>
                                  <v-flex xs12 class="mt-1">
                                    <div>
                                      <b>{{ $t("emailAddressMsg") }}</b>
                                    </div>
                                    <div>{{ user.email }}</div>
                                  </v-flex>
                                  <v-flex xs12 class="mt-1">
                                    <div>
                                      <b>{{ $t("dateAddedMsg") }}</b>
                                    </div>
                                    <div>
                                      {{
                                        user.created_at
                                          | moment("DD/MM/YYYY - hh:m a")
                                      }}
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 class="mt-1">
                                    <div>
                                      <b>{{ $t("roleMsg") }}</b>
                                    </div>
                                    <div>
                                      <div v-if="user.roles.length != 0">
                                        {{ user.roles[0].name }}
                                      </div>
                                      <div v-else>{{ $t("actionsMsg") }}</div>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 class="mt-1">
                                    <div>
                                      <b>{{ $t("accountActiveMsg") }}</b>
                                    </div>
                                    <div>
                                      <div v-if="user.active == 1">
                                        {{ $t("yesMsg") }}
                                      </div>
                                      <div v-else>{{ $t("noMsg") }}</div>
                                    </div>
                                  </v-flex>

                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <!-- <v-flex xs6>
                                        <div class="pa-1">
                                          <v-btn
                                            depressed
                                            class="
                                              primary
                                              text-none
                                              white--text
                                            "
                                            block
                                            @click="showUser(user)"
                                            >{{ $t("viewMsg") }}
                                            <v-icon right> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex> -->
                                      <v-flex xs12>
                                        <div class="pa-1">
                                          <v-btn
                                            depressed
                                            class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                            "
                                            block
                                            @click="editUser(user)"
                                            v-if="$can('user_edit')"
                                            >{{ $t("editMsg") }}
                                            <v-icon right>
                                              mdi-border-color
                                            </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <v-layout row wrap>
              <v-flex xs12 md9>
                <div align="left">
                  <v-pagination
                    v-if="length != 0 && length != 1"
                    :length="length"
                    total-visible="10"
                    v-model="userPagination.current_page"
                    @input="changePage()"
                    circle
                  >
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div v-if="length != 0" align="right" class="mr-3">
                  <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b
                  >{{ userPagination.total | formatNumber }}
                </div>
              </v-flex>
              
            </v-layout>
          </v-flex>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-2">
            <v-card elevation="0" class="background">
              <v-card-title class="header">
                <v-spacer></v-spacer>
                <div v-if="newUser.id == null">{{ $t("addUserMsg") }}</div>
                <div v-else>{{ $t("editUserMsg") }}</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5">
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>{{ $t("nameMsg") }}<span class="error--text">*</span></div>
                        <v-text-field
                          class="text_field background"
                          v-uppercase
                          outlined
                          dense
                          v-model="newUser.name"
                          :rules="inputRules"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>{{ $t("emailAddressMsg") }}<span class="error--text">*</span></div>
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          v-model="newUser.email"
                          :disabled="lockEmail"
                          :rules="emailRules"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>{{ $t("roleMsg") }}*</div>
                        <v-select
                          :items="formData.roles"
                          class="text_field background"
                          item-value="id"
                          item-text="name"
                          outlined
                          v-model="newUser.roleId"
                          dense
                          :rules="inputRules"
                        ></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3 v-if="newUser.id == null">
                      <div class="pa-3">
                        <div>{{ $t("passwordMsg") }}*</div>
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          v-model="newUser.password"
                          :rules="passwordRules"
                          append-icon="mdi-autorenew"
                          @click:append="generate()"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3 v-if="newUser.id != null">
                      <div class="pa-3">
                        <div>{{ $t("passwordMsg") }}</div>
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          v-model="newUser.password"
                          append-icon="mdi-autorenew"
                          @click:append="generate()"
                        ></v-text-field>
                        <div>
                          <i
                            >**{{
                              $t(
                                "leaveBlankToAvoidUnintentionallyUpdatingTheUsersPasswordMsg"
                              )
                            }}</i
                          >
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  depressed
                  class="universal white--text text-none mt-3"
                  @click="save"
                  :disabled="!valid"
                  :loading="loading"
                >
                  {{ $t("saveMsg") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card elevation="0" class="pa-3">
            <div><b>user Name: </b>{{ assignedUser.name }}</div>
            <div><b>Guard Name: </b>{{ assignedUser.guard_name }}</div>
            <div class="mt-3"><b>Permissions</b></div>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
  components: {},
  data() {
    return {
      view: "default",
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      lockEmail: false,
      userIndex: null,
      snackbar: false,
      inputRules: [
        (v) => !!v || "Input is required",
        (v) =>
          !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v) ||
          "Must not have any special character",
      ],
      emailRules: [
        (v) => !!v || "Input is required",
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
            v
          ) ||
          "E-mail must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Minimum 8 characters",
        (v) =>
          /(?=.*[A-Z])/.test(v) || "Must have at least one uppercase character",
        (v) => /(?=.*\d)/.test(v) || "Must have at least one number",
        (v) =>
          /([!@$%.])/.test(v) ||
          "Must have at least one special character [!@#$%.]",
      ],
      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      filteredUsers: false,
      newUser: {
        id: null,
        name: null,
        email: null,
        roleId: null,
        password: null,
      },
      assignedUser: null,
      size: 10,
      characters: "a-z,A-Z,0-9,#",
      formData: null,
    };
  },
  created() {
    if (this.$can("user_view")) {
      this.startUserLoader();
      this.fetchUsers(this.userPagination.current_page);
      this.getFormData();
    }
  },
  methods: {
    ...mapActions([
      "fetchUsers",
      "startUserLoader",
      "stopUserLoader",
      "filterUsers",
    ]),
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredUsers = true;
      this.startUserLoader();
      this.fetchUsers(1);
    },
    showUser(user) {
      this.assignedUser = user;
      this.changeView("show");
    },
    editUser(user) {
      this.lockEmail = true;
      this.newUser.id = user.id;
      this.newUser.name = user.name;
      this.newUser.email = user.email;
      if (user.roles.length != 0) {
        this.newUser.roleId = user.roles[0].id;
      }
      this.changeView("create");
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.newUser.id != null) {
          confirm(this.$t("areYouSureYouWantToUpdateUserMsg")) &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/user/" + this.newUser.id,
              data: this.newUser,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "userUpdatedSuccessfullyMsg";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchUsers(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm(this.$t("areYouSureYouWantToCreateUserMsg")) &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/user",
              data: this.newUser,
              method: "POST",
            })
              .then((resp) => {
                this.message = "userAddedSuccessfullyMsg";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchUsers(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchUsers(1);
              });
          }
        }
      }
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startUserLoader();
        apiCall({
          url: "/api/user?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterUsers(resp);
            this.searchLoader = false;
            this.stopUserLoader();
            this.filteredUsers = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopUserLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.lockEmail = false;
        this.newUser.id = null;
        this.newUser.name = null;
        this.newUser.email = null;
        this.newUser.roleId = null;
        this.newUser.password = null;
      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {
       
      }
      this.view = view;
    },
    changePage() {
      this.startUserLoader();
      if (this.filteredUsers == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/user?page=" +
            this.userPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterUsers(resp);
            this.stopUserLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopUserLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchUsers(this.userPagination.current_page);
      }
    },
    deleteUser(item) {
      confirm(this.$t("areYouSureYouWantToDeleteUserMsg")) &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.userIndex = item.id;
        apiCall({ url: "/api/user/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "userDeletedSuccesfullyMsg";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.userIndex = null;
            this.fetchUsers(this.userPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.userIndex = null;
            console.log(error.response);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/user?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    generate() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.newUser.password = password;
    },
  },
  computed: {
    ...mapGetters([
      "users",
      "userPagination",
      "userLoader",
    ]),
    length: function () {
      return Math.ceil(
        this.userPagination.total / this.userPagination.per_page
      );
    },
  },
};
</script>