const state = {
	attendanceToday: [],
	attendanceOther: [],
	attendancePerInstitutionType: [],
	attendanceRatio: [],
	revenuePerService: [],
	attendancePerInstitution: [],
	attendancePerInstitutionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	attendancePerLocation: [],
	attendancePerLocationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},

	attendanceTodayLoader: false,
	homeOtherLoader: false,
	modulePerformanceLoader: false,
	attendanceRatioLoader: false,
	attendancePerInstitutionLoader: false,
	attendancePerLocationLoader: false,
};

const getters = {
	attendanceToday: (state) => state.attendanceToday,
	attendanceOther: (state) => state.attendanceOther,
	attendancePerInstitutionType: (state) => state.attendancePerInstitutionType,
	attendanceRatio: (state) => state.attendanceRatio,
	attendancePerLocation: (state) => state.attendancePerLocation,
    attendancePerLocationPagination: (state) => state.attendancePerLocationPagination,
	attendancePerInstitution: (state) => state.attendancePerInstitution,
    attendancePerInstitutionPagination: (state) => state.attendancePerInstitutionPagination,

	attendanceTodayLoader: (state) => state.attendanceTodayLoader,
	homeOtherLoader: (state) => state.homeOtherLoader,
	modulePerformanceLoader: (state) => state.modulePerformanceLoader,
	attendanceRatioLoader: (state) => state.attendanceRatioLoader,
	attendancePerLocationLoader: (state) => state.attendancePerLocationLoader,
	attendancePerInstitutionLoader: (state) => state.attendancePerInstitutionLoader,
	attendancePerLocationLoader: (state) => state.attendancePerLocationLoader,
};

const actions = {
	async updateAttendanceToday({commit}, resp) {
		commit('updateAttendanceToday', resp)
		commit('stopAttendanceTodayLoader', resp)
	},
	async updateAttendanceOther({commit}, resp) {
		commit('updateAttendanceOther', resp)
		commit('stopHomeOtherLoader', resp)
	},
	async updateAttendancePerInstitutionType({commit}, resp) {
		commit('updateAttendancePerInstitutionType', resp)
		commit('stopAttendancePerInstitutionTypeLoader', resp)
	},
	async updateAttendanceRatio({commit}, resp) {
		commit('updateAttendanceRatio', resp)
		commit('stopAttendanceRatioLoader', resp)
	},
	async updateAttendancePerLocation({commit}, resp) {
		commit('updateAttendancePerLocation', resp)
		commit('stopAttendanceLocationLoader', resp)
	},
	async updateAttendancePerInstitution({commit}, resp) {
		commit('updateAttendancePerInstitution', resp)
		commit('stopAttendancePerInstitutionLoader', resp)
	},
	async startAttendanceTodayLoader({commit, state},resp){
		commit('startAttendanceTodayLoader', resp)
	},
	async stopAttendanceTodayLoader({commit, state},resp){
		commit('stopAttendanceTodayLoader', resp)
	},
	async startHomeOtherLoader({commit, state},resp){
		commit('startHomeOtherLoader', resp)
	},
	async stopHomeOtherLoader({commit, state},resp){
		commit('stopHomeOtherLoader', resp)
	},
	async startAttendancePerInstitutionTypeLoader({commit, state},resp){
		commit('startAttendancePerInstitutionTypeLoader', resp)
	},
	async stopAttendancePerInstitutionTypeLoader({commit, state},resp){
		commit('stopAttendancePerInstitutionTypeLoader', resp)
	},
	async startAttendanceRatioLoader({commit, state},resp){
		commit('startAttendanceRatioLoader', resp)
	},
	async stopAttendanceRatioLoader({commit, state},resp){
		commit('stopAttendanceRatioLoader', resp)
	},
	async startAttendanceLocationLoader({commit, state},resp){
		commit('startAttendanceLocationLoader', resp)
	},
	async stopAttendanceLocationLoader({commit, state},resp){
		commit('stopAttendanceLocationLoader', resp)
	},
	async startAttendancePerInstitutionLoader({commit, state},resp){
		commit('startAttendancePerInstitutionLoader', resp)
	},
	async stopAttendancePerInstitutionLoader({commit, state},resp){
		commit('stopAttendancePerInstitutionLoader', resp)
	},
};

const mutations = {
	updateAttendanceToday: (state, attendanceToday) => (state.attendanceToday = attendanceToday),
	updateAttendanceOther: (state, attendanceOther) => (state.attendanceOther = attendanceOther),
	updateAttendancePerInstitutionType: (state, attendancePerInstitutionType) => (state.attendancePerInstitutionType = attendancePerInstitutionType),
	updateAttendanceRatio: (state, attendanceRatio) => (state.attendanceRatio = attendanceRatio),
	updateAttendancePerLocation: (state, attendancePerLocation) => {
		state.attendancePerLocation = attendancePerLocation.data
		state.attendancePerLocationPagination.current_page = attendancePerLocation.current_page
		state.attendancePerLocationPagination.total = attendancePerLocation.total
		state.attendancePerLocationPagination.per_page = attendancePerLocation.per_page
	},
	updateAttendancePerInstitution: (state, attendancePerInstitution) => {
		state.attendancePerInstitution = attendancePerInstitution.data
		state.attendancePerInstitutionPagination.current_page = attendancePerInstitution.current_page
		state.attendancePerInstitutionPagination.total = attendancePerInstitution.total
		state.attendancePerInstitutionPagination.per_page = attendancePerInstitution.per_page
	},

	startAttendanceTodayLoader: (state) => state.attendanceTodayLoader = true,
	stopAttendanceTodayLoader: (state) => state.attendanceTodayLoader = false,
	startHomeOtherLoader: (state) => state.homeOtherLoader = true,
	stopHomeOtherLoader: (state) => state.homeOtherLoader = false,
	startAttendancePerInstitutionTypeLoader: (state) => state.modulePerformanceLoader = true,
	stopAttendancePerInstitutionTypeLoader: (state) => state.modulePerformanceLoader = false,
	startAttendanceRatioLoader: (state) => state.attendanceRatioLoader = true,
	stopAttendanceRatioLoader: (state) => state.attendanceRatioLoader = false,
	startAttendanceLocationLoader: (state) => state.attendancePerLocationLoader = true,
	stopAttendanceLocationLoader: (state) => state.attendancePerLocationLoader = false,
	startAttendancePerInstitutionLoader: (state) => state.attendancePerInstitutionLoader = true,
	stopAttendancePerInstitutionLoader: (state) => state.attendancePerInstitutionLoader = false,	
};

export default {
	state,
	getters,
	actions,
	mutations
}