import apiCall from '@/utils/api'

const state = {
	location: {},
	locations: [],
    locationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    locationLoader: false
};

const getters = {
    locationLoader: (state) => state.locationLoader,
	locations: (state) => state.locations,
	location: (state) => state.location,
    locationPagination: (state) => state.locationPagination
}

const actions = {
    async fetchLocations({commit}, page) {
		const response = await apiCall({url: `/api/location?page=${page}`, method: 'GET' });
		commit('setLocations', response)
        commit('stopLoader', response)
	},
    async fetchLocation({commit}, itemId) {
		const response = await apiCall({url: `/api/location/${itemId}`, method: 'GET' });
		commit('setLocation', response)
        commit('stopLoader', response)
	},
    async filterLocations({commit, state},resp){
		commit('setLocations', resp)
	},
	async startLocationLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopLocationLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setLocations: (state, locations) => {
		state.locations = locations.data
		state.locationPagination.current_page = locations.current_page
		state.locationPagination.total = locations.total
		state.locationPagination.per_page = locations.per_page
	},
	setLocation: (state, location) => {
		state.location = location
	},
    startLoader: (state) => state.locationLoader = true,
	stopLoader: (state) => state.locationLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
