import apiCall from '@/utils/api'

const state = {
	pteNgoTraining: {},
	pteNgoTrainings: [],
    pteNgoTrainingPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    pteNgoTrainingLoader: false
};

const getters = {
    pteNgoTrainingLoader: (state) => state.pteNgoTrainingLoader,
	pteNgoTrainings: (state) => state.pteNgoTrainings,
	pteNgoTraining: (state) => state.pteNgoTraining,
    pteNgoTrainingPagination: (state) => state.pteNgoTrainingPagination
}

const actions = {
    async fetchPteNgoTrainings({commit}, page) {
		const response = await apiCall({url: `/api/pte-ngo-training?page=${page}`, method: 'GET' });
		commit('setPteNgoTrainings', response)
        commit('stopPteNgoTrainingLoader', response)
	},
    async fetchPteNgoTraining({commit}, itemId) {
		const response = await apiCall({url: `/api/pte-ngo-training/${itemId}`, method: 'GET' });
		commit('setPteNgoTraining', response)
        commit('stopPteNgoTrainingLoader', response)
	},
    async filterPteNgoTrainings({commit, state},resp){
		commit('setPteNgoTrainings', resp)
	},
	async startPteNgoTrainingLoader({commit, state},resp){
		commit('startPteNgoTrainingLoader', resp)
	},
	async stopPteNgoTrainingLoader({commit, state},resp){
		commit('stopPteNgoTrainingLoader', resp)
	},
};

const mutations = {
	setPteNgoTrainings: (state, pteNgoTrainings) => {
		state.pteNgoTrainings = pteNgoTrainings.data
		state.pteNgoTrainingPagination.current_page = pteNgoTrainings.current_page
		state.pteNgoTrainingPagination.total = pteNgoTrainings.total
		state.pteNgoTrainingPagination.per_page = pteNgoTrainings.per_page
	},
	setPteNgoTraining: (state, pteNgoTraining) => {
		state.pteNgoTraining = pteNgoTraining
	},
    startPteNgoTrainingLoader: (state) => state.pteNgoTrainingLoader = true,
	stopPteNgoTrainingLoader: (state) => state.pteNgoTrainingLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
