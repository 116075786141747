import apiCall from '@/utils/api'

const state = {
	level: {},
	levels: [],
    levelPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    levelLoader: false
};

const getters = {
    levelLoader: (state) => state.levelLoader,
	levels: (state) => state.levels,
	level: (state) => state.level,
    levelPagination: (state) => state.levelPagination
}

const actions = {
    async fetchLevels({commit}, institution_id) {
		const response = await apiCall({url: `/api/level?institution_id=${institution_id}`, method: 'GET' });
		// commit('setLevels', response)
		commit('setLevel', response)
        commit('stopLoader', response)
	},
    async fetchLevel({commit}, itemId) {
		const response = await apiCall({url: `/api/level/${itemId}`, method: 'GET' });
		commit('setLevel', response)
        commit('stopLoader', response)
	},
    async filterLevels({commit, state},resp){
		commit('setLevel', resp)
	},
	async filterLevel({commit, state},resp){
		commit('setLevel', resp)
	},
	async startLevelLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopLevelLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setLevels: (state, levels) => {
		state.levels = levels.data
		state.levelPagination.current_page = levels.current_page
		state.levelPagination.total = levels.total
		state.levelPagination.per_page = levels.per_page
	},
	setLevel: (state, level) => {
		state.level = level
	},
	startLoader: (state) => state.levelLoader = true,
	stopLoader: (state) => state.levelLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
