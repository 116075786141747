import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    en: {
        //global
        dashboardMsg: 'Dashboard',
        searchMsg: 'Search',
        actionsMsg: 'Actions',
        viewMsg: 'View',
        editMsg: 'Edit',
        deleteMsg: 'Delete',
        totalMsg: 'Total',
        saveMsg: 'Save',
        statusMsg: 'Status',
        addMsg: 'Add',
        nextMsg: 'Next',
        backMsg: 'Back',
        profileMsg: 'Profile',
        loginMsg: 'Login',
        signOutMsg: 'Sign Out',
        resetMsg: 'Reset',
        monthNamesMsg: [
            { name: "January" },
            { name: "February" },
            { name: "March" },
            { name: "April" },
            { name: "May" },
            { name: "June" },
            { name: "July" },
            { name: "August" },
            { name: "September" },
            { name: "October" },
            { name: "November" },
            { name: "December" }
        ],
        dateMsg: 'Date',
        yesMsg: 'Yes',
        noMsg: 'No',
        darkModeMsg: "Dark Mode",
        lightModeMsg: "Light Mode",
        darkModeOnMsg: "Dark Mode On",
        darkModeOffMsg: "Dark Mode Off",

        //navigation drawer
        homeMsg: 'Home',

        accessControlMsg: 'Access Control',
        rolesMsg: 'Roles',
        usersMsg: 'Users',

        administrationMsg: 'Administration',
        locationsMsg: 'Locations',
        institutionsMsg: 'Institutions',
        classesMsg: 'Classes',
        classStreamsMsg: 'Class Streams',
        staffMsg: 'Staff',

        staffInstitutionsMsg: 'Staff Institutions',

        //roles
        viewRoleMsg: 'View Role',
        addRoleMsg: 'Add Role',
        editRoleMsg: 'Edit Role',
        noRoleFoundMsg: 'No role found..',
        roleNameMsg: 'Role Name',
        roleGuardMsg: 'Role Guard',
        permissionsMsg: 'Permissions',
        guardNameMsg: 'Guard Name',
        selectedMsg: 'Selected',
        pleasePickAtLeastOnePermissionMsg: 'Please pick at least one permission',
        roleUpdatedSuccessfullyMsg: 'Role updated successfully',
        roleAddedSuccessfullyMsg: 'Role Added Successfully.',
        roleDeletedSuccesfullyMsg: 'Role Deleted Succesfully',
        areYouSureYouWantToDeleteRoleMsg: 'Are you sure you want to delete role?',

        //users
        viewUserMsg: "View User",
        addUserMsg: "Add User",
        noUserFoundMsg: "No user found..",
        nameMsg: "Name",
        dateAddedMsg: "Date Added",
        roleMsg: "Role",
        accountActiveMsg: "Account Active",
        noneMsg: "None",
        editUserMsg: "Edit User",
        leaveBlankToAvoidUnintentionallyUpdatingTheUsersPasswordMsg: "Leave blank to avoid unintentionally updating the user's password",
        userUpdatedSuccessfullyMsg: "User updated successfully.",
        areYouSureYouWantToCreateUserMsg: "Are you sure you want to create user?",
        userAddedSuccessfullyMsg: "User Added Successfully.",
        anErrorOccurredEmailAlreadyTakenMsg: "An error occurred/Email already taken.",
        areYouSureYouWantToDeleteUserMsg: "Are you sure you want to delete user?",
        userDeletedSuccesfullyMsg: "User Deleted Succesfully",
        areYouSureYouWantToUpdateUserMsg: "Are you sure you want to update user?",

        //profile
        uploadProfilePictureMsg: "Upload Profile Picture",
        selectImageMsg: "Select Image",
        approveMsg: "Approve",
        submitMsg: "Submit",
        changeAccountPasswordMsg: "Change Account Password",
        currentPasswordMsg: "Current Password",
        updateMsg: "Update",
        changeImageMsg: "Change Image",
        accountCreationDateMsg: "Account Creation Date",
        accountStatusMsg: "Account Status",
        activeMsg: "Active",
        inactiveMsg: "Inactive",
        changePasswordMsg: "Change Password",
        kindlySelectAFileMsg: "Kindly Select a File",
        newPasswordAndConfirmPasswordDoNotMatchMsg: "New Password and Confirm Password do not match",
        areYouSureYouWantToChangeYourAccountPasswordMsg: "Are you sure you want to change your account password?",
        passwordUpdatedSuccessfullyMsg: "Password Updated Successfully",
        currentPasswordDoesNotMatchWithRecordsMsg: "Current Password does not match with records",
        profilePictureUploadedSuccessfullyMsg: "Profile Picture Uploaded Successfully",

        //footer
        quickLinksMsg: 'Quick Links',
        ourWorkMsg: 'Our Work',
        contactsMsg: 'Contacts',
        privacyPolicyMsg: 'Privacy Policy',
        termsOfUseMsg: 'Terms of Use',

        //locations
        
        //staff
        institutionMsg: 'Institution',
        userMsg: 'User',
        nationalityMsg: 'Nationality',
        identificationMsg: 'Identification',
        phoneNumberMsg: 'Phone Number',
        dateOfBirthMsg: 'Date of Birth',
        genderMsg: 'Gender',
        teacherTypeMsg: 'Teacher Type',
        designationMsg: 'Designation',
        academicQualificationMsg: 'Academic Qualification',
        pteNgoTrainingMsg: 'PTE Ngo Training',
        moeP1TrainingMsg: 'MOE P1 Training',
        ecdeTrainingMsg: 'ECDE Training',
        sneTrainingMsg: 'SNE Training',
        secSubTrainingMsg: 'SEC Sub Training',
        salaryMsg: 'Salary',
        startedOnMsg: 'Started On',

        //home page
        revenueTodayMsg: 'REVENUE TODAY',
        revenueYesterdayMsg: 'REVENUE YESTERDAY',
        revenueThisWeekMsg: 'REVENUE THIS WEEK',
        revenueThisMonthMsg: 'REVENUE THIS MONTH',
        CashInHandMsg: 'CASH IN HAND',
        CashAtAfrilandMsg: 'CASH AT AFRILAND',
        revenuePerModuleMsg: 'REVENUE PER MODULE',
        revenuePerPOSMsg: 'REVENUE PER POS',
        graphsMsg: 'Graphs',
        revenuePerStationMsg: 'Revenue Per Station',
        revenuePerServiceMsg: 'Revenue Per Service',
        periodMsg: 'Period',
        revenueMsg: 'Revenue',
        todayMsg: 'Today',
        yesterdayMsg: 'Yesterday',
        thisWeekMsg: 'This Week',
        thisMonthMsg: 'This Month',
    },
    fr: {
        //global
        dashboardMsg: 'Tableau de bord',
        searchMsg: 'Chercher',
        actionsMsg: 'Actions',
        viewMsg: 'Vue',
        editMsg: 'Modifier',
        deleteMsg: 'Effacer',
        totalMsg: 'Le total',
        saveMsg: 'Enregistrer',
        statusMsg: 'Statut',
        addMsg: 'Ajouter',
        nextMsg: 'Suivante',
        backMsg: 'Arrière',
        profileMsg: 'Profil',
        loginMsg: 'Connexion',
        signOutMsg: 'Se déconnecter',
        resetMsg: 'Réinitialiser',
        monthNamesMsg: [
            { name: "Janvier" },
            { name: "Février" },
            { name: "Mars" },
            { name: "Avril" },
            { name: "Peut" },
            { name: "Juin" },
            { name: "Juillet" },
            { name: "Août" },
            { name: "Septembre" },
            { name: "Octobre" },
            { name: "Novembre" },
            { name: "Décembre" }
        ],
        dateMsg: 'Date',
        yesMsg: 'Oui',
        noMsg: 'Non',
        darkModeMsg: "Mode sombre",
        lightModeMsg: "Mode lumière",
        darkModeOnMsg: "Mode sombre activé",
        darkModeOffMsg: "Mode sombre désactivé",


        //navigation drawer
        homeMsg: 'Accueil',

        accessControlMsg: "Contrôle d'accès",
        rolesMsg: 'Les Rôles',
        usersMsg: 'Utilisateurs',

        administrationMsg: 'Administration',
        locationsMsg: 'Emplacements',
        institutionsMsg: 'Établissements',
        classesMsg: 'Des classes',
        classStreamsMsg: 'Flux de classe',
        staffMsg: 'Personnel',

        staffInstitutionsMsg: 'Établissements du personnel',

        //roles
        viewRoleMsg: 'Afficher le rôle',
        addRoleMsg: 'Ajouter un rôle',
        editRoleMsg: 'Modifier le rôle',
        noRoleFoundMsg: 'Aucun rôle trouvé..',
        roleNameMsg: 'Nom de rôle',
        roleGuardMsg: 'Rôle Garde',
        permissionsMsg: 'Autorisations',
        guardNameMsg: 'Nom du gardien',
        selectedMsg: 'Choisi',
        pleasePickAtLeastOnePermissionMsg: 'Veuillez sélectionner au moins une autorisation',
        roleUpdatedSuccessfullyMsg: 'Rôle mis à jour avec succès',
        roleAddedSuccessfullyMsg: 'Rôle ajouté avec succès.',
        roleDeletedSuccesfullyMsg: 'Rôle supprimé avec succès',
        areYouSureYouWantToDeleteRoleMsg: 'Êtes-vous sûr de vouloir supprimer le rôle?',

        //users
        viewUserMsg: "Afficher l'utilisateur",
        addUserMsg: "Ajouter un utilisateur",
        noUserFoundMsg: "Aucun utilisateur trouvé..",
        nameMsg: "Nom",
        dateAddedMsg: "Date ajoutée",
        roleMsg: "Rôle",
        accountActiveMsg: "Compte Actif",
        noneMsg: "Rien",
        editUserMsg: "Modifier l'utilisateur",
        leaveBlankToAvoidUnintentionallyUpdatingTheUsersPasswordMsg: "Laissez vide pour éviter de mettre à jour involontairement le mot de passe de l'utilisateur",
        userUpdatedSuccessfullyMsg: "Utilisateur mis à jour avec succès.",
        areYouSureYouWantToCreateUserMsg: "Êtes-vous sûr de vouloir créer un utilisateur?",
        userAddedSuccessfullyMsg: "Utilisateur ajouté avec succès.",
        anErrorOccurredEmailAlreadyTakenMsg: "Une erreur s'est produite/Email déjà pris.",
        areYouSureYouWantToDeleteUserMsg: "Êtes-vous sûr de vouloir supprimer l'utilisateur?",
        userDeletedSuccesfullyMsg: "Utilisateur supprimé avec succès",
        areYouSureYouWantToUpdateUserMsg: "Êtes-vous sûr de vouloir mettre à jour l'utilisateur?",

        //profile
        uploadProfilePictureMsg: "Télécharger une photo de profil",
        selectImageMsg: "Sélectionnez une image",
        submitMsg: "Soumettre",
        changeAccountPasswordMsg: "Changer le mot de passe du compte",
        currentPasswordMsg: "Mot de passe actuel",
        updateMsg: "Mettre à jour",
        changeImageMsg: "Changer l'image",
        accountCreationDateMsg: "Date de création du compte",
        accountStatusMsg: "Statut du compte",
        activeMsg: "Actif",
        inactiveMsg: "Inactif",
        changePasswordMsg: "Changer le mot de passe",
        kindlySelectAFileMsg: "Veuillez sélectionner un fichier",
        newPasswordAndConfirmPasswordDoNotMatchMsg: "Le nouveau mot de passe et la confirmation du mot de passe ne correspondent pas",
        areYouSureYouWantToChangeYourAccountPasswordMsg: "Êtes-vous sûr de vouloir changer le mot de passe de votre compte?",
        passwordUpdatedSuccessfullyMsg: "Mot de passe mis à jour avec succès",
        currentPasswordDoesNotMatchWithRecordsMsg: "Le mot de passe actuel ne correspond pas aux enregistrements",
        profilePictureUploadedSuccessfullyMsg: "Photo de profil téléchargée avec succès",

        //footer
        quickLinksMsg: 'Liens rapides',
        ourWorkMsg: 'Notre travail',
        contactsMsg: 'Contacts',
        privacyPolicyMsg: 'Politique de confidentialité',
        termsOfUseMsg: "Conditions d'utilisation",

        //locations
        
        //staff
        institutionMsg: 'Institution',
        userMsg: 'Utilisateur',
        nationalityMsg: 'Nationalité',
        identificationMsg: 'Identification',
        phoneNumberMsg: 'Numéro de téléphone',
        dateOfBirthMsg: 'Date de naissance',
        genderMsg: 'Le sexe',
        teacherTypeMsg: "Type d'enseignant",
        designationMsg: 'La désignation',
        academicQualificationMsg: 'Qualification académique',
        pteNgoTrainingMsg: 'Formation PTE Ngo',
        moeP1TrainingMsg: 'Formation MOE P1',
        ecdeTrainingMsg: 'Formation ECDE',
        sneTrainingMsg: 'Formation SNE',
        secSubTrainingMsg: 'Formation SEC Sub',
        salaryMsg: 'Un salaire',
        startedOnMsg: "Commencé le",

        //home page
        revenueTodayMsg: "REVENU AUJOURD'HUI",
        revenueYesterdayMsg: 'REVENU HIER',
        revenueThisWeekMsg: 'REVENU CETTE SEMAINE',
        revenueThisMonthMsg: 'REVENU CE MOIS',
        CashInHandMsg: 'DU LIQUIDE EN MAIN',
        CashAtAfrilandMsg: 'CASH À AFRILAND',
        revenuePerModuleMsg: 'REVENU PAR MODULE',
        revenuePerPOSMsg: 'REVENU PAR POS',
        graphsMsg: 'Graphiques',
        revenuePerStationMsg: 'Revenu par Station',
        revenuePerServiceMsg: 'Revenu par Service',
        periodMsg: 'Période',
        revenueMsg: 'Revenu',
        todayMsg: "Aujourd'hui",
        yesterdayMsg: 'Hier',
        thisWeekMsg: 'Cette semaine',
        thisMonthMsg: 'Ce mois-ci',
    },
};

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n
