import apiCall from '@/utils/api'

const state = {
	teacherType: {},
	teacherTypes: [],
    teacherTypePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    teacherTypeLoader: false
};

const getters = {
    teacherTypeLoader: (state) => state.teacherTypeLoader,
	teacherTypes: (state) => state.teacherTypes,
	teacherType: (state) => state.teacherType,
    teacherTypePagination: (state) => state.teacherTypePagination
}

const actions = {
    async fetchTeacherTypes({commit}, page) {
		const response = await apiCall({url: `/api/teacher-type?page=${page}`, method: 'GET' });
		commit('setTeacherTypes', response)
        commit('stopTeacherTypeLoader', response)
	},
    async fetchTeacherType({commit}, itemId) {
		const response = await apiCall({url: `/api/teacher-type/${itemId}`, method: 'GET' });
		commit('setTeacherType', response)
        commit('stopTeacherTypeLoader', response)
	},
    async filterTeacherTypes({commit, state},resp){
		commit('setTeacherTypes', resp)
	},
	async startTeacherTypeLoader({commit, state},resp){
		commit('startTeacherTypeLoader', resp)
	},
	async stopTeacherTypeLoader({commit, state},resp){
		commit('stopTeacherTypeLoader', resp)
	},
};

const mutations = {
	setTeacherTypes: (state, teacherTypes) => {
		state.teacherTypes = teacherTypes.data
		state.teacherTypePagination.current_page = teacherTypes.current_page
		state.teacherTypePagination.total = teacherTypes.total
		state.teacherTypePagination.per_page = teacherTypes.per_page
	},
	setTeacherType: (state, teacherType) => {
		state.teacherType = teacherType
	},
    startTeacherTypeLoader: (state) => state.teacherTypeLoader = true,
	stopTeacherTypeLoader: (state) => state.teacherTypeLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
