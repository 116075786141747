<template>
  <div class="staffInstitutions" v-if="$can('teacher_student_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ $t(message) }}</span>
    </v-snackbar>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <div class="title">Classes</div>
        </v-flex>
        <div v-if="view == 'default'">
          <v-flex xs12 class="mt-5 mb-2">
            <v-card elevation="0" class="header">
              <v-layout row wrap>
                <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                <v-flex xs12 md4>
                  <v-text-field outlined rounded dense :label="$t('searchMsg')" append-icon="mdi-undo-variant"
                    @click:append="resetSearch()" v-on:keyup.enter="search" v-model="searchTerm" @input="enableSearch()"
                    class="background search_field my-3 mx-5"></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <!-- <div class="pa-1">
                    <v-btn
                      class="secondary text-none"
                      rounded
                      depressed
                      @click="search"
                      :loading="searchLoader"
                      :disabled="searchButtonDisabled"
                    >
                      {{ $t("searchMsg") }}
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </div> -->
                  <div align="center" class="my-4">
                    <v-btn rounded depressed class="white--text universal text-none" @click="changeView('create')"
                      v-if="$can('institution_create')">
                      Add Institution
                      <v-icon right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <div v-if="staffInstitutions.length == 0">
              <v-card elevation="0">
                <v-layout row wrap>
                  <v-flex xs12 md1>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          <v-icon large class="primary--text">
                            mdi-alert-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md11>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          No Institution Found
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-card>
            </div>
            <div v-else>
              <v-tabs show-arrows v-model="tab" class="mb-3">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab v-for="(view, index) in views" :key="index" @click="changeHomeView(view.name)">
                  <div class="text-none">{{ view.name }}</div>
                </v-tab>
              </v-tabs>


              <div class="hidden-sm-and-down">
                <div v-if="homeView == 'List'">
                  <v-progress-linear v-if="staffInstitutionLoader" height="1" indeterminate color="primary">
                  </v-progress-linear>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead class="header">
                        <tr>
                          <th class="text-left">Logo</th>
                          <th class="text-left">{{ $t("nameMsg") }}</th>
                          <th class="text-left">Location</th>
                          <th class="text-left">Sector</th>
                          <th class="text-left">Provider</th>
                          <th class="text-left">Type</th>
                          <th class="text-left">Gender</th>
                          <th class="text-left">Opened</th>

                          <th class="text-right">{{ $t("actionsMsg") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in staffInstitutions" :key="item.id">
                          <td>
                            <div class="my-2">
                              <!-- <v-img :src="item.image" :alt="item.name" height="100px"></v-img> -->
                              <v-img src="img/badge.png" max-width="40"></v-img>
                            </div>
                          </td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.location.name }}</td>
                          <td>{{ item.institution_sector.name }}</td>
                          <td>{{ item.institution_provider.name }}</td>
                          <td>{{ item.institution_type.name }}</td>
                          <td>{{ item.institution_gender.name }}</td>
                          <td>{{ item.date_opened }}</td>
                          <td>
                            <div align="right">
                              <!-- <v-tooltip top v-if="$can('teacher_student_view')">
                                <template v-slot:activator="{ on, attrs }"> -->
                              <v-btn icon @click="showInstitution(item)" class="button mr-1 universal--text"
                                v-bind="attrs" v-on="on">
                                <v-icon small> mdi-eye </v-icon>
                              </v-btn>
                              <!-- </template>
                                <span>{{ $t("viewMsg") }}</span>
                              </v-tooltip> -->
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div v-else>
                  <v-card elevation="0" class="background">
                    <v-card-text>
                      <v-progress-linear v-if="staffInstitutionLoader" height="1" indeterminate color="primary"
                        class="mr-10">
                      </v-progress-linear>
                      <div>
                        <v-map ref="myMapRef" style="position: absolute; width: 96%; height: 62vh; z-index: 2" :zoom=5
                          :center="[latitude, longitude]" :options="tileOptions">
                          <v-icondefault class="mt-5"></v-icondefault>
                          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></v-tilelayer>
                          <v-marker-cluster :options="clusterOptions">
                            <v-marker v-for="(location, index) in locationArray" :key="location.id"
                              :lat-lng="[location.institution_locations[0].latitude, location.institution_locations[0].longitude]"
                              :icon="icon" @click="showInstitution(location)">
                              <v-popup :content="location.name"></v-popup>
                              <v-tooltip>
                                <div>
                                  {{ location.name }}
                                </div>
                              </v-tooltip>
                            </v-marker>
                          </v-marker-cluster>
                        </v-map>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

              </div>
              <div class="hidden-md-and-up">
                <v-layout column>
                  <template v-for="(institution, index) in staffInstitutions">
                    <div :key="index">
                      <v-flex xs12 class="mb-2">
                        <v-card elevation="0">
                          <div class="pa-5">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs12>
                                    <div class="title primary--text">
                                      <b>{{ institution.name }}</b>
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-divider class="mt-2"></v-divider>
                              <v-flex xs12 class="mt-1">
                                <v-layout column>
                                  <v-flex xs12 class="mt-1">
                                    <div>
                                      <b>{{ $t("dateAddedMsg") }}</b>
                                    </div>
                                    <div>
                                      {{
                                          institution.created_at
                                          | moment("DD/MM/YYYY - hh:m a")
                                      }}
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="pa-1">
                                          <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                            " block @click="
                                              showInstitution(institution)
                                            " v-if="$can('teacher_student_view')">{{ $t("viewMsg") }}
                                            <v-icon right> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <v-layout row wrap>
              <v-flex xs12 md9>
                <div align="left">
                  <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                    v-model="staffInstitutionPagination.current_page" @input="changePage()" circle>
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div v-if="length != 0" align="right" class="mr-3">
                  <b class="universal--text ml-4">{{ $t("totalMsg") }}: </b>{{ staffInstitutionPagination.total |
                      formatNumber
                  }}
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card elevation="0" class="background">
            <v-card-title class="header">
              <v-img src="img/badge.png" max-width="40"></v-img>
              <v-spacer></v-spacer>
              <div>View Institution</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-tabs show-arrows v-model="institutionTab">
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab @click="activeTab = 'classes'">
                <div class="text-none">Classes</div>
              </v-tab>
              <v-tab @click="activeTab = 'details'">
                <div class="text-none">Institution Details</div>
              </v-tab>
            </v-tabs>
            <div v-if="activeTab == 'details'">
              <v-card-text>
                <div class="pa-3">
                  <v-layout column>
                    <v-flex xs12>
                      <v-layout row wrap>
                        <v-flex xs12 md6>
                          <v-layout column>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Location: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>{{ assignedInstitution.location.name }}</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Institution Name: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>{{ assignedInstitution.name }}</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Alternative Name: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div v-if="
                                    assignedInstitution.alternative_name != null
                                  ">
                                    {{ assignedInstitution.alternative_name }}
                                  </div>
                                  <div v-else>-</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Code: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>{{ assignedInstitution.code }}</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Date Opened: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div v-if="assignedInstitution.date_opened != null">
                                    {{ assignedInstitution.date_opened }}
                                  </div>
                                  <div v-else>-</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Date Closed: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div v-if="assignedInstitution.date_closed != null">
                                    {{ assignedInstitution.date_closed }}
                                  </div>
                                  <div v-else>-</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-layout column>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Classification: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution
                                          .institution_classification.name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Sector: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_sector.name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Provider: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_provider
                                          .name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Type: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_type.name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Ownership: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_ownership
                                          .name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Gender: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_gender.name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card-text>
              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Institution Contact Details</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeContactView('create')" v-if="
                  $can('institution_contact_create') &&
                  contactView == 'default' &&
                  assignedInstitution.institution_contacts.length == 0
                ">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-btn icon @click="changeContactView('default')" v-if="contactView != 'default'">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="contactView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedInstitution.institution_contacts.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                Contacts Not Added
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout column>
                        <template v-for="(
                            contact, index
                          ) in assignedInstitution.institution_contacts">
                          <div :key="index">
                            <v-flex xs12>
                              <div class="ml-3">
                                <v-layout row wrap>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-6" v-if="contact.telephone != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Telephone: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ contact.telephone }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap class="grey lighten-3">
                                              <v-flex xs12 md4>
                                                <b>Mobile: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ contact.mobile }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-6" v-if="contact.email != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Email: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{ contact.email }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6" v-if="contact.website != null">
                                            <v-layout row wrap class="grey lighten-3 mr-2">
                                              <v-flex xs12 md4>
                                                <b>Website: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  <a :href="contact.website" target="_blank"
                                                    style="text-decoration: none;">{{ contact.website }}</a>

                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-flex>
                          </div>
                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <!-- <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Institution Resources</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeResourceView('create')" v-if="
                  $can('institution_resource_create') &&
                  resourceView == 'default'
                ">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-btn icon @click="changeResourceView('default')" v-if="resourceView != 'default'">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="resourceView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedInstitution.institution_resource.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                Resources Not Added
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout column>
                        <v-flex xs12>
                          <v-tabs show-arrows v-model="resourceTab">
                            <v-tabs-slider color="primary"></v-tabs-slider>

                            <v-tab v-for="(resource, index) in assignedInstitution.institution_resource" :key="index"
                              @click="assignResource(resource)">
                              {{ index }}
                            </v-tab>
                          </v-tabs>
                        </v-flex>
                        <v-flex xs12 class="mt-7">
                          <div class="ml-3">
                            <v-layout column>
                              <template v-for="(resource, index) in institutionResources">
                                <div :key="index">
                                  <v-flex xs12 class="mb-15">
                                    <v-layout row wrap>
                                      <v-flex xs1>
                                        {{ index + 1 }}.
                                      </v-flex>
                                      <v-flex xs11>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <v-layout row wrap>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Institution Electricity: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.institution_electricity_resource.name }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Institution Water: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.institution_water_resource.name }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>No of School Feeding: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.no_of_school_feeding }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>No of Students Dewormed: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ resource.no_of_school_feeding }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 md6>
                                                <v-layout column>

                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Computers Availabe: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div v-if="resource.computers_availabe == 1">
                                                          Yes
                                                        </div>
                                                        <div v-if="resource.computers_availabe == 0">
                                                          No
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6" v-if="resource.no_of_computers != null">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>No of Computers: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ resource.no_of_computers }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6"
                                                    v-if="resource.no_of_teacher_computer_male != null">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>No of Teacher Computer Male: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ resource.no_of_teacher_computer_male }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6"
                                                    v-if="resource.no_of_teacher_computer_female != null">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>No of Teacher Computer Female: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ resource.no_of_teacher_computer_female }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-4">
                                            <v-layout row wrap>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-10">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <div class="font-weight-black">Washroom Facilities: </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-7">
                                            <v-layout row wrap>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Student Boy: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.institution_washroom_facility.student_boy }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Student Girl: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.institution_washroom_facility.student_girl }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Teacher Male: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ resource.institution_washroom_facility.teacher_male }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Teacher Female: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.institution_washroom_facility.teacher_female }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </div>
                              </template>
                            </v-layout>

                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Institution Location</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeLocationView('create')" v-if="
                  $can('institution_location_create') &&
                  locationView == 'default' &&
                  assignedInstitution.institution_locations.length == 0
                ">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-btn icon @click="changeLocationView('default')" v-if="locationView != 'default'">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="locationView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedInstitution.institution_locations.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                Location Not Added
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout column>
                        <template v-for="(
                            location, index
                          ) in assignedInstitution.institution_locations">
                          <div :key="index">
                            <v-flex xs12>
                              <div class="ml-3">
                                <v-layout row wrap>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-5" v-if="location.address != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Address: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ location.address }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-5" v-if="location.postal_code != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Postal Code: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ location.postal_code }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-5" v-if="location.location_locality_id != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Location Locality: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ location.location_locality.name }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-5" v-if="location.longitude != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Longitude: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{ location.longitude }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-5" v-if="location.latitude != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Latitude: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{ location.latitude }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-flex>
                            <v-flex xs12>
                              <v-flex xs12>
                                <div class="my-10">
                                  <v-map ref="myMapRef" style="position: absolute; width: 92%; height: 50%; z-index: 2"
                                    :zoom=15 :center="[location.latitude, location.longitude]" :options="tileOptions">
                                    <v-icondefault class="mt-5"></v-icondefault>
                                    <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></v-tilelayer>
                                    <v-marker ref="markerRef" :lat-lng="[location.latitude, location.longitude]"
                                      :icon="icon">
                                    </v-marker>
                                  </v-map>
                                </div>

                              </v-flex>
                            </v-flex>


                          </div>
                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </v-card-text> -->
            </div>
            <div v-if="activeTab == 'classes'">
              <v-layout column>
                <div v-if="classView == 'default'">
                  <v-flex xs12 class="mt-5 mb-2">
                    <v-card elevation="0" class="header">
                      <v-layout row wrap>
                        <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                        <v-flex xs12 md4>
                          <v-text-field outlined rounded dense :label="$t('searchMsg')" append-icon="mdi-undo-variant"
                            @click:append="resetLevelSearch()" v-on:keyup.enter="levelSearch" v-model="levelSearchTerm"
                            @input="enableLevelSearch()" class="background search_field my-3 mx-5"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                          <!-- <div class="pa-1">
                            <v-btn
                              class="secondary text-none"
                              rounded
                              depressed
                              @click="search"
                              :loading="searchLoader"
                              :disabled="searchButtonDisabled"
                            >
                              {{ $t("searchMsg") }}
                              <v-icon right>mdi-magnify</v-icon>
                            </v-btn>
                          </div> -->
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 class="mt-10">
                    <div v-if="staffLevelStream.length == 0">
                      <v-card elevation="0">
                        <v-layout row wrap>
                          <v-flex xs12 md1>
                            <v-container fill-height fluid>
                              <v-row align="center" justify="center">
                                <v-col class="text-center">
                                  <v-icon large class="primary--text">
                                    mdi-alert-circle-outline
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-flex>
                          <v-flex xs12 md11>
                            <v-container fill-height fluid>
                              <v-row align="center" justify="center">
                                <v-col class="text-center"> No Class Found </v-col>
                              </v-row>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </div>
                    <div v-else>

                      <v-tabs show-arrows v-model="classTab">
                        <v-tabs-slider color="primary"></v-tabs-slider>

                        <v-tab v-for="(year, index) in staffLevelStream" :key="index" @click="assignYear(year)">
                          {{ index }}
                        </v-tab>
                      </v-tabs>
                      <v-progress-linear v-if="staffLevelStreamLoader" height="1" indeterminate color="primary">
                      </v-progress-linear>
                      <div class="hidden-sm-and-down">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead class="header">
                              <tr>
                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                <th class="text-left">{{ $t("startDateMsg") }}</th>
                                <th class="text-left">{{ $t("endDateMsg") }}</th>
                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in classes" :key="item.id">
                                <td>{{ item.name }}</td>
                                <td>{{ item.start_date | moment("DD/MM/YYYY") }}</td>
                                <td>{{ item.end_date | moment("DD/MM/YYYY") }}</td>
                                <td>
                                  <div align="right">
                                    <!-- <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }"> -->
                                    <v-btn icon @click="showLevel(item)" class="button mr-1 universal--text"
                                      v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-eye </v-icon>
                                    </v-btn>
                                    <!-- </template>
                                      <span>{{ $t("viewMsg") }}</span>
                                    </v-tooltip>
                                    <v-tooltip top v-if="$can('class_edit')">
                                      <template v-slot:activator="{ on, attrs }"> -->
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                      <div class="hidden-md-and-up">
                        <v-layout column>
                          <template v-for="(level, index) in classes">
                            <div :key="index">
                              <v-flex xs12 class="mb-2">
                                <v-card elevation="0">
                                  <div class="pa-5">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <v-layout row wrap>
                                          <v-flex xs12>
                                            <div class="title primary--text">
                                              <b>{{ level.name }}</b>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-divider class="mt-2"></v-divider>
                                      <v-flex xs12 class="mt-1">
                                        <v-layout column>
                                          <v-flex xs12 class="mt-1">
                                            <div>
                                              <b>{{ $t("dateAddedMsg") }}</b>
                                            </div>
                                            <div>
                                              {{
                                                  level.created_at
                                                  | moment("DD/MM/YYYY - hh:m a")
                                              }}
                                            </div>
                                          </v-flex>
                                          <v-flex xs12 class="mt-5">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <div class="pa-1">
                                                  <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                            " block @click="showLevel(level)" v-if="$can('class_view')">{{
                                                $t("viewMsg")
                                            }}
                                                    <v-icon right> mdi-eye </v-icon>
                                                  </v-btn>
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-card>
                              </v-flex>
                            </div>
                          </template>
                        </v-layout>
                      </div>
                    </div>
                  </v-flex>
                  <!-- <v-flex xs12 class="mt-10">
                    <v-layout row wrap>
                      <v-flex xs12 md9>
                        <div align="left">
                          <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                            v-model="staffLevelStreamPagination.current_page" @input="changePage()" circle>
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div v-if="length != 0" align="right" class="mr-3">
                          <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>{{ staffLevelStreamPagination.total |
                              formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                </div>
                <div v-if="classView == 'show'">
                  <v-card elevation="0" class="background">
                    <v-card-title class="header">
                      <v-spacer></v-spacer>
                      <div>View Class</div>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="changeLevelView('default')">
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div class="pa-3 mt-5">
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md6>
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs12 md4>
                                        <b>Institution: </b>
                                      </v-flex>
                                      <v-flex xs12 md8>
                                        <div>{{ assignedLevel.institution.name }}</div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs12 md4>
                                        <b>Class Name: </b>
                                      </v-flex>
                                      <v-flex xs12 md8>
                                        <div>{{ assignedLevel.name }}</div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs12 md4>
                                        <b>Start Date: </b>
                                      </v-flex>
                                      <v-flex xs12 md8>
                                        <div>
                                          {{
                                              assignedLevel.start_date
                                              | moment("DD MMM YYYY")
                                          }}
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs12 md4>
                                        <b>End Date: </b>
                                      </v-flex>
                                      <v-flex xs12 md8>
                                        <div>
                                          {{
                                              assignedLevel.end_date
                                              | moment("DD MMM YYYY")
                                          }}
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                        </v-layout>
                      </div>
                    </v-card-text>
                    <div>
                      <v-layout column>
                        <v-flex xs12 class="mt-5">
                          <div v-if="streamView == 'default'">
                            <v-flex xs12>
                              <div v-if="assignedLevel.level_streams.length == 0">
                                <v-card elevation="0">
                                  <v-layout row wrap>
                                    <v-flex xs12 md1>
                                      <v-container fill-height fluid>
                                        <v-row align="center" justify="center">
                                          <v-col class="text-center">
                                            <v-icon large class="primary--text">
                                              mdi-alert-circle-outline
                                            </v-icon>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-flex>
                                    <v-flex xs12 md11>
                                      <v-container fill-height fluid>
                                        <v-row align="center" justify="center">
                                          <v-col class="text-center">
                                            No Class Stream Found
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-flex>
                                  </v-layout>
                                </v-card>
                              </div>
                              <div v-else>
                                <v-progress-linear v-if="levelstreamLoader" height="1" indeterminate color="primary">
                                </v-progress-linear>
                                <div class="hidden-sm-and-down">
                                  <v-simple-table>
                                    <template v-slot:default>
                                      <thead class="header">
                                        <tr>
                                          <th class="text-left">{{ $t("streamMsg") }}</th>
                                          <th class="text-right">{{ $t("actionsMsg") }}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="item in assignedLevel.level_streams" :key="item.id">
                                          <td>{{ item.name }}</td>
                                          <td>
                                            <div align="right">
                                              <!-- <v-tooltip top v-if="$can('class_stream_view')">
                                                  <template v-slot:activator="{ on, attrs }"> -->
                                              <v-btn icon
                                                @click="showLevelstream(item)" class="button mr-1 universal--text"
                                                v-bind="attrs" v-on="on">
                                                <v-icon small> mdi-eye </v-icon>
                                              </v-btn>
                                              <!-- </template>
                                                  <span>{{ $t("viewMsg") }}</span>
                                                </v-tooltip> -->
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </div>
                                <div class="hidden-md-and-up">
                                  <v-layout column>
                                    <template v-for="(levelStream, index) in assignedLevel.level_streams">
                                      <div :key="index">
                                        <v-flex xs12 class="mb-2">
                                          <v-card elevation="0">
                                            <div class="pa-5">
                                              <v-layout column>
                                                <v-flex xs12>
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div class="title primary--text">
                                                        <b>{{ levelStream.name }}</b>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-divider class="mt-2"></v-divider>
                                                <v-flex xs12 class="mt-1">
                                                  <v-layout column>
                                                    <v-flex xs12 class="mt-1">
                                                      <div>
                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                      </div>
                                                      <div>
                                                        {{
                                                            levelStream.created_at
                                                            | moment("DD/MM/YYYY - hh:m a")
                                                        }}
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12 class="mt-5">
                                                      <v-layout column>
                                                        <v-flex xs12>
                                                          <div class="pa-1">
                                                            <v-btn depressed class="
                                                primary
                                                text-none
                                                white--text
                                              " block @click="showLevelstream(levelStream)"
                                                              v-if="$can('class_stream_view')">{{ $t("viewMsg") }}
                                                              <v-icon right> mdi-eye </v-icon>
                                                            </v-btn>
                                                          </div>
                                                        </v-flex>

                                                      </v-layout>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </div>
                                          </v-card>
                                        </v-flex>
                                      </div>
                                    </template>
                                  </v-layout>
                                </div>
                              </div>
                            </v-flex>
                            <!-- <v-flex xs12 class="mt-10">
              <v-layout row wrap>
                <v-flex xs12 md9>
                  <div align="left">
                    <v-pagination
                      v-if="length != 0 && length != 1"
                      :length="length"
                      total-visible="10"
                      v-model="levelstreamPagination.current_page"
                      @input="changePage()"
                      circle
                    >
                    </v-pagination>
                  </div>
                </v-flex>
                <v-flex xs12 md3>
                  <div v-if="length != 0" align="right" class="mr-3">
                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b
                    >{{ levelstreamPagination.total | formatNumber }}
                  </div>
                </v-flex>
                
              </v-layout>
            </v-flex> -->
                          </div>
                          <div v-if="streamView == 'show'">
                            <v-card elevation="0" class="background">
                              <v-card-title class="header">
                                <v-spacer></v-spacer>
                                <div>View Class Stream</div>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="changeStreamView('default')">
                                  <v-icon> mdi-close </v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <div class="pa-3 mt-5">
                                  <v-layout column>
                                    <v-flex xs12>
                                      <v-layout row wrap>
                                        <v-flex xs12 md6>
                                          <v-layout column>
                                            <v-flex xs12 class="mt-5">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Stream Name: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div>{{ assignedLevelstream.name }}</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                          <v-layout column>
                                            <v-flex xs12>

                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </div>
                              </v-card-text>
                              <div>
              <v-progress-linear v-if="classListLoader" height="1" indeterminate color="primary"></v-progress-linear>
              <div class="hidden-sm-and-down">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="header">
                      <tr>
                        <th class="text-left">Photo</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Gender </th>
                        <th class="text-left">Date of Birth </th>
                        <th class="text-left">Nationality </th>
                        <th class="text-left">Status </th>
                        <!-- <th class="text-right">{{ $t("actionsMsg") }}</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in classList" :key="item.id">
                        <td></td>
                        <td>{{ item.first_name }} {{ item.last_name }} {{ item.other_names }}</td>
                        <td>{{ item.gender.name }}</td>
                        <td>{{ item.dob }}</td>
                        <td>{{ item.nationality.name }}</td>
                        <td>
                          <div v-if="item.status = 1">Active</div>
                          <div v-else>Inactive </div>
                        </td>
                        <!-- <td>
                          <div align="right">
                            <v-tooltip top v-if="$can('student_view')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="showStudent(item)" class="button mr-1 primary--text" v-bind="attrs"
                                  v-on="on">
                                  <v-icon small> mdi-eye </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("viewMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('student_edit')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="button mr-1 success--text" @click="editstudent(item)" v-bind="attrs"
                                  v-on="on">
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("editMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('student_archive')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="button mr-1 error--text" @click="deleteStudent(item)"
                                  :loading="loading && studentIndex == item.id" v-bind="attrs" v-on="on">
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("deleteMsg") }}</span>
                            </v-tooltip>
                          </div>
                        </td> -->
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="hidden-md-and-up">
                <v-layout column>
                  <template v-for="(student, index) in classList">
                    <div :key="index">
                      <v-flex xs12 class="mb-2">
                        <v-card elevation="0">
                          <div class="pa-5">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <div class="title primary--text">
                                      <b>{{ student.first_name }} {{ student.last_name }} {{ student.other_names }}</b>
                                    </div>
                                  </v-flex>
                                  <v-flex xs1>
                                    <!-- <v-btn depressed class="red--text" :loading="loading && studentIndex == student.id"
                                      icon @click="deleteStudent(student)" v-if="$can('user_archive')">
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn> -->
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-divider class="mt-2"></v-divider>
                              <v-flex xs12 class="mt-1">
                                <v-layout column>
                                  <v-flex xs12 class="mt-3">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Gender</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ student.gender.name }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Date of Birth</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ student.dob }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Nationality</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ student.nationality.name }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <!-- <v-flex xs12 class="mt-5">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="pa-1">
                                          <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                            " block @click="showStudent(student)" v-if="$can('student_view')">{{
                                                $t("viewMsg")
                                            }}
                                            <v-icon right> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div class="pa-1">
                                          <v-btn depressed class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                            " block @click="editstudent(student)" v-if="$can('student_edit')">{{
                                                $t("editMsg")
                                            }}
                                            <v-icon right>
                                              mdi-border-color
                                            </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex> -->
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
              </div>
            </div>
                            </v-card>
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card>
                </div>
              </v-layout>
            </div>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

html,
body {
  height: 100%;
  margin: 0;
}
</style>
<script>
import Vue from 'vue'
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

import * as Vue2Leaflet from 'vue2-leaflet'
import { latLng, Icon, icon } from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'

Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster)

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  components: {
    'v-map': Vue2Leaflet.LMap,
    'v-circle': Vue2Leaflet.LCircle,
    'v-tilelayer': Vue2Leaflet.LTileLayer,
    'v-icondefault': Vue2Leaflet.LIconDefault,
    'v-marker': Vue2Leaflet.LMarker,
    'v-popup': Vue2Leaflet.LPopup,
    'v-tooltip': Vue2Leaflet.LTooltip
  },
  data() {
    let customicon = icon(Object.assign({},
      Icon.Default.prototype.options,
      { iconUrl, shadowUrl }
    ))
    return {
      icon: customicon,
      tileOptions: {
        minZoom: 5.5,
        minNativeZoom: 1,
        detectRetina: true,
        updateWhenIdle: false,
        keepBuffer: 10
      },
      clusterOptions: {},
      maploaded: false,
      views: [
        { name: 'List' },
        { name: 'Map' }
      ],
      dragging: false,
      activeTab: 'classes',
      view: "default",
      contactView: "default",
      locationView: "default",
      resourceView: "default",
      homeView: 'List',
      loading: false,
      loadingLocation: false,
      longitude: '36.8219',
      latitude: '-1.2921',

      valid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      tab: null,
      resourceTab: null,
      institutionTab: null,
      classTab: null,

      institutionIndex: null,
      snackbar: false,
      inputRules: [(v) => !!v || "Input is required"],

      computersAvailable: [
        { id: 1, name: 'Yes' },
        { id: 0, name: 'No' },
      ],
      emailRules: [
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
            v
          ) ||
          "E-mail must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],
      websiteRules: [
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
          "URL must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],

      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      levelSearchButtonDisabled: false,
      filteredInstitutions: false,
      assignedInstitution: null,
      formData: null,
      levelFormData: null,
      fromDatePicker: false,
      toDatePicker: false,

      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      institutionResources: [],
      locationArray: [],

      //classes
      classView: "default",
      levelIndex: null,
      searchTerm: null,
      levelSearchTerm: null,
      searchLoader: false,
      levelSearchLoader: false,
      searchButtonDisabled: false,
      filteredLevels: false,
      assignedLevel: null,
      formData: null,

      fromDatePicker: false,
      toDatePicker: false,
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      classes: [],
      classList: [],
      classListLoader: false,

      //streams
      streamView: "default",
      streamSearchTerm: null,
      searchStreamLoader: false,
      searchStreamButtonDisabled: false,
      filteredLevelstreams: false,
      assignedLevelstream: null,
    };
  },
  watch: {
    toDatePicker(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  created() {
    if (this.$can("teacher_student_view")) {
      this.startStaffInstitutionLoader();
      this.fetchStaffInstitutions(this.staffInstitutionPagination.current_page);
      this.getFormData();
      this.getLocation()
    }
  },
  methods: {
    ...mapActions([
      "fetchStaffInstitutions",
      "startStaffInstitutionLoader",
      "stopStaffInstitutionLoader",
      "filterStaffInstitutions",
      "fetchStaffLevelStreams",
      "startStaffLevelStreamLoader",
      "stopStaffLevelStreamLoader",
      "filterStaffLevelStreams",
      "filterLevel"
    ]),
    assignYear(year) {
      this.classes = year
    },
    changeHomeView(view) {
      this.homeView = view
      if (view == 'Map') {
        this.startStaffInstitutionLoader
        apiCall({
          url: "/api/institution?type=map",
          method: "GET",
        })
          .then((resp) => {
            this.locationArray = resp
            this.stopStaffInstitutionLoader
          })
          .catch((error) => {
            this.message = "anErrorOccurredEmailMsg";
            this.color = "error";
            this.loading = false;
            this.snackbar = true;
            this.stopStaffInstitutionLoader
          });
      }
    },
    getLocation() {
      var vm = this
      navigator.geolocation.getCurrentPosition(function (position) {
        vm.latitude = position.coords.latitude.toString()
        vm.longitude = position.coords.longitude.toString()
      });
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    enableLevelSearch() {
      this.levelSearchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredInstitutions = true;
      this.startStaffInstitutionLoader();
      this.fetchStaffInstitutions(1);
    },
    resetLevelSearch() {
      this.levelSearchTerm = null;
      this.filteredLevels = true;
      this.startStaffLevelStreamLoader();
      this.fetchStaffLevelStreams(1);
    },
    showInstitution(institution) {
      this.assignedInstitution = institution;

      this.startStaffLevelStreamLoader()
      apiCall({
        url: "/api/staff-level?institution_id=" + institution.id,
        method: "GET"
      })
        .then((resp) => {         
          this.stopStaffLevelStreamLoader() 
          this.filterStaffLevelStreams(resp);
          if(resp.length != 0){
            this.assignYear(Object.values(resp)[Object.values(resp).length -1])
            this.classTab = Object.keys(resp).length -1
          }
        })
        .catch((error) => {
         
        });

      //this.fetchStaffLevelStreams(institution.id);
      this.changeView("show");
    },
    showLevel(level) {
      this.assignedLevel = level;
      this.changeLevelView("show");
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startStaffInstitutionLoader();
        apiCall({
          url: "/api/institution?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterStaffInstitutions(resp);
            this.searchLoader = false;
            this.stopStaffInstitutionLoader();
            this.filteredInstitutions = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopStaffInstitutionLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    levelSearch() {
      if (this.levelSearchTerm == null) {
        this.levelSearchButtonDisabled = true;
      } else {
        this.levelSearchLoader = true;
        this.levelSearchButtonDisabled = false;
        this.startStaffLevelStreamLoader();
        apiCall({
          url: "/api/level?type=search&search=" + this.levelSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterStaffLevelStreams(resp);
            this.levelSearchLoader = false;
            this.stopStaffLevelStreamLoader();
            this.filteredLevels = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.levelSearchLoader = false;
            this.stopStaffLevelStreamLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeView(view) {
      if (view == "default") {

      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {
      }
      this.view = view;
    },
    changeLevelView(classView) {
      if (classView == "default") {

      } else if (classView == "create") {
        //this.getLevelFormData();

      } else if (classView == "show") {
      }
      this.classView = classView;
    },
    changeContactView(view) {
      if (view == "default") {

      } else if (view == "create") {
        //this.getFormData();
      } else if (view == "show") {
      }
      this.contactView = view;
    },
    changeLocationView(view) {
      if (view == "default") {
        this.getLocation()
      } else if (view == "create") {
        //this.getFormData();
      } else if (view == "show") {
      }
      this.locationView = view;
    },
    changeResourceView(view) {
      if (view == "default") {

      } else if (view == "create") {
        //this.getFormData();
      } else if (view == "show") {
      }
      this.resourceView = view;
    },
    changePage() {
      this.startStaffInstitutionLoader();
      if (this.filteredInstitutions == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/institution?page=" +
            this.staffInstitutionPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterStaffInstitutions(resp);
            this.stopStaffInstitutionLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopStaffInstitutionLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchStaffInstitutions(this.staffInstitutionPagination.current_page);
      }
    },
    getFormData() {
      apiCall({
        url: "/api/institution?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    showLevelstream(levelStream) {
      this.assignedLevelstream = levelStream;
      this.classListLoader = true
      this.changeStreamView("show");
      apiCall({
        url: "/api/student-registration?type=classList&stream_id="+levelStream.id,
        method: "GET",
      })
        .then((resp) => {
          this.classList = resp;        
          this.classListLoader = false
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    changeStreamView(streamView) {
      if (streamView == "default") {

      } else if (streamView == "create") {
        //this.getFormData();
      } else if (streamView == "show") {

      }
      this.streamView = streamView;
    },
    closeFromDate() {
      this.fromDatePicker = false;
    },
    closeToDate() {
      this.toDatePicker = false;
    },
    closeYearDate(date) {
      this.$refs.yearMenu.save(date);
      this.$refs.picker.activePicker = 'YEAR'
      this.toDatePicker = false;
    },
    assignResource(resource) {
      this.institutionResources = resource
    }
  },
  computed: {
    ...mapGetters([
      "staffInstitutions",
      "staffInstitutionPagination",
      "staffInstitutionLoader",
      "staffLevelStream", "staffLevelStreamPagination", "staffLevelStreamLoader"
    ]),
    length: function () {
      return Math.ceil(
        this.staffInstitutionPagination.total / this.staffInstitutionPagination.per_page
      );
    },
    // classLength: function () {
    //   return Math.ceil(
    //     this.staffLevelStreamPagination.total / this.staffLevelStreamPagination.per_page
    //   );
    // },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
    formatFromDate() {
      if (this.newInstitution.date_opened != null) {
        const d = new Date(this.newInstitution.date_opened);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatToDate() {
      if (this.newInstitution.date_closed != null) {
        const d = new Date(this.newInstitution.date_closed);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatLevelFromDate() {
      if (this.newLevel.start_date != null) {
        const d = new Date(this.newLevel.start_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatLevelToDate() {
      if (this.newLevel.end_date != null) {
        const d = new Date(this.newLevel.end_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
  },
};
</script>
