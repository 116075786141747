<template>
    <div class="school-admins" v-if="$can('school_admin_view')">
      <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
        <span>{{ $t(message) }}</span>
      </v-snackbar>
      <v-container fluid>
        <v-layout column>
          <v-flex xs12>
            <div class="title">School Admins
              <!-- <span>
              <v-btn
                class="ma-2"
                color="primary"
                dark
                icon
                @click="changeView('create')"
                v-if="$can('school_admin_create')"
              >
                <v-icon> mdi-plus-box </v-icon>
              </v-btn>
              </span> -->
            </div>
          </v-flex>
          <div v-if="view == 'default'">
            <v-flex xs12 class="mt-5 mb-2">
              <v-card elevation="0" class="header">
                <v-layout row wrap>
                  <v-flex xs12 md4 class="hidden-sm-and-down">
                    <!-- <v-text-field
                      type="date"
                      outlined
                      rounded
                      dense
                      label="Period"
                      @click:append="resetSearch()"
                      v-on:keyup.enter="search"
                      v-model="searchTerm"
                      @input="enableSearch()"
                      class="background search_field my-3 mx-5"
                    ></v-text-field> -->
                  </v-flex>
  
                  <v-flex xs12 md4>
                    <v-text-field outlined rounded dense label="Name" append-icon="mdi-undo-variant" @click:append="resetSearch()"
                      v-on:keyup.enter="search" v-model="searchTerm" @input="enableSearch()"
                      class="background search_field my-3 mx-5"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <!-- <v-select
                      :items="formData.designations"
                      item-value="id"
                      item-text="name"
                      outlined
                      rounded
                      dense
                      label="Designation"
                      @click:append="resetSearch()"
                      v-on:keyup.enter="search"
                      v-model="searchTerm"
                      @input="enableSearch()"
                      class="background search_field my-3 mx-5"
                    ></v-select> -->
                    <div align="center" class="my-4">
                      <v-btn rounded depressed class="white--text universal text-none" @click="changeView('create')"
                        v-if="$can('school_admin_create')">
                        Add School Admin
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
  
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 class="mt-10">
              <div v-if="schoolAdmins.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="primary--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            No School Admin Found
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <v-progress-linear v-if="schoolAdminLoader" height="1" indeterminate color="primary"></v-progress-linear>
                <div class="hidden-sm-and-down">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead class="header">
                        <tr>
                          <th class="text-left">Photo</th>
                          <th class="text-left">UNEMIS Id</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Start Date</th>
                          <th class="text-left">End Date</th>
                          <th class="text-right">{{ $t("actionsMsg") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in schoolAdmins" :key="item.id">
                          <td class="pa-1">
                            <!-- <v-img class="elevation-0" :src="asset_url + item.profile_pic" max-height="140px"
                                max-width="200px"></v-img> -->
                            <div class="my-2">
                              <v-avatar size="56" v-if="item.profile_pic != null">
                              <img :src="path+'/profile_pics/'+item.profile_pic" alt="PPIC" />
                            </v-avatar>
                            <v-avatar v-else>
                              <v-icon> mdi-account-circle </v-icon>
                            </v-avatar>
                            </div>
                          </td>
                          <td>{{ item.id }}</td>
                          <td>{{ item.name }}</td>
  
                          <td>
                            <div v-if="item.school_admin_detail != null">
                              {{ item.school_admin_detail.started_on }}
                            </div>
                            <div v-else>
                              N/A
                            </div>
                          </td>
                          <td>
                            <div v-if="item.school_admin_detail != null">
                              {{ item.school_admin_detail.ended_on }}
                            </div>
                            <div v-else>
                              N/A
                            </div>
                          </td>
                          <!-- <td><i v-if="item.stafflevelstreams.length > 0">Assigned</i><i
                              v-else-if="item.stafflevelstreams == undefined">Unassigned</i></td>
                          <td> -->
                          <td>
                            <div align="right">
                              <v-tooltip top v-if="$can('school_admin_view')">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon @click="showSchoolAdmin(item)" class="button mr-1 universal--text" v-bind="attrs"
                                    v-on="on">
                                    <v-icon small> mdi-eye </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("viewMsg") }}</span>
                              </v-tooltip>
                              <v-tooltip top v-if="$can('school_admin_edit')">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon class="button mr-1 success--text" @click="editStaff(item)" v-bind="attrs"
                                    v-on="on">
                                    <v-icon small> mdi-pencil </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("editMsg") }}</span>
                              </v-tooltip>
                              <v-tooltip top v-if="$can('school_admin_archive')">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon class="button mr-1 error--text" @click="deleteSchoolAdmin(item)"
                                    :loading="loading && schoolAdminIndex == item.id" v-bind="attrs" v-on="on">
                                    <v-icon small> mdi-delete </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("deleteMsg") }}</span>
                              </v-tooltip>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div class="hidden-md-and-up">
                  <v-layout column>
                    <template v-for="(schoolAdmin, index) in schoolAdmins">
                      <div :key="index">
                        <v-flex xs12 class="mb-2">
                          <v-card elevation="0">
                            <div class="pa-5">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs11>
                                      <div class="title primary--text">
                                        <b>{{ schoolAdmin.name }}</b>
                                      </div>
                                    </v-flex>
                                    <v-flex xs1>
                                      <v-btn depressed class="red--text" :loading="loading && schoolAdminIndex == schoolAdmin.id" icon
                                        @click="deleteSchoolAdmin(schoolAdmin)" v-if="$can('school_admin_archive')">
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-divider class="mt-2"></v-divider>
                                <v-flex xs12 class="mt-1">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-3">
                                      <v-layout row wrap>
                                        <v-flex xs4>
                                          <b>UNEMIS ID</b>
                                        </v-flex>
                                        <v-flex xs8>
                                          {{ schoolAdmin.id }}
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs4>
                                          <b>Start Date</b>
                                        </v-flex>
                                        <v-flex xs8>
                                          <div v-if="schoolAdmin.school_admin_detail != null">
                                            {{ schoolAdmin.school_admin_detail.started_on }}
                                          </div>
                                          <div v-else>
                                            N/A
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs4>
                                          <b>End Date</b>
                                        </v-flex>
                                        <v-flex xs8>
                                          <div v-if="schoolAdmin.school_admin_detail != null">
                                            {{ schoolAdmin.school_admin_detail.ended_on }}
                                          </div>
                                          <div v-else>
                                            N/A
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <div>
                                            <v-btn depressed class="
                                                primary
                                                text-none
                                                white--text
                                                mt-3
                                              " block @click="showSchoolAdmin(schoolAdmin)" v-if="$can('school_admin_view')">{{
                                                  $t("viewMsg")
                                              }}
                                              <v-icon right> mdi-eye </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div>
                                            <v-btn depressed class="
                                                green
                                                darken-1
                                                text-none
                                                white--text
                                                mt-1
                                              " block @click="editStaff(schoolAdmin)" v-if="$can('school_admin_edit')">{{
                                                  $t("editMsg")
                                              }}
                                              <v-icon right>
                                                mdi-border-color
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </div>
            </v-flex>
            <v-flex xs12 class="mt-10">
              <v-layout row wrap>
                <v-flex xs12 md9>
                  <div align="left">
                    <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                      v-model="schoolAdminPagination.current_page" @input="changePage()" circle>
                    </v-pagination>
                  </div>
                </v-flex>
                <v-flex xs12 md3>
                  <div v-if="length != 0" align="right" class="mr-3">
                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>{{ schoolAdminPagination.total | formatNumber }}
                  </div>
                </v-flex>
  
              </v-layout>
            </v-flex>
          </div>
          <div v-if="view == 'create'">
            <v-flex class="mt-2">
              <v-card elevation="0" class="background">
                <v-card-title class="header">
                  <v-spacer></v-spacer>
                  <div v-if="newSchoolAdmin.id == null">Add School Admin</div>
                  <div v-else>Edit School Admin</div>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="changeView('default')">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-layout row wrap class="mt-5">
                      <!-- <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>{{ $t("institutionMsg") }}*</div>
                          <v-select :items="formData.institutions" class="text_field background" item-value="id"
                            item-text="name" outlined v-model="newSchoolAdmin.institution_id" dense :rules="inputRules">
                          </v-select>
                        </div>
                      </v-flex> -->
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>{{ $t("nameMsg") }}<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense v-model="newSchoolAdmin.name"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>{{ $t("emailAddressMsg") }}<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense v-model="newSchoolAdmin.email"
                            :disabled="lockEmail" :rules="emailRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4 v-if="newSchoolAdmin.id == null">
                        <div class="pa-3">
                          <div>{{ $t("passwordMsg") }}*</div>
                          <v-text-field class="text_field background" outlined dense v-model="newSchoolAdmin.password"
                            :rules="passwordRules" append-icon="mdi-autorenew" @click:append="generate()"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4 v-if="newSchoolAdmin.id != null">
                        <div class="pa-3">
                          <div>{{ $t("passwordMsg") }}</div>
                          <v-text-field class="text_field background" outlined dense v-model="newSchoolAdmin.password"
                            append-icon="mdi-autorenew" @click:append="generate()"></v-text-field>
                          <div>
                            <i>**{{
                                $t(
                                  "leaveBlankToAvoidUnintentionallyUpdatingTheUsersPasswordMsg"
                                )
                            }}</i>
                          </div>
                        </div>
                      </v-flex>
                      <!-- <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>{{ $t("userMsg") }}*</div>
                          <v-select :items="formData.users" class="text_field background" item-value="id" item-text="name"
                            outlined v-model="newSchoolAdmin.user_id" dense :rules="inputRules"></v-select>
                        </div>
                      </v-flex> -->
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>{{ $t("nationalityMsg") }}*</div>
                          <v-select :items="formData.nationalities" class="text_field background" item-value="id"
                            item-text="name" outlined v-model="newSchoolAdmin.nationality_id" dense :rules="inputRules">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>{{ $t("identificationMsg") }}<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense v-model="newSchoolAdmin.identification"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>{{ $t("phoneNumberMsg") }}<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense v-model="newSchoolAdmin.phone_number"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>{{ $t("dateOfBirthMsg") }}<span class="error--text">*</span></div>
                          <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                            v-model="dobDatePicker" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatDobDate" slot="activator" readonly v-bind="attrs"
                                v-on="on" class="text_field background" :rules="inputRules">
                              </v-text-field>
                            </template>
                            <v-date-picker v-model="newSchoolAdmin.date_of_birth" :max="minLegalAge" @change="closeDobDate">
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>{{ $t("genderMsg") }}<span class="error--text">*</span></div>
                          <v-select :items="formData.genders" class="text_field background" item-value="id"
                            item-text="name" outlined v-model="newSchoolAdmin.gender_id" dense :rules="inputRules"></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>Start Date<span class="error--text">*</span></div>
                          <!-- <v-text-field class="text_field background" outlined dense type="date"
                            v-model="newSchoolAdmin.started_on" :rules="inputRules"></v-text-field> -->
                          <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                            v-model="startDatePicker" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatStartDate" slot="activator" readonly
                                v-bind="attrs" v-on="on" class="text_field background" :rules="inputRules">
                              </v-text-field>
                            </template>
                            <v-date-picker v-model="newSchoolAdmin.started_on" :max="dateToday" @change="closeStartDate">
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>End Date</div>
                          <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                            v-model="endDatePicker" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatEndDate" slot="activator" readonly v-bind="attrs"
                                v-on="on" class="text_field background">
                              </v-text-field>
                            </template>
                            <v-date-picker v-model="newSchoolAdmin.ended_on" :max="dateToday" @change="closeEndDate">
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex xs12>
  
                      </v-flex>
                
                    </v-layout>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn rounded depressed class="universal white--text text-none mt-3" @click="save" :disabled="!valid"
                    :loading="loading">
                    {{ $t("saveMsg") }}
                    <v-icon right> mdi-content-save </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </div>
          <div v-if="view == 'show'">
            <v-card elevation="0" class="pa-3">
              <v-card-title class="header">
                <v-spacer></v-spacer>
                <div>View School Admin </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-tabs show-arrows v-model="tab">
                <v-tabs-slider color="primary"></v-tabs-slider>
  
                <v-tab @click="activeTab = 'personal details'">
                  <div class="text-none">Personal Details</div>
                </v-tab>
                <v-tab @click="activeTab = 'institutions'" v-if="$can('school_admin_assign')">
                  <div class="text-none">Institutions</div>
                </v-tab>
              </v-tabs>
              <div v-if="activeTab == 'personal details'">
                <v-card-text>
                  <v-layout column>
                    <v-flex xs12>
                      <v-layout row wrap>
                        <v-flex xs12 md2>
                          <div class="ma-5">
                          <v-img
                            v-if="assignedSchoolAdmin.profile_pic == null"
                            aspect-ratio="0.9"
                            contain
                            src="/img/default_pic.png"
                          ></v-img>
                          <v-img
                            v-else
                            aspect-ratio="0.9"
                            contain
                            :src="path+'/profile_pics/'+assignedSchoolAdmin.profile_pic"
                          ></v-img>
                            
                        </div>
                        </v-flex>
                        <v-flex xs12 md10>
                          <div class="pa-3">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Name: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>{{ assignedSchoolAdmin.name }}</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap class="grey lighten-3">
                                          <v-flex xs12 md4>
                                            <b>Email: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>{{ assignedSchoolAdmin.email }}</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6" v-if="assignedSchoolAdmin.school_admin_detail != null">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Gender: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedSchoolAdmin.school_admin_detail.gender.name }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6" v-if="assignedSchoolAdmin.school_admin_detail != null">
                                        <v-layout row wrap class="grey lighten-3">
                                          <v-flex xs12 md4>
                                            <b>Date of Birth: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedSchoolAdmin.school_admin_detail.date_of_birth }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6" v-if="assignedSchoolAdmin.school_admin_detail != null">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Start Date: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedSchoolAdmin.school_admin_detail.started_on }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6" v-if="assignedSchoolAdmin.school_admin_detail != null">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>End Date: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedSchoolAdmin.school_admin_detail.ended_on }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-6" v-if="assignedSchoolAdmin.school_admin_detail != null">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Nationality: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedSchoolAdmin.school_admin_detail.nationality.name }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6" v-if="assignedSchoolAdmin.school_admin_detail != null">
                                        <v-layout row wrap class="grey lighten-3">
                                          <v-flex xs12 md4>
                                            <b>Identification: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedSchoolAdmin.school_admin_detail.identification }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6" v-if="assignedSchoolAdmin.school_admin_detail != null">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Phone Number: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>{{ assignedSchoolAdmin.school_admin_detail.phone_number }}</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6" v-if="assignedSchoolAdmin.school_admin_detail != null">
                                        <v-layout row wrap class="grey lighten-3">
                                          <v-flex xs12 md4>
                                            <b class="grey--text text--lighten-3"> .</b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div class="grey--text text--lighten-3">. </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      
                    </v-flex>
                  </v-layout>
  
                </v-card-text>
            
              </div>
              <div v-if="activeTab == 'institutions'">
                <v-card-title class="header mt-5">
                  <v-spacer></v-spacer>
                  <div>Institution Assignments</div>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="changeInstitutionView('create')" v-if="
                    $can('school_admin_assign') &&
                    institutionView == 'default'
                  ">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </v-btn>
  
                  <v-btn icon @click="changeInstitutionView('default')" v-if="institutionView != 'default'">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <div v-if="institutionView == 'default'">
                    <div class="pa-3 mt-5">
                      <div v-if="assignedSchoolAdmin.school_admin_assignment == null">
                        <v-layout row wrap>
                          <v-flex xs12 md1>
                            <v-container fill-height fluid>
                              <v-row align="center" justify="center">
                                <v-col class="text-center">
                                  <v-icon large class="primary--text">
                                    mdi-alert-circle-outline
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-flex>
                          <v-flex xs12 md11>
                            <v-container fill-height fluid>
                              <v-row align="center" justify="center">
                                <v-col class="text-center">
                                  No Institution Assigned
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </div>
                      <div v-else>
                        <v-layout column>
                          <v-flex xs12 class="mt-7">
                            <div class="ml-3">
                              <v-layout column>
                                  <div>
                                    <v-flex xs12 class="mb-15">
                                      <v-layout row wrap>
                                        <v-flex xs11>
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs12 md6>
                                                  <v-layout column>
                                                    <v-flex xs12 class="mt-6">
                                                      <v-layout row wrap>
                                                        <v-flex xs12 md6>
                                                          <b>Institution: </b>
                                                        </v-flex>
                                                        <v-flex xs12 md6>
                                                          <div>{{ assignedSchoolAdmin.school_admin_assignment.institution.name }}</div>
                                                        </v-flex>
                                                      </v-layout>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 md6>
                                                  <v-layout column>
                                                    <v-flex xs12 class="mt-6">
                                                      <v-layout row wrap>
                                                        <v-flex xs12 md6>
                                                          <b>Assignment Date: </b>
                                                        </v-flex>
                                                        <v-flex xs12 md6>
                                                          <div>
                                                            {{ assignedSchoolAdmin.school_admin_assignment.created_at }}
                                                          </div>
                                                        </v-flex>
                                                      </v-layout>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
  
  
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs1>
                                          <!-- <v-btn icon @click="editResource(resource)"
                                            v-if="$can('institution_resource_edit')">
                                            <v-icon small class="green--text">mdi-pencil</v-icon>
                                          </v-btn> -->
                                          <v-btn icon @click="deleteAssignment(assignedSchoolAdmin.school_admin_assignment)"
                                            v-if="$can('staff_assignment_archive')"
                                            :loading="loading && schoolAdminIndex == assignedSchoolAdmin.school_admin_assignment.id">
                                            <v-icon small class="red--text">mdi-delete</v-icon>
                                          </v-btn>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </div>
                               
                              </v-layout>
  
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                    </div>
                  </div>
                  <div v-if="institutionView == 'create'" class="mt-3">
                    <v-form v-model="valid" ref="assignForm" lazy-validation>
                      <v-layout row wrap>
                        <v-flex xs12 md6>
                          <div class="pa-3">
                            <div>Institution Type*</div>
                            <v-select :items="formData.institutionTypes" class="text_field background" item-value="id"
                              item-text="name" outlined v-model="assignSchoolAdmin.institution_type_id" dense :rules="inputRules"
                              @input="populateInstitutions()">
                            </v-select>
                          </div>
                        </v-flex>
                        <v-flex xs12 md6>
                          <div class="pa-3">
                            <div>Institution*</div>
                            <v-select :items="institutions" class="text_field background" item-value="id"
                              item-text="name" outlined v-model="assignSchoolAdmin.institution_id" dense :rules="inputRules"
                              >
                            </v-select>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="right">
                            <v-btn rounded depressed class="universal white--text text-none mt-8" @click="saveAssign"
                              :disabled="!valid" :loading="loading">
                              {{ $t("saveMsg") }}
                              <v-icon right> mdi-content-save </v-icon>
                            </v-btn>
                          </div>
  
                        </v-flex>
  
  
                      </v-layout>
                    </v-form>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </div>
        </v-layout>
      </v-container>
    </div>
  </template>
  <style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  </style>
  <script>
  import apiCall from "@/utils/api";
  import { mapGetters, mapState, mapActions } from "vuex";
  
  export default {
    components: {},
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
        view: "default",
        activeTab: 'personal details',
        loading: false,
        valid: true,
        message: "",
        color: "",
        delete: false,
        confirm: false,
        lockEmail: false,
        size: 10,
        characters: "a-z,A-Z,0-9,#",
        dobDatePicker: false,
        startDatePicker: false,
        endDatePicker: false,
  
        schoolAdminIndex: null,
        snackbar: false,
        inputRules: [
          (v) => !!v || "Input is required"
        ],
        emailRules: [
          (v) => !!v || "Input is required",
          (v) =>
            !v ||
            /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
              v
            ) ||
            "E-mail must be valid",
          (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
        ],
        passwordRules: [
          (v) => !!v || "Password is required",
          (v) => (v && v.length >= 8) || "Minimum 8 characters",
          (v) =>
            /(?=.*[A-Z])/.test(v) || "Must have at least one uppercase character",
          (v) => /(?=.*\d)/.test(v) || "Must have at least one number",
          (v) =>
            /([!@$%{}<>~%#|.])/.test(v) ||
            "Must have at least one special character [!@#$%.]",
        ],
        asset_url: process.env.VUE_APP_API_URL,
        searchTerm: null,
        searchLoader: false,
        searchButtonDisabled: false,
        filteredStaffs: false,
        files: {},
        genderDistribution: [23, 77],
        chartOptions: {
          chart: {
            width: 150,
            type: 'donut',
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 270
            }
          },
          dataLabels: {
            enabled: false
          },
          fill: {
            type: 'gradient',
          },
          legend: {
            customLegendItems: ['male', 'female'],
          },
          labels: ['male', 'female'],
          colors: ['#F1571E', '#1BE27F'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 100
              },
              legend: {
                position: 'bottom',
                customLegendItems: ['male', 'female'],
              }
            }
          }]
        },
        newSchoolAdmin: {
          id: null,
          name: null,
          email: null,
          password: null,
          nationality_id: null,
          identification: null,
          phone_number: null,
          date_of_birth: null,
          gender_id: null,
          institution_id: null,
          user_id: null,
          started_on: null,
          ended_on: null,
          profile_pic: null
  
        },
        assignSchoolAdmin: {
          user_id: null,
          institution_type_id: null,
          institution_id: null,
        },
        assignedSchoolAdmin: null,
        formData: null,
        monthNames: [
          { name: "January" },
          { name: "February" },
          { name: "March" },
          { name: "April" },
          { name: "May" },
          { name: "June" },
          { name: "July" },
          { name: "August" },
          { name: "September" },
          { name: "October" },
          { name: "November" },
          { name: "December" },
        ],
        months: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ],
        tab: null,
        classTab: null,
        institutionView: 'default',
        activeAssignments: null,
        institutions: [],
      };
    },
    created() {
      if (this.$can("school_admin_view")) {
        this.startSchoolAdminLoader();
        this.fetchSchoolAdmins(this.schoolAdminPagination.current_page);
        this.getFormData();
      }
    },
    methods: {
      ...mapActions([
        "fetchSchoolAdmins",
        "startSchoolAdminLoader",
        "stopSchoolAdminLoader",
        "filterSchoolAdmins",
      ]),
      populateInstitutions(){
        let x = this.formData.institutionTypes.find(
          (institutionType) => institutionType.id == this.assignSchoolAdmin.institution_type_id
        );
  
        this.institutions = x.institutions;
      },
      assignAssignment(assignment) {
        this.activeAssignments = assignment
      },

      enableSearch() {
        this.searchButtonDisabled = false;
      },
      resetSearch() {
        this.searchTerm = null;
        this.filteredStaffs = true;
        this.startSchoolAdminLoader();
        this.fetchSchoolAdmins(1);
      },
      showSchoolAdmin(schoolAdmin) {
        this.assignedSchoolAdmin = schoolAdmin;
        this.changeView("show");
      },

      editStaff(schoolAdmin) {
        console.log("schoolAdmin", schoolAdmin)
        this.newSchoolAdmin.id = schoolAdmin.id;
        this.newSchoolAdmin.name = schoolAdmin.name
        this.newSchoolAdmin.email = schoolAdmin.email
        this.newSchoolAdmin.user_id = schoolAdmin.id;
  
        if (schoolAdmin.school_admin_detail != null) {
          this.newSchoolAdmin.nationality_id = schoolAdmin.school_admin_detail.nationality_id;
          this.newSchoolAdmin.identification = schoolAdmin.school_admin_detail.identification;
          this.newSchoolAdmin.phone_number = schoolAdmin.school_admin_detail.phone_number;
          this.newSchoolAdmin.date_of_birth = schoolAdmin.school_admin_detail.date_of_birth;
          this.newSchoolAdmin.gender_id = schoolAdmin.school_admin_detail.gender_id;
          
          this.newSchoolAdmin.started_on = schoolAdmin.school_admin_detail.started_on;
          this.newSchoolAdmin.ended_on = schoolAdmin.school_admin_detail.ended_on;
        }
        this.changeView("create");
  
      },
  
      save() {
        if (this.$refs.form.validate()) {
          if (this.newSchoolAdmin.id != null) {
            confirm('Are you sure you want to update schoolAdmin details?') && (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              // fD.append("_method", "PUT");
              apiCall({
                url: "/api/school-admin/" + this.newSchoolAdmin.id,
                data: this.newSchoolAdmin,
                method: "PUT"
              })
                .then((resp) => {
                  this.message = "School Admin Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("default");
                  this.fetchSchoolAdmins(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred/Email Already Used";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {
            confirm('Are you sure you want to create schoolAdmin details?') && (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/school-admin",
                data: this.newSchoolAdmin,
                method: "POST",
              })
                .then((resp) => {
                  this.message = "School Admin Added Successfully";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.changeView("default");
                  this.fetchSchoolAdmins(1);
                })
                .catch((error) => {
                  this.message = "anErrorOccurredEmailMsg";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          }
        }
      },
      search() {
        if (this.searchTerm == null) {
          this.searchButtonDisabled = true;
        } else {
          this.searchLoader = true;
          this.searchButtonDisabled = false;
          this.startSchoolAdminLoader();
          apiCall({
            url: "/api/school-admin?type=search&search=" + this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterSchoolAdmins(resp);
              this.searchLoader = false;
              this.stopSchoolAdminLoader();
              this.filteredStaffs = true;
            })
            .catch((error) => {
              console.log(error.response);
              this.searchLoader = false;
              this.stopSchoolAdminLoader();
              this.message = "anErrorOccurredMsg";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      saveAssign() {
        if (this.$refs.assignForm.validate()) {
          confirm('Are you sure you want to assign institution?') && (this.confirm = true);
          if (this.confirm) {
            this.assignSchoolAdmin.user_id = this.assignedSchoolAdmin.id
            this.loading = true;
            apiCall({
              url: "/api/school-admin-assignment",
              data: this.assignSchoolAdmin,
              method: "POST",
              multipart: true
            })
              .then((resp) => {
                this.message = "Assignment Successful";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.assignedSchoolAdmin.school_admin_assignment = resp
                this.changeInstitutionView("default");
  
                this.fetchSchoolAdmins(this.schoolAdminPagination.current_page);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
  
        }
      },
      changeView(view) {
        if (view == "default") {
          this.newSchoolAdmin.id = null
          this.newSchoolAdmin.name = null
          this.newSchoolAdmin.email = null
          this.newSchoolAdmin.password = null
          this.newSchoolAdmin.nationality_id = null;
          this.newSchoolAdmin.identification = null;
          this.newSchoolAdmin.phone_number = null;
          this.newSchoolAdmin.date_of_birth = null;
          this.newSchoolAdmin.gender_id = null;
          
          this.newSchoolAdmin.institution_id = null;
          this.newSchoolAdmin.user_id = null;
          this.newSchoolAdmin.started_on = null;
          this.newSchoolAdmin.ended_on = null;
  
        } else if (view == "create") {
          this.getFormData();
        } else if (view == "show") {
  
        }
        this.view = view;
      },
      changeInstitutionView(view) {
        if (view == "default") {
          this.assignSchoolAdmin.id = null;
          this.assignSchoolAdmin.institution_type_id = null;
          this.assignSchoolAdmin.institution_id = null;
  
        } else if (view == "create") {
          this.getFormData();
        } else if (view == "show") {
  
        }
        this.institutionView = view;
      },
      changePage() {
        this.startSchoolAdminLoader();
        if (this.filteredStaffs == true) {
          this.searchButtonDisabled = false;
          apiCall({
            url:
              "/api/school-admin?page=" +
              this.schoolAdminPagination.current_page +
              "&type=search&search=" +
              this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterSchoolAdmins(resp);
              this.stopSchoolAdminLoader();
            })
            .catch((error) => {
              console.log(error.response);
              this.stopSchoolAdminLoader();
              this.message = "anErrorOccurredMsg";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          this.fetchSchoolAdmins(this.schoolAdminPagination.current_page);
        }
      },
      deleteSchoolAdmin(item) {
        confirm("Are You Sure You Want to Delete School Admin") && (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.schoolAdminIndex = item.id;
          apiCall({ url: "/api/user/" + item.id, method: "DELETE" })
            .then((resp) => {
              this.message = "School Admin Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.schoolAdminIndex = null;
              this.fetchSchoolAdmins(this.schoolAdminPagination.current_page);
            })
            .catch((error) => {
              this.message = "anErrorOccurredMsg";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.schoolAdminIndex = null;
              console.log(error.response);
            });
        }
      },
      deleteAssignment(item) {
        confirm("Are You Sure You Want to Delete Assignment") && (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.schoolAdminIndex = item.id;
          apiCall({ url: "/api/school-admin-assignment/" + item.id, method: "DELETE" })
            .then((resp) => {
              this.message = "Assignment Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.schoolAdminIndex = null;

              this.assignedSchoolAdmin.school_admin_assignment = null
  
              this.fetchSchoolAdmins(this.schoolAdminPagination.current_page);
            })
            .catch((error) => {
              this.message = "anErrorOccurredMsg";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.schoolAdminIndex = null;
              console.log(error.response);
            });
        }
      },
      deleteStaffProfQualification(item) {
        confirm("Are You Sure You Want to Delete Professional Qualification") && (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.schoolAdminIndex = item.id;
          apiCall({ url: "/api/school-admin-prof-qualification/" + item.id, method: "DELETE" })
            .then((resp) => {
              this.message = "Professional Qualification Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.schoolAdminIndex = null;
  
              const index = this.assignedSchoolAdmin.staff_prof_qualifications.indexOf(item);
              if (index > -1) {
                this.assignedSchoolAdmin.staff_prof_qualifications.splice(index, 1);
              }
              this.fetchSchoolAdmins(this.schoolAdminPagination.current_page);
            })
            .catch((error) => {
              this.message = "anErrorOccurredMsg";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.schoolAdminIndex = null;
              console.log(error.response);
            });
        }
      },
      getFormData() {
        apiCall({
          url: "/api/school-admin?type=formData",
          method: "GET",
        })
          .then((resp) => {
            this.formData = resp;
          })
          .catch((error) => {
            this.message = "anErrorOccurredCouldntGetFormDataMsg";
            this.color = "error";
          });
      },
      generate() {
        let charactersArray = this.characters.split(",");
        let CharacterSet = "";
        let password = "";
  
        if (charactersArray.indexOf("a-z") >= 0) {
          CharacterSet += "abcdefghijklmnopqrstuvwxyz";
        }
        if (charactersArray.indexOf("A-Z") >= 0) {
          CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }
        if (charactersArray.indexOf("0-9") >= 0) {
          CharacterSet += "0123456789";
        }
        if (charactersArray.indexOf("#") >= 0) {
          CharacterSet += "![]{}()%&*$#^<>~@|";
        }
  
        for (let i = 0; i < this.size; i++) {
          password += CharacterSet.charAt(
            Math.floor(Math.random() * CharacterSet.length)
          );
        }
        this.newSchoolAdmin.password = password;
      },
      closeDobDate() {
        this.dobDatePicker = false;
      },
      closeStartDate() {
        this.startDatePicker = false;
      },
      closeEndDate() {
        this.endDatePicker = false;
      },
    },
    computed: {
      ...mapGetters([
        "schoolAdmins",
        "schoolAdminPagination",
        "schoolAdminLoader",
      ]),
      length: function () {
        return Math.ceil(
          this.schoolAdminPagination.total / this.schoolAdminPagination.per_page
        );
      },
      minLegalAge() {
        var today = new Date();
  
        var timestamp =
          (today.getFullYear() - 16) +
          "-" +
          this.months[today.getMonth()] +
          "-" +
          ("0" + today.getDate()).slice(-2);
        return timestamp;
      },
      dateToday() {
        var today = new Date();
  
        var timestamp =
          (today.getFullYear()) +
          "-" +
          this.months[today.getMonth()] +
          "-" +
          ("0" + today.getDate()).slice(-2);
        return timestamp;
      },
      formatDobDate() {
        if (this.newSchoolAdmin.date_of_birth != null) {
          const d = new Date(this.newSchoolAdmin.date_of_birth);
          const year = d.getFullYear();
          const date = d.getDate();
          const month = d.getMonth();
  
          return date + " " + this.monthNames[month].name + " " + year;
        }
      },
      formatStartDate() {
        if (this.newSchoolAdmin.started_on != null) {
          const d = new Date(this.newSchoolAdmin.started_on);
          const year = d.getFullYear();
          const date = d.getDate();
          const month = d.getMonth();
  
          return date + " " + this.monthNames[month].name + " " + year;
        }
      },
      formatEndDate() {
        if (this.newSchoolAdmin.ended_on != null) {
          const d = new Date(this.newSchoolAdmin.ended_on);
          const year = d.getFullYear();
          const date = d.getDate();
          const month = d.getMonth();
  
          return date + " " + this.monthNames[month].name + " " + year;
        }
      },
      
    },
  };
  </script>
  