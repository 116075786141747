import apiCall from '@/utils/api'

const state = {
	country: {},
	countries: [],
    countryPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    countryLoader: false
};

const getters = {
    countryLoader: (state) => state.countryLoader,
	countries: (state) => state.countries,
	country: (state) => state.country,
    countryPagination: (state) => state.countryPagination
}

const actions = {
    async fetchCountries({commit}, page) {
		const response = await apiCall({url: `/api/country?page=${page}`, method: 'GET' });
		commit('setCountries', response)
        commit('stopCountryLoader', response)
	},
    async fetchCountry({commit}, itemId) {
		const response = await apiCall({url: `/api/country/${itemId}`, method: 'GET' });
		commit('setCountry', response)
        commit('stopCountryLoader', response)
	},
    async filterCountries({commit, state},resp){
		commit('setCountries', resp)
	},
	async startCountryLoader({commit, state},resp){
		commit('startCountryLoader', resp)
	},
	async stopCountryLoader({commit, state},resp){
		commit('stopCountryLoader', resp)
	},
};

const mutations = {
	setCountries: (state, countries) => {
		state.countries = countries.data
		state.countryPagination.current_page = countries.current_page
		state.countryPagination.total = countries.total
		state.countryPagination.per_page = countries.per_page
	},
	setCountry: (state, country) => {
		state.country = country
	},
    startCountryLoader: (state) => state.countryLoader = true,
	stopCountryLoader: (state) => state.countryLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
