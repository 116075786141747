import apiCall from '@/utils/api'

const state = {
	locationLocality: {},
	locationLocalities: [],
    locationLocalityPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    locationLocalityLoader: false
};

const getters = {
    locationLocalityLoader: (state) => state.locationLocalityLoader,
	locationLocalities: (state) => state.locationLocalities,
	locationLocality: (state) => state.locationLocality,
    locationLocalityPagination: (state) => state.locationLocalityPagination
}

const actions = {
    async fetchLocationLocalities({commit}, page) {
		const response = await apiCall({url: `/api/location-locality?page=${page}`, method: 'GET' });
		commit('setLocationLocalities', response)
        commit('stopLocationLocalityLoader', response)
	},
    async fetchLocationLocality({commit}, itemId) {
		const response = await apiCall({url: `/api/location-locality/${itemId}`, method: 'GET' });
		commit('setLocationLocality', response)
        commit('stopLocationLocalityLoader', response)
	},
    async filterLocationLocalities({commit, state},resp){
		commit('setLocationLocalities', resp)
	},
	async startLocationLocalityLoader({commit, state},resp){
		commit('startLocationLocalityLoader', resp)
	},
	async stopLocationLocalityLoader({commit, state},resp){
		commit('stopLocationLocalityLoader', resp)
	},
};

const mutations = {
	setLocationLocalities: (state, locationLocalities) => {
		state.locationLocalities = locationLocalities.data
		state.locationLocalityPagination.current_page = locationLocalities.current_page
		state.locationLocalityPagination.total = locationLocalities.total
		state.locationLocalityPagination.per_page = locationLocalities.per_page
	},
	setLocationLocality: (state, locationLocality) => {
		state.locationLocality = locationLocality
	},
    startLocationLocalityLoader: (state) => state.locationLocalityLoader = true,
	stopLocationLocalityLoader: (state) => state.locationLocalityLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
