<template>
  <div class="home">
    <div>
      <v-container>
        <v-layout column>
          <v-flex xs12>
            <div class="title">Dashboard</div>
          </v-flex>
          <v-flex xs12>
            <v-tabs show-arrows="">
              <v-tabs-slider color="primary"></v-tabs-slider>
              <template v-for="(allAttendancePeriod, index) in allAttendancePeriods">
                <v-tab class="text-none" @click="activePeriod(allAttendancePeriod.id)" :key=""index>{{
                  allAttendancePeriod.name
                }}</v-tab>
              </template>
            </v-tabs>
          </v-flex>
          <v-flex xs12 class="my-5 mx-1">
            <v-layout row wrap>
              <v-flex xs12 md3>
                <div class="pa-2">
                  <v-progress-linear v-if="attendanceTodayLoader" height="1" indeterminate color="primary">
                  </v-progress-linear>
                  <v-card elevation="0" class="pa-5 header">
                    <div class="pa-2">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center" class="grey--text mb-7">
                            <b>Attendance Today</b>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout row wrap>
                            <v-flex xs9>
                              <template v-for="(attendanceTodayHeader) in attendanceToday">
                                <template v-for="(attendance, index) in attendanceTodayHeader">
                                  <div class="ml-2 mt-4" :key="index" v-if="index=='Total'">
                                    <v-layout row wrap>
                                      <v-flex xs5>
                                        <b>{{index}}:</b>
                                      </v-flex>
                                      <v-flex xs7>
                                        <div class="red--text"><b class="green--text">{{ attendance.Present | formatNumber }}</b>/{{ attendance.Absent | formatNumber }}</div>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                  <div class="ml-2 mt-2" :key="index" v-else>
                                    <v-layout row wrap>
                                      <v-flex xs5>
                                        <b>{{index}}:</b>
                                      </v-flex>
                                      <v-flex xs7>
                                        <div class="red--text"><b class="green--text">{{ attendance.Present | formatNumber }}</b>/{{ attendance.Absent | formatNumber }}</div>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </template>
                              </template>
                              

                            </v-flex>
                            <v-flex xs3>
                              <div align="center">
                                <v-avatar size="70" style="background-image: linear-gradient(#6070e4, #7f5fe4);">
                                  <v-icon x-large class="white--text">
                                    mdi-calendar-blank
                                  </v-icon>
                                </v-avatar>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>

                    </div>
                  </v-card>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div class="pa-2">
                  <v-progress-linear v-if="homeOtherLoader" height="1" indeterminate color="primary">
                  </v-progress-linear>
                  <v-card elevation="0" class="pa-5 header">
                    <div class="pa-2">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center" class="grey--text mb-7">
                            <b>Attendance Yesterday</b>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout row wrap>
                            <v-flex xs9>
                              <template v-for="(attendanceOtherHeader, header) in attendanceOther">
                                <div v-if="header=='Yesterday'" :key="header">
                                  <template v-for="(attendance, index) in attendanceOtherHeader">
                                    <div class="ml-2 mt-4" :key="index" v-if="index=='Total'">
                                      <v-layout row wrap>
                                        <v-flex xs5>
                                          <b>{{index}}:</b>
                                        </v-flex>
                                        <v-flex xs7>
                                          <div class="red--text"><b class="green--text">{{ attendance.Present | formatNumber }}</b>/{{ attendance.Absent | formatNumber }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                    <div class="ml-2 mt-2" :key="index" v-else>
                                      <v-layout row wrap>
                                        <v-flex xs5>
                                          <b>{{index}}:</b>
                                        </v-flex>
                                        <v-flex xs7>
                                          <div class="red--text"><b class="green--text">{{ attendance.Present | formatNumber }}</b>/{{ attendance.Absent | formatNumber }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                  </template>
                                </div>
                              </template>
                            </v-flex>
                            <v-flex xs3>
                              <div align="center">
                                <v-avatar size="70" style="background-image: linear-gradient(#f4c4f3, #fc67fa);">
                                  <v-icon x-large class="white--text">
                                    mdi-calendar-today
                                  </v-icon>
                                </v-avatar>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>

                    </div>
                  </v-card>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div class="pa-2">
                  <v-progress-linear v-if="homeOtherLoader" height="1" indeterminate color="primary">
                  </v-progress-linear>
                  <v-card elevation="0" class="pa-5 header">
                    <div class="pa-2">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center" class="grey--text mb-7">
                            <b>Average This Week</b>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout row wrap>
                            <v-flex xs9>
                              <template v-for="(attendanceOtherHeader, header) in attendanceOther">
                                <div v-if="header=='Week'" :key="header">
                                  <template v-for="(attendance, index) in attendanceOtherHeader">
                                    <div class="ml-2 mt-4" :key="index" v-if="index=='Average'">
                                      <v-layout row wrap>
                                        <v-flex xs5>
                                          <b>{{index}}:</b>
                                        </v-flex>
                                        <v-flex xs7>
                                          <div class="red--text"><b class="green--text">{{ attendance.Present | formatDecimal }}</b>/{{ attendance.Absent | formatDecimal }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                    <div class="ml-2 mt-2" :key="index" v-else>
                                      <v-layout row wrap>
                                        <v-flex xs5>
                                          <b>{{index}}:</b>
                                        </v-flex>
                                        <v-flex xs7>
                                          <div class="red--text"><b class="green--text">{{ attendance.Present | formatDecimal }}</b>/{{ attendance.Absent | formatDecimal }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                  </template>
                                </div>
                              </template>

                            </v-flex>
                            <v-flex xs3>
                              <div align="center">
                                <v-avatar size="70" style="background-image: linear-gradient(#fb6740, #fbad40);">
                                  <v-icon x-large class="white--text">
                                    mdi-calendar-week
                                  </v-icon>
                                </v-avatar>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>

                    </div>
                  </v-card>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div class="pa-2">
                  <v-progress-linear v-if="homeOtherLoader" height="1" indeterminate color="primary">
                  </v-progress-linear>

                  <v-card elevation="0" class="pa-5 header">
                    <div class="pa-2">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center" class="grey--text mb-7">
                            <b>Average This Month</b>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout row wrap>
                            <v-flex xs9>
                              <template v-for="(attendanceOtherHeader, header) in attendanceOther">
                                <div v-if="header=='Month'" :key="header">
                                  <template v-for="(attendance, index) in attendanceOtherHeader">
                                    <div class="ml-2 mt-4" :key="index" v-if="index=='Average'">
                                      <v-layout row wrap>
                                        <v-flex xs5>
                                          <b>{{index}}:</b>
                                        </v-flex>
                                        <v-flex xs7>
                                          <div class="red--text"><b class="green--text">{{ attendance.Present | formatDecimal }}</b>/{{ attendance.Absent | formatDecimal }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                    <div class="ml-2 mt-2" :key="index" v-else>
                                      <v-layout row wrap>
                                        <v-flex xs5>
                                          <b>{{index}}:</b>
                                        </v-flex>
                                        <v-flex xs7>
                                          <div class="red--text"><b class="green--text">{{ attendance.Present | formatDecimal }}</b>/{{ attendance.Absent | formatDecimal }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                  </template>
                                </div>
                              </template>

                            </v-flex>
                            <v-flex xs3>
                              <div align="center">
                                <v-avatar size="70" style="background-image: linear-gradient(#2dce8b, #2dcec8);">
                                  <v-icon x-large class="white--text">
                                    mdi-calendar-month
                                  </v-icon>
                                </v-avatar>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>

                    </div>
                  </v-card>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-container>
      <v-divider class="mx-5"></v-divider>
    </v-container>
    <div>
      <v-container class="mt-4">
        <v-layout column>
          <v-flex xs12>
            <v-tabs show-arrows="">
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab class="text-none" @click="activeTab('graph')">{{
                  $t("graphsMsg")
              }}</v-tab>
              <v-tab class="text-none" @click="activeTab('institution')">
                Attendance Per Institution
              </v-tab>
              <v-tab class="text-none" @click="activeTab('location')">
                Attendance Per Location
              </v-tab>
            </v-tabs>
          </v-flex>
          <v-flex xs12 class="mt-5" v-if="tab == 'graph'">
            <v-layout column>
              <v-flex xs12 class="mx-1">
                <v-layout row wrap>
                  <v-flex xs12 md7>

                    <v-card elevation="0" class="ma-2 header">
                      <v-progress-linear v-if="modulePerformanceLoader" height="1" indeterminate color="primary">
                      </v-progress-linear>
                      <div class="pa-5">
                        <div class="grey--text">
                          <b>Attendance Per Institution Type</b>
                        </div>
                        <div class="my-2">
                          <v-btn small depressed class="primary text-none mb-1" v-if="activeInstitutionType == 'today'"
                            @click="changeInstitutionTypeTime('today')">
                            {{ $t("todayMsg") }}
                          </v-btn>
                          <v-btn small depressed class="text-none mb-1" v-if="activeInstitutionType != 'today'"
                            @click="changeInstitutionTypeTime('today')">
                            {{ $t("todayMsg") }}
                          </v-btn>
                          <v-btn small depressed class="primary text-none mb-1"
                            v-if="activeInstitutionType == 'yesterday'" @click="changeInstitutionTypeTime('yesterday')">
                            {{ $t("yesterdayMsg") }}
                          </v-btn>
                          <v-btn small depressed class="text-none mb-1" v-if="activeInstitutionType != 'yesterday'"
                            @click="changeInstitutionTypeTime('yesterday')">
                            {{ $t("yesterdayMsg") }}
                          </v-btn>
                          <v-btn small depressed class="primary text-none mb-1" v-if="activeInstitutionType == 'week'"
                            @click="changeInstitutionTypeTime('week')">
                            {{ $t("thisWeekMsg") }}
                          </v-btn>
                          <v-btn small depressed class="text-none mb-1" v-if="activeInstitutionType != 'week'"
                            @click="changeInstitutionTypeTime('week')">
                            {{ $t("thisWeekMsg") }}
                          </v-btn>
                          <v-btn small depressed class="primary text-none mb-1" v-if="activeInstitutionType == 'month'"
                            @click="changeInstitutionTypeTime('month')">
                            {{ $t("thisMonthMsg") }}
                          </v-btn>
                          <v-btn small depressed class="text-none mb-1" v-if="activeInstitutionType != 'month'"
                            @click="changeInstitutionTypeTime('month')">
                            {{ $t("thisMonthMsg") }}
                          </v-btn>
                        </div>
                        <apexchart v-if="showChart" ref="attendancePerInstitutionTypeChart" width="100%" height="405" type="bar" :options="chartOptions"
                          :series="attendancePerInstitutionTypeSeries"></apexchart>
                        <div class="my-2" align="center">
                          <template v-for="(gender, indexOne) in attendancePerInstitutionType">
                            <template v-for="(attendance, indexTwo) in gender">
                              <v-btn small depressed class="primary text-none mb-1" v-if="activeGender == indexOne+' '+indexTwo"
                                @click="changeGender(indexOne, indexTwo)" :key="indexOne+indexTwo">
                                {{indexOne}} {{indexTwo}}
                              </v-btn>
                              <v-btn small depressed class="text-none mb-1" v-if="activeGender != indexOne+' '+indexTwo"
                                @click="changeGender(indexOne, indexTwo)" :key="indexOne+indexTwo">
                                {{indexOne}} {{indexTwo}}
                              </v-btn>
                            </template>
                            
                          </template>
                        </div>
                      </div>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md5>
                    <v-card elevation="0" class="ma-2 header">
                      <v-progress-linear v-if="attendanceRatioLoader" height="1" indeterminate color="primary">
                      </v-progress-linear>
                      <div class="pa-5">
                        <div class="grey--text">
                          <b>Attendance Per Gender</b>
                        </div>
                        <div class="my-2">
                          <v-btn small depressed class="primary text-none mb-1" v-if="activeRatioPeriod == 'today'"
                            @click="changeAttendanceRatio('today')">
                            {{ $t("todayMsg") }}
                          </v-btn>
                          <v-btn small depressed class="text-none mb-1" v-if="activeRatioPeriod != 'today'"
                            @click="changeAttendanceRatio('today')">
                            {{ $t("todayMsg") }}
                          </v-btn>
                          <v-btn small depressed class="primary text-none mb-1" v-if="activeRatioPeriod == 'yesterday'"
                            @click="changeAttendanceRatio('yesterday')">
                            {{ $t("yesterdayMsg") }}
                          </v-btn>
                          <v-btn small depressed class="text-none mb-1" v-if="activeRatioPeriod != 'yesterday'"
                            @click="changeAttendanceRatio('yesterday')">
                            {{ $t("yesterdayMsg") }}
                          </v-btn>
                          <v-btn small depressed class="primary text-none mb-1" v-if="activeRatioPeriod == 'week'"
                            @click="changeAttendanceRatio('week')">
                            {{ $t("thisWeekMsg") }}
                          </v-btn>
                          <v-btn small depressed class="text-none mb-1" v-if="activeRatioPeriod != 'week'"
                            @click="changeAttendanceRatio('week')">
                            {{ $t("thisWeekMsg") }}
                          </v-btn>
                          <v-btn small depressed class="primary text-none mb-1" v-if="activeRatioPeriod == 'month'"
                            @click="changeAttendanceRatio('month')">
                            {{ $t("thisMonthMsg") }}
                          </v-btn>
                          <v-btn small depressed class="text-none mb-1" v-if="activeRatioPeriod != 'month'"
                            @click="changeAttendanceRatio('month')">
                            {{ $t("thisMonthMsg") }}
                          </v-btn>
                        </div>
                        <apexchart width="100%" height="450" type="pie" :options="chartOptions2" :series="series2">
                        </apexchart>
                        <div class="my-2" align="center">
                          <template v-for="(attendance, index) in attendanceRatio">
                            <v-btn small depressed class="primary text-none mb-1" v-if="activeRatio == index"
                              @click="changeActiveRatio(index)" :key="index">
                                {{index}}
                            </v-btn>
                            <v-btn small depressed class="text-none mb-1" v-if="activeRatio != index"
                              @click="changeActiveRatio(index)" :key="index">
                                {{index}}
                            </v-btn>
                          </template>
                        </div>
                      </div>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 class="mt-5" v-if="tab == 'institution'">
            <div class="mx-1">
              <v-layout row wrap>
                <v-flex xs12 md6 v-for="(institution, index) in attendancePerInstitution" :key="index">
                  <div class="pa-2">
                    <v-progress-linear v-if="attendancePerInstitutionLoader" height="1" indeterminate color="primary">
                    </v-progress-linear>
                    <v-card elevation="0" class="header">
                      <div class="pa-5">
                        <div class="ml-2">
                          <b>{{ institution.name }}</b>
                        </div>
                        <v-simple-table class="header">
                          <template v-slot:default>
                            <thead>
                              <tr>                              
                                  <th class="text-left">{{ $t("periodMsg") }}</th>
                                    <template v-for="(gender, genderIndex) in institution.attendance['Month']">
                                      <template v-for="(status, statusIndex) in gender">
                                        <th>
                                          <div align="right">{{statusIndex}} ({{genderIndex}})</div>
                                        </th>
                                      </template>
                                      
                                    </template>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(genderData, index) in institution.attendance">
                                <tr>
                                  <td>
                                    <div>
                                      <b>{{index}}</b>
                                    </div>
                                  </td>
                                  <template v-for="(gender, genderIndex) in genderData">
                                    <template v-for="(status, statusIndex) in gender">
                                      <td>
                                        <div align="right">{{ status | formatNumber }}</div>
                                      </td>
                                    </template>
                                    
                                  </template>
                                </tr>
                                
                              </template>                             
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                    </v-card>
                  </div>
                </v-flex>
              </v-layout>

              <div class="mt-3">
                <v-layout row wrap>
                  <v-flex xs12 md3>
                    <div>
                      <b class="primary--text ml-4">{{ $t("totalMsg") }}: </b>{{ attendancePerInstitutionPagination.total }}
                    </div>
                  </v-flex>
                  <v-flex xs12 md9>
                    <div align="right">
                      <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="5"
                        v-model="attendancePerInstitutionPagination.current_page" @input="changePage()" circle>
                      </v-pagination>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-5" v-if="tab == 'location'">
            <div class="mx-1" v-if="attendancePerLocation.length != 0">
              <v-layout row wrap>
                <v-flex xs12 md6 v-for="(location, index) in attendancePerLocation" :key="index">
                  <div class="pa-2">
                    <v-progress-linear v-if="attendancePerLocationLoader" height="1" indeterminate color="primary">
                    </v-progress-linear>
                    <v-card elevation="0" class="header">
                      <div class="pa-5">
                        <div class="ml-2">
                          <b>{{ location.name }}</b>
                        </div>
                        <v-simple-table class="header">
                          <template v-slot:default>
                            <thead>
                              <tr>                              
                                  <th class="text-left">{{ $t("periodMsg") }}</th>
                                    <template v-for="(gender, genderIndex) in location.attendance['Month']">
                                      <template v-for="(status, statusIndex) in gender">
                                        <th>
                                          <div align="right">{{statusIndex}} ({{genderIndex}})</div>
                                        </th>
                                      </template>
                                      
                                    </template>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(genderData, index) in location.attendance">
                                <tr>
                                  <td>
                                    <div>
                                      <b>{{index}}</b>
                                    </div>
                                  </td>
                                  <template v-for="(gender, genderIndex) in genderData">
                                    <template v-for="(status, statusIndex) in gender">
                                      <td>
                                        <div align="right">{{ status | formatNumber }}</div>
                                      </td>
                                    </template>
                                    
                                  </template>
                                </tr>
                                
                              </template>                             
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                    </v-card>
                  </div>
                </v-flex>
              </v-layout>

              <div class="mt-3">
                <v-layout row wrap>
                  <v-flex xs12 md3>
                    <div>
                      <b class="primary--text ml-4">{{ $t("totalMsg") }}: </b>{{ attendancePerLocationPagination.total }}
                    </div>
                  </v-flex>
                  <v-flex xs12 md9>
                    <div align="right">
                      <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="5"
                        v-model="attendancePerLocationPagination.current_page" @input="changeLocationPage()" circle>
                      </v-pagination>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import Vue from "vue";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { mapGetters, mapState, mapActions } from "vuex";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

var numeral = require("numeral");
Vue.use(require("vue-moment"));
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0");
});
Vue.filter("formatDecimal", function (value) {
  return numeral(value).format("0,0.00");
});

export default {
  components: {},
  data: () => ({
    showChart: true,
    page: 1,
    path: process.env.VUE_APP_API_URL,
    color: "",
    message: "",
    tab: "graph",
    activeInstitutionType: 'today',
    activeRatioPeriod: 'today',
    activeGender: 'present girls',
    activeRatio: 'girls',
    period: 'attendancePerInstitutionTypeToday',

    chartOptions: {
      xaxis: {
        categories: [],
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    chartOptions2: {
      chart: {
        id: "attendance-ratio",
        width: "100%",
        type: "pie",
      },
      labels: [],
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 1,
        colors: undefined,
      },
      yaxis: {
        show: true,
      },
      legend: {
        position: "bottom",
      },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
          },
          spokes: {
            strokeWidth: 0,
          },
        },
      },
    },
    series2: [0, 0],
    attendancePerInstitutionTypeSeries: [
      
    ],
    loadingModulePerformanceSeries: [],
  }),
  created() {
    this.startAttendancePerInstitutionTypeLoader()
    this.startAttendanceTodayLoader()
    this.startHomeOtherLoader()
    this.startAttendanceRatioLoader()

    this.fetchAllAttendancePeriods();

    this.activePeriod(this.allAttendancePeriods[0].id)

    this.getAttendanceToday(this.attendancePeriod);
    this.getAttendanceOther(this.attendancePeriod);
    this.getAttendancePerInstitutionTypeToday(this.attendancePeriod);
    this.changeAttendanceRatio('today')

    this.assignAttendancePerInstitutionType();
    this.assignAttendanceRatio();
  },
  methods: {
    ...mapActions([
      "updateAttendanceToday",
      "updateAttendanceOther",
      "updateAttendancePerInstitutionType",
      "updateAttendanceRatio",
      "updateAttendancePerLocation",
      "updateAttendancePerInstitution",
      "startAttendancePerInstitutionTypeLoader",
      "startAttendanceTodayLoader",
      "startHomeOtherLoader",
      "startAttendanceRatioLoader",
      "stopAttendanceRatioLoader",
      "startAttendanceLocationLoader",
      "startAttendancePerInstitutionLoader",
      "fetchAllAttendancePeriods"
    ]),
    activePeriod(id){
      this.attendancePeriod = id

      this.startAttendancePerInstitutionTypeLoader()
      this.startAttendanceTodayLoader()
      this.startHomeOtherLoader()
      this.startAttendanceRatioLoader()

      this.getAttendanceToday(this.attendancePeriod);
      this.getAttendanceOther(this.attendancePeriod);
      this.getAttendancePerInstitutionTypeToday(this.attendancePeriod);
      this.changeAttendanceRatio('today')

      this.assignAttendancePerInstitutionType();
      this.assignAttendanceRatio();
    },
    assignAttendancePerInstitutionType() {
      for (var prop1 in this.attendancePerInstitutionType) {
        if ( this.attendancePerInstitutionType.hasOwnProperty(prop1) ) {
          for (var prop2 in this.attendancePerInstitutionType[prop1]) {
            if ( this.attendancePerInstitutionType[prop1].hasOwnProperty(prop2) ) {
              this.activeGender = prop1+' '+prop2
              this.attendancePerInstitutionTypeSeries = this.attendancePerInstitutionType[prop1][prop2]
              this.showChart = true
            }
            break
          }
        }
        
      }
    },
    assignAttendanceRatio() {
      for (var prop in this.attendanceRatio) {
        if ( this.attendanceRatio.hasOwnProperty(prop) ) {
          this.activeRatio = prop
          this.chartOptions2.labels = this.attendanceRatio[prop].labels
          this.series2 = this.attendanceRatio[prop].series
        }
        break
      }
    },
    changeActiveRatio(ratio) {
      this.activeRatio = ratio
      this.chartOptions2.labels = this.attendanceRatio[ratio].labels
      this.series2 = this.attendanceRatio[ratio].series
    },
    changeGender(gender, attendance) {
      this.activeGender = gender+' '+attendance
      this.attendancePerInstitutionTypeSeries = this.attendancePerInstitutionType[gender][attendance]
      
    },
    changeAttendanceRatio(time) {
      this.chartOptions2.labels = []
      this.series2 = [0, 0]
      if (time == 'today') {
        this.period = 'attendanceRatioToday'
        this.activeRatioPeriod = 'today'
      } else if (time == 'yesterday') {
        this.period = 'attendanceRatioYesterday'
        this.activeRatioPeriod = 'yesterday'
      } else if (time == 'week') {
        this.period = 'attendanceRatioWeek'
        this.activeRatioPeriod = 'week'
      } else if (time == 'month') {
        this.period = 'attendanceRatioMonth'
        this.activeRatioPeriod = 'month'
      }
      this.startAttendanceRatioLoader()
      apiCall({
        url: "/api/dashboard?type=" + this.period + "&attendancePeriod=" + this.attendancePeriod,
        method: "GET",
      })
        .then((resp) => {
          this.updateAttendanceRatio(resp);
          this.assignAttendanceRatio()
          this.stopAttendanceRatioLoader()
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    changeInstitutionTypeTime(time) {
      this.chartOptions.xaxis.categories[0] = 'loading..'
      this.attendancePerInstitutionTypeSeries = []

      if (time == 'today') {
        this.period = 'attendancePerInstitutionTypeToday'
        this.activeInstitutionType = 'today'
      } else if (time == 'yesterday') {
        this.period = 'attendancePerInstitutionTypeYesterday'
        this.activeInstitutionType = 'yesterday'
      } else if (time == 'week') {
        this.period = 'attendancePerInstitutionTypeWeek'
        this.activeInstitutionType = 'week'
      } else if (time == 'month') {
        this.period = 'attendancePerInstitutionTypeMonth'
        this.activeInstitutionType = 'month'
      }
      this.startAttendancePerInstitutionTypeLoader()
      apiCall({
        url: "/api/dashboard?type=" + this.period + "&attendancePeriod=" + this.attendancePeriod,
        method: "GET",
      })
        .then((resp) => {
          this.chartOptions.xaxis.categories[0] = time
          this.updateAttendancePerInstitutionType(resp);
          this.assignAttendancePerInstitutionType()
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },

    activeTab(tab) {
      this.tab = tab;
      if (tab == 'location') {
        this.getAttendancePerLocation();
      } else if (tab == 'institution') {
        this.getAttendancePerInstitution();
      }
    },
    getAttendanceToday(id) {
      apiCall({
        url: "/api/dashboard?type=attendanceToday&attendancePeriod="+id,
        method: "GET",
      })
        .then((resp) => {
          this.updateAttendanceToday(resp);
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    getAttendanceOther(id) {
      apiCall({
        url: "/api/dashboard?type=attendanceOther&attendancePeriod="+id,
        method: "GET",
      })
        .then((resp) => {
          this.updateAttendanceOther(resp);
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    getAttendancePerInstitutionTypeToday(id) {
      apiCall({
        url: "/api/dashboard?type=attendancePerInstitutionTypeToday&attendancePeriod="+id,
        method: "GET",
      })
        .then((resp) => {
          this.updateAttendancePerInstitutionType(resp);
          this.assignAttendancePerInstitutionType();
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    getAttendancePerLocation() {
      this.startAttendanceLocationLoader()
      apiCall({
        url: "/api/dashboard?type=attendancePerLocation&page=" + this.attendancePerLocationPagination.current_page,
        method: "GET",
      })
        .then((resp) => {
          this.updateAttendancePerLocation(resp);
          //this.assignAttendanceRatio();
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    getAttendancePerInstitution() {
      this.startAttendancePerInstitutionLoader()
      apiCall({
        url: "/api/dashboard?type=attendancePerInstitution&page=" + this.attendancePerInstitutionPagination.current_page + "&attendancePeriod=" + this.attendancePeriod,
        method: "GET",
      })
        .then((resp) => {
          this.updateAttendancePerInstitution(resp);
          //this.assignAttendanceRatio();
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    changePage() {
      this.getAttendancePerInstitution()
    },
    changeLocation() {
      this.getAttendancePerLocation()
    },
  },
  computed: {
    ...mapGetters([
      "attendanceToday",
      "attendanceOther",
      "attendancePerInstitutionType",
      "attendanceRatio",
      "attendancePerLocation",
      "attendancePerLocationPagination",
      "attendancePerInstitution",
      "attendancePerInstitutionPagination",
      "language",
      "attendanceTodayLoader",
      "homeOtherLoader",
      "modulePerformanceLoader",
      "attendanceRatioLoader",
      "attendancePerLocationLoader",
      "attendancePerInstitutionLoader",
      "allAttendancePeriods"
    ]),
    length: function () {
      return Math.ceil(
        this.attendancePerInstitutionPagination.total / this.attendancePerInstitutionPagination.per_page
      );
    },
  },
};
</script>