import apiCall from '@/utils/api'

const state = {
	institutionElectricityResource: {},
	institutionElectricityResources: [],
    institutionElectricityResourcePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    institutionElectricityResourceLoader: false
};

const getters = {
    institutionElectricityResourceLoader: (state) => state.institutionElectricityResourceLoader,
	institutionElectricityResources: (state) => state.institutionElectricityResources,
	institutionElectricityResource: (state) => state.institutionElectricityResource,
    institutionElectricityResourcePagination: (state) => state.institutionElectricityResourcePagination
}

const actions = {
    async fetchInstitutionElectricityResources({commit}, page) {
		const response = await apiCall({url: `/api/institution-electricity-resource?page=${page}`, method: 'GET' });
		commit('setInstitutionElectricityResources', response)
        commit('stopInstitutionElectricityResourceLoader', response)
	},
    async fetchInstitutionElectricityResource({commit}, itemId) {
		const response = await apiCall({url: `/api/institution-electricity-resource/${itemId}`, method: 'GET' });
		commit('setInstitutionElectricityResource', response)
        commit('stopInstitutionElectricityResourceLoader', response)
	},
    async filterInstitutionElectricityResources({commit, state},resp){
		commit('setInstitutionElectricityResources', resp)
	},
	async startInstitutionElectricityResourceLoader({commit, state},resp){
		commit('startInstitutionElectricityResourceLoader', resp)
	},
	async stopInstitutionElectricityResourceLoader({commit, state},resp){
		commit('stopInstitutionElectricityResourceLoader', resp)
	},
};

const mutations = {
	setInstitutionElectricityResources: (state, institutionElectricityResources) => {
		state.institutionElectricityResources = institutionElectricityResources.data
		state.institutionElectricityResourcePagination.current_page = institutionElectricityResources.current_page
		state.institutionElectricityResourcePagination.total = institutionElectricityResources.total
		state.institutionElectricityResourcePagination.per_page = institutionElectricityResources.per_page
	},
	setInstitutionElectricityResource: (state, institutionElectricityResource) => {
		state.institutionElectricityResource = institutionElectricityResource
	},
    startInstitutionElectricityResourceLoader: (state) => state.institutionElectricityResourceLoader = true,
	stopInstitutionElectricityResourceLoader: (state) => state.institutionElectricityResourceLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
