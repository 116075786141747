import apiCall from '@/utils/api'

const state = {
	designation: {},
	designations: [],
    designationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    designationLoader: false
};

const getters = {
    designationLoader: (state) => state.designationLoader,
	designations: (state) => state.designations,
	designation: (state) => state.designation,
    designationPagination: (state) => state.designationPagination
}

const actions = {
    async fetchDesignations({commit}, page) {
		const response = await apiCall({url: `/api/designation?page=${page}`, method: 'GET' });
		commit('setDesignations', response)
        commit('stopDesignationLoader', response)
	},
    async fetchDesignation({commit}, itemId) {
		const response = await apiCall({url: `/api/designation/${itemId}`, method: 'GET' });
		commit('setDesignation', response)
        commit('stopDesignationLoader', response)
	},
    async filterDesignations({commit, state},resp){
		commit('setDesignations', resp)
	},
	async startDesignationLoader({commit, state},resp){
		commit('startDesignationLoader', resp)
	},
	async stopDesignationLoader({commit, state},resp){
		commit('stopDesignationLoader', resp)
	},
};

const mutations = {
	setDesignations: (state, designations) => {
		state.designations = designations.data
		state.designationPagination.current_page = designations.current_page
		state.designationPagination.total = designations.total
		state.designationPagination.per_page = designations.per_page
	},
	setDesignation: (state, designation) => {
		state.designation = designation
	},
    startDesignationLoader: (state) => state.designationLoader = true,
	stopDesignationLoader: (state) => state.designationLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
