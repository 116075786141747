<template>
  <div class="home">
    <v-container fill-height fluid>
      <v-row align="center">
        <v-col class="text-center mt-10 mx-10">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "@/store/actions/auth";
export default {
  created() {
    if (this.getProfile.roles[0].name == "System Admin") {
          this.$router.push("/admin-home");
        } else if (this.getProfile.roles[0].name == "School Admin"){
          this.$router.push("/school-admin-home");
        } else {
          this.$router.push("/staff-home");
        }
  },
  computed: {
    ...mapGetters(["getProfile"]),
  },
};
</script>