import apiCall from '@/utils/api'

const state = {
	institutionSector: {},
	institutionSectors: [],
    institutionSectorPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    institutionSectorLoader: false
};

const getters = {
    institutionSectorLoader: (state) => state.institutionSectorLoader,
	institutionSectors: (state) => state.institutionSectors,
	institutionSector: (state) => state.institutionSector,
    institutionSectorPagination: (state) => state.institutionSectorPagination
}

const actions = {
    async fetchInstitutionSectors({commit}, page) {
		const response = await apiCall({url: `/api/institution-sector?page=${page}`, method: 'GET' });
		commit('setInstitutionSectors', response)
        commit('stopInstitutionSectorLoader', response)
	},
    async fetchInstitutionSector({commit}, itemId) {
		const response = await apiCall({url: `/api/institution-sector/${itemId}`, method: 'GET' });
		commit('setInstitutionSector', response)
        commit('stopInstitutionSectorLoader', response)
	},
    async filterInstitutionSectors({commit, state},resp){
		commit('setInstitutionSectors', resp)
	},
	async startInstitutionSectorLoader({commit, state},resp){
		commit('startInstitutionSectorLoader', resp)
	},
	async stopInstitutionSectorLoader({commit, state},resp){
		commit('stopInstitutionSectorLoader', resp)
	},
};

const mutations = {
	setInstitutionSectors: (state, institutionSectors) => {
		state.institutionSectors = institutionSectors.data
		state.institutionSectorPagination.current_page = institutionSectors.current_page
		state.institutionSectorPagination.total = institutionSectors.total
		state.institutionSectorPagination.per_page = institutionSectors.per_page
	},
	setInstitutionSector: (state, institutionSector) => {
		state.institutionSector = institutionSector
	},
    startInstitutionSectorLoader: (state) => state.institutionSectorLoader = true,
	stopInstitutionSectorLoader: (state) => state.institutionSectorLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
