import apiCall from '@/utils/api'

const state = {
	levelStream: {},
	levelstreams: [],
    levelstreamPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    levelstreamLoader: false
};

const getters = {
    levelstreamLoader: (state) => state.levelstreamLoader,
	levelstreams: (state) => state.levelstreams,
	levelStream: (state) => state.levelStream,
    levelstreamPagination: (state) => state.levelstreamPagination
}

const actions = {
    async fetchLevelstreams({commit}, page) {
		const response = await apiCall({url: `/api/levelStream?page=${page}`, method: 'GET' });
		commit('setLevelStreams', response)
        commit('stopLoader', response)
	},
    async fetchLevelstream({commit}, itemId) {
		const response = await apiCall({url: `/api/levelStream/${itemId}`, method: 'GET' });
		commit('setLevelStream', response)
        commit('stopLoader', response)
	},
    async filterLevelstreams({commit, state},resp){
		commit('setLevelStreams', resp)
	},
	async startLevelstreamLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopLevelstreamLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setLevelStreams: (state, levelstreams) => {
		state.levelstreams = levelstreams.data
		state.levelstreamPagination.current_page = levelstreams.current_page
		state.levelstreamPagination.total = levelstreams.total
		state.levelstreamPagination.per_page = levelstreams.per_page
	},
	setLevelStream: (state, levelStream) => {
		state.levelStream = levelStream
	},
    startLoader: (state) => state.levelstreamLoader = true,
	stopLoader: (state) => state.levelstreamLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
