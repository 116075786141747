<template>
    <div class="reports" v-if="$can('reports_view')">
        <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
            <span>{{ $t(message) }}</span>
        </v-snackbar>
        <v-container>
            <v-layout column>
                <v-flex xs12>
                    <div class="title">Computed Reports</div>
                </v-flex>
                <div v-if="view == 'default'">
                    <v-flex xs12 class="mt-5 mb-2">
                        <v-card elevation="0" class="header">
                            <v-layout column class="pa-5">
                                <v-flex xs12 class="mt-1">
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <div class="ml-1"><b>Location</b></div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap class="mt-2">
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>County</div>
                                                <v-select :items="formData.counties" class="text_field background"
                                                    item-value="id" item-text="name" outlined dense
                                                    v-model="reportData.county_id" @input="populateSubCounties">
                                                </v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>Sub County</div>
                                                <v-select :items="subcounties" class="text_field background"
                                                    item-value="id" item-text="name" outlined dense
                                                    v-model="reportData.subcounty_id" @input="populateWards">
                                                </v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>Ward</div>
                                                <v-select :items="wards" class="text_field background" item-value="id"
                                                    item-text="name" outlined dense v-model="reportData.ward_id">
                                                </v-select>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-9">
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <div class="ml-1"><b>Year</b></div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap class="mt-2">
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <!-- <div>County</div> -->
                                                <v-select :items="formData.years" v-model="reportData.year"
                                                    class="text_field background" item-value="year" item-text="year"
                                                    outlined dense></v-select>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-9">
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <div class="ml-1 mb-3"><b>Enrolment Rate Filters</b></div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout column>
                                        <v-flex xs12>
                                            <div align="right">
                                                <v-btn rounded depressed class="primary text-none" @click="addDisabilityField()">
                                                    Add Level
                                                    <v-icon right> mdi-plus </v-icon>
                                                </v-btn>
                                            </div>
                                        </v-flex>
                                        <template v-for="(enrolmentFilter, index) in enrolmentFilters">
                                            <div :key="index" class="mx-3 mb-3">
                                                <v-flex xs12>                                                    
                                                        <v-layout row wrap>
                                                            <v-flex xs11>
                                                                <v-layout row wrap class="mt-2">
                                                                    <v-flex xs12 md4>
                                                                        <div class="pa-1">
                                                                            <div>Institution Type</div>
                                                                            <v-select :items="formData.institutionTypes" v-model="enrolmentFilters[index].institution_type_id"
                                                                                class="text_field background" item-value="id" item-text="name"
                                                                                outlined dense></v-select>
                                                                        </div>
                                                                    </v-flex>
                                                                    <v-flex xs12 md4>
                                                                        <div class="pa-1">
                                                                            <div>Min Age</div>
                                                                            <v-text-field class="text_field background" outlined dense
                                                                                v-model="enrolmentFilters[index].min_age" :rules="inputRules" type="number"></v-text-field>
                                                                        </div>
                                                                    </v-flex>
                                                                    <v-flex xs12 md4>
                                                                        <div class="pa-1">
                                                                            <div>Max Age</div>
                                                                            <v-text-field class="text_field background" outlined dense
                                                                                v-model="enrolmentFilters[index].max_age" :rules="inputRules" type="number"></v-text-field>
                                                                        </div>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                            <v-flex xs1>
                                                                <div class="pa-3" align="right">
                                                                    <v-btn icon class="red--text mt-6" @click="removeEnrolmentFilter(index)">
                                                                        <v-icon> mdi-close-circle </v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </v-flex>
                                                        </v-layout>


                                                        
                                                </v-flex>
                                            </div>
                                        </template>
                                    </v-layout>
                                    
                                </v-flex>
                                <v-flex xs12 class="mt-8">
                                    <div align="right">
                                        <v-btn rounded depressed class="white--text universal text-none"
                                            :loading="loading" @click="generate()">
                                            Show Data
                                            <v-icon right>mdi-cogs</v-icon>
                                        </v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>

                        </v-card>
                    </v-flex>
                    <v-flex xs12 class="mt-5" v-if="reports.length!=0">
                        <v-layout column>
                            <v-flex xs12>
                                <v-tabs show-arrows="">
                                    <v-tabs-slider color="primary"></v-tabs-slider>
                                    <template v-for="(report, index) in reports">
                                        <v-tab class="text-none" @click="activeTab(index)" :key="index">
                                            {{index}}
                                        </v-tab>
                                    </template>
                                    </v-tabs>
                                </v-tabs>                                
                            </v-flex>
                            <v-flex xs12 class="mx-3 mt-4" v-if="tab=='Pupil Teacher Ratio'">
                                <v-layout row wrap>
                                    <template v-for="(report, index) in reports['Pupil Teacher Ratio']">
                                        <v-flex xs12 md4 :key="index">
                                            <div class="ma-1 header">
                                                <apexchart width="100%" height="450" type="pie" :options="pupilTeacherChartOptions"
                                                    :series="pupilTeacherSeries[index]">
                                                </apexchart>
                                                <div align="center">
                                                    <b>{{index}}:</b> {{pupilTeacherSeries[index][0]/pupilTeacherSeries[index][1] | formatNumber}}
                                                </div>
                                            </div>
                                            
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mx-3 mt-4" v-if="tab=='Average Class Size'">
                                <v-layout row wrap>
                                    <template v-for="(report, index) in reports['Average Class Size']">
                                        <v-flex xs12 md4 :key="index">
                                            <div class="ma-1 header">
                                                <apexchart width="100%" height="450" type="pie" :options="averageClassSizeChartOptions"
                                                    :series="averageClassSizeSeries[index]">
                                                </apexchart>
                                                <div align="center">
                                                    <b>{{index}}:</b> {{averageClassSizeSeries[index][0]/averageClassSizeSeries[index][1] | formatNumber}}
                                                </div>
                                            </div>
                                            
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mx-3 mt-4" v-if="tab=='Gross Enrolment Rate'">
                                <v-layout row wrap>
                                    <template v-for="(report, index) in reports['Gross Enrolment Rate']">
                                        <v-flex xs12 md4 :key="index">
                                            <div class="ma-1 header">
                                                <apexchart width="100%" height="450" type="pie" :options="grossEnrolmentRateChartOptions"
                                                    :series="grossEnrolmentRateSeries[index]">
                                                </apexchart>
                                                <div align="center">
                                                    <b>{{index}}:</b> {{grossEnrolmentRateSeries[index][0]/grossEnrolmentRateSeries[index][1] | formatNumber}}
                                                </div>
                                            </div>
                                            
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mx-3 mt-4" v-if="tab=='Net Enrolment Rate'">
                                <v-layout row wrap>
                                    <template v-for="(report, index) in reports['Net Enrolment Rate']">
                                        <v-flex xs12 md4 :key="index">
                                            <div class="ma-1 header">
                                                <apexchart width="100%" height="450" type="pie" :options="netEnrolmentRateChartOptions"
                                                    :series="netEnrolmentRateSeries[index]">
                                                </apexchart>
                                                <div align="center">
                                                    <b>{{index}}:</b> {{netEnrolmentRateSeries[index][0]/netEnrolmentRateSeries[index][1] | formatNumber}}
                                                </div>
                                            </div>
                                            
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </div>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import Vue from 'vue'
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0.00");
});

export default {
    components: {

    },
    data() {
        return {
            view: "default",
            tab: null,
            loading: false,
            pdfLoading: false,
            csvLoading: false,
            valid: true,
            message: "",
            color: "",
            delete: false,
            confirm: false,
            snackbar: false,

            formData: null,
            reports: [],

            subcounties: [],
            wards: [],

            enrolmentFilters: [
                {
                    institution_type_id: null,
                    min_age: null,
                    max_age: null,
                }
            ],

            reportData: {
                id: null,
                county_id: null,
                subcounty_id: null,
                ward_id: null,
                year: null,
                enrolment_filters: null
            },
            monthNames: [
                { name: "January" },
                { name: "February" },
                { name: "March" },
                { name: "April" },
                { name: "May" },
                { name: "June" },
                { name: "July" },
                { name: "August" },
                { name: "September" },
                { name: "October" },
                { name: "November" },
                { name: "December" },
            ],
            months: [
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
            ],
            pupilTeacherChartOptions: {
                chart: {
                    id: "pupil-teacher-ratio",
                    width: "100%",
                    type: "pie",
                },
                labels: [],
                fill: {
                    opacity: 1,
                },
                stroke: {
                    width: 1,
                    colors: undefined,
                },
                yaxis: {
                    show: true,
                },
                legend: {
                    position: "bottom",
                },
                plotOptions: {
                    polarArea: {
                        rings: {
                            strokeWidth: 0,
                        },
                        spokes: {
                            strokeWidth: 0,
                        },
                    },
                },
            },
            pupilTeacherSeries: [],

            averageClassSizeChartOptions: {
                chart: {
                    id: "pupil-teacher-ratio",
                    width: "100%",
                    type: "pie",
                },
                labels: [],
                fill: {
                    opacity: 1,
                    colors: ['#F44336', '#E91E63', '#9C27B0']
                },
                stroke: {
                    width: 1,
                    colors: undefined,
                },
                yaxis: {
                    show: true,
                },
                legend: {
                    position: "bottom",
                },
                plotOptions: {
                    polarArea: {
                        rings: {
                            strokeWidth: 0,
                        },
                        spokes: {
                            strokeWidth: 0,
                        },
                    },
                },
            },
            averageClassSizeSeries: [],

            grossEnrolmentRateChartOptions: {
                chart: {
                    id: "gross-enrolment-rate",
                    width: "100%",
                    type: "pie",
                },
                labels: [],
                fill: {
                    opacity: 1
                },
                stroke: {
                    width: 1,
                    colors: undefined,
                },
                yaxis: {
                    show: true,
                },
                legend: {
                    position: "bottom",
                },
                plotOptions: {
                    polarArea: {
                        rings: {
                            strokeWidth: 0,
                        },
                        spokes: {
                            strokeWidth: 0,
                        },
                    },
                },
            },
            grossEnrolmentRateSeries: [],

            netEnrolmentRateChartOptions: {
                chart: {
                    id: "net-enrolment-rate",
                    width: "100%",
                    type: "pie",
                },
                labels: [],
                fill: {
                    opacity: 1
                },
                stroke: {
                    width: 1,
                    colors: undefined,
                },
                yaxis: {
                    show: true,
                },
                legend: {
                    position: "bottom",
                },
                plotOptions: {
                    polarArea: {
                        rings: {
                            strokeWidth: 0,
                        },
                        spokes: {
                            strokeWidth: 0,
                        },
                    },
                },
            },
            netEnrolmentRateSeries: [],
        };
    },
    created() {
        if (this.$can("reports_view")) {
            this.getFormData();
            this.reportData.year = this.currentYear
        }
    },
    methods: {
        ...mapActions([

        ]),
        activeTab(tab){
            this.tab = tab
        },
        addDisabilityField() {
            let obj = {}
            obj['institution_type_id'] = null,
            obj['min_age'] = null,
            obj['max_age'] = null,

            this.enrolmentFilters.push(obj)
        },
        removeEnrolmentFilter(index) {
            if (this.enrolmentFilters.length != 0) {
                this.enrolmentFilters.splice(index, 1);
            }

        },
        populateSubCounties() {
            this.reportData.subcounty_id = null;
            this.reportData.ward_id = null;
            let x = this.formData.counties.find((county) => county.id == this.reportData.county_id);
            this.subcounties = x.subcounties;
        },
        populateWards() {
            this.reportData.ward_id = null;
            let x = this.subcounties.find((subcounty) => subcounty.id == this.reportData.subcounty_id);
            this.wards = x.wards;
        },
        generate() {
            this.loading = true;
            this.reportData.enrolment_filters = this.enrolmentFilters
            apiCall({
                url: "/api/computed-report",
                data: this.reportData,
                method: "POST",
            })
                .then((resp) => {
                    this.reports = resp

                    for( var ratio in resp['Pupil Teacher Ratio']){
                        this.pupilTeacherChartOptions.labels = resp['Pupil Teacher Ratio'][ratio].labels
                        break
                    }
                    for( var ratio in resp['Pupil Teacher Ratio']){
                        this.pupilTeacherSeries[ratio] = resp['Pupil Teacher Ratio'][ratio].series
                    }

                    for( var ratio in resp['Average Class Size']){
                        this.averageClassSizeChartOptions.labels = resp['Average Class Size'][ratio].labels
                        break
                    }
                    for( var ratio in resp['Average Class Size']){
                        this.averageClassSizeSeries[ratio] = resp['Average Class Size'][ratio].series
                    }

                    for( var ratio in resp['Gross Enrolment Rate']){
                        this.grossEnrolmentRateChartOptions.labels = resp['Gross Enrolment Rate'][ratio].labels
                        break
                    }
                    for( var ratio in resp['Gross Enrolment Rate']){
                        this.grossEnrolmentRateSeries[ratio] = resp['Gross Enrolment Rate'][ratio].series
                    }  
                    
                    for( var ratio in resp['Net Enrolment Rate']){
                        this.netEnrolmentRateChartOptions.labels = resp['Net Enrolment Rate'][ratio].labels
                        break
                    }
                    for( var ratio in resp['Net Enrolment Rate']){
                        this.netEnrolmentRateSeries[ratio] = resp['Net Enrolment Rate'][ratio].series
                    }  

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error.response);
                    this.loading = false;
                    this.message = "anErrorOccurredMsg";
                    this.color = "error";
                    this.snackbar = true;
                });
        },
        getFormData() {
            apiCall({
                url: "/api/computed-report?type=formData",
                method: "GET",
            })
                .then((resp) => {
                    this.formData = resp;
                })
                .catch((error) => {
                    this.message = "anErrorOccurredCouldntGetFormDataMsg";
                    this.color = "error";
                });
        },
        closeYearDate(date) {
            this.$refs.yearMenu.save(date);
            this.$refs.picker.activePicker = 'YEAR'
            this.toDatePicker = false;
        },

    },
    computed: {
        ...mapGetters([

        ]),
        currentYear() {
            var today = new Date();

            var timestamp = today.getFullYear()

            return timestamp;
        },
    },
};
</script>
