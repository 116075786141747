import apiCall from '@/utils/api'

const state = {
	staff: {},
	staffs: [],
    staffPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    staffLoader: false
};

const getters = {
    staffLoader: (state) => state.staffLoader,
	staffs: (state) => state.staffs,
	staff: (state) => state.staff,
    staffPagination: (state) => state.staffPagination
}

const actions = {
    async fetchStaffs({commit}, page) {
		const response = await apiCall({url: `/api/staff?page=${page}`, method: 'GET' });
		commit('setStaffs', response)
        commit('stopLoader', response)
	},
    async fetchStaff({commit}, itemId) {
		const response = await apiCall({url: `/api/staff/${itemId}`, method: 'GET' });
		commit('setStaff', response)
        commit('stopLoader', response)
	},
    async filterStaffs({commit, state},resp){
		commit('setStaffs', resp)
	},
	async startStaffLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopStaffLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setStaffs: (state, staffs) => {
		state.staffs = staffs.data
		state.staffPagination.current_page = staffs.current_page
		state.staffPagination.total = staffs.total
		state.staffPagination.per_page = staffs.per_page
	},
	setStaff: (state, staff) => {
		state.staff = staff
	},
    startLoader: (state) => state.staffLoader = true,
	stopLoader: (state) => state.staffLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
