<template>
  <v-footer
    inset
    relative 
    dark
    padless
    width="auto"
    color="header"
    height="auto"
  >
  <v-layout column>
      
      <v-flex xs12 class="header">
        <v-divider class="black--text mt-10"></v-divider>
          <v-container class="my-5">
            <v-layout row wrap>
                <v-flex xs12 md6>
                <a href="" target="_blank" class="black--text" style="text-decoration: none;">Privacy Policy</a>
                    •
                <a href="" target="_blank" class="black--text" style="text-decoration: none;">Terms of Use</a>
                </v-flex>
                <v-flex xs12 md6>
                <div align="right" class="black--text">
                    © {{ new Date().getFullYear() }} • <a href="http://www.ilabafrica.ac.ke/" target="_blank" class="black--text" style="text-decoration: none;">@iLabAfrica</a>
                </div>
                </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
  </v-layout>
  
        
  </v-footer>
</template>
<script>
  export default {
    data: () => ({
      socials: [
        {icon: 'mdi-facebook', link: 'https://www.facebook.com/iLabAfricaStrathmore/?fref=ts'},
        {icon: 'mdi-twitter', link: 'https://twitter.com/ilabafrica'},
        {icon: 'mdi-linkedin', link: 'https://www.linkedin.com/company/2596334?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A2596334%2Cidx%3A2-1-2%2CtarId%3A1465459099320%2Ctas%3AILABAFRICA'},
        {icon: 'mdi-youtube', link: 'https://www.youtube.com/channel/UCeo0kj1SRiVkje78cyxnAzQ'},
        {icon: 'mdi-instagram', link: 'https://www.instagram.com/ilabafrica/?hl=en'},
      ],
    }),
  }
</script>