<template>
  <div class="levels" v-if="$can('class_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ $t(message) }}</span>
    </v-snackbar>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <div class="title">Class</div>
        </v-flex>
        <div v-if="classView == 'default'">
          <v-flex xs12 class="mt-5 mb-2">
            <v-card elevation="0" class="header">
              <v-layout row wrap>
                <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                <v-flex xs12 md4>
                  <v-text-field
                    outlined
                    rounded
                    dense
                    :label="$t('searchMsg')"
                    append-icon="mdi-undo-variant"
                    @click:append="resetSearch()"
                    v-on:keyup.enter="search"
                    v-model="searchTerm"
                    @input="enableSearch()"
                    class="background search_field my-3 mx-5"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <!-- <div class="pa-1">
                    <v-btn
                      class="secondary text-none"
                      rounded
                      depressed
                      @click="search"
                      :loading="searchLoader"
                      :disabled="searchButtonDisabled"
                    >
                      {{ $t("searchMsg") }}
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </div> -->
                  <div align="center" class="my-4">
                    <v-btn
                      rounded
                      depressed
                      class="white--text universal text-none"
                      @click="changeView('create')"
                      v-if="$can('class_create')"
                    >
                      Add Class
                      <v-icon right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <div v-if="levels.length == 0">
              <v-card elevation="0">
                <v-layout row wrap>
                  <v-flex xs12 md1>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          <v-icon large class="primary--text">
                            mdi-alert-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md11>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center"> No Class Found </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-card>
            </div>
            <div v-else>
              <v-progress-linear
                v-if="levelLoader"
                height="1"
                indeterminate
                color="primary"
              ></v-progress-linear>
              <div class="hidden-sm-and-down">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="header">
                      <tr>
                        <th class="text-left">{{ $t("institutionMsg") }}</th>
                        <th class="text-left">{{ $t("nameMsg") }}</th>
                        <th class="text-left">{{ $t("startDateMsg") }}</th>
                        <th class="text-left">{{ $t("endDateMsg") }}</th>
                        <th class="text-right">{{ $t("actionsMsg") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in levels" :key="item.id">
                        <td>{{ item.institution.name }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.start_date | moment("DD/MM/YYYY") }}</td>
                        <td>{{ item.end_date | moment("DD/MM/YYYY") }}</td>
                        <td>
                          <div align="right">
                            <v-tooltip top v-if="$can('class_view')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="showLevel(item)"
                                  class="button mr-1 universal--text"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon small> mdi-eye </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("viewMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('class_edit')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  class="button mr-1 success--text"
                                  @click="editLevel(item)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("editMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('class_archive')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  class="button mr-1 error--text"
                                  @click="deleteLevel(item)"
                                  :loading="loading && levelIndex == item.id"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("deleteMsg") }}</span>
                            </v-tooltip>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="hidden-md-and-up">
                <v-layout column>
                  <template v-for="(level, index) in levels">
                    <div :key="index">
                      <v-flex xs12 class="mb-2">
                        <v-card elevation="0">
                          <div class="pa-5">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <div class="title primary--text">
                                      <b>{{ level.name }}</b>
                                    </div>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn
                                      depressed
                                      class="red--text"
                                      :loading="
                                        loading && levelIndex == level.id
                                      "
                                      icon
                                      @click="deleteLevel(level)"
                                      v-if="$can('class_archive')"
                                    >
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-divider class="mt-2"></v-divider>
                              <v-flex xs12 class="mt-1">
                                <v-layout column>
                                  <v-flex xs12 class="mt-1">
                                    <div>
                                      <b>{{ $t("dateAddedMsg") }}</b>
                                    </div>
                                    <div>
                                      {{
                                        level.created_at
                                          | moment("DD/MM/YYYY - hh:m a")
                                      }}
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="pa-1">
                                          <v-btn
                                            depressed
                                            class="
                                              primary
                                              text-none
                                              white--text
                                            "
                                            block
                                            @click="showLevel(level)"
                                            v-if="$can('class_view')"
                                            >{{ $t("viewMsg") }}
                                            <v-icon right> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div class="pa-1">
                                          <v-btn
                                            depressed
                                            class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                            "
                                            block
                                            @click="editLevel(level)"
                                            v-if="$can('class_edit')"
                                            >{{ $t("editMsg") }}
                                            <v-icon right>
                                              mdi-border-color
                                            </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <v-layout row wrap>
              <v-flex xs12 md9>
                <div align="left">
                  <v-pagination
                    v-if="length != 0 && length != 1"
                    :length="length"
                    total-visible="10"
                    v-model="levelPagination.current_page"
                    @input="changePage()"
                    circle
                  >
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div v-if="length != 0" align="right" class="mr-3">
                  <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b
                  >{{ levelPagination.total | formatNumber }}
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </div>
        <div v-if="classView == 'create'">
          <v-flex class="mt-2">
            <v-card elevation="0">
              <v-card-title class="header">
                <v-spacer></v-spacer>
                <div v-if="newLevel.id == null">Add Class</div>
                <div v-else>Edit Class</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5">
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>{{ $t("institutionMsg") }}*</div>
                        <v-select
                          :items="formData.institutions"
                          class="text_field background"
                          item-value="id"
                          item-text="name"
                          outlined
                          v-model="newLevel.institution_id"
                          dense
                          :rules="inputRules"
                        ></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>
                          {{ $t("nameMsg") }}<span class="error--text">*</span>
                        </div>
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          v-model="newLevel.name"
                          :rules="inputRules"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>Start Date*</div>

                        <v-menu
                          :close-on-content-click="false"
                          max-width="290"
                          transition="scale-transition"
                          v-model="fromDatePicker"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              dense
                              :value="formatFromDate"
                              slot="activator"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="text_field background"
                              :rules="inputRules"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="newLevel.start_date"
                            :max="newLevel.end_date"
                            @change="closeFromDate"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>End Date*</div>

                        <v-menu
                          :close-on-content-click="false"
                          max-width="290"
                          transition="scale-transition"
                          v-model="toDatePicker"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              dense
                              :value="formatToDate"
                              slot="activator"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="text_field background"
                              :rules="inputRules"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="newLevel.end_date"
                            :min="newLevel.start_date"
                            @change="closeToDate"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  depressed
                  class="universal white--text text-none mt-3"
                  @click="save"
                  :disabled="!valid"
                  :loading="loading"
                >
                  {{ $t("saveMsg") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="classView == 'show'">
          <v-card elevation="0" class="background">
            <v-card-title class="header">
              <v-spacer></v-spacer>
              <div>View Class</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="pa-3 mt-5">
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Institution: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>{{ assignedLevel.institution.name }}</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Class Name: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>{{ assignedLevel.name }}</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Start Date: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>
                                  {{
                                    assignedLevel.start_date
                                      | moment("DD MMM YYYY")
                                  }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>End Date: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>
                                  {{
                                    assignedLevel.end_date
                                      | moment("DD MMM YYYY")
                                  }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      classView: "default",
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,

      levelIndex: null,
      snackbar: false,
      inputRules: [(v) => !!v || "Input is required"],

      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      filteredLevels: false,
      newLevel: {
        id: null,
        name: null,
        institution_id: null,
        start_date: null,
        end_date: null,
      },
      assignedLevel: null,
      formData: null,

      fromDatePicker: false,
      toDatePicker: false,
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
    };
  },
  created() {
    if (this.$can("class_view")) {
      this.startLevelLoader();
      this.fetchLevels(this.levelPagination.current_page);
      this.getFormData();
    }
  },
  methods: {
    ...mapActions([
      "fetchLevels",
      "startLevelLoader",
      "stopLevelLoader",
      "filterLevels",
    ]),
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredLevels = true;
      this.startLevelLoader();
      this.fetchLevels(1);
    },
    showLevel(level) {
      this.assignedLevel = level;
      this.changeView("show");
    },
    editLevel(level) {
      this.newLevel.id = level.id;
      this.newLevel.name = level.name;
      this.newLevel.institution_id = level.institution_id;
      this.newLevel.start_date = level.start_date;
      this.newLevel.end_date = level.end_date;
      this.changeView("create");
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.newLevel.id != null) {
          confirm("Are you sure you want to update class?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/level/" + this.newLevel.id,
              data: this.newLevel,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Class Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchLevels(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create class?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/level",
              data: this.newLevel,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Class Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchLevels(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchLevels(1);
              });
          }
        }
      }
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startLevelLoader();
        apiCall({
          url: "/api/level?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterLevels(resp);
            this.searchLoader = false;
            this.stopLevelLoader();
            this.filteredLevels = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopLevelLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeView(classView) {
      if (classView == "default") {
        this.newLevel.id = null;
        this.newLevel.name = null;
        this.newLevel.institution_id = null;
        this.newLevel.start_date = null;
        this.newLevel.end_date = null;
      } else if (classView == "create") {
        this.getFormData();
      } else if (classView == "show") {
      }
      this.classView = classView;
    },
    changePage() {
      this.startLevelLoader();
      if (this.filteredLevels == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/level?page=" +
            this.levelPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterLevels(resp);
            this.stopLevelLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopLevelLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchLevels(this.levelPagination.current_page);
      }
    },
    deleteLevel(item) {
      confirm("Are You Sure You Want to Delete Class") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.levelIndex = item.id;
        apiCall({ url: "/api/level/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Class Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.levelIndex = null;
            this.fetchLevels(this.levelPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.levelIndex = null;
            console.log(error.response);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/level?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    closeFromDate() {
      this.fromDatePicker = false;
    },
    closeToDate() {
      this.toDatePicker = false;
    },
  },
  computed: {
    ...mapGetters(["levels", "levelPagination", "levelLoader"]),
    length: function () {
      return Math.ceil(
        this.levelPagination.total / this.levelPagination.per_page
      );
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
    formatFromDate() {
      if (this.newLevel.start_date != null) {
        const d = new Date(this.newLevel.start_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatToDate() {
      if (this.newLevel.end_date != null) {
        const d = new Date(this.newLevel.end_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
  },
};
</script>
