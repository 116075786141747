<template>
  <div>
    <v-app-bar elevate-on-scroll class="header" app>
      <v-app-bar-nav-icon @click="drawer = !drawer" class="universal--text"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-layout row wrap class="mr-5 hidden-sm-and-down">
        <v-flex xs8> </v-flex>
        <v-flex xs1>
          <v-img v-if="$i18n.locale == 'en'" max-width="25" src="/img/flags/english.png" class="ml-3 mt-1"></v-img>
          <v-img v-if="$i18n.locale == 'fr'" max-width="25" src="/img/flags/french.ico" class="ml-3 mt-1"></v-img>
        </v-flex>
        <v-flex xs3>
          <v-select outlined dense :items="languages" item-text="title" item-value="language" v-model="languageChoice"
            class="language_field background" @input="changeLocale()"></v-select>
        </v-flex>
      </v-layout>
      <!-- <v-btn icon to="/notifications" class="mr-2">
        <div v-if="number != 0">
          <v-badge light overlap color="red" :content="number">
            <v-icon dark class="universal--text"> mdi-bell </v-icon>
          </v-badge>
        </div>
        <div v-else>
          <v-icon dark class="universal--text"> mdi-bell </v-icon>
        </div>
      </v-btn>
      <v-btn icon to="/messages">
        <div v-if="number2 != 0">
          <v-badge light overlap color="red" :content="number2">
            <v-icon dark class="universal--text"> mdi-android-messages </v-icon>
          </v-badge>
        </div>
        <div v-else>
          <v-icon dark class="universal--text"> mdi-android-messages </v-icon>
        </div>
      </v-btn> -->
      <v-menu left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn large text v-bind="attrs" v-on="on" class="ml-3 text-none">
            <v-avatar size="36" v-if="getProfile.profile_pic != null">
              <img :src="path+'/profile_pics/'+getProfile.profile_pic" alt="PPIC" />
            </v-avatar>
            <v-avatar v-else>
              <v-icon> mdi-account-circle </v-icon>
            </v-avatar>
            <div class="ml-2">{{ getProfile.name }}</div>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item router to="/profile">
            <v-list-item-title>{{ $t("profileMsg") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut">
            <v-list-item-title>{{ $t("signOutMsg") }}</v-list-item-title>
          </v-list-item>

          <v-tooltip v-if="!$vuetify.theme.dark" bottom>
            <template v-slot:activator="{ on }">
              <div class="mx-2 mt-3">
                <v-btn deprsessed v-on="on" color="info" small @click="changeDarkState" block>
                  <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("darkModeMsg") }}</span>
          </v-tooltip>

          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
              <div class="mx-2 mt-3">
                <v-btn deprsessed v-on="on" color="info" small @click="changeDarkState" block>
                  <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("lightModeMsg") }}</span>
          </v-tooltip>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" class="nav">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content>
            <v-img width="10" src="img/UNHCR.png"> </v-img>
          </v-list-item-content>
        </v-list-item>
      </template>
      <div class="mb-5 mt-5 hidden-md-and-up ma-5">
        <v-layout row wrap>
          <v-flex xs2>
            <div align="right">
              <v-img v-if="$i18n.locale == 'en'" max-width="25" src="/img/flags/english.png" class="mt-2 mr-2"></v-img>
              <v-img v-if="$i18n.locale == 'fr'" max-width="25" src="/img/flags/french.ico" class="mt-2 mr-2"></v-img>
            </div>
          </v-flex>
          <v-flex xs10>
            <v-select outlined dense :items="languages" item-text="title" item-value="language" v-model="languageChoice"
              class="language_field background" @input="changeLocale()"></v-select>
          </v-flex>
        </v-layout>
      </div>
      <v-divider class="mx-5 mb-3 white"></v-divider>
      <v-list dense shaped>
        <div v-if="$can('admin_dashboard_view') || $can('staff_view') || $can('student_view')">
          <template v-for="(item, index) in adminPages">
            <v-list-item :key="index" class="white" router :to="item.route"
              v-if="item.route == $route.path && $can(item.permission)">
              <v-list-item-icon>
                <v-icon class="universal--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="universal--text">
                  {{ $t(item.title) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :key="index" router :to="item.route" v-if="item.route != $route.path && $can(item.permission)">
              <v-list-item-icon>
                <v-icon class="white--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  {{ $t(item.title) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>
        <div v-if="$can('teacher_student_view')">
          <template v-for="(item, index) in teacherPages">
            <v-list-item :key="index" class="white" router :to="item.route"
              v-if="item.route == $route.path && $can(item.permission)">
              <v-list-item-icon>
                <v-icon class="universal--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="universal--text">
                  {{ $t(item.title) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :key="index" router :to="item.route" v-if="item.route != $route.path && $can(item.permission)">
              <v-list-item-icon>
                <v-icon class="white--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  {{ $t(item.title) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>
        <v-list-group no-action v-if="$can('reports_view')" class="white--text" color="white" dark>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon class="white--text">mdi-poll</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                Reports
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(item, index) in reportsPages">
            <v-list-item :key="index" class="white" router :to="item.route"
              v-if="item.route == $route.path && $can(item.permission)">
              <v-list-item-icon>
                <v-icon class="universal--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="universal--text">
                  {{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :key="index" router :to="item.route" v-if="item.route != $route.path && $can(item.permission)">
              <v-list-item-icon>
                <v-icon class="white--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">{{
                    $t(item.title)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <div v-if="$can('reports_view')">
          <v-list-item class="white" router to="/computed-reports"
              v-if="$route.path == '/computed-reports'">
              <v-list-item-icon>
                <v-icon class="universal--text">mdi-slash-forward-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="universal--text">
                  Computed Reports
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/computed-reports" v-if="$route.path != '/computed-reports'">
              <v-list-item-icon>
                <v-icon class="white--text">mdi-slash-forward-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  Computed Reports
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </div>
        <v-list-group no-action v-if="
          $can('location_view') ||
          $can('institution_view') ||
          $can('calendar_view')
        " class="white--text" color="white" dark>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon class="white--text">mdi-tune</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{
                  $t("administrationMsg")
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(item, index) in administrationPages">
            <v-list-item :key="index" class="white" router :to="item.route"
              v-if="item.route == $route.path && $can(item.permission)">
              <v-list-item-icon>
                <v-icon class="universal--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="universal--text">
                  {{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :key="index" router :to="item.route" v-if="item.route != $route.path && $can(item.permission)">
              <v-list-item-icon>
                <v-icon class="white--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  {{ $t(item.title) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-group no-action v-if="$can('role_view') || $can('user_view')" class="white--text" color="white" dark>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon class="white--text">mdi-shield-half-full</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{
                  $t("accessControlMsg")
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(item, index) in accessControlPages">
            <v-list-item :key="index" class="white" router :to="item.route"
              v-if="item.route == $route.path && $can(item.permission)">
              <v-list-item-icon>
                <v-icon class="universal--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="universal--text">
                  {{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :key="index" router :to="item.route" v-if="item.route != $route.path && $can(item.permission)">
              <v-list-item-icon>
                <v-icon class="white--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">{{
                    $t(item.title)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import Vue from "vue";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapState, mapActions } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: true,
      adminPages: [
      {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "/school-admin-home",
          permission: "school_admin_dashboard_view",
        },
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "/admin-home",
          permission: "admin_dashboard_view",
        },
        {
          title: "School Admins",
          icon: "mdi-account-child-circle",
          route: "/school-admins",
          permission: "school_admin_view",
        },
        {
          title: "staffMsg",
          icon: "mdi-clipboard-account",
          route: "/staff",
          permission: "staff_view",
        },
        {
          title: "Students",
          icon: "mdi-account-group",
          route: "/students",
          permission: "student_view",
        },
      ],
      teacherPages: [
        {
          title: "Home",
          icon: "mdi-home",
          route: "/staff-home",
        },
        {
          title: "Classes",
          icon: "mdi-human-male-board",
          route: "/staff-students",
          permission: "teacher_student_view",
        },
        {
          title: "Attendance",
          icon: "mdi-list-status",
          route: "/attendance",
          permission: "teacher_student_view",
        },
      ],
      accessControlPages: [
        {
          title: "rolesMsg",
          icon: "mdi-account-hard-hat",
          route: "/roles",
          permission: "role_view",
        },
        {
          title: "usersMsg",
          icon: "mdi-account-multiple",
          route: "/users",
          permission: "user_view",
        },
      ],
      reportsPages: [
        {
          title: "Attendance",
          icon: "mdi-order-bool-ascending-variant",
          route: "/reports/attendance",
          permission: "reports_view",
        },
        {
          title: "Schools",
          icon: "mdi-town-hall",
          route: "/reports/schools",
          permission: "reports_view",
        },
        {
          title: "Students",
          icon: "mdi-account-school",
          route: "/reports/students",
          permission: "reports_view",
        },
        {
          title: "Teachers",
          icon: "mdi-human-male-board-poll",
          route: "/reports/teachers",
          permission: "reports_view",
        },
        {
          title: "Classrooms",
          icon: "mdi-google-classroom",
          route: "/reports/classrooms",
          permission: "reports_view",
        },
        {
          title: "Latrines/Toilets",
          icon: "mdi-gender-male-female",
          route: "/reports/toilets",
          permission: "reports_view",
        },
        {
          title: "Elec/Internet",
          icon: "mdi-lightning-bolt",
          route: "/reports/electricity-internet",
          permission: "reports_view",
        },
      ],
      administrationPages: [
        {
          title: "locationsMsg",
          icon: "mdi-map-marker",
          route: "/locations",
          permission: "location_view",
        },
        {
          title: "institutionsMsg",
          icon: "mdi-domain",
          route: "/institutions",
          permission: "institution_view",
        },
        {
          title: "School Calendar",
          icon: "mdi-calendar",
          route: "/school-calendar",
          permission: "calendar_view",
        },
        {
          title: "Table Data",
          icon: "mdi-table-check",
          route: "/table-data",
          permission: "table_data_view",
        },
      ],
      number: 5,
      number2: 3,
      languageChoice: "",
      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "fr", language: "fr", title: "Français" },
      ],
    };
  },
  watch: {
    darkState(oldval, newval) {
      this.handledarkmode();
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    this.$store.dispatch(USER_REQUEST);
  },
  methods: {
    ...mapActions(["changeDarkState", "changeLanguage"]),
    signOut: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/"));
    },
    initialize() {
      this.languageChoice = this.language;
    },
    handledarkmode() {
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
    changeLocale() {
      i18n.locale = this.languageChoice;
      this.changeLanguage(this.languageChoice);
    },
  },
  computed: {
    ...mapGetters(["getProfile", "darkState", "language"]),
  },
};
</script>