import apiCall from '@/utils/api'

const state = {
	student: {},
	students: [],
    studentPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    studentLoader: false
};

const getters = {
    studentLoader: (state) => state.studentLoader,
	students: (state) => state.students,
	student: (state) => state.student,
    studentPagination: (state) => state.studentPagination
}

const actions = {
    async fetchStudents({commit}, page) {
		const response = await apiCall({url: `/api/student?page=${page}`, method: 'GET' });
		commit('setItems', response)
        commit('stopLoader', response)
	},
    async fetchStudent({commit}, itemId) {
		const response = await apiCall({url: `/api/student/${itemId}`, method: 'GET' });
		commit('setItem', response)
        commit('stopLoader', response)
	},
    async filterStudents({commit, state},resp){
		commit('setItems', resp)
	},
	async startStudentLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopStudentLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setItems: (state, students) => {
		state.students = students.data
		state.studentPagination.current_page = students.current_page
		state.studentPagination.total = students.total
		state.studentPagination.per_page = students.per_page
	},
	setItem: (state, student) => {
		state.student = student
	},
    startLoader: (state) => state.studentLoader = true,
	stopLoader: (state) => state.studentLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
