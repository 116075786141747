import apiCall from '@/utils/api'

const state = {
	attendance: {},
	attendance_list: [],
    attendancePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    attendanceLoader: false
};

const getters = {
    attendanceLoader: (state) => state.attendanceLoader,
	attendance_list: (state) => state.attendance_list,
	attendance: (state) => state.attendance,
    attendancePagination: (state) => state.attendancePagination
}

const actions = {
    async fetchAttendanceList({commit}, page) {
		const response = await apiCall({url: `/api/attendance?page=${page}`, method: 'GET' });
		commit('setAttendanceList', response)
        commit('stopLoader', response)
	},
    async fetchAttendance({commit}, itemId) {
		const response = await apiCall({url: `/api/attendance/${itemId}`, method: 'GET' });
		commit('setAttendance', response)
        commit('stopLoader', response)
	},
    async filteredAttendanceList({commit, state},resp){
		commit('setAttendanceList', resp)
	},
	async startAttendanceLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopAttendanceLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setAttendanceList: (state, attendance_list) => {
		state.attendance_list = attendance_list.data
		state.attendancePagination.current_page = attendance_list.current_page
		state.attendancePagination.total = attendance_list.total
		state.attendancePagination.per_page = attendance_list.per_page
	},
	setAttendance: (state, attendance) => {
		state.attendance = attendance
	},
    startLoader: (state) => state.attendanceLoader = true,
	stopLoader: (state) => state.attendanceLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
