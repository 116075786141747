<template>
    <div class="calendars" v-if="$can('calendar_view')">
        <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
            <span>{{ $t(message) }}</span>
        </v-snackbar>
        <v-container>
            <v-layout column>
                <v-flex xs12>
                    <div class="title">Calendars</div>
                </v-flex>
                <div v-if="view == 'default'">
                    <!-- <v-flex xs12 class="mt-5">
                        <v-card elevation="0" class="header">
                            <v-layout row wrap>
                                <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                                <v-flex xs12 md4>
                                    <v-text-field outlined rounded dense :label="$t('searchMsg')"
                                        append-icon="mdi-undo-variant" @click:append="resetSearch()"
                                        v-on:keyup.enter="search" v-model="searchTerm" @input="enableSearch()"
                                        class="background search_field my-3 mx-5"></v-text-field>
                                </v-flex>
                                <v-flex xs12 md4>
                                    
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-flex> -->
                    <v-flex xs12 class="mt-10">
                        <div v-if="calendars.length == 0">
                            <v-card elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md1>
                                        <v-container fill-height fluid>
                                            <v-row align="center" justify="center">
                                                <v-col class="text-center">
                                                    <v-icon large class="primary--text">
                                                        mdi-alert-circle-outline
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-flex>
                                    <v-flex xs12 md11>
                                        <v-container fill-height fluid>
                                            <v-row align="center" justify="center">
                                                <v-col class="text-center">
                                                    No Calendar Found
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </div>
                        <div v-else>
                            <div class="hidden-sm-and-down">
                                <div>
                                    <v-progress-linear v-if="calendarLoader" height="1" indeterminate color="primary">
                                    </v-progress-linear>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead class="header">
                                                <tr>
                                                    <th class="text-left">{{ $t("nameMsg") }}</th>
                                                    <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in calendars" :key="item.id">
                                                    <td>{{ item.name }}</td>
                                                    <td>
                                                        <div align="right">
                                                            <v-btn v-if="$can('calendar_view')" icon @click="showCalendar(item)"
                                                                class="button mr-1 universal--text" v-bind="attrs"
                                                                v-on="on">
                                                                <v-icon small> mdi-eye </v-icon>
                                                            </v-btn>
                                                            <!-- </template>
                                <span>{{ $t("viewMsg") }}</span>
                              </v-tooltip>
                              <v-tooltip top v-if="$can('location_edit')">
                                <template v-slot:activator="{ on, attrs }"> -->
                                                            <!-- <v-btn icon class="button mr-1 success--text"
                                                                @click="editCalendar(item)" v-bind="attrs" v-on="on">
                                                                <v-icon small> mdi-pencil </v-icon>
                                                            </v-btn> -->
                                                            <!-- </template>
                                <span>{{ $t("editMsg") }}</span>
                              </v-tooltip>
                              <v-tooltip top v-if="$can('location_archive')">
                                <template v-slot:activator="{ on, attrs }"> -->
                                                            <!-- <v-btn icon class="button mr-1 error--text"
                                                                @click="deleteCalendar(item)"
                                                                :loading="loading && calendarIndex == item.id"
                                                                v-bind="attrs" v-on="on">
                                                                <v-icon small> mdi-delete </v-icon>
                                                            </v-btn> -->
                                                            <!-- </template>
                                <span>{{ $t("deleteMsg") }}</span>
                              </v-tooltip> -->
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </div>
                            <div class="hidden-md-and-up">
                                <v-layout column>
                                    <template v-for="(calendar, index) in calendars">
                                        <div :key="index">
                                            <v-flex xs12 class="mb-2">
                                                <v-card elevation="0">
                                                    <div class="pa-5">
                                                        <v-layout column>
                                                            <v-flex xs12>
                                                                <v-layout row wrap>
                                                                    <v-flex xs11>
                                                                        <div class="title primary--text">
                                                                            <b>{{ calendar.name }}</b>
                                                                        </div>
                                                                    </v-flex>
                                                                    <v-flex xs1>
                                                                        <v-btn depressed class="red--text"
                                                                            :loading="loading && calendarIndex == calendar.id"
                                                                            icon @click="deleteCalendar(calendar)"
                                                                            v-if="$can('calendar_archive')">
                                                                            <v-icon> mdi-delete </v-icon>
                                                                        </v-btn>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                            <v-divider class="mt-2"></v-divider>
                                                            <v-flex xs12 class="mt-1">
                                                                <v-layout column>
                                                                    <v-flex xs12 class="mt-1">
                                                                        <div>
                                                                            <b>{{ $t("dateAddedMsg") }}</b>
                                                                        </div>
                                                                        <div>
                                                                            {{
                                                                                    calendar.created_at
                                                                                    | moment("DD/MM/YYYY - hh:m a")
                                                                            }}
                                                                        </div>
                                                                    </v-flex>
                                                                    <v-flex xs12 class="mt-5">
                                                                        <v-layout column>
                                                                            <v-flex xs12>
                                                                                <div class="pa-1">
                                                                                    <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                            " block @click="showCalendar(calendar)" v-if="$can('calendar_view')">{{
                                                    $t("viewMsg")
                                            }}
                                                                                        <v-icon right> mdi-eye </v-icon>
                                                                                    </v-btn>
                                                                                </div>
                                                                            </v-flex>
                                                                            <v-flex xs12>
                                                                                <div class="pa-1">
                                                                                    <v-btn depressed class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                            " block @click="editCalendar(calendar)" v-if="$can('location_edit')">{{
                                                    $t("editMsg")
                                            }}
                                                                                        <v-icon right>
                                                                                            mdi-border-color
                                                                                        </v-icon>
                                                                                    </v-btn>
                                                                                </div>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                        </v-layout>
                                                    </div>
                                                </v-card>
                                            </v-flex>
                                        </div>
                                    </template>
                                </v-layout>
                            </div>
                        </div>
                    </v-flex>
                    <v-flex xs12 class="mt-10">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                                        v-model="calendarPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="length != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>{{
                                            calendarPagination.total | formatNumber
                                    }}
                                </div>
                            </v-flex>

                        </v-layout>
                    </v-flex>
                </div>
                <div v-if="view == 'create'">
                    <v-flex class="mt-2">
                        <v-card elevation="0" class="background">
                            <v-card-title class="header">
                                <v-spacer></v-spacer>
                                <div v-if="newCalendar.id == null">Add Calendar</div>
                                <div v-else>Edit Calendar</div>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="changeView('default')">
                                    <v-icon> mdi-close </v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-form v-model="valid" ref="form" lazy-validation>
                                    <v-layout row wrap class="mt-5 mb-15">
                                        <v-flex xs12 md4>
                                            <div class="pa-3">
                                                <div>Institution Type<span class="error--text">*</span></div>
                                                <v-select :items="formData.institutionTypes"
                                                    v-model="newCalendar.institution_type_id"
                                                    class="text_field background my-2" item-value="id" item-text="name"
                                                    outlined dense :rules="inputRules"></v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-3">
                                                <div>{{ $t("nameMsg") }}<span class="error--text">*</span></div>
                                                <v-text-field class="text_field background my-2" outlined dense
                                                    v-model="newCalendar.name" :rules="inputRules"></v-text-field>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-3" align="right">
                                                <v-btn rounded depressed class="universal white--text text-none mt-7"
                                                    @click="save" :disabled="!valid" :loading="loading">
                                                    {{ $t("saveMsg") }}
                                                    <v-icon right> mdi-content-save </v-icon>
                                                </v-btn>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-form>
                            </v-card-text>
                            <!-- <v-card-actions class="mt-15">
                <v-spacer></v-spacer>
                <v-btn rounded depressed class="universal white--text text-none mt-15" @click="save" :disabled="!valid"
                  :loading="loading">
                  {{ $t("saveMsg") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions> -->
                        </v-card>
                    </v-flex>
                </div>
                <div v-if="view == 'show'">

                    <v-card elevation="0" class="background">
                        <v-card-title class="header">
                            <v-spacer></v-spacer>
                            <div>View Calendar</div>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="changeView('default')">
                                <v-icon> mdi-close </v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div class="pa-3 mt-5">
                                <v-layout column>
                                    <v-flex xs12>
                                        <v-layout row wrap>
                                            <v-flex xs12 md6>
                                                <v-layout column>
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs12 md4>
                                                                <b>Calendar Name: </b>
                                                            </v-flex>
                                                            <v-flex xs12 md8>
                                                                <div>{{ assignedCalendar.name }}</div>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                    
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs12 md6>
                                                <v-layout column>
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs12 md4>
                                                                <b>Calendars: </b>
                                                            </v-flex>
                                                            <v-flex xs12 md8>
                                                                <div>{{ assignedCalendar.calendars.length }}</div>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-card-text>
                        <v-card-title class="header">
                            <v-spacer></v-spacer>
                            <div>School Calendar</div>
                            <v-spacer></v-spacer>
                            <div align="center">
                                <v-btn rounded depressed class="white--text universal text-none"
                                    @click="changeView('create')" v-if="$can('location_create')">
                                    Add Calendar
                                    <v-icon right>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <div class="pa-3 mt-5">
                                <div v-if="assignedCalendar.calendars.length == 0">
                                    <v-card elevation="0">
                                        <v-layout row wrap>
                                            <v-flex xs12 md1>
                                                <v-container fill-height fluid>
                                                    <v-row align="center" justify="center">
                                                        <v-col class="text-center">
                                                            <v-icon large class="primary--text">
                                                                mdi-alert-circle-outline
                                                            </v-icon>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-flex>
                                            <v-flex xs12 md11>
                                                <v-container fill-height fluid>
                                                    <v-row align="center" justify="center">
                                                        <v-col class="text-center">
                                                            No Calendar Found
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </div>
                                <div v-else>
                                    <v-layout column>
                  <template v-for="(calendar, index) in assignedCalendar.calendars">
                    <div :key="index">
                      <v-flex xs12>
                        <v-card elevation="0">
                          <div class="pa-3">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                    <v-flex xs1>
                                        {{index + 1}}.
                                    </v-flex>
                                  <v-flex xs10>
                                    <div>
                                      {{ calendar.name }}
                                    </div>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn depressed class="red--text" :loading="
                                      loading &&
                                      calendarIndex == calendar.id
                                    " icon @click="deleteCalendar(calendar)" v-if="$can('calendar_archive')">
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>

                </div>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import Vue from 'vue'
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
    components: {

    },
    data() {
        return {
            view: "default",
            loading: false,
            valid: true,
            message: "",
            color: "",
            delete: false,
            confirm: false,

            calendarIndex: null,
            snackbar: false,
            inputRules: [
                (v) => !!v || "Input is required"
            ],

            searchTerm: null,
            searchLoader: false,
            searchButtonDisabled: false,
            filteredCalendars: false,
            newCalendar: {
                id: null,
                name: null,
                institution_type_id: null,
            },
            assignedCalendar: null,
            formData: null,
            dragging: false,
            views: [
                { name: 'List' },
                { name: 'Map' }
            ],
            clusterOptions: {},
        };
    },
    created() {
        if (this.$can("calendar_view")) {
            this.startCalendarLoader();
            this.fetchCalendars(this.calendarPagination.current_page);
            this.getFormData();
        }
    },
    methods: {
        ...mapActions([
            "fetchCalendars",
            "startCalendarLoader",
            "stopCalendarLoader",
            "filterCalendars",
        ]),
        enableSearch() {
            this.searchButtonDisabled = false;
        },
        resetSearch() {
            this.searchTerm = null;
            this.filteredCalendars = true;
            this.startCalendarLoader();
            this.fetchCalendars(1);
        },
        showCalendar(calendar) {
            this.assignedCalendar = calendar;
            this.changeView("show");
        },
        editCalendar(calendar) {
            this.newCalendar.id = calendar.id;
            this.newCalendar.name = calendar.name;
            this.newCalendar.institution_type_id = calendar.institution_type_id;

            this.changeView("create");
        },

        save() {
            if (this.$refs.form.validate()) {
                if (this.newCalendar.id != null) {
                    confirm('Are you sure you want to update calendar?') && (this.confirm = true);
                    if (this.confirm) {
                        this.loading = true;
                        apiCall({
                            url: "/api/calendar/" + this.newCalendar.id,
                            data: this.newCalendar,
                            method: "PUT",
                        })
                            .then((resp) => {
                                this.message = "Calendar Updated Successfully";
                                this.color = "orange";
                                this.loading = false;
                                this.snackbar = true;
                                this.confirm = false;
                                this.changeView("default");
                                this.fetchCalendars(1);
                            })
                            .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                    }
                } else {
                    confirm('Are you sure you want to create calendar?') && (this.confirm = true);
                    if (this.confirm) {
                        this.loading = true;
                        apiCall({
                            url: "/api/calendar",
                            data: this.newCalendar,
                            method: "POST",
                        })
                            .then((resp) => {
                                this.message = "Calendar Added Successfully";
                                this.color = "success";
                                this.loading = false;
                                this.snackbar = true;
                                this.changeView("default");
                                this.fetchCalendars(1);
                                apiCall({
                                    url: "/api/calendar?type=map",
                                    method: "GET",
                                })
                                    .then((resp) => {
                                        this.locationArray = resp
                                        this.calendarLoader = false
                                    })
                                    .catch((error) => {
                                        this.message = "anErrorOccurredEmailMsg";
                                        this.color = "error";
                                        this.loading = false;
                                        this.snackbar = true;
                                        this.calendarLoader = false
                                    });
                            })
                            .catch((error) => {
                                this.message = "anErrorOccurredEmailMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                                this.changeView("default");
                                this.fetchCalendars(1);
                            });
                    }
                }
            }
        },
        search() {
            if (this.searchTerm == null) {
                this.searchButtonDisabled = true;
            } else {
                this.searchLoader = true;
                this.searchButtonDisabled = false;
                this.startCalendarLoader();
                apiCall({
                    url: "/api/calendar?type=search&search=" + this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterCalendars(resp);
                        this.searchLoader = false;
                        this.stopCalendarLoader();
                        this.filteredCalendars = true;
                    })
                    .catch((error) => {
                        console.log(error.response);
                        this.searchLoader = false;
                        this.stopCalendarLoader();
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                    });
            }
        },
        changeView(view) {
            if (view == "default") {
                this.newCalendar.id = null;
                this.newCalendar.name = null;
                this.newCalendar.institution_type_id = null;
            } else if (view == "create") {
                this.newCalendar.institution_type_id = this.assignedCalendar.id
                this.getFormData();
            } else if (view == "show") {

            }
            this.view = view;
        },
        changePage() {
            this.startCalendarLoader();
            if (this.filteredCalendars == true) {
                this.searchButtonDisabled = false;
                apiCall({
                    url:
                        "/api/calendar?page=" +
                        this.calendarPagination.current_page +
                        "&type=search&search=" +
                        this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterCalendars(resp);
                        this.stopCalendarLoader();
                    })
                    .catch((error) => {
                        console.log(error.response);
                        this.stopCalendarLoader();
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                    });
            } else {
                this.fetchCalendars(this.calendarPagination.current_page);
            }
        },
        deleteCalendar(item) {
            confirm("Are You Sure You Want to Delete Calendar") && (this.delete = true);
            if (this.delete) {
                this.loading = true;
                this.calendarIndex = item.id;
                apiCall({ url: "/api/calendar/" + item.id, method: "DELETE" })
                    .then((resp) => {
                        this.message = "Calendar Deleted Succesfully";
                        this.color = "success";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.calendarIndex = null;
                        this.changeView("default")
                        this.fetchCalendars(this.calendarPagination.current_page);
                    })
                    .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.calendarIndex = null;
                        console.log(error.response);
                    });
            }
        },
        getFormData() {
            apiCall({
                url: "/api/calendar?type=formData",
                method: "GET",
            })
                .then((resp) => {
                    this.formData = resp;
                })
                .catch((error) => {
                    this.message = "anErrorOccurredCouldntGetFormDataMsg";
                    this.color = "error";
                });
        },
    },
    computed: {
        ...mapGetters([
            "calendars",
            "calendarPagination",
            "calendarLoader",
        ]),
        length: function () {
            return Math.ceil(
                this.calendarPagination.total / this.calendarPagination.per_page
            );
        },
    },
};
</script>
