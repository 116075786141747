import apiCall from '@/utils/api'

const state = {
	professionalInstitution: {},
	professionalInstitutions: [],
    professionalInstitutionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    professionalInstitutionLoader: false
};

const getters = {
    professionalInstitutionLoader: (state) => state.professionalInstitutionLoader,
	professionalInstitutions: (state) => state.professionalInstitutions,
	professionalInstitution: (state) => state.professionalInstitution,
    professionalInstitutionPagination: (state) => state.professionalInstitutionPagination
}

const actions = {
    async fetchProfessionalInstitutions({commit}, page) {
		const response = await apiCall({url: `/api/professional-institution?page=${page}`, method: 'GET' });
		commit('setProfessionalInstitutions', response)
        commit('stopProfessionalInstitutionLoader', response)
	},
    async fetchProfessionalInstitution({commit}, itemId) {
		const response = await apiCall({url: `/api/professional-institution/${itemId}`, method: 'GET' });
		commit('setProfessionalInstitution', response)
        commit('stopProfessionalInstitutionLoader', response)
	},
    async filterProfessionalInstitutions({commit, state},resp){
		commit('setProfessionalInstitutions', resp)
	},
	async startProfessionalInstitutionLoader({commit, state},resp){
		commit('startProfessionalInstitutionLoader', resp)
	},
	async stopProfessionalInstitutionLoader({commit, state},resp){
		commit('stopProfessionalInstitutionLoader', resp)
	},
};

const mutations = {
	setProfessionalInstitutions: (state, professionalInstitutions) => {
		state.professionalInstitutions = professionalInstitutions.data
		state.professionalInstitutionPagination.current_page = professionalInstitutions.current_page
		state.professionalInstitutionPagination.total = professionalInstitutions.total
		state.professionalInstitutionPagination.per_page = professionalInstitutions.per_page
	},
	setProfessionalInstitution: (state, professionalInstitution) => {
		state.professionalInstitution = professionalInstitution
	},
    startProfessionalInstitutionLoader: (state) => state.professionalInstitutionLoader = true,
	stopProfessionalInstitutionLoader: (state) => state.professionalInstitutionLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
