import apiCall from '@/utils/api'

const state = {
	absenceReason: {},
	absenceReasons: [],
    absenceReasonPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    absenceReasonLoader: false
};

const getters = {
    absenceReasonLoader: (state) => state.absenceReasonLoader,
	absenceReasons: (state) => state.absenceReasons,
	absenceReason: (state) => state.absenceReason,
    absenceReasonPagination: (state) => state.absenceReasonPagination
}

const actions = {
    async fetchAbsenceReasons({commit}, page) {
		const response = await apiCall({url: `/api/absence-reason?page=${page}`, method: 'GET' });
		commit('setAbsenceReasons', response)
        commit('stopAbsenceReasonLoader', response)
	},
    async fetchAbsenceReason({commit}, itemId) {
		const response = await apiCall({url: `/api/absence-reason/${itemId}`, method: 'GET' });
		commit('setAbsenceReason', response)
        commit('stopAbsenceReasonLoader', response)
	},
    async filterAbsenceReasons({commit, state},resp){
		commit('setAbsenceReasons', resp)
	},
	async startAbsenceReasonLoader({commit, state},resp){
		commit('startAbsenceReasonLoader', resp)
	},
	async stopAbsenceReasonLoader({commit, state},resp){
		commit('stopAbsenceReasonLoader', resp)
	},
};

const mutations = {
	setAbsenceReasons: (state, absenceReasons) => {
		state.absenceReasons = absenceReasons.data
		state.absenceReasonPagination.current_page = absenceReasons.current_page
		state.absenceReasonPagination.total = absenceReasons.total
		state.absenceReasonPagination.per_page = absenceReasons.per_page
	},
	setAbsenceReason: (state, absenceReason) => {
		state.absenceReason = absenceReason
	},
    startAbsenceReasonLoader: (state) => state.absenceReasonLoader = true,
	stopAbsenceReasonLoader: (state) => state.absenceReasonLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
