import apiCall from '@/utils/api'

const state = {
	staffLevelStream: {},
	staffLevelStreams: [],
    staffLevelStreamPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    staffLevelStreamLoader: false
};

const getters = {
    staffLevelStreamLoader: (state) => state.staffLevelStreamLoader,
	staffLevelStreams: (state) => state.staffLevelStreams,
	staffLevelStream: (state) => state.staffLevelStream,
    staffLevelStreamPagination: (state) => state.staffLevelStreamPagination
}

const actions = {
    async fetchStaffLevelStreams({commit}, institution_id) {
		const response = await apiCall({url: `/api/staff-level?institution_id=${institution_id}`, method: 'GET' });
		//commit('setStaffLevelStreams', response)
        commit('setStaffLevelStream', response)
        commit('stopLoader', response)
	},
    async fetchStaffLevelStream({commit}, itemId) {
		const response = await apiCall({url: `/api/staff-level/${itemId}`, method: 'GET' });
		commit('setStaffLevelStream', response)
        commit('stopLoader', response)
	},
    async filterStaffLevelStreams({commit, state},resp){
		commit('setStaffLevelStream', resp)
	},
	async startStaffLevelStreamLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopStaffLevelStreamLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setStaffLevelStreams: (state, staffLevelStreams) => {
		state.staffLevelStreams = staffLevelStreams.data
		state.staffLevelStreamPagination.current_page = staffLevelStreams.current_page
		state.staffLevelStreamPagination.total = staffLevelStreams.total
		state.staffLevelStreamPagination.per_page = staffLevelStreams.per_page
	},
	setStaffLevelStream: (state, staffLevelStream) => {
		state.staffLevelStream = staffLevelStream
	},
    startLoader: (state) => state.staffLevelStreamLoader = true,
	stopLoader: (state) => state.staffLevelStreamLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
