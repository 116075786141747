import apiCall from '@/utils/api'

const state = {
	academicQualification: {},
	academicQualifications: [],
    academicQualificationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    academicQualificationLoader: false
};

const getters = {
    academicQualificationLoader: (state) => state.academicQualificationLoader,
	academicQualifications: (state) => state.academicQualifications,
	academicQualification: (state) => state.academicQualification,
    academicQualificationPagination: (state) => state.academicQualificationPagination
}

const actions = {
    async fetchAcademicQualifications({commit}, page) {
		const response = await apiCall({url: `/api/academic-qualification?page=${page}`, method: 'GET' });
		commit('setAcademicQualifications', response)
        commit('stopAcademicQualificationLoader', response)
	},
    async fetchAcademicQualification({commit}, itemId) {
		const response = await apiCall({url: `/api/academic-qualification/${itemId}`, method: 'GET' });
		commit('setAcademicQualification', response)
        commit('stopAcademicQualificationLoader', response)
	},
    async filterAcademicQualifications({commit, state},resp){
		commit('setAcademicQualifications', resp)
	},
	async startAcademicQualificationLoader({commit, state},resp){
		commit('startAcademicQualificationLoader', resp)
	},
	async stopAcademicQualificationLoader({commit, state},resp){
		commit('stopAcademicQualificationLoader', resp)
	},
};

const mutations = {
	setAcademicQualifications: (state, academicQualifications) => {
		state.academicQualifications = academicQualifications.data
		state.academicQualificationPagination.current_page = academicQualifications.current_page
		state.academicQualificationPagination.total = academicQualifications.total
		state.academicQualificationPagination.per_page = academicQualifications.per_page
	},
	setAcademicQualification: (state, academicQualification) => {
		state.academicQualification = academicQualification
	},
    startAcademicQualificationLoader: (state) => state.academicQualificationLoader = true,
	stopAcademicQualificationLoader: (state) => state.academicQualificationLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
