import apiCall from '@/utils/api'

const state = {
	schoolCategory: {},
	schoolCategories: [],
    schoolCategoryPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    schoolCategoryLoader: false
};

const getters = {
    schoolCategoryLoader: (state) => state.schoolCategoryLoader,
	schoolCategories: (state) => state.schoolCategories,
	schoolCategory: (state) => state.schoolCategory,
    schoolCategoryPagination: (state) => state.schoolCategoryPagination
}

const actions = {
    async fetchSchoolCategories({commit}, page) {
		const response = await apiCall({url: `/api/school-category?page=${page}`, method: 'GET' });
		commit('setSchoolCategories', response)
        commit('stopSchoolCategoryLoader', response)
	},
    async fetchSchoolCategory({commit}, itemId) {
		const response = await apiCall({url: `/api/school-category/${itemId}`, method: 'GET' });
		commit('setSchoolCategory', response)
        commit('stopSchoolCategoryLoader', response)
	},
    async filterSchoolCategories({commit, state},resp){
		commit('setSchoolCategories', resp)
	},
	async startSchoolCategoryLoader({commit, state},resp){
		commit('startSchoolCategoryLoader', resp)
	},
	async stopSchoolCategoryLoader({commit, state},resp){
		commit('stopSchoolCategoryLoader', resp)
	},
};

const mutations = {
	setSchoolCategories: (state, schoolCategories) => {
		state.schoolCategories = schoolCategories.data
		state.schoolCategoryPagination.current_page = schoolCategories.current_page
		state.schoolCategoryPagination.total = schoolCategories.total
		state.schoolCategoryPagination.per_page = schoolCategories.per_page
	},
	setSchoolCategory: (state, schoolCategory) => {
		state.schoolCategory = schoolCategory
	},
    startSchoolCategoryLoader: (state) => state.schoolCategoryLoader = true,
	stopSchoolCategoryLoader: (state) => state.schoolCategoryLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
