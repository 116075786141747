import apiCall from '@/utils/api'

const state = {
	termsOfService: {},
	termsOfServices: [],
    termsOfServicePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    termsOfServiceLoader: false
};

const getters = {
    termsOfServiceLoader: (state) => state.termsOfServiceLoader,
	termsOfServices: (state) => state.termsOfServices,
	termsOfService: (state) => state.termsOfService,
    termsOfServicePagination: (state) => state.termsOfServicePagination
}

const actions = {
    async fetchTermsOfServices({commit}, page) {
		const response = await apiCall({url: `/api/terms-of-service?page=${page}`, method: 'GET' });
		commit('setTermsOfServices', response)
        commit('stopTermsOfServiceLoader', response)
	},
    async fetchTermsOfService({commit}, itemId) {
		const response = await apiCall({url: `/api/terms-of-service/${itemId}`, method: 'GET' });
		commit('setTermsOfService', response)
        commit('stopTermsOfServiceLoader', response)
	},
    async filterTermsOfServices({commit, state},resp){
		commit('setTermsOfServices', resp)
	},
	async startTermsOfServiceLoader({commit, state},resp){
		commit('startTermsOfServiceLoader', resp)
	},
	async stopTermsOfServiceLoader({commit, state},resp){
		commit('stopTermsOfServiceLoader', resp)
	},
};

const mutations = {
	setTermsOfServices: (state, termsOfServices) => {
		state.termsOfServices = termsOfServices.data
		state.termsOfServicePagination.current_page = termsOfServices.current_page
		state.termsOfServicePagination.total = termsOfServices.total
		state.termsOfServicePagination.per_page = termsOfServices.per_page
	},
	setTermsOfService: (state, termsOfService) => {
		state.termsOfService = termsOfService
	},
    startTermsOfServiceLoader: (state) => state.termsOfServiceLoader = true,
	stopTermsOfServiceLoader: (state) => state.termsOfServiceLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
