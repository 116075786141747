import apiCall from '@/utils/api'

const state = {
	calendar: {},
	calendars: [],
    calendarPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    calendarLoader: false
};

const getters = {
    calendarLoader: (state) => state.calendarLoader,
	calendars: (state) => state.calendars,
	calendar: (state) => state.calendar,
    calendarPagination: (state) => state.calendarPagination
}

const actions = {
    async fetchCalendars({commit}, page) {
		const response = await apiCall({url: `/api/calendar?page=${page}`, method: 'GET' });
		commit('setCalendars', response)
        commit('stopLoader', response)
	},
    async fetchCalendar({commit}, itemId) {
		const response = await apiCall({url: `/api/calendar/${itemId}`, method: 'GET' });
		commit('setCalendar', response)
        commit('stopLoader', response)
	},
    async filterCalendars({commit, state},resp){
		commit('setCalendars', resp)
	},
	async startCalendarLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopCalendarLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setCalendars: (state, calendars) => {
		state.calendars = calendars.data
		state.calendarPagination.current_page = calendars.current_page
		state.calendarPagination.total = calendars.total
		state.calendarPagination.per_page = calendars.per_page
	},
	setCalendar: (state, calendar) => {
		state.calendar = calendar
	},
    startLoader: (state) => state.calendarLoader = true,
	stopLoader: (state) => state.calendarLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
