<template>
  <div class="locations" v-if="$can('location_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ $t(message) }}</span>
    </v-snackbar>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <div class="title">Locations</div>
        </v-flex>
        <div v-if="view == 'default'">
          <v-flex xs12 class="mt-5">
            <v-card elevation="0" class="header">
              <v-layout row wrap>
                <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                <v-flex xs12 md4>
                  <v-text-field outlined rounded dense :label="$t('searchMsg')" append-icon="mdi-undo-variant"
                    @click:append="resetSearch()" v-on:keyup.enter="search" v-model="searchTerm" @input="enableSearch()"
                    class="background search_field my-3 mx-5"></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <!-- <div class="pa-1">
                    <v-btn
                      class="secondary text-none"
                      rounded
                      depressed
                      @click="search"
                      :loading="searchLoader"
                      :disabled="searchButtonDisabled"
                    >
                      {{ $t("searchMsg") }}
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </div> -->
                  <div align="center" class="my-4">
                    <v-btn rounded depressed class="white--text universal text-none" @click="changeView('create')"
                      v-if="$can('location_create')">
                      Add Location
                      <v-icon right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <div v-if="locations.length == 0">
              <v-card elevation="0">
                <v-layout row wrap>
                  <v-flex xs12 md1>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          <v-icon large class="primary--text">
                            mdi-alert-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md11>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          No Location Found
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-card>
            </div>
            <div v-else>
              <v-tabs show-arrows v-model="tab" class="mb-3">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab v-for="(view, index) in views" :key="index" @click="changeHomeView(view.name)">
                  <div class="text-none">{{ view.name }}</div>
                </v-tab>
              </v-tabs>

              <div class="hidden-sm-and-down">
                <div v-if="homeView == 'List'">
                  <v-progress-linear v-if="locationLoader" height="1" indeterminate color="primary"></v-progress-linear>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead class="header">
                        <tr>
                          <th class="text-left">{{ $t("nameMsg") }}</th>
                          <th class="text-right">{{ $t("actionsMsg") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in locations" :key="item.id">
                          <td>{{ item.name }}</td>
                          <td>
                            <div align="right">
                              <v-btn v-if="$can('location_view')" icon @click="showLocation(item)" class="button mr-1 universal--text" v-bind="attrs"
                                v-on="on">
                                <v-icon small> mdi-eye </v-icon>
                              </v-btn>
                              <v-btn v-if="$can('location_edit')" icon class="button mr-1 success--text" @click="editLocation(item)" v-bind="attrs"
                                v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                              </v-btn>
                              <v-btn v-if="$can('location_archive')" icon class="button mr-1 error--text" @click="deleteLocation(item)"
                                :loading="loading && locationIndex == item.id" v-bind="attrs" v-on="on">
                                <v-icon small> mdi-delete </v-icon>
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div v-else>
                  <v-card elevation="0" class="background">
                    <v-card-text>
                      <v-progress-linear v-if="locationLoader" height="1" indeterminate color="primary" class="mr-10">
                      </v-progress-linear>
                      <div>
                        <v-map ref="myMapRef" style="position: absolute; width: 96%; height: 62vh; z-index: 2" :zoom=5
                          :center="[newLocation.latitude, newLocation.longitude]" :options="tileOptions">
                          <v-icondefault class="mt-5"></v-icondefault>
                          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></v-tilelayer>
                          <v-marker-cluster :options="clusterOptions">
                            <v-marker v-for="(location, index) in locationArray" :key="location.id"
                              :lat-lng="[location.latitude, location.longitude]" :icon="icon"
                              @click="locationDataBasic(location)">
                              <v-popup :content="location.name"></v-popup>
                              <v-tooltip>
                                <div>
                                  {{location.name}}
                                </div>
                              </v-tooltip>
                            </v-marker>
                          </v-marker-cluster>
                        </v-map>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
              <div class="hidden-md-and-up">
                <v-layout column>
                  <template v-for="(location, index) in locations">
                    <div :key="index">
                      <v-flex xs12 class="mb-2">
                        <v-card elevation="0">
                          <div class="pa-5">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <div class="title primary--text">
                                      <b>{{ location.name }}</b>
                                    </div>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn depressed class="red--text"
                                      :loading="loading && locationIndex == location.id" icon
                                      @click="deleteLocation(location)" v-if="$can('location_archive')">
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-divider class="mt-2"></v-divider>
                              <v-flex xs12 class="mt-1">
                                <v-layout column>
                                  <v-flex xs12 class="mt-1">
                                    <div>
                                      <b>{{ $t("dateAddedMsg") }}</b>
                                    </div>
                                    <div>
                                      {{
                                          location.created_at
                                          | moment("DD/MM/YYYY - hh:m a")
                                      }}
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="pa-1">
                                          <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                            " block @click="showLocation(location)" v-if="$can('location_view')">{{
                                                $t("viewMsg")
                                            }}
                                            <v-icon right> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div class="pa-1">
                                          <v-btn depressed class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                            " block @click="editLocation(location)" v-if="$can('location_edit')">{{
                                                $t("editMsg")
                                            }}
                                            <v-icon right>
                                              mdi-border-color
                                            </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-10" v-if="homeView == 'List'">
            <v-layout row wrap>
              <v-flex xs12 md9>
                <div align="left">
                  <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                    v-model="locationPagination.current_page" @input="changePage()" circle>
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div v-if="length != 0" align="right" class="mr-3">
                  <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>{{ locationPagination.total | formatNumber
                  }}
                </div>
              </v-flex>

            </v-layout>
          </v-flex>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-2">
            <v-card elevation="0" class="background">
              <v-card-title class="header">
                <v-spacer></v-spacer>
                <div v-if="newLocation.id == null">Add Location</div>
                <div v-else>Edit Location</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5 mb-15">
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>{{ $t("nameMsg") }}<span class="error--text">*</span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newLocation.name"
                          :rules="inputRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>Longitude<span class="error--text">*</span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newLocation.longitude"
                          :rules="inputRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>Latitude<span class="error--text">*</span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newLocation.latitude"
                          :rules="inputRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3" align="center">
                        <v-btn rounded depressed class="universal white--text text-none mt-6" @click="save"
                          :disabled="!valid" :loading="loading">
                          {{ $t("saveMsg") }}
                          <v-icon right> mdi-content-save </v-icon>
                        </v-btn>
                      </div>
                    </v-flex>

                    <v-flex xs12>
                      <v-progress-linear v-if="dragging" height="1" indeterminate color="primary"></v-progress-linear>
                      <div class="mr-5">
                        <v-map @click="onMapClick" ref="myMapRef"
                          style="position: absolute; width: 100%; height: 200%; z-index: 2" :zoom=15
                          :center="[newLocation.latitude, newLocation.longitude]" :options="tileOptions">
                          <v-icondefault class="mt-5"></v-icondefault>
                          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></v-tilelayer>
                          <v-marker ref="markerRef" :lat-lng="[newLocation.latitude, newLocation.longitude]"
                            :icon="icon" :draggable="true" :autoPan="true" @dragstart="dragging = true"
                            @dragend="dragEvent">
                          </v-marker>
                        </v-map>
                      </div>

                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <!-- <v-card-actions class="mt-15">
                <v-spacer></v-spacer>
                <v-btn rounded depressed class="universal white--text text-none mt-15" @click="save" :disabled="!valid"
                  :loading="loading">
                  {{ $t("saveMsg") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">

          <v-card elevation="0" class="background">
            <v-card-title class="header">
              <v-spacer></v-spacer>
              <div>View Location</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="pa-3 mt-5">
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Location Name: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>{{ assignedLocation.name }}</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Longitude: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>{{ assignedLocation.longitude }}</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Latitude: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>{{ assignedLocation.latitude }}</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <div class="mt-10 mr-15">
                      <v-map ref="myMapRef" style="position: absolute; width: 95%; height: 300%; z-index: 2" :zoom=15
                        :center="[assignedLocation.latitude, assignedLocation.longitude]" :options="tileOptions">
                        <v-icondefault class="mt-5"></v-icondefault>
                        <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></v-tilelayer>
                        <v-marker ref="markerRef" :lat-lng="[assignedLocation.latitude, assignedLocation.longitude]"
                          :icon="icon">
                        </v-marker>
                      </v-map>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-card-text>
          </v-card>

        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

html,
body {
  height: 100%;
  margin: 0;
}
</style>
<script>
import Vue from 'vue'
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

import * as Vue2Leaflet from 'vue2-leaflet'
import { latLng, Icon, icon } from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'

Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster)

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  components: {
    'v-map': Vue2Leaflet.LMap,
    'v-circle': Vue2Leaflet.LCircle,
    'v-tilelayer': Vue2Leaflet.LTileLayer,
    'v-icondefault': Vue2Leaflet.LIconDefault,
    'v-marker': Vue2Leaflet.LMarker,
    'v-popup': Vue2Leaflet.LPopup,
    'v-tooltip': Vue2Leaflet.LTooltip
  },
  data() {
    let customicon = icon(Object.assign({},
      Icon.Default.prototype.options,
      { iconUrl, shadowUrl }
    ))
    return {
      icon: customicon,
      tileOptions: {
        minZoom: 5.5,
        minNativeZoom: 1,
        detectRetina: true,
        updateWhenIdle: false,
        keepBuffer: 10
      },
      maploaded: false,
      view: "default",
      homeView: 'List',
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,

      locationIndex: null,
      snackbar: false,
      inputRules: [
        (v) => !!v || "Input is required"
      ],

      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      filteredLocations: false,
      newLocation: {
        id: null,
        name: null,
        longitude: '36.8219',
        latitude: '-1.2921'
      },
      assignedLocation: null,
      formData: null,
      dragging: false,
      views: [
        { name: 'List' },
        { name: 'Map' }
      ],
      locationArray: [],
      clusterOptions: {},
    };
  },
  created() {
    if (this.$can("location_view")) {
      this.startLocationLoader();
      this.fetchLocations(this.locationPagination.current_page);
      this.getFormData();
      this.getLocation()
    }
  },
  methods: {
    ...mapActions([
      "fetchLocations",
      "startLocationLoader",
      "stopLocationLoader",
      "filterLocations",
    ]),
    changeHomeView(view) {
      this.homeView = view
      if (view == 'Map') {
        this.locationLoader = true
        apiCall({
          url: "/api/location?type=map",
          method: "GET",
        })
          .then((resp) => {
            this.locationArray = resp
            this.locationLoader = false
          })
          .catch((error) => {
            this.message = "anErrorOccurredEmailMsg";
            this.color = "error";
            this.loading = false;
            this.snackbar = true;
            this.locationLoader = false
          });
      }
    },
    getLocation() {
      var vm = this
      navigator.geolocation.getCurrentPosition(function (position) {
        vm.newLocation.latitude = position.coords.latitude.toString()
        vm.newLocation.longitude = position.coords.longitude.toString()
      });
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredLocations = true;
      this.startLocationLoader();
      this.fetchLocations(1);
    },
    showLocation(location) {
      this.assignedLocation = location;
      this.changeView("show");
    },
    editLocation(location) {
      this.newLocation.id = location.id;
      this.newLocation.name = location.name;
      this.newLocation.longitude = location.longitude;
      this.newLocation.latitude = location.latitude;

      this.changeView("create");
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.newLocation.id != null) {
          confirm('Are you sure you want to update location?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/location/" + this.newLocation.id,
              data: this.newLocation,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Location Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchLocations(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm('Are you sure you want to create location?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/location",
              data: this.newLocation,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Location Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchLocations(1);
                apiCall({
                  url: "/api/location?type=map",
                  method: "GET",
                })
                  .then((resp) => {
                    this.locationArray = resp
                    this.locationLoader = false
                  })
                  .catch((error) => {
                    this.message = "anErrorOccurredEmailMsg";
                    this.color = "error";
                    this.loading = false;
                    this.snackbar = true;
                    this.locationLoader = false
                  });
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchLocations(1);
              });
          }
        }
      }
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startLocationLoader();
        apiCall({
          url: "/api/location?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterLocations(resp);
            this.searchLoader = false;
            this.stopLocationLoader();
            this.filteredLocations = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopLocationLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.newLocation.id = null;
        this.newLocation.name = null;
        this.newLocation.longitude = '36.8219';
        this.newLocation.latitude = '-1.2921';
      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {

      }
      this.view = view;
    },
    changePage() {
      this.startLocationLoader();
      if (this.filteredLocations == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/location?page=" +
            this.locationPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterLocations(resp);
            this.stopLocationLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopLocationLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchLocations(this.locationPagination.current_page);
      }
    },
    deleteLocation(item) {
      confirm("Are You Sure You Want to Delete Location") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.locationIndex = item.id;
        apiCall({ url: "/api/location/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Location Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.locationIndex = null;
            this.fetchLocations(this.locationPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.locationIndex = null;
            console.log(error.response);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/location?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    onMapClick(value) {
      this.newLocation.latitude = value.latlng.lat
      this.newLocation.longitude = value.latlng.lng
    },
    dragEvent() {
      var coordinates = this.$refs.markerRef.mapObject.getLatLng()
      this.newLocation.latitude = coordinates.lat
      this.newLocation.longitude = coordinates.lng
      this.dragging = false
    }
  },
  computed: {
    ...mapGetters([
      "locations",
      "locationPagination",
      "locationLoader",
    ]),
    length: function () {
      return Math.ceil(
        this.locationPagination.total / this.locationPagination.per_page
      );
    },
  },
};
</script>
