<template>
  <div class="institutions" v-if="$can('institution_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ $t(message) }}</span>
    </v-snackbar>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="imageDialog"
    >
      <v-card max-width="600">
        <v-toolbar color="primary" dark flat>
          Upload School Emblem
          <v-spacer></v-spacer>
          <v-btn icon @click="imageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="imageForm" v-model="imageValid" lazy-validation>
          <v-layout column>
            <v-flex xs12>
              <v-file-input
                class="mx-2 mt-3"
                :loading="imageLoading"
                :rules="fileRules"
                filled
                dense
                show-size
                chips
                accept="image/*"
                v-model="imageFile"
                :label="$t('selectImageMsg')"
              ></v-file-input>
            </v-flex>
            <v-flex xs12>
              <div align="right" class="pa-3">
                <v-btn
                  depressed
                  block
                  class="secondary text-none mb-3"
                  @click="uploadImage"
                  :loading="imageLoading"
                  :disabled="!imageValid"
                >
                  {{ $t("submitMsg") }} <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="importDialog" width="700" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="primary white--text">
          Import Students
          <v-spacer></v-spacer>
          <v-btn icon @click="importDialog = false" class="white--text">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="my-7">

          <v-form ref="excelForm" v-model="excelValid" lazy-validation>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <div class="pa-3">
                  <div>Institution Type<span class="error--text">*</span></div>
                  <v-select :items="institutionTypes" class="text_field background" item-value="id"
                    item-text="name" outlined v-model="newClassImport.institution_type_id" dense :rules="inputRules"
                    @input="populateInstitutions"></v-select>
                </div>
              </v-flex>
              <v-flex xs12 md4>
                <div class="pa-3">
                  <div>Institution<span class="error--text">*</span></div>
                  <v-select :items="filteredInstitutions" class="text_field background" item-value="id"
                    item-text="name" outlined v-model="newClassImport.institution_id" dense :rules="inputRules"
                    @input="populateYears"></v-select>
                </div>
              </v-flex>
              <v-flex xs12 md4>
                <div class="pa-3">
                  <div>Year<span class="error--text">*</span></div>
                  <v-select :items="filteredYears" class="text_field background" item-value="year" item-text="year"
                    outlined v-model="newClassImport.year" dense :rules="inputRules" @input="populateClasses()">
                  </v-select>
                </div>
              </v-flex>
              <v-flex xs12 md4>
                  <div class="pa-3">
                    <div>Class/Grade<span class="error--text">*</span></div>
                    <v-select :items="filteredClasses" class="text_field background" item-value="id" item-text="name"
                      outlined v-model="newClassImport.level_id" dense :rules="inputRules"
                      @input="populateClassStreams"></v-select>
                  </div>
                </v-flex>
                <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Stream<span class="error--text">*</span></div>
                        <v-select :items="filteredStreams" class="text_field background" item-value="id" item-text="name"
                          outlined v-model="newClassImport.level_stream_id" dense :rules="inputRules"
                          @input="activateImportButton()"></v-select>
                      </div>
                    </v-flex>
              
              <v-flex xs12 md4>
                <div align="right">
                  <v-btn rounded depressed class="primary text-none mt-10" :disabled="importButton" @click="importClassStream()"
                    :loading="loading">
                    Import
                    <v-icon right>
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <div class="title">Institutions</div>
        </v-flex>
        <div v-if="view == 'default'">
          <v-flex xs12 class="mt-5 mb-2">
            <v-card elevation="0" class="header">
              <v-layout row wrap>
                <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                <v-flex xs12 md4>
                  <v-text-field outlined rounded dense :label="$t('searchMsg')" append-icon="mdi-undo-variant"
                    @click:append="resetSearch()" v-on:keyup.enter="search" v-model="searchTerm" @input="enableSearch()"
                    class="background search_field my-3 mx-5"></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <!-- <div class="pa-1">
                    <v-btn
                      class="secondary text-none"
                      rounded
                      depressed
                      @click="search"
                      :loading="searchLoader"
                      :disabled="searchButtonDisabled"
                    >
                      {{ $t("searchMsg") }}
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </div> -->
                  <div align="center" class="my-4">
                    <v-btn rounded depressed class="white--text universal text-none" @click="changeView('create')"
                      v-if="$can('institution_create')">
                      Add Institution
                      <v-icon right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <div v-if="institutions.length == 0">
              <v-card elevation="0">
                <v-layout row wrap>
                  <v-flex xs12 md1>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          <v-icon large class="primary--text">
                            mdi-alert-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md11>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          No Institution Found
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-card>
            </div>
            <div v-else>
              <v-tabs show-arrows v-model="tab" class="mb-3">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab v-for="(view, index) in views" :key="index" @click="changeHomeView(view.name)">
                  <div class="text-none">{{ view.name }}</div>
                </v-tab>
              </v-tabs>


              <div class="hidden-sm-and-down">
                <div v-if="homeView == 'List'">
                  <v-progress-linear v-if="institutionLoader" height="1" indeterminate color="primary">
                  </v-progress-linear>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead class="header">
                        <tr>
                          <th class="text-left">Logo</th>
                          <th class="text-left">{{ $t("nameMsg") }}</th>
                          <th class="text-left">Location</th>
                          <th class="text-left">Sector</th>
                          <th class="text-left">Provider</th>
                          <th class="text-left">Type</th>
                          <th class="text-left">Gender</th>
                          <th class="text-left">Opened</th>

                          <th class="text-right">{{ $t("actionsMsg") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in institutions" :key="item.id">
                          <td>
                            <div class="my-2">
                              <v-img v-if="item.logo==null" src="img/badge.png" max-width="40"></v-img>
                              <v-avatar size="48" v-if="item.logo != null">
                                <v-img
                                  contain
                                  :src="path+'/school_emblems/'+item.logo"
                                ></v-img>
                              </v-avatar>
                            </div>
                          </td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.location.name }}</td>
                          <td>{{ item.institution_sector.name }}</td>
                          <td>{{ item.institution_provider.name }}</td>
                          <td>{{ item.institution_type.name }}</td>
                          <td>{{ item.institution_gender.name }}</td>
                          <td>{{ item.date_opened }}</td>
                          <td>
                            <div align="right">
                              <v-btn icon v-if="$can('institution_view')" @click="showInstitution(item)" class="button mr-1 universal--text"
                                v-bind="attrs" v-on="on">
                                <v-icon small> mdi-eye </v-icon>
                              </v-btn>
                              <v-btn v-if="$can('institution_edit')" icon class="button mr-1 success--text" @click="editInstitution(item)"
                                v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                              </v-btn>
                              <v-btn v-if="$can('institution_archive')" icon class="button mr-1 error--text" @click="deleteInstitution(item)" :loading="
                                loading && institutionIndex == item.id
                              " v-bind="attrs" v-on="on">
                                <v-icon small> mdi-delete </v-icon>
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div v-else>
                  <v-card elevation="0" class="background">
                    <v-card-text>
                      <v-progress-linear v-if="institutionLoader" height="1" indeterminate color="primary"
                        class="mr-10">
                      </v-progress-linear>
                      <div>
                        <v-map ref="myMapRef" style="position: absolute; width: 96%; height: 62vh; z-index: 2" :zoom=5
                          :center="[newLocation.latitude, newLocation.longitude]" :options="tileOptions">
                          <v-icondefault class="mt-5"></v-icondefault>
                          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></v-tilelayer>
                          <v-marker-cluster :options="clusterOptions">
                            <v-marker v-for="(location, index) in locationArray" :key="location.id"
                              :lat-lng="[location.institution_locations[0].latitude, location.institution_locations[0].longitude]"
                              :icon="icon" @click="showInstitution(location)">
                              <v-popup :content="location.name"></v-popup>
                              <v-tooltip>
                                <div>
                                  {{ location.name }}
                                </div>
                              </v-tooltip>
                            </v-marker>
                          </v-marker-cluster>
                        </v-map>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

              </div>
              <div class="hidden-md-and-up">
                <div v-if="homeView == 'List'">
                  <v-layout column>
                  <template v-for="(institution, index) in institutions">
                    <div :key="index">
                      <v-flex xs12 class="mb-2">
                        <v-card elevation="0">
                          <div class="pa-5">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <div class="title primary--text">
                                      <b>{{ institution.name }}</b>
                                    </div>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn depressed class="red--text" :loading="
                                      loading &&
                                      institutionIndex == institution.id
                                    " icon @click="deleteInstitution(institution)" v-if="$can('institution_archive')">
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-divider class="mt-2"></v-divider>
                              <v-flex xs12 class="mt-1">
                                <v-layout column>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Location</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ institution.location.name }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Sector</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ institution.institution_sector.name }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Type</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ institution.institution_type.name }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Gender</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ institution.institution_gender.name }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>{{ $t("dateAddedMsg") }}</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ institution.created_at | moment("DD/MM/YYYY - hh:m a") }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs12>
                                        <div>
                                          <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                              mt-3
                                            " block @click="
                                              showInstitution(institution)
                                            " v-if="$can('institution_view')">{{ $t("viewMsg") }}
                                            <v-icon right> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div>
                                          <v-btn depressed class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                              mt-1
                                            " block @click="
                                              editInstitution(institution)
                                            " v-if="$can('institution_edit')">{{ $t("editMsg") }}
                                            <v-icon right>
                                              mdi-border-color
                                            </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
                </div>
                <div v-else>
                  <v-card elevation="0" class="background">
                    <v-card-text>
                      <v-progress-linear v-if="institutionLoader" height="1" indeterminate color="primary"
                        class="mr-10">
                      </v-progress-linear>
                      <div>
                        <v-map ref="myMapRef" style="position: absolute; width: 94%; height: 55vh; z-index: 2" :zoom=5
                          :center="[newLocation.latitude, newLocation.longitude]" :options="tileOptions">
                          <v-icondefault class="mt-5"></v-icondefault>
                          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></v-tilelayer>
                          <v-marker-cluster :options="clusterOptions">
                            <v-marker v-for="(location, index) in locationArray" :key="location.id"
                              :lat-lng="[location.institution_locations[0].latitude, location.institution_locations[0].longitude]"
                              :icon="icon" @click="showInstitution(location)">
                              <v-popup :content="location.name"></v-popup>
                              <v-tooltip>
                                <div>
                                  {{ location.name }}
                                </div>
                              </v-tooltip>
                            </v-marker>
                          </v-marker-cluster>
                        </v-map>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <v-layout row wrap>
              <v-flex xs12 md9>
                <div align="left">
                  <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                    v-model="institutionPagination.current_page" @input="changePage()" circle>
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div v-if="length != 0" align="right" class="mr-3">
                  <b class="universal--text ml-4">{{ $t("totalMsg") }}: </b>{{ institutionPagination.total |
                      formatNumber
                  }}
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-2">
            <v-card elevation="0" class="background">
              <v-card-title class="header">
                <v-img v-if="newInstitution.id != null" src="img/badge.png" max-width="40"></v-img>
                <v-spacer></v-spacer>
                <div v-if="newInstitution.id == null">Add Institution</div>
                <div v-else>Edit Institution</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5">
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Location<span class="error--text">*</span></div>
                        <v-select :items="formData.locations" v-model="newInstitution.location_id"
                          class="text_field background my-2" item-value="id" item-text="name" outlined dense
                          :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Name<span class="error--text">*</span></div>
                        <v-text-field class="text_field background my-2" outlined dense v-model="newInstitution.name"
                          :rules="inputRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Alternative Name</div>
                        <v-text-field class="text_field background my-2" outlined dense
                          v-model="newInstitution.alternative_name"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>KNEC Code<span class="error--text">*</span></div>
                        <v-text-field class="text_field background my-2" outlined dense v-model="newInstitution.knec_code" :rules="inputRules">
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Registration Code<span class="error--text">*</span></div>
                        <v-text-field class="text_field background my-2" outlined dense v-model="newInstitution.registration_code" :rules="inputRules">
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>
                          Institution Classification<span class="error--text">*</span>
                        </div>
                        <v-select :items="formData.institutionClassifications"
                          v-model="newInstitution.institution_classification_id" class="text_field background my-2"
                          item-value="id" item-text="name" outlined dense :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>
                          Institution Sectors<span class="error--text">*</span>
                        </div>
                        <v-select :items="formData.institutionSectors" v-model="newInstitution.institution_sector_id"
                          class="text_field background my-2" item-value="id" item-text="name" outlined dense
                          :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>
                          Institution Providers<span class="error--text">*</span>
                        </div>
                        <v-select :items="formData.institutionProviders"
                          v-model="newInstitution.institution_provider_id" class="text_field background my-2"
                          item-value="id" item-text="name" outlined dense :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>
                          Institution Type<span class="error--text">*</span>
                        </div>
                        <v-select :items="formData.institutionTypes" v-model="newInstitution.institution_type_id"
                          class="text_field background my-2" item-value="id" item-text="name" outlined dense
                          :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>
                          Institution Ownership<span class="error--text">*</span>
                        </div>
                        <v-select :items="formData.institutionOwnerships"
                          v-model="newInstitution.institution_ownership_id" class="text_field background my-2"
                          item-value="id" item-text="name" outlined dense :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>
                          Institution Gender<span class="error--text">*</span>
                        </div>
                        <v-select :items="formData.institutionGenders" v-model="newInstitution.institution_gender_id"
                          class="text_field background my-2" item-value="id" item-text="name" outlined dense
                          :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>
                          Institution Accomodation<span class="error--text">*</span>
                        </div>
                        <v-select :items="formData.institutionAccomodations" v-model="newInstitution.institution_accomodation_id"
                          class="text_field background my-2" item-value="id" item-text="name" outlined dense
                          :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>
                          Institution Category<span class="error--text">*</span>
                        </div>
                        <v-select :items="formData.institutionCategories" v-model="newInstitution.institution_category_id"
                          class="text_field background my-2" item-value="id" item-text="name" outlined dense
                          :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>
                          Institution Cluster<span class="error--text">*</span>
                        </div>
                        <v-select :items="formData.institutionClusters" v-model="newInstitution.institution_cluster_id"
                          class="text_field background my-2" item-value="id" item-text="name" outlined dense
                          :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3 mt-7">
                        <v-checkbox class="text_field background" outlined dense v-model="newInstitution.asal_area"
                          label="Asal Area"></v-checkbox>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Date Opened:</div>

                        <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                          v-model="fromDatePicker" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense :value="formatFromDate" slot="activator" readonly
                              v-bind="attrs" v-on="on" class="text_field background my-2"></v-text-field>
                          </template>
                          <v-date-picker v-model="newInstitution.date_opened" :max="newInstitution.date_closed"
                            @change="closeFromDate"></v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Date Closed:</div>

                        <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                          v-model="toDatePicker" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense :value="formatToDate" slot="activator" readonly v-bind="attrs"
                              v-on="on" class="text_field background my-2"></v-text-field>
                          </template>
                          <v-date-picker v-model="newInstitution.date_closed" :min="newInstitution.date_opened"
                            :max="dateToday" @change="closeToDate"></v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded depressed class="universal white--text text-none mt-3" @click="save" :disabled="!valid"
                  :loading="loading">
                  {{ $t("saveMsg") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card elevation="0" class="background">
            <v-card-title class="header">              
              <v-img v-if="assignedInstitution.logo==null" src="img/badge.png" max-width="40"></v-img>
              <v-avatar size="55" v-if="assignedInstitution.logo != null">
                <v-img
                  contain
                  :src="path+'/school_emblems/'+assignedInstitution.logo"
                ></v-img>
              </v-avatar>
              <v-btn icon small @click="imageDialog = true" v-if="$can('institution_add_emblem')"><v-icon>mdi-pen</v-icon></v-btn>
              <v-spacer></v-spacer>
              <div>View Institution</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-tabs show-arrows v-model="institutionTab">
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab @click="activeTab = 'details'">
                <div class="text-none">Institution Details</div>
              </v-tab>
              <v-tab @click="activeTab = 'classes'" v-if="$can('class_view')">
                <div class="text-none">Classes</div>
              </v-tab>
            </v-tabs>
            <div v-if="activeTab == 'details'">
              <v-card-text>
                <div class="pa-3">
                  <v-layout column>
                    <v-flex xs12>
                      <v-layout row wrap>
                        <v-flex xs12 md6>
                          <v-layout column>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Location: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>{{ assignedInstitution.location.name }}</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Institution Name: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>{{ assignedInstitution.name }}</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Alternative Name: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div v-if="
                                    assignedInstitution.alternative_name != null
                                  ">
                                    {{ assignedInstitution.alternative_name }}
                                  </div>
                                  <div v-else>-</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>KNEC Code: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>{{ assignedInstitution.knec_code }}</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Registration Code: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>{{ assignedInstitution.registration_code }}</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Category: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div v-if="assignedInstitution.institution_category != null">
                                    {{ assignedInstitution.institution_category.name }}
                                  </div>
                                  <div v-else>-</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Cluster: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div v-if="assignedInstitution.institution_cluster != null">
                                    {{ assignedInstitution.institution_cluster.name }}
                                  </div>
                                  <div v-else>-</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Date Opened: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div v-if="assignedInstitution.date_opened != null">
                                    {{ assignedInstitution.date_opened }}
                                  </div>
                                  <div v-else>-</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Date Closed: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div v-if="assignedInstitution.date_closed != null">
                                    {{ assignedInstitution.date_closed }}
                                  </div>
                                  <div v-else>-</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-layout column>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Classification: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution
                                          .institution_classification.name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Sector: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_sector.name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Provider: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_provider
                                          .name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Type: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_type.name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Ownership: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_ownership
                                          .name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Gender: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_gender.name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Accomodation: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div>
                                    {{
                                        assignedInstitution.institution_accomodation.name
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap class="grey lighten-3">
                                <v-flex xs12 md4>
                                  <b>Asal Area: </b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <div v-if="assignedInstitution.asal_area==1">
                                    Yes
                                  </div>
                                  <div v-else>
                                    No
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card-text>
              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Institution Contact Details</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeContactView('create')" v-if="
                  $can('institution_contact_create') &&
                  contactView == 'default' &&
                  assignedInstitution.institution_contacts.length == 0
                ">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-btn icon @click="changeContactView('default')" v-if="contactView != 'default'">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="contactView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedInstitution.institution_contacts.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                Contacts Not Added
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout column>
                        <template v-for="(
                            contact, index
                          ) in assignedInstitution.institution_contacts">
                          <div :key="index">
                            <v-flex xs12>
                              <div class="ml-3">
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-6" v-if="contact.telephone != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Telephone: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ contact.telephone }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap class="grey lighten-3">
                                              <v-flex xs12 md4>
                                                <b>Mobile: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ contact.mobile }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-6" v-if="contact.email != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Email: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{ contact.email }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6" v-if="contact.website != null">
                                            <v-layout row wrap class="grey lighten-3 mr-2">
                                              <v-flex xs12 md4>
                                                <b>Website: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  <a :href="contact.website" target="_blank"
                                                    style="text-decoration: none;">{{ contact.website }}</a>

                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn icon @click="editContact(contact)" v-if="$can('institution_contact_edit')">
                                      <v-icon small class="green--text">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="deleteContact(contact)"
                                      v-if="$can('institution_contact_archive')" :loading="loading">
                                      <v-icon small class="red--text">mdi-delete</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-flex>
                          </div>
                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="contactView == 'create'" class="mt-5">
                  <v-form v-model="valid" ref="contactForm" lazy-validation>
                    <v-layout row wrap>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Telephone</div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newContact.telephone">
                          </v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Mobile<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense v-model="newContact.mobile"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Email</div>
                          <v-text-field class="text_field background my-2" outlined dense v-model="newContact.email"
                            :rules="emailRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Website</div>
                          <v-text-field class="text_field background my-2" outlined dense v-model="newContact.website"
                            :rules="websiteRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="right">
                          <v-btn rounded depressed class="universal white--text text-none" @click="saveContact"
                            :disabled="!valid" :loading="loading">
                            {{ $t("saveMsg") }}
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </div>
              </v-card-text>
              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Institution Resources</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeResourceView('create')" v-if="
                  $can('institution_resource_create') &&
                  resourceView == 'default'
                ">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-btn icon @click="changeResourceView('default')" v-if="resourceView != 'default'">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="resourceView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedInstitution.institution_resource.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                Resources Not Added
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout column>
                        <v-flex xs12>
                          <v-tabs show-arrows v-model="resourceTab">
                            <v-tabs-slider color="primary"></v-tabs-slider>

                            <v-tab v-for="(resource, index) in assignedInstitution.institution_resource" :key="index"
                              @click="assignResource(resource)">
                              {{ index }}
                            </v-tab>
                          </v-tabs>
                        </v-flex>
                        <v-flex xs12 class="mt-7">
                          <div class="ml-3">
                            <v-layout column>
                              <template v-for="(resource, index) in institutionResources">
                                <div :key="index">
                                  <v-flex xs12 class="mb-15">
                                    <v-layout row wrap>
                                      <v-flex xs1>
                                        {{ index + 1 }}.
                                      </v-flex>
                                      <v-flex xs10>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <v-layout row wrap>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Institution Electricity: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.institution_electricity_resource.name }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Electricity Source: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div v-if="resource.electricity_source!=null">{{ resource.electricity_source.name }}</div>
                                                        <div v-else>N/A</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap >
                                                      <v-flex xs12 md6>
                                                        <b>Institution Water: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.institution_water_resource.name }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Water Storage Capacilty (L): </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.water_storage_capacity }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>No of School Feeding: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.no_of_school_feeding }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>No of Students Dewormed: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ resource.no_of_students_dewormed }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>No of Permanent Classrooms: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.no_of_permanent_classrooms }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>No of Unused Permanent Classrooms: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ resource.no_of_unused_permanent_classrooms }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Internet Available: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div v-if="resource.internet_available == 1">
                                                          Yes
                                                        </div>
                                                        <div v-if="resource.internet_available == 0">
                                                          No
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Computers Availabe: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div v-if="resource.computers_availabe == 1">
                                                          Yes
                                                        </div>
                                                        <div v-if="resource.computers_availabe == 0">
                                                          No
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>No of Computers: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div v-if="resource.no_of_computers != null">
                                                          {{ resource.no_of_computers }}
                                                        </div>
                                                        <div v-else>
                                                          N/A
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>No of Teacher Computer Male: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div v-if="resource.no_of_teacher_computer_male != null">
                                                          {{ resource.no_of_teacher_computer_male }}
                                                        </div>
                                                        <div v-else>
                                                          N/A
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>No of Teacher Computer Female: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div v-if="resource.no_of_teacher_computer_female != null">
                                                          {{ resource.no_of_teacher_computer_female }}
                                                        </div>
                                                        <div v-else>
                                                          N/A
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>No of Laboratories: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                          {{ resource.no_of_laboratories }}                                                        
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>No of Temporal Classrooms: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.no_of_temporal_classrooms }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>No of Unused Temporal Classrooms: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ resource.no_of_unused_temporal_classrooms }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-4">
                                            <v-layout row wrap>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-10">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <div class="font-weight-black">Washroom Facilities: </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-7">
                                            <v-layout row wrap>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Student Boy: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.institution_washroom_facility.student_boy }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Student Girl: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.institution_washroom_facility.student_girl }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3 mr-4">
                                                      <v-flex xs12 md6>
                                                        <b>Latrines: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div class="ml-3">{{ resource.institution_washroom_facility.no_of_latrines }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Teacher Male: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ resource.institution_washroom_facility.teacher_male }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Teacher Female: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ resource.institution_washroom_facility.teacher_female }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn icon @click="editResource(resource)"
                                          v-if="$can('institution_resource_edit')">
                                          <v-icon small class="green--text">mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn icon @click="deleteResource(resource)"
                                          v-if="$can('institution_resource_archive')" :loading="deleteResourceLoading">
                                          <v-icon small class="red--text">mdi-delete</v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </div>
                              </template>
                            </v-layout>

                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="resourceView == 'create'" class="mt-5">
                  <v-form v-model="valid" ref="resourceForm" lazy-validation>
                    <v-layout row wrap>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Institution Electricity<span class="error--text">*</span></div>
                          <v-select :items="formData.institutionElectricityResources"
                            v-model="newResource.institution_elec_resource_id" class="text_field background my-2"
                            item-value="id" item-text="name" outlined dense :rules="inputRules"></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Electricity Source</div>
                          <v-select :items="formData.electricitySources"
                            v-model="newResource.electricity_source_id" class="text_field background my-2"
                            item-value="id" item-text="name" outlined dense :rules="inputRules"></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Institution Water<span class="error--text">*</span></div>
                          <v-select :items="formData.institutionWaterResources"
                            v-model="newResource.institution_water_resource_id" class="text_field background my-2"
                            item-value="id" item-text="name" outlined dense :rules="inputRules"></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Water Storage Capacity (L)<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.water_storage_capacity" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>No of School Feeding<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_school_feeding" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>No of Students Dewormed</div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_students_dewormed" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>No of Permanent Classrooms<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_permanent_classrooms" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>No of Unused Permanent Classrooms<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_unused_permanent_classrooms" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>No of Temporal Classrooms<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_temporal_classrooms" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>No of Unused Temporal Classrooms<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_unused_temporal_classrooms" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>No of Laboratories<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_laboratories" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Internet Available<span class="error--text">*</span></div>
                          <v-select :items="resourceAvailable" v-model="newResource.internet_available"
                            class="text_field background my-2" item-value="id" item-text="name" outlined dense>
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Computers Availabe<span class="error--text">*</span></div>
                          <v-select :items="resourceAvailable" v-model="newResource.computers_availabe"
                            class="text_field background my-2" item-value="id" item-text="name" outlined dense>
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3 v-if="newResource.computers_availabe == 1">
                        <div class="pa-1">
                          <div>No of Computers<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_computers" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3 v-if="newResource.computers_availabe == 1">
                        <div class="pa-1">
                          <div>No of Male Computer Teacher(s)<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_teacher_computer_male" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3 v-if="newResource.computers_availabe == 1">
                        <div class="pa-1">
                          <div>No of Female Computer Teacher(s)<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_teacher_computer_female" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Year<span class="error--text">*</span></div>

                          <v-menu ref="yearMenu" :close-on-content-click="false" max-width="290"
                            transition="scale-transition" v-model="toDatePicker" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatYear" slot="activator" readonly v-bind="attrs"
                                v-on="on" class="text_field background my-2" :rules="inputRules"></v-text-field>
                            </template>
                            <v-date-picker v-model="newResource.year" ref="picker" :max="dateToday"
                              @input="closeYearDate(newResource.year)" reactive no-title></v-date-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="pa-1 mt-3">
                          <div><b>Washroom Facilities</b></div>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Student Girl<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.student_girl" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Student Boy<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.student_boy" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Teacher Female<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.teacher_female" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Teacher Male<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.teacher_male" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>No of Latrines<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newResource.no_of_latrines" :rules="inputRules" type="number"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="right">
                          <v-btn rounded depressed class="universal white--text text-none" @click="saveResource"
                            :disabled="!valid" :loading="loadingResource">
                            {{ $t("saveMsg") }}
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </div>
              </v-card-text>
              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Institution Administrative Location</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeAdministrativeLocationView('create')" v-if="
                  $can('institution_administrative_location_create') &&
                  administrativeLocationView == 'default' &&
                  assignedInstitution.institution_administrative_location == null
                ">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-btn icon @click="changeAdministrativeLocationView('default')"
                  v-if="administrativeLocationView != 'default'">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="administrativeLocationView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedInstitution.institution_administrative_location == null">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                Administrative Location Not Added
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout column>
                        <v-flex xs12>
                          <div class="ml-3">
                            <v-layout row wrap>
                              <v-flex xs11>
                                <v-layout row wrap>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-5"
                                        v-if="assignedInstitution.institution_administrative_location.county_id != null">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>County: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>{{ assignedInstitution.institution_administrative_location.county.name
                                            }}</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5"
                                        v-if="assignedInstitution.institution_administrative_location.subcounty_id != null">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Sub County: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>{{
                                                assignedInstitution.institution_administrative_location.subcounty.name
                                            }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-5"
                                        v-if="assignedInstitution.institution_administrative_location.ward_id != null">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Ward: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedInstitution.institution_administrative_location.ward.name }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs1>
                                <v-btn icon
                                  @click="editAdminLocation(assignedInstitution.institution_administrative_location)"
                                  v-if="$can('institution_administrative_location_edit')">
                                  <v-icon small class="green--text">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon
                                  @click="deleteAdminLocation(assignedInstitution.institution_administrative_location)"
                                  v-if="$can('institution_administrative_location_archive')"
                                  :loading="deleteAdminLocationLoading">
                                  <v-icon small class="red--text">mdi-delete</v-icon>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="administrativeLocationView == 'create'" class="mt-5">
                  <v-form v-model="valid" ref="adminLocationForm" lazy-validation>
                    <v-layout row wrap>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>County<span class="error--text">*</span></div>
                          <v-select :items="formData.counties" class="text_field background" item-value="id"
                            item-text="name" outlined dense v-model="newAdministrativeLocation.county_id"
                            @input="populateSubCounties"></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Sub County</div>
                          <v-select :items="subcounties" class="text_field background" item-value="id" item-text="name"
                            outlined dense v-model="newAdministrativeLocation.subcounty_id" @input="populateWards">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Ward<span class="error--text">*</span></div>
                          <v-select :items="wards" class="text_field background" item-value="id" item-text="name"
                            outlined dense v-model="newAdministrativeLocation.ward_id"></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div align="right">
                          <v-btn rounded depressed class="universal white--text text-none mt-8"
                            @click="saveAdminLocation" :disabled="!valid" :loading="loadingAdminLocation">
                            {{ $t("saveMsg") }}
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </div>
              </v-card-text>


              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Institution Location</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeLocationView('create')" v-if="
                  $can('institution_location_create') &&
                  locationView == 'default' &&
                  assignedInstitution.institution_locations.length == 0
                ">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-btn icon @click="changeLocationView('default')" v-if="locationView != 'default'">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="locationView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedInstitution.institution_locations.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                Location Not Added
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout column>
                        <template v-for="(
                            location, index
                          ) in assignedInstitution.institution_locations">
                          <div :key="index">
                            <v-flex xs12>
                              <div class="ml-3">
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-5" v-if="location.address != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Address: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ location.address }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-5" v-if="location.postal_code != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Postal Code: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ location.postal_code }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-5" v-if="location.location_locality_id != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Location Locality: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ location.location_locality.name }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-5" v-if="location.longitude != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Longitude: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{ location.longitude }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-5" v-if="location.latitude != null">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Latitude: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{ location.latitude }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn icon @click="editLocation(location)"
                                      v-if="$can('institution_location_edit')">
                                      <v-icon small class="green--text">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="deleteLocation(location)"
                                      v-if="$can('institution_location_archive')" :loading="deleteLocationLoading">
                                      <v-icon small class="red--text">mdi-delete</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-flex>
                            <v-flex xs12>
                              <v-flex xs12>
                                <div class="my-10">
                                  <v-map ref="myMapRef" style="position: absolute; width: 92%; height: 50%; z-index: 2"
                                    :zoom=15 :center="[location.latitude, location.longitude]" :options="tileOptions">
                                    <v-icondefault class="mt-5"></v-icondefault>
                                    <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></v-tilelayer>
                                    <v-marker ref="markerRef" :lat-lng="[location.latitude, location.longitude]"
                                      :icon="icon">
                                    </v-marker>
                                  </v-map>
                                </div>

                              </v-flex>
                            </v-flex>


                          </div>
                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="locationView == 'create'" class="mt-5">
                  <v-form v-model="valid" ref="locationForm" lazy-validation>
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <div class="pa-1">
                          <div>Address<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense v-model="newLocation.address"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Postal Code</div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newLocation.postal_code"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Location Locality<span class="error--text">*</span></div>
                          <v-select :items="formData.locationLocalities" v-model="newLocation.location_locality_id"
                            class="text_field background my-2" item-value="id" item-text="name" outlined dense
                            :rules="inputRules"></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Longitude<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense
                            v-model="newLocation.longitude" :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-1">
                          <div>Latitude<span class="error--text">*</span></div>
                          <v-text-field class="text_field background my-2" outlined dense v-model="newLocation.latitude"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs6>
                        <div align="right">
                          <v-btn rounded depressed class="universal white--text text-none mt-8" @click="saveLocation"
                            :disabled="!valid" :loading="loadingLocation">
                            {{ $t("saveMsg") }}
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <v-progress-linear v-if="dragging" height="1" indeterminate color="primary"></v-progress-linear>
                        <div class="mr-5">
                          <v-map @click="onMapClick" ref="myMapRef"
                            style="position: absolute; width: 100%; height: 70%; z-index: 2" :zoom=15
                            :center="[newLocation.latitude, newLocation.longitude]" :options="tileOptions">
                            <v-icondefault class="mt-5"></v-icondefault>
                            <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></v-tilelayer>
                            <v-marker ref="markerRef" :lat-lng="[newLocation.latitude, newLocation.longitude]"
                              :icon="icon" :draggable="true" :autoPan="true" @dragstart="dragging = true"
                              @dragend="dragEvent">
                            </v-marker>
                          </v-map>
                        </div>

                      </v-flex>
                    </v-layout>
                  </v-form>
                </div>
              </v-card-text>

            </div>
            <div v-if="activeTab == 'classes'">
              <v-layout column>
                <div v-if="classView == 'default'">
                  <v-flex xs12 class="mt-5 mb-2">
                    <v-card elevation="0" class="header">
                      <v-layout row wrap>
                        <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                        <v-flex xs12 md4>
                          <v-text-field outlined rounded dense :label="$t('searchMsg')" append-icon="mdi-undo-variant"
                            @click:append="resetLevelSearch()" v-on:keyup.enter="levelSearch" v-model="levelSearchTerm"
                            @input="enableLevelSearch()" class="background search_field my-3 mx-5"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                          <!-- <div class="pa-1">
                            <v-btn
                              class="secondary text-none"
                              rounded
                              depressed
                              @click="search"
                              :loading="searchLoader"
                              :disabled="searchButtonDisabled"
                            >
                              {{ $t("searchMsg") }}
                              <v-icon right>mdi-magnify</v-icon>
                            </v-btn>
                          </div> -->
                          <div align="center" class="my-4">
                            <v-btn rounded depressed class="white--text universal text-none"
                              @click="changeLevelView('create')" v-if="$can('class_create')">
                              Add Class
                              <v-icon right>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 class="mt-10">
                    <div v-if="level.length == 0">
                      <v-card elevation="0">
                        <v-layout row wrap>
                          <v-flex xs12 md1>
                            <v-container fill-height fluid>
                              <v-row align="center" justify="center">
                                <v-col class="text-center">
                                  <v-icon large class="primary--text">
                                    mdi-alert-circle-outline
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-flex>
                          <v-flex xs12 md11>
                            <v-container fill-height fluid>
                              <v-row align="center" justify="center">
                                <v-col class="text-center"> No Class Found </v-col>
                              </v-row>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </div>
                    <div v-else>

                      <v-tabs show-arrows v-model="classTab">
                        <v-tabs-slider color="primary"></v-tabs-slider>

                        <v-tab v-for="(year, index) in level" :key="index" @click="assignYear(year)">
                          {{ index }}
                        </v-tab>
                      </v-tabs>
                      <v-progress-linear v-if="levelLoader" height="1" indeterminate color="primary">
                      </v-progress-linear>
                      <div class="hidden-sm-and-down">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead class="header">
                              <tr>
                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                <th class="text-left">{{ $t("startDateMsg") }}</th>
                                <th class="text-left">{{ $t("endDateMsg") }}</th>
                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in classes" :key="item.id">
                                <td>{{ item.name }}</td>
                                <td>{{ item.start_date | moment("DD/MM/YYYY") }}</td>
                                <td>{{ item.end_date | moment("DD/MM/YYYY") }}</td>
                                <td>
                                  <div align="right">
                                    <v-btn v-if="$can('class_view')" icon @click="showLevel(item)"
                                      class="button mr-1 universal--text" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-eye </v-icon>
                                    </v-btn>
                                    <v-btn v-if="$can('class_edit')" icon class="button mr-1 success--text"
                                      @click="editLevel(item)" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-pencil </v-icon>
                                    </v-btn>
                                    <v-btn v-if="$can('class_archive')" icon class="button mr-1 error--text"
                                      @click="deleteLevel(item)" :loading="loading && levelIndex == item.id"
                                      v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-delete </v-icon>
                                    </v-btn>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                      <div class="hidden-md-and-up">
                        <v-layout column>
                          <template v-for="(level, index) in classes">
                            <div :key="index">
                              <v-flex xs12 class="mb-2">
                                <v-card elevation="0">
                                  <div class="pa-5">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <v-layout row wrap>
                                          <v-flex xs11>
                                            <div class="title primary--text">
                                              <b>{{ level.name }}</b>
                                            </div>
                                          </v-flex>
                                          <v-flex xs1>
                                            <v-btn depressed class="red--text" :loading="
                                              loading && levelIndex == level.id
                                            " icon @click="deleteLevel(level)" v-if="$can('class_archive')">
                                              <v-icon> mdi-delete </v-icon>
                                            </v-btn>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-divider class="mt-2"></v-divider>
                                      <v-flex xs12 class="mt-1">
                                        <v-layout column>
                                          <v-flex xs12 class="mt-1">
                                            <div>
                                              <b>{{ $t("dateAddedMsg") }}</b>
                                            </div>
                                            <div>
                                              {{
                                                  level.created_at
                                                  | moment("DD/MM/YYYY - hh:m a")
                                              }}
                                            </div>
                                          </v-flex>
                                          <v-flex xs12 class="mt-5">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <div class="pa-1">
                                                  <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                            " block @click="showLevel(level)" v-if="$can('class_view')">{{
                                                $t("viewMsg")
                                            }}
                                                    <v-icon right> mdi-eye </v-icon>
                                                  </v-btn>
                                                </div>
                                              </v-flex>
                                              <v-flex xs12>
                                                <div class="pa-1">
                                                  <v-btn depressed class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                            " block @click="editLevel(level)" v-if="$can('class_edit')">{{
                                                $t("editMsg")
                                            }}
                                                    <v-icon right>
                                                      mdi-border-color
                                                    </v-icon>
                                                  </v-btn>
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-card>
                              </v-flex>
                            </div>
                          </template>
                        </v-layout>
                      </div>
                    </div>
                  </v-flex>
                  <!-- <v-flex xs12 class="mt-10">
                    <v-layout row wrap>
                      <v-flex xs12 md9>
                        <div align="left">
                          <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                            v-model="levelPagination.current_page" @input="changePage()" circle>
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div v-if="length != 0" align="right" class="mr-3">
                          <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>{{ levelPagination.total |
                              formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                </div>
                <div v-if="classView == 'create'">
                  <v-flex class="mt-2">
                    <v-card elevation="0">
                      <v-card-title class="header">
                        <v-spacer></v-spacer>
                        <div v-if="newLevel.id == null">Add Class</div>
                        <div v-else>Edit Class</div>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="changeLevelView('default')">
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-form v-model="valid" ref="levelForm" lazy-validation>
                          <v-layout row wrap class="mt-5">
                            <!-- <v-flex xs12 md3>
                              <div class="pa-3">
                                <div>{{ $t("institutionMsg") }}*</div>
                                <v-select :items="levelFormData.institutions" class="text_field background" item-value="id"
                                  item-text="name" outlined v-model="newLevel.institution_id" dense :rules="inputRules">
                                </v-select>
                              </div>
                            </v-flex> -->
                            <v-flex xs12 md4>
                              <div class="pa-3">
                                <div>
                                  {{ $t("nameMsg") }}<span class="error--text">*</span>
                                </div>
                                <v-text-field class="text_field background" outlined dense v-model="newLevel.name"
                                  :rules="inputRules"></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-3">
                                <div>Start Date*</div>

                                <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                                  v-model="fromDatePicker" offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined dense :value="formatLevelFromDate" slot="activator" readonly
                                      v-bind="attrs" v-on="on" class="text_field background" :rules="inputRules">
                                    </v-text-field>
                                  </template>
                                  <v-date-picker v-model="newLevel.start_date" :max="newLevel.end_date"
                                    @change="closeFromDate"></v-date-picker>
                                </v-menu>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-3">
                                <div>End Date*</div>

                                <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                                  v-model="toDatePicker" offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined dense :value="formatLevelToDate" slot="activator" readonly
                                      v-bind="attrs" v-on="on" class="text_field background" :rules="inputRules">
                                    </v-text-field>
                                  </template>
                                  <v-date-picker v-model="newLevel.end_date" :min="newLevel.start_date"
                                    @change="closeToDate"></v-date-picker>
                                </v-menu>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn rounded depressed class="universal white--text text-none mt-3" @click="saveLevel"
                          :disabled="!valid" :loading="loading">
                          {{ $t("saveMsg") }}
                          <v-icon right> mdi-content-save </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </div>
                <div v-if="classView == 'show'">
                  <v-card elevation="0" class="background">
                    <v-card-title class="header">
                      <v-spacer></v-spacer>
                      <div>View Class</div>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="changeLevelView('default')">
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div class="pa-3 mt-5">
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md6>
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs12 md4>
                                        <b>Institution: </b>
                                      </v-flex>
                                      <v-flex xs12 md8>
                                        <div>{{ assignedLevel.institution.name }}</div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs12 md4>
                                        <b>Class Name: </b>
                                      </v-flex>
                                      <v-flex xs12 md8>
                                        <div>{{ assignedLevel.name }}</div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 md6>
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs12 md4>
                                        <b>Start Date: </b>
                                      </v-flex>
                                      <v-flex xs12 md8>
                                        <div>
                                          {{
                                              assignedLevel.start_date
                                              | moment("DD MMM YYYY")
                                          }}
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs12 md4>
                                        <b>End Date: </b>
                                      </v-flex>
                                      <v-flex xs12 md8>
                                        <div>
                                          {{
                                              assignedLevel.end_date
                                              | moment("DD MMM YYYY")
                                          }}
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                        </v-layout>
                      </div>
                    </v-card-text>
                    <v-layout column>
                      <v-flex xs12 class="mt-1">
                        <div v-if="streamView == 'default'">
                          <v-flex xs12 class="mt-5 mb-2">
                            <v-card elevation="0" class="header">
                              <v-layout row wrap>
                                <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                                <v-flex xs12 md4>
                                  <!-- <v-text-field outlined rounded dense :label="$t('searchMsg')"
                                        append-icon="mdi-undo-variant" @click:append="resetStreamSearch()"
                                        v-on:keyup.enter="searchStream" v-model="streamSearchTerm"
                                        @input="enableStreamSearch()" class="background search_field my-3 mx-5">
                                      </v-text-field> -->
                                </v-flex>
                                <v-flex xs12 md4>
                                  <!-- <div class="pa-1">
                                        <v-btn
                                          class="secondary text-none"
                                          rounded
                                          depressed
                                          @click="searchStream"
                                          :loading="searchStreamLoader"
                                          :disabled="searchStreamButtonDisabled"
                                        >
                                          {{ $t("searchMsg") }}
                                          <v-icon right>mdi-magnify</v-icon>
                                        </v-btn>
                                      </div> -->
                                  <div align="center" class="my-4">
                                    <v-btn rounded depressed class="white--text universal text-none"
                                      @click="changeStreamView('create')" v-if="$can('class_stream_create')">
                                      Add Class Stream
                                      <v-icon right>mdi-plus-circle-outline</v-icon>
                                    </v-btn>
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                          <v-flex xs12 class="mt-10">
                            <div v-if="assignedLevel.level_streams.length == 0">
                              <v-card elevation="0">
                                <v-layout row wrap>
                                  <v-flex xs12 md1>
                                    <v-container fill-height fluid>
                                      <v-row align="center" justify="center">
                                        <v-col class="text-center">
                                          <v-icon large class="primary--text">
                                            mdi-alert-circle-outline
                                          </v-icon>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-flex>
                                  <v-flex xs12 md11>
                                    <v-container fill-height fluid>
                                      <v-row align="center" justify="center">
                                        <v-col class="text-center">
                                          No Class Stream Found
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </div>
                            <div v-else>
                              <v-progress-linear v-if="levelstreamLoader" height="1" indeterminate color="primary">
                              </v-progress-linear>
                              <div class="hidden-sm-and-down">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead class="header">
                                      <tr>
                                        <th class="text-left">{{ $t("streamMsg") }}</th>
                                        <th class="text-right">{{ $t("actionsMsg") }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="item in assignedLevel.level_streams" :key="item.id">
                                        <td>{{ item.name }}</td>
                                        <td>
                                          <div align="right">
                                            <!-- <v-tooltip top v-if="$can('class_stream_view')">
                                                <template v-slot:activator="{ on, attrs }"> -->
                                            <v-btn v-if="$can('class_stream_view')" icon @click="showLevelstream(item)"
                                              class="button mr-1 universal--text" v-bind="attrs" v-on="on">
                                              <v-icon small> mdi-eye </v-icon>
                                            </v-btn>
                                            <!-- </template>
                                                <span>{{ $t("viewMsg") }}</span>
                                              </v-tooltip>
                                              <v-tooltip top v-if="$can('class_stream_edit')">
                                                <template v-slot:activator="{ on, attrs }"> -->
                                            <v-btn v-if="$can('class_stream_edit')" icon
                                              class="button mr-1 success--text" @click="editLevelstream(item)"
                                              v-bind="attrs" v-on="on">
                                              <v-icon small> mdi-pencil </v-icon>
                                            </v-btn>
                                            <!-- </template>
                                                <span>{{ $t("editMsg") }}</span>
                                              </v-tooltip>
                                              <v-tooltip top v-if="$can('class_stream_archive')">
                                                <template v-slot:activator="{ on, attrs }"> -->
                                            <v-btn v-if="$can('class_stream_archive')" icon
                                              class="button mr-1 error--text" @click="deleteLevelstream(item)"
                                              :loading="loading && levelstreamIndex == item.id" v-bind="attrs"
                                              v-on="on">
                                              <v-icon small> mdi-delete </v-icon>
                                            </v-btn>
                                            <!-- </template>
                                                <span>{{ $t("deleteMsg") }}</span>
                                              </v-tooltip> -->
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </div>
                              <div class="hidden-md-and-up">
                                <v-layout column>
                                  <template v-for="(levelstream, index) in assignedLevel.level_streams">
                                    <div :key="index">
                                      <v-flex xs12 class="mb-2">
                                        <v-card elevation="0">
                                          <div class="pa-5">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <v-layout row wrap>
                                                  <v-flex xs11>
                                                    <div class="title primary--text">
                                                      <b>{{ levelstream.name }}</b>
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs1>
                                                    <v-btn depressed class="red--text"
                                                      :loading="loading && levelstreamIndex == levelstream.id" icon
                                                      @click="deleteLevelstream(levelstream)"
                                                      v-if="$can('class_stream_archive')">
                                                      <v-icon> mdi-delete </v-icon>
                                                    </v-btn>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-divider class="mt-2"></v-divider>
                                              <v-flex xs12 class="mt-1">
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-1">
                                                    <div>
                                                      <b>{{ $t("dateAddedMsg") }}</b>
                                                    </div>
                                                    <div>
                                                      {{
                                                          levelstream.created_at
                                                          | moment("DD/MM/YYYY - hh:m a")
                                                      }}
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout column>
                                                      <v-flex xs12>
                                                        <div class="pa-1">
                                                          <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                            " block @click="showLevelstream(levelstream)"
                                                            v-if="$can('class_stream_view')">{{ $t("viewMsg") }}
                                                            <v-icon right> mdi-eye </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div class="pa-1">
                                                          <v-btn depressed class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                            " block @click="editLevelstream(levelstream)"
                                                            v-if="$can('class_stream_edit')">{{ $t("editMsg") }}
                                                            <v-icon right>
                                                              mdi-border-color
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </div>
                                        </v-card>
                                      </v-flex>
                                    </div>
                                  </template>
                                </v-layout>
                              </div>
                            </div>
                          </v-flex>
                          <!-- <v-flex xs12 class="mt-10">
            <v-layout row wrap>
              <v-flex xs12 md9>
                <div align="left">
                  <v-pagination
                    v-if="length != 0 && length != 1"
                    :length="length"
                    total-visible="10"
                    v-model="levelstreamPagination.current_page"
                    @input="changePage()"
                    circle
                  >
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div v-if="length != 0" align="right" class="mr-3">
                  <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b
                  >{{ levelstreamPagination.total | formatNumber }}
                </div>
              </v-flex>
              
            </v-layout>
          </v-flex> -->
                        </div>
                        <div v-if="streamView == 'create'">
                          <v-flex class="mt-2">
                            <v-card elevation="0">
                              <v-card-title class="header">
                                <v-spacer></v-spacer>
                                <div v-if="newLevelstream.id == null">Add Class Stream</div>
                                <div v-else>Edit Class Stream</div>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="changeStreamView('default')">
                                  <v-icon> mdi-close </v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <v-form v-model="valid" ref="streamForm" lazy-validation>
                                  <v-layout row wrap class="mt-5">
                                    <v-flex xs12 md4>
                                      <div class="pa-1">
                                        <div>{{ $t("nameMsg") }}<span class="error--text">*</span></div>
                                        <v-text-field class="text_field background" outlined dense
                                          v-model="newLevelstream.name" :rules="inputRules"></v-text-field>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div class="pa-1" align="right">
                                        <v-btn rounded depressed class="universal white--text text-none mt-5"
                                          @click="saveStream" :disabled="!valid" :loading="loading">
                                          {{ $t("saveMsg") }}
                                          <v-icon right> mdi-content-save </v-icon>
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-form>
                              </v-card-text>
                              <!-- <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn rounded depressed class="universal white--text text-none mt-3"
                                    @click="saveStream" :disabled="!valid" :loading="loading">
                                    {{ $t("saveMsg") }}
                                    <v-icon right> mdi-content-save </v-icon>
                                  </v-btn>
                                </v-card-actions> -->
                            </v-card>
                          </v-flex>
                        </div>
                        <div v-if="streamView == 'show'">
                          <v-card elevation="0" class="background">
                            <v-card-title class="header">
                              <v-spacer></v-spacer>
                              <div>View Class Stream</div>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="changeStreamView('default')">
                                <v-icon> mdi-close </v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <div class="pa-3 mt-5">
                                <v-layout column>
                                  <v-flex xs12>
                                    <div align="right">
                                      <v-btn rounded depressed class="white--text universal text-none"
                                        @click="importClasslist()" v-if="$can('import_classlist')">
                                        Import Classlist
                                        <v-icon right>mdi-chevron-double-down</v-icon>
                                      </v-btn>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-5">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Stream Name: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>{{ assignedLevelstream.name }}</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12>

                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card-text>
                            <div>
                              <v-progress-linear v-if="classListLoader" height="1" indeterminate color="primary">
                              </v-progress-linear>
                              <div class="hidden-sm-and-down">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead class="header">
                                      <tr>
                                        <th class="text-left">Photo</th>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Gender </th>
                                        <th class="text-left">Date of Birth </th>
                                        <th class="text-left">Nationality </th>
                                        <th class="text-left">Status </th>
                                        <!-- <th class="text-right">{{ $t("actionsMsg") }}</th> -->
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="item in classList" :key="item.id">
                                        <td></td>
                                        <td>{{ item.first_name }} {{ item.last_name }} {{ item.other_names }}</td>
                                        <td>{{ item.gender.name }}</td>
                                        <td>{{ item.dob }}</td>
                                        <td>{{ item.nationality.name }}</td>
                                        <td>
                                          <div v-if="item.status = 1">Active</div>
                                          <div v-else>Inactive </div>
                                        </td>
                                        <!-- <td>
                          <div align="right">
                            <v-tooltip top v-if="$can('student_view')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="showStudent(item)" class="button mr-1 primary--text" v-bind="attrs"
                                  v-on="on">
                                  <v-icon small> mdi-eye </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("viewMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('student_edit')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="button mr-1 success--text" @click="editstudent(item)" v-bind="attrs"
                                  v-on="on">
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("editMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('student_archive')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="button mr-1 error--text" @click="deleteStudent(item)"
                                  :loading="loading && studentIndex == item.id" v-bind="attrs" v-on="on">
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("deleteMsg") }}</span>
                            </v-tooltip>
                          </div>
                        </td> -->
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </div>
                              <div class="hidden-md-and-up">
                                <v-layout column>
                                  <template v-for="(student, index) in classList">
                                    <div :key="index">
                                      <v-flex xs12 class="mb-2">
                                        <v-card elevation="0">
                                          <div class="pa-5">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <v-layout row wrap>
                                                  <v-flex xs11>
                                                    <div class="title primary--text">
                                                      <b>{{ student.first_name }} {{ student.last_name }} {{ student.other_names }}</b>
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs1>
                                                    <!-- <v-btn depressed class="red--text"
                                                      :loading="loading && studentIndex == student.id" icon
                                                      @click="deleteStudent(student)" v-if="$can('user_archive')">
                                                      <v-icon> mdi-delete </v-icon>
                                                    </v-btn> -->
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-divider class="mt-2"></v-divider>
                                              <v-flex xs12 class="mt-1">
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Gender</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{ student.gender.name }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Date of Birth</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{ student.dob }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Nationality</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{ student.nationality.name }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </div>
                                        </v-card>
                                      </v-flex>
                                    </div>
                                  </template>
                                </v-layout>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
              </v-layout>
            </div>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

html,
body {
  height: 100%;
  margin: 0;
}
</style>
<script>
import Vue from 'vue'
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

import * as Vue2Leaflet from 'vue2-leaflet'
import { latLng, Icon, icon } from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'

Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster)

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  components: {
    'v-map': Vue2Leaflet.LMap,
    'v-circle': Vue2Leaflet.LCircle,
    'v-tilelayer': Vue2Leaflet.LTileLayer,
    'v-icondefault': Vue2Leaflet.LIconDefault,
    'v-marker': Vue2Leaflet.LMarker,
    'v-popup': Vue2Leaflet.LPopup,
    'v-tooltip': Vue2Leaflet.LTooltip
  },
  data() {
    let customicon = icon(Object.assign({},
      Icon.Default.prototype.options,
      { iconUrl, shadowUrl }
    ))
    return {
      path: process.env.VUE_APP_API_URL,
      icon: customicon,
      tileOptions: {
        minZoom: 5.5,
        minNativeZoom: 1,
        detectRetina: true,
        updateWhenIdle: false,
        keepBuffer: 10
      },
      clusterOptions: {},
      maploaded: false,
      views: [
        { name: 'List' },
        { name: 'Map' }
      ],
      dragging: false,
      importDialog: false,
      activeTab: 'details',
      view: "default",
      contactView: "default",
      locationView: "default",
      administrativeLocationView: "default",
      resourceView: "default",
      homeView: 'List',
      loading: false,
      loadingLocation: false,
      loadingAdminLocation: false,
      loadingResource: false,
      deleteResourceLoading: false,
      deleteLocationLoading: false,
      deleteAdminLocationLoading: false,
      valid: true,
      importButton: true,
      excelValid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      tab: null,
      resourceTab: null,
      institutionTab: null,
      classTab: null,

      institutionIndex: null,
      snackbar: false,
      inputRules: [(v) => !!v || "Input is required"],

      resourceAvailable: [
        { id: 1, name: 'Yes' },
        { id: 0, name: 'No' },
      ],
      emailRules: [
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
            v
          ) ||
          "E-mail must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],
      websiteRules: [
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
          "URL must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],

      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      levelSearchButtonDisabled: false,
      filteredInstitutions: false,
      newInstitution: {
        id: null,
        location_id: null,
        name: null,
        alternative_name: null,
        knec_code: null,
        registration_code: null,
        institution_classification_id: null,
        institution_sector_id: null,
        institution_provider_id: null,
        institution_type_id: null,
        institution_ownership_id: null,
        institution_gender_id: null,
        institution_accomodation_id: null,
        institution_category_id: null,
        institution_cluster_id: null,
        asal_area: 0,
        date_opened: null,
        date_closed: null,
      },
      newContact: {
        institutionId: null,
        id: null,
        telephone: null,
        mobile: null,
        email: null,
        website: null,
      },
      newLocation: {
        id: null,
        institution_id: null,
        address: null,
        postal_code: null,
        location_locality_id: null,
        longitude: '36.8219',
        latitude: '-1.2921'
      },
      newAdministrativeLocation: {
        id: null,
        county_id: null,
        subcounty_id: null,
        ward_id: null,
      },
      newResource: {
        id: null,
        institution_id: null,
        institution_elec_resource_id: null,
        electricity_source_id: null,
        institution_water_resource_id: null,
        institution_washroom_facility_id: null,
        no_of_school_feeding: null,
        no_of_students_dewormed: null,

        no_of_permanent_classrooms: null,
        no_of_unused_permanent_classrooms: null,
        no_of_temporal_classrooms: null,
        no_of_unused_temporal_classrooms: null,
        internet_available: null,

        computers_availabe: null,
        no_of_computers: null,
        no_of_teacher_computer_male: null,
        no_of_teacher_computer_female: null,
        year: null,
        student_girl: null,
        student_boy: null,
        teacher_female: null,
        teacher_male: null,
        no_of_laboratories: null,
        water_storage_capacity: null,
        no_of_latrines: null
      },
      assignedInstitution: null,
      formData: null,
      levelFormData: null,
      fromDatePicker: false,
      toDatePicker: false,

      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      institutionResources: [],
      locationArray: [],

      imageDialog: false,
      imageLoading: false,
      fileRules: [(v) => !!v || this.$t("kindlySelectAFileMsg")],
      imageFile: null,
      imageValid: true,

      //classes
      classView: "default",
      levelIndex: null,
      searchTerm: null,
      levelSearchTerm: null,
      searchLoader: false,
      levelSearchLoader: false,
      searchButtonDisabled: false,
      filteredLevels: false,
      newLevel: {
        id: null,
        name: null,
        institution_id: null,
        start_date: null,
        end_date: null,
      },
      assignedLevel: null,

      fromDatePicker: false,
      toDatePicker: false,
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      classes: [],
      classList: [],
      classListLoader: false,

      //streams
      streamView: "default",
      levelstreamIndex: null,
      streamSearchTerm: null,
      searchStreamLoader: false,
      searchStreamButtonDisabled: false,
      filteredLevelstreams: false,
      classes: [],
      newLevelstream: {
        id: null,
        institution_id: null,
        level_id: null,
        name: null
      },
      newClassImport: {
        institution_type_id: null,
        institution_id: null,
        year: null,
        level_id: null,
        level_stream_id: null,
        target_level_stream_id: null,
      },
      filteredInstitutions: [],
      filteredYears: [],
      filteredClasses: [],
      filteredStreams: [],

      assignedLevelstream: null,
      subcounties: [],
      wards: [],
      institutionTypes: []
    };
  },
  watch: {
    toDatePicker(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  created() {
    if (this.$can("institution_view")) {
      this.startInstitutionLoader();
      this.fetchInstitutions(this.institutionPagination.current_page);
      this.getFormData();
      this.getLocation()
    }
  },
  methods: {
    ...mapActions([
      "fetchInstitutions",
      "startInstitutionLoader",
      "stopInstitutionLoader",
      "filterInstitutions",
      "fetchLevels",
      "startLevelLoader",
      "stopLevelLoader",
      "filterLevels",
      "filterLevel"
    ]),
    importClasslist(){
      this.importDialog = true
      apiCall({
        url: "/api/student-registration?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.institutionTypes = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    populateInstitutions() {
      this.importButton = true
      this.newClassImport.institution_id = null;
      this.newClassImport.level_id = null;
      this.newClassImport.level_stream_id = null;

      this.filteredInstitutions = []
      this.filteredYears = []
      this.filteredClasses = []
      this.filteredStreams = []

      let x = this.institutionTypes.find(
        (institutionType) => institutionType.id == this.newClassImport.institution_type_id
      );
      this.filteredInstitutions = x.institutions;
    },
    populateYears() {
      let x = this.filteredInstitutions.find(
        (institution) => institution.id == this.newClassImport.institution_id
      );

      var keys = [];
      var values = [];

      for (let value of Object.keys(x.levels)) {
        keys.push(value);
      }

      for (let value of Object.values(x.levels)) {
        values.push(value);
      }

      for (var i = 0; i < keys.length; i++) {
        var obj = {
          "year": keys[i],
          "data": values[i]
        }
        this.filteredYears.push(obj);
      }
      // console.log("this.years", this.years)
      //this.years = result;
    },
    populateClasses() {
      this.importButton = true
      this.newClassImport.level_id = null;
      this.newClassImport.level_stream_id = null;

      this.filteredClasses = []
      this.filteredStreams = []

      let x = this.filteredYears.find(
        (year) => year.year == this.newClassImport.year
      );
      this.filteredClasses = x.data;
    },
    populateClassStreams() {
      this.importButton = true
      this.newClassImport.level_stream_id = null;

      this.filteredStreams = []

      let x = this.filteredClasses.find(
        (stream) => stream.id == this.newClassImport.level_id
      );
      this.filteredStreams = x.level_streams;
    },

    activateImportButton(){
      this.importButton = false
    },
    assignYear(year) {
      this.classes = year
    },
    populateSubCounties() {
      this.newAdministrativeLocation.subcounty_id = null;
      this.newAdministrativeLocation.ward_id = null;
      let x = this.formData.counties.find((county) => county.id == this.newAdministrativeLocation.county_id);
      this.subcounties = x.subcounties;
    },
    populateWards() {
      this.newAdministrativeLocation.ward_id = null;
      let x = this.subcounties.find((subcounty) => subcounty.id == this.newAdministrativeLocation.subcounty_id);
      this.wards = x.wards;
    },
    changeHomeView(view) {
      this.homeView = view
      if (view == 'Map') {
        this.startInstitutionLoader
        apiCall({
          url: "/api/institution?type=map",
          method: "GET",
        })
          .then((resp) => {
            this.locationArray = resp
            this.stopInstitutionLoader
          })
          .catch((error) => {
            this.message = "anErrorOccurredEmailMsg";
            this.color = "error";
            this.loading = false;
            this.snackbar = true;
            this.stopInstitutionLoader
          });
      }
    },
    dragEvent() {
      var coordinates = this.$refs.markerRef.mapObject.getLatLng()
      this.newLocation.latitude = coordinates.lat
      this.newLocation.longitude = coordinates.lng
      this.dragging = false
    },
    onMapClick(value) {
      this.newLocation.latitude = value.latlng.lat
      this.newLocation.longitude = value.latlng.lng
    },
    getLocation() {
      var vm = this
      navigator.geolocation.getCurrentPosition(function (position) {
        vm.newLocation.latitude = position.coords.latitude.toString()
        vm.newLocation.longitude = position.coords.longitude.toString()
      });
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    enableLevelSearch() {
      this.levelSearchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredInstitutions = true;
      this.startInstitutionLoader();
      this.fetchInstitutions(1);
    },
    resetLevelSearch() {
      this.levelSearchTerm = null;
      this.filteredLevels = true;
      this.startLevelLoader();
      this.fetchLevels(1);
    },
    showInstitution(institution) {
      this.assignedInstitution = institution;
      this.newContact.institutionId = institution.id;

      if (this.assignedInstitution.institution_resource.length != 0) {
        this.assignResource(Object.values(this.assignedInstitution.institution_resource)[Object.values(this.assignedInstitution.institution_resource).length - 1])
        this.resourceTab = Object.keys(this.assignedInstitution.institution_resource).length - 1
      }

      this.startLevelLoader()
      apiCall({
        url: "/api/level?institution_id=" + institution.id,
        method: "GET"
      })
        .then((resp) => {
          this.stopLevelLoader()
          this.filterLevels(resp);
          if (resp.length != 0) {
            this.assignYear(Object.values(resp)[Object.values(resp).length - 1])
            this.classTab = Object.keys(resp).length - 1
          }
        })
        .catch((error) => {

        });
      //this.fetchLevels(institution.id);
      this.changeView("show");
    },
    showLevel(level) {
      this.assignedLevel = level;
      this.changeLevelView("show");
    },
    editLevel(level) {
      this.newLevel.id = level.id;
      this.newLevel.name = level.name;
      this.newLevel.institution_id = level.institution_id;
      this.newLevel.start_date = level.start_date;
      this.newLevel.end_date = level.end_date;
      this.changeLevelView("create");
    },
    editInstitution(institution) {
      this.newInstitution.id = institution.id;
      this.newInstitution.location_id = institution.location_id;
      this.newInstitution.name = institution.name;
      this.newInstitution.alternative_name = institution.alternative_name;
      this.newInstitution.knec_code = institution.knec_code;
      this.newInstitution.registration_code = institution.registration_code;
      this.newInstitution.institution_classification_id =
        institution.institution_classification_id;
      this.newInstitution.institution_sector_id =
        institution.institution_sector_id;
      this.newInstitution.institution_provider_id =
        institution.institution_provider_id;
      this.newInstitution.institution_type_id = institution.institution_type_id;
      this.newInstitution.institution_ownership_id =
        institution.institution_ownership_id;
      this.newInstitution.institution_gender_id =
        institution.institution_gender_id;
      this.newInstitution.institution_accomodation_id =
        institution.institution_accomodation_id;

      this.newInstitution.institution_category_id = institution.institution_category_id
      this.newInstitution.institution_cluster_id = institution.institution_cluster_id
      this.newInstitution.asal_area = institution.asal_area

      this.newInstitution.date_opened = institution.date_opened;
      this.newInstitution.date_closed = institution.date_closed;

      this.changeView("create");
    },
    editContact(contact) {
      this.newContact.id = contact.id;
      this.newContact.telephone = contact.telephone;
      this.newContact.mobile = contact.mobile;
      this.newContact.email = contact.email;
      this.newContact.website = contact.website;
      this.contactView = 'create'
    },
    editLocation(location) {
      this.newLocation.id = location.id;
      this.newLocation.address = location.address;
      this.newLocation.postal_code = location.postal_code;
      this.newLocation.location_locality_id = location.location_locality_id;
      this.newLocation.latitude = location.latitude;
      this.newLocation.longitude = location.longitude;
      this.locationView = 'create'
    },
    editAdminLocation(location) {
      this.newAdministrativeLocation.id = location.id;
      this.newAdministrativeLocation.county_id = location.county_id;
      this.newAdministrativeLocation.subcounty_id = location.subcounty_id;
      this.newAdministrativeLocation.ward_id = location.ward_id;

      this.administrativeLocationView = 'create'

      apiCall({
        url: "/api/institution-admin-loc?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;

          let x = this.formData.counties.find((county) => county.id == this.newAdministrativeLocation.county_id);
          this.subcounties = x.subcounties;

          let y = this.subcounties.find((subcounty) => subcounty.id == this.newAdministrativeLocation.subcounty_id);
          this.wards = y.wards;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    editResource(resource) {
      this.newResource.id = resource.id;
      this.newResource.institution_id = resource.institution_id;
      this.newResource.institution_elec_resource_id = resource.institution_elec_resource_id;
      this.newResource.electricity_source_id = resource.electricity_source_id;
      this.newResource.institution_water_resource_id = resource.institution_water_resource_id;
      this.newResource.water_storage_capacity = resource.water_storage_capacity
      this.newResource.institution_washroom_facility_id = resource.institution_washroom_facility.id;
      this.newResource.no_of_school_feeding = resource.no_of_school_feeding;
      this.newResource.no_of_students_dewormed = resource.no_of_students_dewormed;

      this.newResource.no_of_permanent_classrooms = resource.no_of_permanent_classrooms
      this.newResource.no_of_unused_permanent_classrooms = resource.no_of_unused_permanent_classrooms
      this.newResource.no_of_temporal_classrooms = resource.no_of_temporal_classrooms
      this.newResource.no_of_unused_temporal_classrooms = resource.no_of_unused_temporal_classrooms
      this.newResource.no_of_laboratories = resource.no_of_laboratories
      this.newResource.internet_available = resource.internet_available

      this.newResource.computers_availabe = resource.computers_availabe;
      this.newResource.no_of_computers = resource.no_of_computers;
      this.newResource.no_of_teacher_computer_male = resource.no_of_teacher_computer_male;
      this.newResource.no_of_teacher_computer_female = resource.no_of_teacher_computer_female;
      this.newResource.year = resource.year;
      this.newResource.student_girl = resource.institution_washroom_facility.student_girl,
      this.newResource.student_boy = resource.institution_washroom_facility.student_boy,
      this.newResource.teacher_female = resource.institution_washroom_facility.teacher_female,
      this.newResource.teacher_male = resource.institution_washroom_facility.teacher_male,
      this.newResource.no_of_latrines = resource.institution_washroom_facility.no_of_latrines
      this.resourceView = 'create'
    },
    uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedInstitution.id);

        apiCall({
          url: "/api/institution?type=image",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "profilePictureUploadedSuccessfullyMsg";
            this.color = "success";
            this.fetchInstitutions(this.institutionPagination.current_page);
            this.imageLoading = false;
            this.imageDialog = false;
            this.assignedInstitution.logo = resp.logo
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.newInstitution.id != null) {
          confirm("Are you sure you want to update institution?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/institution/" + this.newInstitution.id,
              data: this.newInstitution,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Institution Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create institution?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/institution",
              data: this.newInstitution,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Institution Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchInstitutions(1);
              });
          }
        }
      }
    },
    saveLevel() {
      if (this.$refs.levelForm.validate()) {
        if (this.newLevel.id != null) {
          confirm("Are you sure you want to update class?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/level/" + this.newLevel.id,
              data: this.newLevel,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Class Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeLevelView("default");
                //this.filterLevel(resp)
                this.fetchLevel(this.assignedInstitution.id);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create class?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/level",
              data: this.newLevel,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Class Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.startLevelLoader()
                this.changeLevelView("default");
                apiCall({
                  url: "/api/level?institution_id=" + this.assignedInstitution.id,
                  method: "GET"
                })
                  .then((resp) => {
                    this.stopLevelLoader()
                    this.filterLevels(resp);
                    if (resp.length != 0) {
                      this.assignYear(Object.values(resp)[Object.values(resp).length - 1])
                      this.classTab = Object.keys(resp).length - 1
                    }
                  })
                  .catch((error) => {

                  });
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      }
    },
    saveContact() {
      if (this.$refs.contactForm.validate()) {
        if (this.newContact.id != null) {
          confirm("Are you sure you want to update contact information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/institution-contact/" + this.newContact.id,
              data: this.newContact,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Contact Information Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.$nextTick(() => {
                  this.assignedInstitution.institution_contacts = resp;
                });

                this.changeContactView("default");

                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create contact information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/institution-contact",
              data: this.newContact,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Contact Information Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeContactView("default");
                this.assignedInstitution.institution_contacts = resp;
                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.fetchInstitutions(1);
              });
          }
        }
      }
    },
    saveAdminLocation() {
      if (this.$refs.adminLocationForm.validate()) {
        if (this.newAdministrativeLocation.id != null) {
          confirm("Are you sure you want to update administrative location information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.newAdministrativeLocation.institution_id = this.assignedInstitution.id
            this.loadingAdminLocation = true;
            apiCall({
              url: "/api/institution-admin-loc/" + this.newAdministrativeLocation.id,
              data: this.newAdministrativeLocation,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Location Information Updated Successfully";
                this.color = "orange";
                this.loadingAdminLocation = false;
                this.snackbar = true;
                this.confirm = false;
                this.$nextTick(() => {
                  this.assignedInstitution.institution_administrative_location = resp;
                });

                this.changeAdministrativeLocationView("default");

                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loadingAdminLocation = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to add administrative location?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.newAdministrativeLocation.institution_id = this.assignedInstitution.id
            this.loadingAdminLocation = true;
            apiCall({
              url: "/api/institution-admin-loc",
              data: this.newAdministrativeLocation,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Administrative Location Information Added Successfully";
                this.color = "success";
                this.loadingAdminLocation = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeAdministrativeLocationView("default");
                this.assignedInstitution.institution_administrative_location = resp;
                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loadingAdminLocation = false;
                this.snackbar = true;
                this.fetchInstitutions(1);
              });
          }
        }
      }
    },
    saveLocation() {
      if (this.$refs.locationForm.validate()) {
        if (this.newLocation.id != null) {
          confirm("Are you sure you want to update location information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loadingLocation = true;
            apiCall({
              url: "/api/institution-location/" + this.newLocation.id,
              data: this.newLocation,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Location Information Updated Successfully";
                this.color = "orange";
                this.loadingLocation = false;
                this.snackbar = true;
                this.confirm = false;
                this.$nextTick(() => {
                  this.assignedInstitution.institution_locations = resp;
                });

                this.changeLocationView("default");

                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loadingLocation = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to add location?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.newLocation.institution_id = this.assignedInstitution.id
            this.loadingLocation = true;
            apiCall({
              url: "/api/institution-location",
              data: this.newLocation,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Location Information Added Successfully";
                this.color = "success";
                this.loadingLocation = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeLocationView("default");
                this.assignedInstitution.institution_locations = resp;
                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loadingLocation = false;
                this.snackbar = true;
                this.fetchInstitutions(1);
              });
          }
        }
      }
    },
    saveResource() {
      if (this.$refs.resourceForm.validate()) {
        if (this.newResource.id != null) {
          confirm("Are you sure you want to update resource information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loadingResource = true;
            apiCall({
              url: "/api/institution-resource/" + this.newResource.id,
              data: this.newResource,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Resource Information Updated Successfully";
                this.color = "orange";
                this.loadingResource = false;
                this.snackbar = true;
                this.confirm = false;
                this.$nextTick(() => {
                  this.assignedInstitution.institution_resource = resp;
                });

                this.changeResourceView("default");

                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loadingResource = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to add resource?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.newResource.institution_id = this.assignedInstitution.id
            this.loadingResource = true;
            apiCall({
              url: "/api/institution-resource",
              data: this.newResource,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Resource Information Added Successfully";
                this.color = "success";
                this.loadingResource = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeResourceView("default");
                this.assignedInstitution.institution_resource = resp;
                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loadingResource = false;
                this.snackbar = true;
                this.fetchInstitutions(1);
              });
          }
        }
      }
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startInstitutionLoader();
        apiCall({
          url: "/api/institution?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterInstitutions(resp);
            this.searchLoader = false;
            this.stopInstitutionLoader();
            this.filteredInstitutions = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopInstitutionLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    levelSearch() {
      if (this.levelSearchTerm == null) {
        this.levelSearchButtonDisabled = true;
      } else {
        this.levelSearchLoader = true;
        this.levelSearchButtonDisabled = false;
        this.startLevelLoader();
        apiCall({
          url: "/api/level?type=search&search=" + this.levelSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterLevels(resp);
            this.levelSearchLoader = false;
            this.stopLevelLoader();
            this.filteredLevels = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.levelSearchLoader = false;
            this.stopLevelLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.newInstitution.id = null;
        this.newInstitution.location_id = null;
        this.newInstitution.name = null;
        this.newInstitution.alternative_name = null;
        this.newInstitution.knec_code = null;
        this.newInstitution.registration_code = null;
        this.newInstitution.institution_classification_id = null;
        this.newInstitution.institution_sector_id = null;
        this.newInstitution.institution_provider_id = null;
        this.newInstitution.institution_type_id = null;
        this.newInstitution.institution_ownership_id = null;
        this.newInstitution.institution_gender_id = null;
        this.newInstitution.institution_accomodation_id = null;        
        this.newInstitution.institution_category_id = null
        this.newInstitution.institution_cluster_id = null
        this.newInstitution.asal_area = 0
        this.newInstitution.date_opened = null;
        this.newInstitution.date_closed = null;
        this.changeLevelView("default");
      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {
      }
      this.view = view;
    },
    changeLevelView(classView) {
      if (classView == "default") {
        this.newLevel.id = null;
        this.newLevel.name = null;
        this.newLevel.start_date = null;
        this.newLevel.end_date = null;
        this.changeStreamView('default')
      } else if (classView == "create") {
        //this.getLevelFormData();
        this.newLevel.institution_id = this.assignedInstitution.id
      } else if (classView == "show") {
      }
      this.classView = classView;
    },
    changeContactView(view) {
      if (view == "default") {
        this.newContact.id = null;
        this.newContact.telephone = null;
        this.newContact.mobile = null;
        this.newContact.email = null;
        this.newContact.website = null;
      } else if (view == "create") {
        //this.getFormData();
      } else if (view == "show") {
      }
      this.contactView = view;
    },
    changeLocationView(view) {
      if (view == "default") {
        this.newLocation.id = null;
        this.newLocation.institution_id = null;
        this.newLocation.address = null;
        this.newLocation.postal_code = null;
        this.newLocation.location_locality_id = null;
        this.newLocation.latitude = '-1.2921';
        this.newLocation.longitude = '36.8219';
        this.getLocation()
      } else if (view == "create") {
        //this.getFormData();
      } else if (view == "show") {
      }
      this.locationView = view;
    },
    changeAdministrativeLocationView(view) {
      if (view == "default") {
        this.newAdministrativeLocation.id = null;
        this.newAdministrativeLocation.institution_id = null;
        this.newAdministrativeLocation.county_id = null;
        this.newAdministrativeLocation.subcounty_id = null;
        this.newAdministrativeLocation.ward_id = null;
      } else if (view == "create") {
        apiCall({
          url: "/api/institution-admin-loc?type=formData",
          method: "GET",
        })
          .then((resp) => {
            this.formData = resp;
          })
          .catch((error) => {
            this.message = "anErrorOccurredCouldntGetFormDataMsg";
            this.color = "error";
          });
        //this.getFormData();
      } else if (view == "show") {
      }
      this.administrativeLocationView = view;
    },
    changeResourceView(view) {
      if (view == "default") {
        this.newResource.id = null
        this.newResource.institution_id = null
        this.newResource.institution_elec_resource_id = null
        this.newResource.electricity_source_id = null;
        this.newResource.institution_water_resource_id = null
        this.newResource.water_storage_capacity = null
        this.newResource.institution_washroom_facility_id = null
        this.newResource.no_of_school_feeding = null
        this.newResource.no_of_students_dewormed = null

        this.newResource.no_of_permanent_classrooms = null
        this.newResource.no_of_unused_permanent_classrooms = null
        this.newResource.no_of_temporal_classrooms = null
        this.newResource.no_of_unused_temporal_classrooms = null
        this.newResource.no_of_laboratories = null
        this.newResource.internet_available = null

        this.newResource.computers_availabe = null
        this.newResource.no_of_computers = null
        this.newResource.no_of_teacher_computer_male = null
        this.newResource.no_of_teacher_computer_female = null
        this.newResource.year = null
        this.newResource.student_girl = null
        this.newResource.student_boy = null
        this.newResource.teacher_female = null
        this.newResource.teacher_male = null
        this.newResource.no_of_laboratories = null
        this.newResource.water_storage_capacity = null
        this.newResource.no_of_latrines = null
      } else if (view == "create") {
        //this.getFormData();
      } else if (view == "show") {
      }
      this.resourceView = view;
    },
    changePage() {
      this.startInstitutionLoader();
      if (this.filteredInstitutions == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/institution?page=" +
            this.institutionPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterInstitutions(resp);
            this.stopInstitutionLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopInstitutionLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchInstitutions(this.institutionPagination.current_page);
      }
    },
    deleteInstitution(item) {
      confirm("Are You Sure You Want to Delete Institution") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.institutionIndex = item.id;
        apiCall({ url: "/api/institution/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Institution Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionIndex = null;
            this.fetchInstitutions(this.institutionPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionIndex = null;
            console.log(error.response);
          });
      }
    },
    deleteContact(item) {
      confirm("Are You Sure You Want to Delete Contact Information?") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        apiCall({ url: "/api/institution-contact/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Contact Information Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.$nextTick(() => {
              this.assignedInstitution.institution_contacts = [];
            });
            this.fetchInstitutions(this.institutionPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionIndex = null;
            console.log(error.response);
          });
      }
    },
    deleteAdminLocation(item) {
      confirm("Are You Sure You Want to Delete Admin Location?") &&
        (this.delete = true);
      if (this.delete) {
        this.deleteAdminLocationLoading = true;
        apiCall({ url: "/api/institution-admin-loc/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Admin Location Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deleteAdminLocationLoading = false;
            this.delete = false;
            this.$nextTick(() => {
              this.assignedInstitution.institution_administrative_location = null;
            });
            this.fetchInstitutions(this.institutionPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.deleteAdminLocationLoading = false;
            this.delete = false;
            this.institutionIndex = null;
            console.log(error.response);
          });
      }
    },
    deleteLocation(item) {
      confirm("Are You Sure You Want to Delete Location?") &&
        (this.delete = true);
      if (this.delete) {
        this.deleteLocationLoading = true;
        apiCall({ url: "/api/institution-location/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Location Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deleteLocationLoading = false;
            this.delete = false;
            this.$nextTick(() => {
              this.assignedInstitution.institution_locations = [];
            });
            this.fetchInstitutions(this.institutionPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.deleteLocationLoading = false;
            this.delete = false;
            this.institutionIndex = null;
            console.log(error.response);
          });
      }
    },
    deleteResource(item) {
      confirm("Are You Sure You Want to Delete Resource?") &&
        (this.delete = true);
      if (this.delete) {
        this.deleteResourceLoading = true;
        apiCall({ url: "/api/institution-resource/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Resource Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deleteResourceLoading = false;
            this.delete = false;
            this.$nextTick(() => {
              this.assignedInstitution.institution_resources = [];
            });
            this.fetchInstitutions(this.institutionPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.deleteResourceLoading = false;
            this.delete = false;
            this.institutionIndex = null;
            console.log(error.response);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/institution?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    // getLevelFormData() {
    //   apiCall({
    //     url: "/api/level?type=formData",
    //     method: "GET",
    //   })
    //     .then((resp) => {
    //       this.levelFormData = resp;
    //     })
    //     .catch((error) => {
    //       this.message = "anErrorOccurredCouldntGetFormDataMsg";
    //       this.color = "error";
    //     });
    // },
    showLevelstream(levelstream) {
      this.assignedLevelstream = levelstream;
      this.classList = []
      this.classListLoader = true
      this.changeStreamView("show");
      apiCall({
        url: "/api/student-registration?type=classList&stream_id=" + levelstream.id,
        method: "GET",
      })
        .then((resp) => {
          this.classList = resp;
          this.classListLoader = false
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    importClassStream(){
      confirm('Are you sure you want to import this class list?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.newClassImport.target_level_stream_id = this.assignedLevelstream.id
            apiCall({
              url: "/api/student-registration?type=classListImport",
              data: this.newClassImport,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Class Stream Imported Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.importDialog = false;

                this.showLevelstream(this.assignedLevelstream)
                
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeStreamView("default");
              });
          }
    },
    editLevelstream(levelstream) {
      this.newLevelstream.id = levelstream.id;
      this.newLevelstream.name = levelstream.name;
      this.changeStreamView("create");
    },
    saveStream() {
      if (this.$refs.streamForm.validate()) {
        if (this.newLevelstream.id != null) {
          confirm('Are you sure you want to update class?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.newLevelstream.level_id = this.assignedLevel.id
            apiCall({
              url: "/api/levelstream/" + this.newLevelstream.id,
              data: this.newLevelstream,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Class Stream Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.assignedLevel.level_streams = resp
                this.changeStreamView("default");
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm('Are you sure you want to create class stream?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.newLevelstream.level_id = this.assignedLevel.id
            apiCall({
              url: "/api/levelstream",
              data: this.newLevelstream,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Class Stream Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.assignedLevel.level_streams = resp
                this.changeStreamView("default");
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeStreamView("default");
              });
          }
        }
      }
    },
    changeStreamView(streamView) {
      if (streamView == "default") {
        this.newLevelstream.id = null;
        this.newLevelstream.name = null;
        this.newLevelstream.level_id = null;
        this.newLevelstream.institution_id = null;

      } else if (streamView == "create") {
        //this.getFormData();
      } else if (streamView == "show") {

      }
      this.streamView = streamView;
    },
    deleteLevelstream(item) {
      confirm("Are You Sure You Want to Delete Class Stream") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.levelstreamIndex = item.id;
        apiCall({ url: "/api/levelstream/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Class Stream Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.levelstreamIndex = null;

            const index = this.assignedLevel.level_streams.indexOf(item);
            if (index > -1) {
              this.assignedLevel.level_streams.splice(index, 1);
            }

          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.levelstreamIndex = null;
          });
      }
    },
    closeFromDate() {
      this.fromDatePicker = false;
    },
    closeToDate() {
      this.toDatePicker = false;
    },
    closeYearDate(date) {
      this.$refs.yearMenu.save(date);
      this.$refs.picker.activePicker = 'YEAR'
      this.toDatePicker = false;
    },
    assignResource(resource) {
      this.institutionResources = resource
    }
  },
  computed: {
    ...mapGetters([
      "institutions",
      "institutionPagination",
      "institutionLoader",
      "level", "levelPagination", "levelLoader"
    ]),
    length: function () {
      return Math.ceil(
        this.institutionPagination.total / this.institutionPagination.per_page
      );
    },
    // classLength: function () {
    //   return Math.ceil(
    //     this.levelPagination.total / this.levelPagination.per_page
    //   );
    // },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
    formatFromDate() {
      if (this.newInstitution.date_opened != null) {
        const d = new Date(this.newInstitution.date_opened);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatToDate() {
      if (this.newInstitution.date_closed != null) {
        const d = new Date(this.newInstitution.date_closed);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatYear() {
      if (this.newResource.year != null) {
        const d = new Date(this.newResource.year);
        const year = d.getFullYear();

        this.newResource.year = year

        return year;
      }
    },
    formatLevelFromDate() {
      if (this.newLevel.start_date != null) {
        const d = new Date(this.newLevel.start_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatLevelToDate() {
      if (this.newLevel.end_date != null) {
        const d = new Date(this.newLevel.end_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
  },
};
</script>
