<template>
    <div>
      <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-container class="my-5">
        <v-layout column class="mt-5">
          <v-flex xs12 class="mt-5">
            <v-skeleton-loader
              v-if="skeletonLoader == true"
              v-bind="attrs"
              type="image, actions"
            ></v-skeleton-loader>
            <v-card
              v-if="skeletonLoader == false"
              class="pa-5 mt-5"
              elevation="0"
            >
              <v-layout row wrap>
                <v-flex xs12 md3>
                  <div align="center" class="my-5">
                    <v-avatar color="grey lighten-3" size="100">
                      <v-icon x-large class="green--text">
                        mdi-lock-reset
                      </v-icon>
                    </v-avatar>
                  </div>
                </v-flex>
                <v-flex xs12 md9>
                  <v-layout column>
                    <v-flex xs12>
                      <p class="mt-5">
                        Reset Password
                      </p>
                    </v-flex>
                    <div v-if="isActive == true">
                      <div v-if="init">
                        <v-form ref="form" v-model="valid" lazy-validation>
                          <v-flex xs12>
                            <v-text-field
                              filled
                              dense
                              v-model="userData.password"
                              :rules="passwordRules"
                              label="New Password*"
                              type="password"
                              prepend-inner-icon="mdi-lock-outline"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field
                                filled
                                dense
                                v-model="confirmpassword"
                                :rules="passwordConfirmRules"
                                label="Confirm Password*"
                                type="password"
                                prepend-inner-icon="mdi-lock-outline"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <div align="right">
                              <v-btn
                                depressed
                                class="secondary white--text text-none"
                                :disabled="!valid"
                                :loading="loading"
                                @click="save()"
                              >
                                Reset
                                <v-icon right>mdi-lock-open</v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                        </v-form>
                      </div>
                      <div v-else>
                        <v-alert type="success"
                          >Password successfully changed</v-alert
                        >
                        <v-btn class="secondary--text mt-5 text-none" text block to="/">Back Home</v-btn>
                      </div>
                    </div>
                    <div v-else>
                      <v-alert type="error"
                        >Invalid/Expired Token</v-alert
                      >
                    </div>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </template>
  <script>
  import apiCall from "@/utils/api";
  export default {
    components: {},
    data() {
      return {
        skeletonLoader: true,
        isActive: false,
        init: true,
        snackbar: false,
        userData: {
          token: null,
          password: null
        },
        loading: false,
        valid: true,
        message: "",
        color: "",
        confirmpassword: '',
        passwordRules: [
            v => !!v || 'Password is required',
            v => (v && v.length >= 8) || 'Minimum 8 characters',
            v => /(?=.*[A-Z])/.test(v) || 'Must have at least one uppercase character',
            v => /(?=.*\d)/.test(v) || 'Must have at least one number',
            v => /([!@$%.])/.test(v) || 'Must have at least one special character [!@#$%.]'
        ],
        passwordConfirmRules: [
            v => !!v || ' '
        ],
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      initialize() {
        apiCall({
          url: "/api/check-token/" + this.$route.params.token,
          method: "GET",
        })
          .then((resp) => {
            this.referenceData = resp;
            this.skeletonLoader = false;
            this.isActive = true;
          })
          .catch((error) => {
            this.skeletonLoader = false;
            this.isActive = false;
            console.log(error.response);
          });
      },
      save() {
        if (this.$refs.form.validate()) {
          if(this.userData.password != this.confirmpassword){
              this.message = "Passwords do not match"
              this.color = 'error'
              this.snackbar = true;
              this.alertType = 'error'
          }else{
            this.loading = true;
            this.userData.token = this.$route.params.token;
            apiCall({
              url: "/api/post-reset-password",
              data: this.userData,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Password reset successful.";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.init = false;
              })
              .catch((error) => {
                this.message = "An error occurred.";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      },
    },
  };
  </script>