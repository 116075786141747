import apiCall from '@/utils/api'

const state = {
	users: [],
    userPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    userLoader: false
};

const getters = {
    userLoader: (state) => state.userLoader,
	users: (state) => state.users,
    userPagination: (state) => state.userPagination
}

const actions = {
    async fetchUsers({commit}, page) {
		const response = await apiCall({url: `/api/user?page=${page}`, method: 'GET' });
		commit('setUsers', response)
        commit('stopLoader', response)
	},
    async filterUsers({commit, state},resp){
		commit('setUsers', resp)
	},
	async startUserLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopUserLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setUsers: (state, users) => {
		state.users = users.data
		state.userPagination.current_page = users.current_page
		state.userPagination.total = users.total
		state.userPagination.per_page = users.per_page
	},
    startLoader: (state) => state.userLoader = true,
	stopLoader: (state) => state.userLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
