<template>
  <div class="roles" v-if="$can('role_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ $t(message) }}</span>
    </v-snackbar>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <div class="title">{{ $t("rolesMsg") }}</div>
        </v-flex>
        <div v-if="view == 'default'">
          <v-flex xs12 class="mt-5 mb-2">
            <v-card elevation="0" class="header">
              <v-layout row wrap>
                <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                <v-flex xs12 md4>
                  <v-text-field
                    outlined
                    rounded
                    dense
                    :label="$t('searchMsg')"
                    append-icon="mdi-undo-variant"
                    @click:append="resetSearch()"
                    v-on:keyup.enter="search"
                    v-model="searchTerm"
                    @input="enableSearch()"
                    class="background search_field my-3 mx-5"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <div align="center" class="my-4">
                    <v-btn
                      rounded
                      depressed
                      class="white--text universal text-none"
                      @click="changeView('create')"
                      v-if="$can('role_create')"
                    >
                      {{ $t("addRoleMsg") }}
                      <v-icon right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                  <!-- <div class="pa-1">
                    <v-btn
                      class="secondary text-none"
                      depressed
                      block
                      @click="search"
                      :loading="searchLoader"
                      :disabled="searchButtonDisabled"
                    >
                      {{ $t("viewRoleMsg") }}
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </div> -->
                </v-flex>
                <v-flex xs12 md2 class="hidden-sm-and-down"> </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <div v-if="roles.length == 0">
              <v-card elevation="0">
                <v-layout row wrap>
                  <v-flex xs12 md1>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          <v-icon large class="primary--text">
                            mdi-alert-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md11>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          {{ $t("noRoleFoundMsg") }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-card>
            </div>
            <div v-else>
              <v-progress-linear
                v-if="roleLoader"
                height="1"
                indeterminate
                color="primary"
              ></v-progress-linear>
              <div class="hidden-sm-and-down">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="header">
                      <tr>
                        <th class="text-left">
                          {{ $t("roleNameMsg") }}
                        </th>
                        <th class="text-left">
                          {{ $t("roleGuardMsg") }}
                        </th>
                        <th class="text-left">
                          {{ $t("permissionsMsg") }}
                        </th>
                        <th class="text-right">
                          {{ $t("actionsMsg") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in roles" :key="item.id">
                        <td>
                          <div
                            v-if="highLightId == item.id"
                            class="green--text"
                          >
                            {{ item.name }}
                          </div>
                          <div v-else>
                            {{ item.name }}
                          </div>
                        </td>
                        <td>
                          <div
                            v-if="highLightId == item.id"
                            class="green--text"
                          >
                            {{ item.guard_name }}
                          </div>
                          <div v-else>
                            {{ item.guard_name }}
                          </div>
                        </td>
                        <td>
                          <div
                            v-if="highLightId == item.id"
                            class="green--text"
                          >
                            {{ item.permissions.length }}
                          </div>
                          <div v-else>
                            {{ item.permissions.length }}
                          </div>
                        </td>
                        <td>
                          <div align="right">
                            <v-tooltip top v-if="$can('role_view')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="showRole(item)"
                                  class="button mr-1 universal--text"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon small> mdi-eye </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("viewMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('role_edit')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="editRole(item)"
                                  class="button mr-1 success--text"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("editMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('role_archive')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="deleteRole(item)"
                                  :loading="loading && roleIndex == item.id"
                                  class="button error--text"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("deleteMsg") }}</span>
                            </v-tooltip>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="hidden-md-and-up">
                <v-layout column>
                  <template v-for="(role, index) in roles">
                    <div :key="index">
                      <v-flex xs12 class="mb-2">
                        <v-card elevation="0">
                          <div class="pa-5">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <div class="title primary--text">
                                      <b>{{ role.name }}</b>
                                    </div>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn
                                      depressed
                                      class="red--text"
                                      :loading="loading && roleIndex == role.id"
                                      icon
                                      @click="deleteRole(role)"
                                    >
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-divider class="mt-2"></v-divider>
                              <v-flex xs12 class="mt-1">
                                <v-layout column>
                                  <v-flex xs12 class="mt-1">
                                    <div>
                                      <b>{{ $t("roleGuardMsg") }}</b>
                                    </div>
                                    <div>{{ role.guard_name }}</div>
                                  </v-flex>
                                  <v-flex xs12 class="mt-1">
                                    <div>
                                      <b>{{ $t("permissionsMsg") }}</b>
                                    </div>
                                    <div>{{ role.permissions.length }}</div>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs6>
                                        <div class="pa-1">
                                          <v-btn
                                            depressed
                                            class="
                                              primary
                                              text-none
                                              white--text
                                            "
                                            block
                                            @click="showRole(role)"
                                            >{{ $t("viewMsg") }}
                                            <v-icon right> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                      <v-flex xs6>
                                        <div class="pa-1">
                                          <v-btn
                                            depressed
                                            class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                            "
                                            block
                                            @click="editRole(role)"
                                            >{{ $t("editMsg") }}
                                            <v-icon right>
                                              mdi-border-color
                                            </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <v-layout row wrap>
              <v-flex xs12 md9>
                <div align="left">
                  <v-pagination
                    v-if="length != 0 && length != 1"
                    :length="length"
                    total-visible="10"
                    v-model="rolePagination.current_page"
                    @input="changePage()"
                    prev-icon="mdi-menu-left"
                    next-icon="mdi-menu-right"
                  >
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div v-if="length != 0" align="right" class="mr-3">
                  <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b
                  >{{ rolePagination.total }}
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-2">
            <v-card elevation="0" class="background">
              <v-card-title class="header">
                <v-spacer></v-spacer>
                <div v-if="newRole.id == null">{{ $t("addRoleMsg") }}</div>
                <div v-else>{{ $t("editRoleMsg") }}</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5">
                    <v-flex xs12 md6>
                      <div class="pa-3">
                        <div>{{ $t("roleNameMsg") }}</div>
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          v-model="newRole.name"
                          :rules="inputRules"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md6>
                      <div class="pa-3">
                        <div>{{ $t("guardNameMsg") }}</div>
                        <v-select
                          :items="uniqueGuards"
                          v-model="newRole.guard_name"
                          class="text_field background"
                          item-value="name"
                          item-text="name"
                          outlined
                          dense
                          :rules="inputRules"
                        ></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="pa-5">
                        <v-layout column>
                          <v-flex xs12 class="my-2">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <div class="mt-5 ml-3">
                                  <b>{{ $t("permissionsMsg") }}</b>
                                </div>
                              </v-flex>

                              <v-flex xs12 md4>
                                <div class="pa-1">
                                  <v-text-field
                                    class="search_field header"
                                    rounded
                                    outlined
                                    dense
                                    :label="$t('searchMsg')"
                                    v-on:keyup.enter="searchPermission"
                                    v-model="searchPermissionTerm"
                                    @input="enablePermissionSearch()"
                                  ></v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 md4>
                                <!-- <div class="pa-1">
                                  <v-btn
                                    class="secondary text-none"
                                    depressed
                                    block
                                    @click="searchPermission"
                                    :loading="searchPermissionLoader"
                                    :disabled="permissionSearchButtonDisabled"
                                  >
                                    {{ $t("searchMsg") }}
                                    <v-icon right>mdi-magnify</v-icon>
                                  </v-btn>
                                </div> -->
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <!-- <div><b>{{ $t('selectedMsg') }}: </b>{{permissionIds}}</div> -->
                            <v-progress-linear
                              class="mb-1"
                              v-if="permissionLoader"
                              height="1"
                              indeterminate
                              color="primary"
                            ></v-progress-linear>
                            <v-layout row wrap>
                              <template v-for="(item, index) in permissions">
                                <v-flex xs12 md4 :key="index">
                                  <v-checkbox
                                    v-model="permissionIds"
                                    :value="item.name"
                                    :label="item.name"
                                  ></v-checkbox>
                                </v-flex>
                              </template>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12>
                            <div align="center">
                              <v-pagination
                                :length="permissionLength"
                                class="universal--text"
                                total-visible="10"
                                v-model="permissionPagination.current_page"
                                @input="changePermissionPage()"
                                circle
                              >
                              </v-pagination>
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  rounded
                  class="universal white--text text-none mt-3"
                  @click="save"
                  :disabled="!valid"
                  :loading="loading"
                >
                  {{ $t("saveMsg") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card elevation="0" class="background">
            <v-card-title class="header">
              <v-spacer></v-spacer>
              <div>{{ $t("viewRoleMsg") }}</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
                <v-layout column>
                    <v-flex xs12>
                        <v-layout row wrap class="mt-3">
                            <v-flex xs12 md6>
                            <div>
                                <b>{{ $t("roleNameMsg") }}: </b>{{ assignedRole.name }}
                            </div>
                            </v-flex>
                            <v-flex xs12 md6>
                            <div>
                                <b>{{ $t("guardNameMsg") }}: </b
                                >{{ assignedRole.guard_name }}
                            </div>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12>
                        <div class="my-5" align="center">
                            <b>{{ $t("permissionsMsg") }}</b>
                        </div>
                    </v-flex>
                </v-layout>
              

              
              <div class="pa-3">
                <v-layout row wrap>
                  <template v-for="(item, index) in assignedRole.permissions">
                    <v-flex xs12 md3 :key="index">
                      <v-checkbox
                        disabled
                        v-model="assignedRole.permissions"
                        :value="item"
                        :label="item"
                      ></v-checkbox>
                    </v-flex>
                  </template>
                </v-layout>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      dashBreadcrumb: true,
      showBreadcrumb: false,

      view: "default",
      page: 1,
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      roleIndex: null,
      snackbar: false,
      searchFilter: null,
      inputRules: [
        (v) => !!v || "Input is required",
        (v) =>
          !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v) ||
          "Must not have any special character",
      ],
      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      filteredRoles: false,
      searchPermissionTerm: null,
      permissionSearchButtonDisabled: false,
      searchButtonDisabled: false,
      searchPermissionLoader: false,
      filteredPermissions: false,
      permissionIds: [],
      uniqueGuards: [],
      newRole: {
        id: null,
        name: null,
        guard_name: null,
      },
      assignedRole: {
        name: null,
        guard_name: null,
        permissions: [],
      },
      highLightId: null,
    };
  },
  created() {
    if (this.$can("role_view")) {
      this.fetchRoles(this.rolePagination.current_page);
      this.startpermissionLoader();
      this.fetchPermissions(1);
    }
  },
  methods: {
    ...mapActions([
      "fetchRoles",
      "fetchPermissions",
      "filterPermissions",
      "startpermissionLoader",
      "stoppermissionLoader",
      "startRoleLoader",
      "stopRoleLoader",
      "filterRoles",
    ]),
    enablePermissionSearch() {
      this.permissionSearchButtonDisabled = false;
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    resetSearch() {
      (this.searchTerm = null), this.startRoleLoader();
      this.fetchRoles(1);
    },
    showRole(role) {
      this.highLightId = role.id;
      var flags = [],
        l = role.permissions.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[role.permissions[i].name]) continue;
        flags[role.permissions[i].name] = true;
        this.assignedRole.permissions.push(role.permissions[i].name);
      }

      this.assignedRole.name = role.name;
      this.assignedRole.guard_name = role.guard_name;
      this.changeView("show");
    },
    editRole(role) {
      this.highLightId = role.id;
      var flags = [],
        l = role.permissions.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[role.permissions[i].name]) continue;
        flags[role.permissions[i].name] = true;
        this.permissionIds.push(role.permissions[i].name);
      }

      this.newRole.id = role.id;
      this.newRole.name = role.name;
      this.newRole.guard_name = role.guard_name;

      this.changeView("create");
    },
    save() {
      if (this.permissionIds.length == 0) {
        this.message = "pleasePickAtLeastOnePermissionMsg";
        this.color = "error";
        this.loading = false;
        this.snackbar = true;
      } else {
        if (this.$refs.form.validate()) {
          this.loading = true;
          this.newRole.permissions = this.permissionIds;
          if (this.newRole.id != null) {
            apiCall({
              url: "/api/role/" + this.newRole.id,
              data: this.newRole,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "roleUpdatedSuccessfullyMsg";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchRoles(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          } else {
            apiCall({
              url: "/api/role",
              data: this.newRole,
              method: "POST",
            })
              .then((resp) => {
                this.message = "roleAddedSuccessfullyMsg";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchRoles(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      }
    },
    searchPermission() {
      if (this.searchPermissionTerm == null) {
        this.permissionSearchButtonDisabled = true;
      } else {
        this.startpermissionLoader();
        this.totalSelected = this.permissionIds;
        this.permissionIds = this.permissionIds.slice(0);
        this.searchPermissionLoader = true;
        this.permissionSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/permission?type=search&search=" + this.searchPermissionTerm,
          method: "GET",
        })
          .then((resp) => {
            this.stoppermissionLoader();
            this.filterPermissions(resp);
            this.permissionIds = this.totalSelected;
            this.searchPermissionLoader = false;
            this.filteredPermissions = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchPermissionLoader = false;
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changePermissionPage() {
      this.startpermissionLoader();
      this.permissionSearchButtonDisabled = false;
      this.totalSelected = this.permissionIds;
      this.permissionIds = this.permissionIds.slice(0);
      if (this.filteredPermissions == true) {
        apiCall({
          url:
            "/api/permission?page=" +
            this.permissionPagination.current_page +
            "&type=search&search=" +
            this.searchPermissionTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterPermissions(resp);
            this.stoppermissionLoader();
            this.permissionIds = this.totalSelected;
          })
          .catch((error) => {
            console.log(error.response);
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url: "/api/permission?page=" + this.permissionPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.filterPermissions(resp);
            this.stoppermissionLoader();
            this.permissionIds = this.totalSelected;
          })
          .catch((error) => {
            console.log(error.response);
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startRoleLoader();
        apiCall({
          url: "/api/role?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterRoles(resp);
            this.searchLoader = false;
            this.stopRoleLoader();
            this.filteredRoles = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopRoleLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.newRole.id = null;
        this.newRole.name = null;
        this.newRole.guard_name = null;
        this.permissionIds = [];
        this.dashBreadcrumb = true;
        this.showBreadcrumb = false;
        this.assignedRole.permissions = [];
      } else if (view == "create") {
        var flags = [],
          l = this.permissions.length,
          i;
        for (i = 0; i < l; i++) {
          if (flags[this.permissions[i].guard_name]) continue;
          flags[this.permissions[i].guard_name] = true;
          this.uniqueGuards.push(this.permissions[i].guard_name);
        }
      } else if (view == "show") {
        this.dashBreadcrumb = false;
        this.showBreadcrumb = true;
      }
      this.view = view;
    },
    changePage() {
      this.startRoleLoader();
      if (this.filteredRoles == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/role?page=" +
            this.rolePagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterRoles(resp);
            this.stopRoleLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopRoleLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchRoles(this.rolePagination.current_page);
      }
    },
    deleteRole(item) {
      confirm(this.$t("areYouSureYouWantToDeleteRoleMsg")) &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.roleIndex = item.id;
        apiCall({ url: "/api/role/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "roleDeletedSuccesfullyMsg";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.roleIndex = null;
            this.fetchRoles(this.rolePagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.roleIndex = null;
            console.log(error.response);
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "roles",
      "rolePagination",
      "roleLoader",
      "permissions",
      "permissionPagination",
      "permissionLoader",
    ]),
    length: function () {
      return Math.ceil(
        this.rolePagination.total / this.rolePagination.per_page
      );
    },
    permissionLength: function () {
      return Math.ceil(
        this.permissionPagination.total / this.permissionPagination.per_page
      );
    },
  },
};
</script>