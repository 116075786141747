import apiCall from '@/utils/api'

const state = {
	schoolAdmin: {},
	schoolAdmins: [],
    schoolAdminPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    schoolAdminLoader: false
};

const getters = {
    schoolAdminLoader: (state) => state.schoolAdminLoader,
	schoolAdmins: (state) => state.schoolAdmins,
	schoolAdmin: (state) => state.schoolAdmin,
    schoolAdminPagination: (state) => state.schoolAdminPagination
}

const actions = {
    async fetchSchoolAdmins({commit}, page) {
		const response = await apiCall({url: `/api/school-admin?page=${page}`, method: 'GET' });
		commit('setSchoolAdmins', response)
        commit('stopLoader', response)
	},
    async fetchSchoolAdmin({commit}, itemId) {
		const response = await apiCall({url: `/api/school-admin/${itemId}`, method: 'GET' });
		commit('setSchoolAdmin', response)
        commit('stopLoader', response)
	},
    async filterSchoolAdmins({commit, state},resp){
		commit('setSchoolAdmins', resp)
	},
	async startSchoolAdminLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopSchoolAdminLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setSchoolAdmins: (state, schoolAdmins) => {
		state.schoolAdmins = schoolAdmins.data
		state.schoolAdminPagination.current_page = schoolAdmins.current_page
		state.schoolAdminPagination.total = schoolAdmins.total
		state.schoolAdminPagination.per_page = schoolAdmins.per_page
	},
	setSchoolAdmin: (state, schoolAdmin) => {
		state.schoolAdmin = schoolAdmin
	},
    startLoader: (state) => state.schoolAdminLoader = true,
	stopLoader: (state) => state.schoolAdminLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
