<template>
    <div class="academicQualifications" v-if="$can('location_view')">
        <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
            <span>{{ $t(message) }}</span>
        </v-snackbar>
        <v-container>
            <v-layout column>
                <v-flex xs12>
                    <div class="title">Table Data</div>
                </v-flex>

                <v-flex xs12 class="mt-10">
                    <v-layout row wrap>
                        <v-flex xs12 md3 class="hidden-sm-and-down">
                            <div class="mr-3"
                                style="margin:1px, 1px; padding:1px; width: auto; height: 76vh; overflow-x: hidden; overflow-y: scroll; text-align:justify;">
                                <v-list dense class="header">
                                    <v-subheader>Tables</v-subheader>
                                    <v-list-item-group color="primary">
                                        <template v-for="(item, index) in items">
                                            <v-list-item :key="index" v-if="selectedItem == item.name" class="primary"
                                                @click="changeSelectedItem(item.name)">
                                                <v-list-item-icon>
                                                    <v-icon v-text="item.icon" class="white--text"></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.name" class="white--text">
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item :key="index" v-else @click="changeSelectedItem(item.name)">
                                                <v-list-item-icon>
                                                    <v-icon v-text="item.icon"></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                            </div>
                        </v-flex>
                        <v-flex xs12 md9>
                            <div v-if="view == 'default'">
                                <v-flex xs12 class="mt-3 mb-5" v-if="selectedItem!=null">
                                    <v-card elevation="0" class="header">
                                        <v-layout row wrap>
                                            <v-flex xs12 md8 class="hidden-sm-and-down"> </v-flex>

                                            <v-flex xs12 md4>
                                                <div align="center" class="my-4">
                                                    <v-btn rounded depressed class="white--text universal text-none"
                                                        @click="changeView('create')" v-if="$can('table_data_create')">
                                                        Add Item
                                                        <v-icon right>mdi-plus-circle-outline</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <div v-if="selectedItem == 'Academic Qualifications'">
                                    <div v-if="academicQualifications.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        
                                        <v-progress-linear v-if="academicQualificationLoader" height="1"
                                            indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}zz</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in academicQualifications" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in academicQualifications">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Absence Reasons'">
                                    <div v-if="absenceReasons.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        <v-progress-linear v-if="absenceReasonLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in absenceReasons" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in absenceReasons">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Attendance Periods'">
                                    <div v-if="attendancePeriods.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        <v-progress-linear v-if="attendancePeriodLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in attendancePeriods" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in attendancePeriods">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Countries'">
                                    <div v-if="countries.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <div class="hidden-sm-and-down">
                                                <v-progress-linear v-if="countryLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in countries" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in countries">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Counties'">
                                    <div v-if="counties.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <div class="hidden-sm-and-down">
                                                <v-progress-linear v-if="countyLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in counties" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in counties">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Designations'">
                                    <div v-if="designations.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <div class="hidden-sm-and-down">
                                                <v-progress-linear v-if="designationLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in designations" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in designations">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Genders'">
                                    <div v-if="genders.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <v-progress-linear v-if="genderLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in genders" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in genders">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Institution Accomodations'">
                                    <div v-if="institutionAccomodations.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <v-progress-linear v-if="institutionAccomodationLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in institutionAccomodations" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in institutionAccomodations">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Institution Classifications'">
                                    <div v-if="institutionClassifications.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        
                                        <v-progress-linear v-if="institutionClassificationLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in institutionClassifications" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in institutionClassifications">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Institution Electricity Resource'">
                                    <div v-if="institutionElectricityResources.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <v-progress-linear v-if="institutionElectricityResourceLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in institutionElectricityResources" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in institutionElectricityResources">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Institution Genders'">
                                    <div v-if="institutionGenders.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <v-progress-linear v-if="institutionGenderLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">                                                
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in institutionGenders" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in institutionGenders">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Institution Ownership'">
                                    <div v-if="institutionOwnerships.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <div class="hidden-sm-and-down">
                                                <v-progress-linear v-if="institutionOwnershipLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in institutionOwnerships" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in institutionOwnerships">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Institution Providers'">
                                    <div v-if="institutionProviders.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <v-progress-linear v-if="institutionProviderLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in institutionProviders" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in institutionProviders">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Institution Sectors'">
                                    <div v-if="institutionSectors.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <v-progress-linear v-if="institutionSectorLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in institutionSectors" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in institutionSectors">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Institution Types'">
                                    <div v-if="institutionTypes.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <v-progress-linear v-if="institutionTypeLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in institutionTypes" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in institutionTypes">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Institution Water Resource'">
                                    <div v-if="institutionWaterResources.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <div class="hidden-sm-and-down">
                                                <v-progress-linear v-if="institutionWaterResourceLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in institutionWaterResources" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in institutionWaterResources">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Location Localities'">
                                    <div v-if="locationLocalities.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <v-progress-linear v-if="locationLocalityLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">                                                
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in locationLocalities" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in locationLocalities">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Professional Institutions'">
                                    <div v-if="professionalInstitutions.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        <v-progress-linear v-if="professionalInstitutionLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in professionalInstitutions" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in professionalInstitutions">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'PTE NGO Trainings'">
                                    <div v-if="pteNgoTrainings.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        <v-progress-linear v-if="pteNgoTrainingLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in pteNgoTrainings" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in pteNgoTrainings">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'School Categories'">
                                    <div v-if="schoolCategories.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>

                                        <div class="hidden-sm-and-down">
                                                <v-progress-linear v-if="schoolCategoryLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in schoolCategories" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in schoolCategories">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Student Identifier'">
                                    <div v-if="studentIdentifiers.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        <v-progress-linear v-if="studentIdentifierLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in studentIdentifiers" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in studentIdentifiers">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Sub Counties'">
                                    <div v-if="subCounties.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        <v-progress-linear v-if="subCountyLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-left">Sub County</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in subCounties" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>{{ item.county.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in subCounties">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Teacher Types'">
                                    <div v-if="teacherTypes.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        
                                        <v-progress-linear v-if="teacherTypeLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in teacherTypes" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in teacherTypes">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Terms of Services'">
                                    <div v-if="termsOfServices.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        
                                        <v-progress-linear v-if="termsOfServiceLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in termsOfServices" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in termsOfServices">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedItem == 'Wards'">
                                    <div v-if="wards.length == 0">
                                        <v-card elevation="0">
                                            <v-layout row wrap>
                                                <v-flex xs12 md1>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                <v-icon large class="primary--text">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                                <v-flex xs12 md11>
                                                    <v-container fill-height fluid>
                                                        <v-row align="center" justify="center">
                                                            <v-col class="text-center">
                                                                No Item Found
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-tabs show-arrows v-model="tab" class="mb-3 hidden-md-and-up">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab v-for="(item, index) in items" :key="index"
                                                @click="changeSelectedItem(item.text)">
                                                <div class="text-none caption">{{ item.text }}</div>
                                            </v-tab>
                                        </v-tabs>
                                        
                                        <v-progress-linear v-if="wardLoader" height="1"
                                                    indeterminate color="primary"></v-progress-linear>
                                        <div class="hidden-sm-and-down">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr>
                                                                <th class="text-left">{{ $t("nameMsg") }}</th>
                                                                <th class="text-left">County</th>
                                                                <th class="text-left">Sub County</th>
                                                                <th class="text-right">{{ $t("actionsMsg") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in wards" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>{{ item.subcounty.county.name }}</td>
                                                                <td>{{ item.subcounty.name }}</td>
                                                                <td>
                                                                    <div align="right">
                                                                        <v-tooltip top v-if="$can('table_data_edit')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 success--text"
                                                                                    @click="editTableData(item)"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-pencil </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("editMsg") }}</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip top
                                                                            v-if="$can('table_data_archive')">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon
                                                                                    class="button mr-1 error--text"
                                                                                    @click="deleteTableData(item)"
                                                                                    :loading="loading && itemIndex == item.id"
                                                                                    v-bind="attrs" v-on="on">
                                                                                    <v-icon small> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t("deleteMsg") }}</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <v-layout column>
                                                <template v-for="(item, index) in wards">
                                                    <div :key="index">
                                                        <v-flex xs12 class="mb-2">
                                                            <v-card elevation="0">
                                                                <div class="pa-5">
                                                                    <v-layout column>
                                                                        <v-flex xs12>
                                                                            <v-layout row wrap>
                                                                                <v-flex xs11>
                                                                                    <div class="title primary--text">
                                                                                        <b>{{ item.name }}</b>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs1>
                                                                                    <v-btn depressed class="red--text"
                                                                                        :loading="loading && itemIndex == item.id"
                                                                                        icon
                                                                                        @click="deleteTableData(item)"
                                                                                        v-if="$can('table_data_archive')">
                                                                                        <v-icon> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                        <v-divider class="mt-2"></v-divider>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <v-layout column>
                                                                                <v-flex xs12 class="mt-1">
                                                                                    <div>
                                                                                        <b>{{ $t("dateAddedMsg") }}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        {{
                                                                                                item.created_at
                                                                                                | moment("DD/MM/YYYY - hh:m a")
                                                                                        }}
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 class="mt-5">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <div class="pa-1">
                                                                                                <v-btn depressed class="
                                                                                                    green
                                                                                                    darken-1
                                                                                                    text-none
                                                                                                    white--text
                                                                                                    " block
                                                                                                    @click="editTableData(item)"
                                                                                                    v-if="$can('table_data_edit')">
                                                                                                    {{
                                                                                                            $t("editMsg")
                                                                                                    }}
                                                                                                    <v-icon right>
                                                                                                        mdi-border-color
                                                                                                    </v-icon>
                                                                                                </v-btn>
                                                                                            </div>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </div>
                                                            </v-card>
                                                        </v-flex>
                                                    </div>
                                                </template>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="view == 'create'">
                                <v-flex class="mt-2">
                                    <v-card elevation="0" class="background">
                                        <v-card-title class="header">
                                            <v-spacer></v-spacer>
                                            <div v-if="newItem.id == null">Add Item</div>
                                            <div v-else>Edit Item</div>
                                            <v-spacer></v-spacer>
                                            <v-btn icon @click="changeView('default')">
                                                <v-icon> mdi-close </v-icon>
                                            </v-btn>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-form v-model="valid" ref="form" lazy-validation>
                                                <v-layout row wrap class="mt-5 mb-15">
                                                    <v-flex xs12 md6 v-if="selectedItem == 'Sub Counties' || selectedItem == 'Wards'">
                                                        <div class="pa-3">
                                                            <div>County<span class="error--text">*</span>
                                                            </div>
                                                            <v-select :items="formData.counties" class="text_field background" item-value="id"
                                                                item-text="name" outlined dense v-model="newItem.county_id" @input="populateSubCounties"></v-select>
                                                        </div>
                                                    </v-flex>
                                                    <v-flex xs12 md6 v-if="selectedItem == 'Wards'">
                                                        <div class="pa-3">
                                                            <div>Sub County<span class="error--text">*</span>
                                                            </div>
                                                            <v-select :items="subcounties" class="text_field background" item-value="id"
                                                                item-text="name" outlined dense v-model="newItem.subcounty_id"></v-select>
                                                        </div>
                                                    </v-flex>
                                                    <v-flex xs12 md6>
                                                        <div class="pa-3">
                                                            <div>{{ $t("nameMsg") }}<span class="error--text">*</span>
                                                            </div>
                                                            <v-text-field class="text_field background" outlined dense
                                                                v-model="newItem.name" :rules="inputRules">
                                                            </v-text-field>
                                                        </div>
                                                    </v-flex>
                                                    <v-flex xs12 md3 v-if="selectedItem != 'Sub Counties'">
                                                        <div class="pa-3">
                                                            
                                                        </div>
                                                    </v-flex>
                                                    <v-flex xs12 md3>
                                                        <div class="pa-3" align="center">
                                                            <v-btn rounded depressed
                                                                class="universal white--text text-none mt-6"
                                                                @click="save" :disabled="!valid" :loading="loading">
                                                                {{ $t("saveMsg") }}
                                                                <v-icon right> mdi-content-save </v-icon>
                                                            </v-btn>
                                                        </div>
                                                    </v-flex>
                                                </v-layout>
                                            </v-form>
                                        </v-card-text>
                                    </v-card>
                                </v-flex>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 class="mt-7">
                    <div v-if="selectedItem == 'Academic Qualifications'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="academicQualificationLength != 0 && academicQualificationLength != 1" :length="academicQualificationLength" total-visible="10"
                                        v-model="academicQualificationPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="academicQualificationLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ academicQualificationPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Absence Reasons'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="absenceReasonLength != 0 && absenceReasonLength != 1" :length="absenceReasonLength" total-visible="10"
                                        v-model="absenceReasonPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="absenceReasonLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ absenceReasonPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Attendance Periods'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="attendancePeriodLength != 0 && attendancePeriodLength != 1" :length="attendancePeriodLength" total-visible="10"
                                        v-model="attendancePeriodPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="attendancePeriodLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ attendancePeriodPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Countries'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="countryLength != 0 && countryLength != 1" :length="countryLength" total-visible="10"
                                        v-model="countryPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="countryLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ countryPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Counties'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="countyLength != 0 && countyLength != 1" :length="countyLength" total-visible="10"
                                        v-model="countyPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="countyLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ countyPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Designations'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="designationLength != 0 && designationLength != 1" :length="designationLength" total-visible="10"
                                        v-model="designationPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="designationLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ designationPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Genders'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="genderLength != 0 && genderLength != 1" :length="genderLength" total-visible="10"
                                        v-model="genderPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="genderLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ genderPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Institution Accomodations'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="institutionAccomodationLength != 0 && institutionAccomodationLength != 1" :length="institutionAccomodationLength" total-visible="10"
                                        v-model="institutionAccomodationPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="institutionAccomodationLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ institutionAccomodationPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Student Identifier'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="studentIdentifierLength != 0 && studentIdentifierLength != 1" :length="studentIdentifierLength" total-visible="10"
                                        v-model="studentIdentifierPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="studentIdentifierLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ studentIdentifierPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Institution Classifications'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="institutionClassificationLength != 0 && institutionClassificationLength != 1" :length="institutionClassificationLength" total-visible="10"
                                        v-model="institutionClassificationPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="institutionClassificationLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ institutionClassificationPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Institution Electricity Resource'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="institutionElectricityResourceLength != 0 && institutionElectricityResourceLength != 1" :length="institutionElectricityResourceLength" total-visible="10"
                                        v-model="institutionElectricityResourcePagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="institutionElectricityResourceLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ institutionElectricityResourcePagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Institution Genders'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="institutionGenderLength != 0 && institutionGenderLength != 1" :length="institutionGenderLength" total-visible="10"
                                        v-model="institutionGenderPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="institutionGenderLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ institutionGenderPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Institution Ownership'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="institutionOwnershipLength != 0 && institutionOwnershipLength != 1" :length="institutionOwnershipLength" total-visible="10"
                                        v-model="institutionOwnershipPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="institutionOwnershipLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ institutionOwnershipPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Institution Providers'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="institutionProviderLength != 0 && institutionProviderLength != 1" :length="institutionProviderLength" total-visible="10"
                                        v-model="institutionProviderPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="institutionProviderLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ institutionProviderPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Institution Sectors'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="institutionSectorLength != 0 && institutionSectorLength != 1" :length="institutionSectorLength" total-visible="10"
                                        v-model="institutionSectorPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="institutionSectorLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ institutionSectorPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Institution Types'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="institutionTypeLength != 0 && institutionTypeLength != 1" :length="institutionTypeLength" total-visible="10"
                                        v-model="institutionTypePagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="institutionTypeLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ institutionTypePagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Institution Water Resource'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="institutionWaterResourceLength != 0 && institutionWaterResourceLength != 1" :length="institutionWaterResourceLength" total-visible="10"
                                        v-model="institutionWaterResourcePagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="institutionWaterResourceLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ institutionWaterResourcePagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Location Localities'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="locationLocalityLength != 0 && locationLocalityLength != 1" :length="locationLocalityLength" total-visible="10"
                                        v-model="locationLocalityPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="locationLocalityLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ locationLocalityPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Professional Institutions'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="professionalInstitutionLength != 0 && professionalInstitutionLength != 1" :length="professionalInstitutionLength" total-visible="10"
                                        v-model="professionalInstitutionPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="professionalInstitutionLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ professionalInstitutionPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'PTE NGO Trainings'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="pteNgoTrainingLength != 0 && pteNgoTrainingLength != 1" :length="pteNgoTrainingLength" total-visible="10"
                                        v-model="pteNgoTrainingPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="pteNgoTrainingLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ pteNgoTrainingPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'School Categories'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="schoolCategoryLength != 0 && schoolCategoryLength != 1" :length="schoolCategoryLength" total-visible="10"
                                        v-model="schoolCategoryPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="schoolCategoryLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ schoolCategoryPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Sub Counties'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="subCountyLength != 0 && subCountyLength != 1" :length="subCountyLength" total-visible="10"
                                        v-model="subCountyPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="subCountyLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ subCountyPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Teacher Types'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="teacherTypeLength != 0 && teacherTypeLength != 1" :length="teacherTypeLength" total-visible="10"
                                        v-model="teacherTypePagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="teacherTypeLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ teacherTypePagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Terms of Services'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="termsOfServiceLength != 0 && termsOfServiceLength != 1" :length="termsOfServiceLength" total-visible="10"
                                        v-model="termsOfServicePagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="termsOfServiceLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ termsOfServicePagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="selectedItem == 'Wards'">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="wardLength != 0 && wardLength != 1" :length="wardLength" total-visible="10"
                                        v-model="wardPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="wardLength != 0" align="right" class="mr-3">
                                    <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>
                                        {{ wardPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<style>
/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0f1e3d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1e85f1;
}
</style>
<script>
import Vue from 'vue'
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
    components: {

    },
    data() {
        return {
            view: "default",
            loading: false,
            valid: true,
            message: "",
            color: "",
            delete: false,
            confirm: false,

            formData: null,

            itemIndex: null,
            snackbar: false,
            inputRules: [
                (v) => !!v || "Input is required"
            ],
            
            selectedItem: null,
            items: [
                { name: 'Academic Qualifications', icon: 'mdi-school' },
                { name: 'Absence Reasons', icon: 'mdi-account-question' },
                { name: 'Attendance Periods', icon: 'mdi-clipboard-text-clock' },
                { name: 'Countries', icon: 'mdi-earth' },
                { name: 'Counties', icon: 'mdi-map' },
                { name: 'Designations', icon: 'mdi-briefcase' },
                { name: 'Genders', icon: 'mdi-gender-male-female' },
                { name: 'Institution Accomodations', icon: 'mdi-bed' },
                { name: 'Institution Classifications', icon: 'mdi-shape' },
                { name: 'Institution Electricity Resource', icon: 'mdi-transmission-tower' },
                { name: 'Institution Genders', icon: 'mdi-gender-male-female-variant' },
                { name: 'Institution Ownership', icon: 'mdi-key-chain-variant' },
                { name: 'Institution Providers', icon: 'mdi-handshake' },
                { name: 'Institution Sectors', icon: 'mdi-format-list-bulleted-type' },
                { name: 'Institution Types', icon: 'mdi-order-bool-ascending-variant' },
                { name: 'Institution Water Resource', icon: 'mdi-water-pump' },
                { name: 'Location Localities', icon: 'mdi-domain' },
                { name: 'Professional Institutions', icon: 'mdi-office-building' },
                { name: 'PTE NGO Trainings', icon: 'mdi-book-open-page-variant' },
                { name: 'School Categories', icon: 'mdi-account-school' },
                { name: 'Sub Counties', icon: 'mdi-map-search' },
                { name: 'Student Identifier', icon: 'mdi-account-search' },
                { name: 'Teacher Types', icon: 'mdi-human-male-board' },
                { name: 'Terms of Services', icon: 'mdi-file-sign' },
                { name: 'Wards', icon: 'mdi-map-marker-radius' },
            ],
            subcounties: [],
            newItem: {
                id: null,
                county_id: null,
                subcounty_id: null,
                name: null
            }
        };
    },
    created() {

    },
    methods: {
        ...mapActions([
            "fetchAcademicQualifications",
            "startAcademicQualificationLoader",
            "stopAcademicQualificationLoader",
            "filterAcademicQualifications",

            "fetchAbsenceReasons",
            "startAbsenceReasonLoader",
            "stopAbsenceReasonLoader",
            "filterAbsenceReasons",

            "fetchAttendancePeriods",
            "startAttendancePeriodLoader",
            "stopAttendancePeriodLoader",
            "filterAttendancePeriods",

            "fetchCountries",
            "startCountryLoader",
            "stopCountryLoader",
            "filterCountries",

            "fetchCounties",
            "startCountyLoader",
            "stopCountyLoader",
            "filterCounties",

            "fetchDesignations",
            "startDesignationLoader",
            "stopDesignationLoader",
            "filterDesignations",

            "fetchGenders",
            "startGenderLoader",
            "stopGenderLoader",
            "filterGenders",

            "fetchInstitutionAccomodations",
            "startInstitutionAccomodationLoader",
            "stopInstitutionAccomodationLoader",
            "filterInstitutionAccomodations",

            "fetchStudentIdentifiers",
            "startStudentIdentifierLoader",
            "stopStudentIdentifierLoader",
            "filterStudentIdentifiers",

            "fetchInstitutionClassifications",
            "startInstitutionClassificationLoader",
            "stopInstitutionClassificationLoader",
            "filterInstitutionClassifications",

            "fetchInstitutionElectricityResources",
            "startInstitutionElectricityResourceLoader",
            "stopInstitutionElectricityResourceLoader",
            "filterInstitutionElectricityResources",

            "fetchInstitutionGenders",
            "startInstitutionGenderLoader",
            "stopInstitutionGenderLoader",
            "filterInstitutionGenders",

            "fetchInstitutionOwnerships",
            "startInstitutionOwnershipLoader",
            "stopInstitutionOwnershipLoader",
            "filterInstitutionOwnerships",

            "fetchInstitutionProviders",
            "startInstitutionProviderLoader",
            "stopInstitutionProviderLoader",
            "filterInstitutionProviders",

            "fetchInstitutionSectors",
            "startInstitutionSectorLoader",
            "stopInstitutionSectorLoader",
            "filterInstitutionSectors",

            "fetchInstitutionTypes",
            "startInstitutionTypeLoader",
            "stopInstitutionTypeLoader",
            "filterInstitutionTypes",

            "fetchInstitutionWaterResources",
            "startInstitutionWaterResourceLoader",
            "stopInstitutionWaterResourceLoader",
            "filterInstitutionWaterResources",

            "fetchLocationLocalities",
            "startLocationLocalityLoader",
            "stopLocationLocalityLoader",
            "filterLocationLocalities",

            "fetchProfessionalInstitutions",
            "startProfessionalInstitutionLoader",
            "stopProfessionalInstitutionLoader",
            "filterProfessionalInstitutions",
            
            "fetchPteNgoTrainings",
            "startPteNgoTrainingLoader",
            "stopPteNgoTrainingLoader",
            "filterPteNgoTrainings",

            "fetchSchoolCategories",
            "startSchoolCategoryLoader",
            "stopSchoolCategoryLoader",
            "filterSchoolCategories",

            "fetchSubCounties",
            "startSubCountyLoader",
            "stopSubCountyLoader",
            "filterSubCounties",

            "fetchTeacherTypes",
            "startTeacherTypeLoader",
            "stopTeacherTypeLoader",
            "filterTeacherTypes",

            "fetchTermsOfServices",
            "startTermsOfServiceLoader",
            "stopTermsOfServiceLoader",
            "filterTermsOfServices",

            "fetchWards",
            "startWardLoader",
            "stopWardLoader",
            "filterWards",
        ]),
        populateSubCounties(){
            this.newItem.subcounty_id = null;
            let x = this.formData.counties.find((county) => county.id == this.newItem.county_id);
            this.subcounties = x.subcounties;
        },
        changeSelectedItem(item) {
            this.selectedItem = item
            if (item == 'Academic Qualifications') {
                this.startAcademicQualificationLoader();
                this.fetchAcademicQualifications(this.academicQualificationPagination.current_page);
            }
            if (item == 'Absence Reasons') {
                this.startAbsenceReasonLoader();
                this.fetchAbsenceReasons(this.absenceReasonPagination.current_page);
            }
            if (item == 'Attendance Periods') {
                this.startAttendancePeriodLoader();
                this.fetchAttendancePeriods(this.attendancePeriodPagination.current_page);
            }
            if (item == 'Countries') {
                this.startCountryLoader();
                this.fetchCountries(this.countryPagination.current_page);
            }
            if (item == 'Counties') {
                this.startCountyLoader();
                this.fetchCounties(this.countyPagination.current_page);
            }
            if (item == 'Designations') {
                this.startDesignationLoader();
                this.fetchDesignations(this.designationPagination.current_page);
            }
            if (item == 'Genders') {
                this.startGenderLoader();
                this.fetchGenders(this.genderPagination.current_page);
            }
            if (item == 'Institution Accomodations') {
                this.startInstitutionAccomodationLoader();
                this.fetchInstitutionAccomodations(this.institutionAccomodationPagination.current_page);
            }
            if (item == 'Student Identifier') {
                this.startStudentIdentifierLoader();
                this.fetchStudentIdentifiers(this.studentIdentifierPagination.current_page);
            }
            if (item == 'Institution Classifications') {
                this.startInstitutionClassificationLoader();
                this.fetchInstitutionClassifications(this.institutionClassificationPagination.current_page);
            }
            if (item == 'Institution Electricity Resource') {
                this.startInstitutionElectricityResourceLoader();
                this.fetchInstitutionElectricityResources(this.institutionElectricityResourcePagination.current_page);
            }
            if (item == 'Institution Genders') {
                this.startInstitutionGenderLoader();
                this.fetchInstitutionGenders(this.institutionGenderPagination.current_page);
            }
            if (item == 'Institution Ownership') {
                this.startInstitutionOwnershipLoader();
                this.fetchInstitutionOwnerships(this.institutionOwnershipPagination.current_page);
            }
            if (item == 'Institution Providers') {
                this.startInstitutionProviderLoader();
                this.fetchInstitutionProviders(this.institutionProviderPagination.current_page);
            }
            if (item == 'Institution Sectors') {
                this.startInstitutionSectorLoader();
                this.fetchInstitutionSectors(this.institutionSectorPagination.current_page);
            }
            if (item == 'Institution Types') {
                this.startInstitutionTypeLoader();
                this.fetchInstitutionTypes(this.institutionTypePagination.current_page);
            }
            if (item == 'Institution Water Resource') {
                this.startInstitutionWaterResourceLoader();
                this.fetchInstitutionWaterResources(this.institutionWaterResourcePagination.current_page);
            }
            if (item == 'Location Localities') {
                this.startLocationLocalityLoader();
                this.fetchLocationLocalities(this.locationLocalityPagination.current_page);
            }
            if (item == 'Professional Institutions') {
                this.startProfessionalInstitutionLoader();
                this.fetchProfessionalInstitutions(this.professionalInstitutionPagination.current_page);
            }
            if (item == 'PTE NGO Trainings') {
                this.startPteNgoTrainingLoader();
                this.fetchPteNgoTrainings(this.pteNgoTrainingPagination.current_page);
            }
            if (item == 'School Categories') {
                this.startSchoolCategoryLoader();
                this.fetchSchoolCategories(this.schoolCategoryPagination.current_page);
            }
            if (item == 'Sub Counties') {
                this.startSubCountyLoader();
                this.fetchSubCounties(this.subCountyPagination.current_page);

                apiCall({
                    url: "/api/subcounty?type=formData",
                    method: "GET",
                })
                    .then((resp) => {
                    this.formData = resp;
                    })
                    .catch((error) => {
                    this.message = "anErrorOccurredCouldntGetFormDataMsg";
                    this.color = "error";
                    });

            }
            if (item == 'Teacher Types') {
                this.startTeacherTypeLoader();
                this.fetchTeacherTypes(this.teacherTypePagination.current_page);
            }
            if (item == 'Terms of Services') {
                this.startTermsOfServiceLoader();
                this.fetchTermsOfServices(this.termsOfServicePagination.current_page);
            }
            if (item == 'Wards') {
                this.startWardLoader();
                this.fetchWards(this.wardPagination.current_page);

                apiCall({
                    url: "/api/ward?type=formData",
                    method: "GET",
                })
                    .then((resp) => {
                    this.formData = resp;
                    })
                    .catch((error) => {
                    this.message = "anErrorOccurredCouldntGetFormDataMsg";
                    this.color = "error";
                    });
            }
        },
        editTableData(item) {
            this.newItem.id = item.id;
            this.newItem.name = item.name;

            if(this.selectedItem == 'Sub Counties'){
                this.newItem.county_id = item.county_id;
            }
            if(this.selectedItem == 'Wards'){
                this.newItem.county_id = item.subcounty.county_id;
                this.newItem.subcounty_id = item.subcounty_id;

                let x = this.formData.counties.find((county) => county.id == this.newItem.county_id);
                this.subcounties = x.subcounties;
            }
                

            this.changeView("create");
        },

        save() {
            if (this.$refs.form.validate()) {
                if (this.newItem.id != null) {
                    confirm('Are you sure you want to update item?') && (this.confirm = true);
                    if (this.confirm) {
                        this.loading = true;
                        if(this.selectedItem == 'Academic Qualifications'){
                            apiCall({
                                url: "/api/academic-qualification/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchAcademicQualifications(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Absence Reasons'){
                            apiCall({
                                url: "/api/absence-reason/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchAbsenceReasons(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Attendance Periods'){
                            apiCall({
                                url: "/api/attendance-period/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchAttendancePeriods(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Countries'){
                            apiCall({
                                url: "/api/country/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchCountries(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Counties'){
                            apiCall({
                                url: "/api/county/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchCounties(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Designations'){
                            apiCall({
                                url: "/api/designation/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchDesignations(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Genders'){
                            apiCall({
                                url: "/api/gender/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchGenders(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Institution Accomodations'){
                            apiCall({
                                url: "/api/institution-accomodation/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchInstitutionAccomodations(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Student Identifier'){
                            apiCall({
                                url: "/api/student-identifier/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchStudentIdentifiers(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Institution Classifications'){
                            apiCall({
                                url: "/api/institution-classification/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchInstitutionClassifications(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Institution Electricity Resource'){
                            apiCall({
                                url: "/api/institution-electricity-resource/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchInstitutionElectricityResources(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Institution Genders'){
                            apiCall({
                                url: "/api/institution-gender/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchInstitutionGenders(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Institution Ownership'){
                            apiCall({
                                url: "/api/institution-ownership/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchInstitutionOwnerships(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Institution Providers'){
                            apiCall({
                                url: "/api/institution-provider/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchInstitutionProviders(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Institution Sectors'){
                            apiCall({
                                url: "/api/institution-sector/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchInstitutionSectors(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Institution Types'){
                            apiCall({
                                url: "/api/institution-type/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchInstitutionTypes(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Institution Water Resource'){
                            apiCall({
                                url: "/api/institution-water-resource/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchInstitutionWaterResources(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Location Localities'){
                            apiCall({
                                url: "/api/location-locality/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchLocationLocalities(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Professional Institutions'){
                            apiCall({
                                url: "/api/professional-institution/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchProfessionalInstitutions(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'PTE NGO Trainings'){
                            apiCall({
                                url: "/api/pte-ngo-training/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchPteNgoTrainings(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'School Categories'){
                            apiCall({
                                url: "/api/school-category/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchSchoolCategories(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Sub Counties'){
                            apiCall({
                                url: "/api/subcounty/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchSubCounties(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Teacher Types'){
                            apiCall({
                                url: "/api/teacher-type/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchTeacherTypes(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Terms of Services'){
                            apiCall({
                                url: "/api/terms-of-service/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchTermsOfServices(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                        if(this.selectedItem == 'Wards'){
                            apiCall({
                                url: "/api/ward/" + this.newItem.id,
                                data: this.newItem,
                                method: "PUT",
                            })
                                .then((resp) => {
                                    this.message = "Item Updated Successfully";
                                    this.color = "orange";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.confirm = false;
                                    this.fetchWards(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                this.message = "anErrorOccurredMsg";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                        }
                    }
                } else {
                    confirm('Are you sure you want to create item?') && (this.confirm = true);
                    if (this.confirm) {
                        this.loading = true;
                        if(this.selectedItem == 'Academic Qualifications'){
                            apiCall({
                                url: "/api/academic-qualification",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchAcademicQualifications(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Absence Reasons'){
                            apiCall({
                                url: "/api/absence-reason",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchAbsenceReasons(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Attendance Periods'){
                            apiCall({
                                url: "/api/attendance-period",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchAttendancePeriods(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Countries'){
                            apiCall({
                                url: "/api/country",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchCountries(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Counties'){
                            apiCall({
                                url: "/api/county",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchCounties(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Designations'){
                            apiCall({
                                url: "/api/designation",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchDesignations(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Genders'){
                            apiCall({
                                url: "/api/gender",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchGenders(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Institution Accomodations'){
                            apiCall({
                                url: "/api/institution-accomodation",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchInstitutionAccomodations(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Student Identifier'){
                            apiCall({
                                url: "/api/student-identifier",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchStudentIdentifiers(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Institution Classifications'){
                            apiCall({
                                url: "/api/institution-classification",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchInstitutionClassifications(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Institution Electricity Resource'){
                            apiCall({
                                url: "/api/institution-electricity-resource",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchInstitutionElectricityResources(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Institution Genders'){
                            apiCall({
                                url: "/api/institution-gender",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchInstitutionGenders(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Institution Ownership'){
                            apiCall({
                                url: "/api/institution-ownership",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchInstitutionOwnerships(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Institution Providers'){
                            apiCall({
                                url: "/api/institution-provider",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchInstitutionProviders(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Institution Sectors'){
                            apiCall({
                                url: "/api/institution-sector",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchInstitutionSectors(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Institution Types'){
                            apiCall({
                                url: "/api/institution-type",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchInstitutionTypes(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Institution Water Resource'){
                            apiCall({
                                url: "/api/institution-water-resource",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchInstitutionWaterResources(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Location Localities'){
                            apiCall({
                                url: "/api/location-locality",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchLocationLocalities(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Professional Institutions'){
                            apiCall({
                                url: "/api/professional-institution",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchProfessionalInstitutions(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'PTE NGO Trainings'){
                            apiCall({
                                url: "/api/pte-ngo-raining",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchPteNgoTrainings(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'School Categories'){
                            apiCall({
                                url: "/api/school-category",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchSchoolCategories(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Sub Counties'){
                            apiCall({
                                url: "/api/subcounty",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchSubCounties(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Teacher Types'){
                            apiCall({
                                url: "/api/teacher-type",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchTeacherTypes(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Terms of Services'){
                            apiCall({
                                url: "/api/terms-of-service",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchTermsOfServices(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                        if(this.selectedItem == 'Wards'){
                            apiCall({
                                url: "/api/ward",
                                data: this.newItem,
                                method: "POST",
                            })
                                .then((resp) => {
                                    this.message = "Item Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.fetchWards(1);
                                    this.changeView("default");                                
                                })
                                .catch((error) => {
                                    this.message = "anErrorOccurredEmailMsg";
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                });
                        }
                    }
                }
            }
        },
        changeView(view) {
            if (view == "default") {
                this.newItem.id = null;
                this.newItem.name = null;
            } else if (view == "create") {
                
            } else if (view == "show") {

            }
            this.view = view;
        },
        changePage() {
            if(this.selectedItem == 'Academic Qualifications'){
                this.startAcademicQualificationLoader();
                this.fetchAcademicQualifications(this.academicQualificationPagination.current_page);
            }
            if(this.selectedItem == 'Absence Reasons'){
                this.startAbsenceReasonLoader();
                this.fetchAbsenceReasons(this.absenceReasonPagination.current_page);
            }
            if(this.selectedItem == 'Attendance Periods'){
                this.startAttendancePeriodLoader();
                this.fetchAttendancePeriods(this.attendancePeriodPagination.current_page);
            }
            if(this.selectedItem == 'Countries'){
                this.startCountryLoader();
                this.fetchCountries(this.countryPagination.current_page);
            }
            if(this.selectedItem == 'Counties'){
                this.startCountyLoader();
                this.fetchCounties(this.countyPagination.current_page);
            }
            if(this.selectedItem == 'Designations'){
                this.startDesignationLoader();
                this.fetchDesignations(this.designationPagination.current_page);
            }
            if(this.selectedItem == 'Genders'){
                this.startGenderLoader();
                this.fetchGenders(this.genderPagination.current_page);
            }
            if(this.selectedItem == 'Institution Accomodations'){
                this.startInstitutionAccomodationLoader();
                this.fetchInstitutionAccomodations(this.institutionAccomodationPagination.current_page);
            }
            if(this.selectedItem == 'Student Identifier'){
                this.startStudentIdentifierLoader();
                this.fetchStudentIdentifiers(this.studentIdentifierPagination.current_page);
            }
            if(this.selectedItem == 'Institution Classifications'){
                this.startInstitutionClassificationLoader();
                this.fetchInstitutionClassifications(this.institutionClassificationPagination.current_page);
            }
            if(this.selectedItem == 'Institution Electricity Resource'){
                this.startInstitutionElectricityResourceLoader();
                this.fetchInstitutionElectricityResources(this.institutionElectricityResourcePagination.current_page);
            }
            if(this.selectedItem == 'Institution Genders'){
                this.startInstitutionGenderLoader();
                this.fetchInstitutionGenders(this.institutionGenderPagination.current_page);
            }
            if(this.selectedItem == 'Institution Ownership'){
                this.startInstitutionOwnershipLoader();
                this.fetchInstitutionOwnerships(this.institutionOwnershipPagination.current_page);
            }
            if(this.selectedItem == 'Institution Providers'){
                this.startInstitutionProviderLoader();
                this.fetchInstitutionProviders(this.institutionProviderPagination.current_page);
            }
            if(this.selectedItem == 'Institution Sectors'){
                this.startInstitutionSectorLoader();
                this.fetchInstitutionSectors(this.institutionSectorPagination.current_page);
            }
            if(this.selectedItem == 'Institution Types'){
                this.startInstitutionTypeLoader();
                this.fetchInstitutionTypes(this.institutionTypePagination.current_page);
            }
            if(this.selectedItem == 'Institution Water Resource'){
                this.startInstitutionWaterResourceLoader();
                this.fetchInstitutionWaterResources(this.institutionWaterResourcePagination.current_page);
            }
            if(this.selectedItem == 'Location Localities'){
                this.startLocationLocalityLoader();
                this.fetchLocationLocalities(this.locationLocalityPagination.current_page);
            }
            if(this.selectedItem == 'Professional Institutions'){
                this.startProfessionalInstitutionLoader();
                this.fetchProfessionalInstitutions(this.professionalInstitutionPagination.current_page);
            }
            if(this.selectedItem == 'PTE NGO Trainings'){
                this.startPteNgoTrainingLoader();
                this.fetchPteNgoTrainings(this.pteNgoTrainingPagination.current_page);
            }
            if(this.selectedItem == 'School Categories'){
                this.startSchoolCategoryLoader();
                this.fetchSchoolCategories(this.schoolCategoryPagination.current_page);
            }
            if(this.selectedItem == 'Sub Counties'){
                this.startSubCountyLoader();
                this.fetchSubCounties(this.subCountyPagination.current_page);
            }
            if(this.selectedItem == 'Teacher Types'){
                this.startTeacherTypeLoader();
                this.fetchTeacherTypes(this.teacherTypePagination.current_page);
            }
            if(this.selectedItem == 'Terms of Services'){
                this.startTermsOfServiceLoader();
                this.fetchTermsOfServices(this.termsOfServicePagination.current_page);
            }
            if(this.selectedItem == 'Wards'){
                this.startWardLoader();
                this.fetchWards(this.wardPagination.current_page);
            }
        },
        deleteTableData(item) {
            confirm("Are You Sure You Want to Delete Item") && (this.delete = true);
            if (this.delete) {
                this.loading = true;
                this.itemIndex = item.id;
                if(this.selectedItem == 'Academic Qualifications'){
                    apiCall({ url: "/api/academic-qualification/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchAcademicQualifications(this.academicQualificationPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Absence Reasons'){
                    apiCall({ url: "/api/absence-reason/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchAbsenceReasons(this.absenceReasonPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Attendance Periods'){
                    apiCall({ url: "/api/attendance-period/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchAttendancePeriods(this.attendancePeriodPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Countries'){
                    apiCall({ url: "/api/country/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchCountries(this.countryPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Counties'){
                    apiCall({ url: "/api/county/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchCounties(this.countyPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Designations'){
                    apiCall({ url: "/api/designation/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchDesignations(this.designationPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Genders'){
                    apiCall({ url: "/api/gender/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchGenders(this.genderPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Institution Accomodations'){
                    apiCall({ url: "/api/institution-accomodation/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchInstitutionAccomodations(this.institutionAccomodationPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Student Identifier'){
                    apiCall({ url: "/api/student-identifier/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchStudentIdentifiers(this.studentIdentifierPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Institution Classifications'){
                    apiCall({ url: "/api/institution-classification/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchInstitutionClassifications(this.institutionClassificationPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Institution Electricity Resource'){
                    apiCall({ url: "/api/institution-electricity-resource/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchInstitutionElectricityResources(this.institutionElectricityResourcePagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Institution Genders'){
                    apiCall({ url: "/api/institution-gender/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchInstitutionGenders(this.institutionGenderPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Institution Ownership'){
                    apiCall({ url: "/api/institution-ownership/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchInstitutionOwnerships(this.institutionOwnershipPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Institution Providers'){
                    apiCall({ url: "/api/institution-provider/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchInstitutionProviders(this.institutionProviderPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Institution Sectors'){
                    apiCall({ url: "/api/institution-sector/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchInstitutionSectors(this.institutionSectorPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Institution Types'){
                    apiCall({ url: "/api/institution-type/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchInstitutionTypes(this.institutionTypePagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Institution Water Resource'){
                    apiCall({ url: "/api/institution-water-resource/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchInstitutionWaterResources(this.institutionWaterResourcePagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Location Localities'){
                    apiCall({ url: "/api/location-locality/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchLocationLocalities(this.locationLocalityPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Professional Institutions'){
                    apiCall({ url: "/api/professional-institution/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchProfessionalInstitutions(this.professionalInstitutionPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'PTE NGO Trainings'){
                    apiCall({ url: "/api/pte-ngo-training/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchPteNgoTrainings(this.pteNgoTrainingPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'School Categories'){
                    apiCall({ url: "/api/school-category/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchSchoolCategories(this.schoolCategoryPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Sub Counties'){
                    apiCall({ url: "/api/subcounty/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchSubCounties(this.subCountyPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Teacher Types'){
                    apiCall({ url: "/api/teacher-type/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchTeacherTypes(this.teacherTypePagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Terms of Services'){
                    apiCall({ url: "/api/terms-of-service/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchTermsOfServices(this.termsOfServicePagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
                if(this.selectedItem == 'Wards'){
                    apiCall({ url: "/api/ward/" + item.id, method: "DELETE" })
                        .then((resp) => {
                            this.message = "Item Deleted Succesfully";
                            this.color = "success";
                            this.snackbar = true;
                            this.loading = false;
                            this.delete = false;
                            this.itemIndex = null;
                            this.fetchWards(this.wardPagination.current_page);
                        })
                        .catch((error) => {
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.itemIndex = null;
                        console.log(error.response);
                    });
                }
            }
        },
    },
    computed: {
        ...mapGetters([
            "academicQualifications",
            "academicQualificationPagination",
            "academicQualificationLoader",

            "absenceReasons",
            "absenceReasonPagination",
            "absenceReasonLoader",

            "attendancePeriods",
            "attendancePeriodPagination",
            "attendancePeriodLoader",
            
            "countries",
            "countryPagination",
            "countryLoader",

            "counties",
            "countyPagination",
            "countyLoader",

            "designations",
            "designationPagination",
            "designationLoader",

            "genders",
            "genderPagination",
            "genderLoader",

            "institutionAccomodations",
            "institutionAccomodationPagination",
            "institutionAccomodationLoader",

            "studentIdentifiers",
            "studentIdentifierPagination",
            "studentIdentifierLoader",

            "institutionClassifications",
            "institutionClassificationPagination",
            "institutionClassificationLoader",

            "institutionElectricityResources",
            "institutionElectricityResourcePagination",
            "institutionElectricityResourceLoader",

            "institutionGenders",
            "institutionGenderPagination",
            "institutionGenderLoader",

            "institutionOwnerships",
            "institutionOwnershipPagination",
            "institutionOwnershipLoader",

            "institutionProviders",
            "institutionProviderPagination",
            "institutionProviderLoader",

            "institutionSectors",
            "institutionSectorPagination",
            "institutionSectorLoader",

            "institutionTypes",
            "institutionTypePagination",
            "institutionTypeLoader",

            "institutionWaterResources",
            "institutionWaterResourcePagination",
            "institutionWaterResourceLoader",

            "locationLocalities",
            "locationLocalityPagination",
            "locationLocalityLoader",

            "professionalInstitutions",
            "professionalInstitutionPagination",
            "professionalInstitutionLoader",
            
            "pteNgoTrainings",
            "pteNgoTrainingPagination",
            "pteNgoTrainingLoader",

            "schoolCategories",
            "schoolCategoryPagination",
            "schoolCategoryLoader",

            "subCounties",
            "subCountyPagination",
            "subCountyLoader",

            "teacherTypes",
            "teacherTypePagination",
            "teacherTypeLoader",

            "termsOfServices",
            "termsOfServicePagination",
            "termsOfServiceLoader",

            "wards",
            "wardPagination",
            "wardLoader",
        ]),
        academicQualificationLength: function () {
            return Math.ceil(
                this.academicQualificationPagination.total / this.academicQualificationPagination.per_page
            );
        },
        absenceReasonLength: function () {
            return Math.ceil(
                this.absenceReasonPagination.total / this.absenceReasonPagination.per_page
            );
        },
        attendancePeriodLength: function () {
            return Math.ceil(
                this.attendancePeriodPagination.total / this.attendancePeriodPagination.per_page
            );
        },
        countryLength: function () {
            return Math.ceil(
                this.countryPagination.total / this.countryPagination.per_page
            );
        },
        countyLength: function () {
            return Math.ceil(
                this.countyPagination.total / this.countyPagination.per_page
            );
        },
        designationLength: function () {
            return Math.ceil(
                this.designationPagination.total / this.designationPagination.per_page
            );
        },
        genderLength: function () {
            return Math.ceil(
                this.genderPagination.total / this.genderPagination.per_page
            );
        },
        institutionAccomodationLength: function () {
            return Math.ceil(
                this.institutionAccomodationPagination.total / this.institutionAccomodationPagination.per_page
            );
        },
        studentIdentifierLength: function () {
            return Math.ceil(
                this.studentIdentifierPagination.total / this.studentIdentifierPagination.per_page
            );
        },
        institutionClassificationLength: function () {
            return Math.ceil(
                this.institutionClassificationPagination.total / this.institutionClassificationPagination.per_page
            );
        },
        institutionElectricityResourceLength: function () {
            return Math.ceil(
                this.institutionElectricityResourcePagination.total / this.institutionElectricityResourcePagination.per_page
            );
        },
        institutionGenderLength: function () {
            return Math.ceil(
                this.institutionGenderPagination.total / this.institutionGenderPagination.per_page
            );
        },
        institutionOwnershipLength: function () {
            return Math.ceil(
                this.institutionOwnershipPagination.total / this.institutionOwnershipPagination.per_page
            );
        },
        institutionProviderLength: function () {
            return Math.ceil(
                this.institutionProviderPagination.total / this.institutionProviderPagination.per_page
            );
        },
        institutionSectorLength: function () {
            return Math.ceil(
                this.institutionSectorPagination.total / this.institutionSectorPagination.per_page
            );
        },
        institutionTypeLength: function () {
            return Math.ceil(
                this.institutionTypePagination.total / this.institutionTypePagination.per_page
            );
        },
        institutionWaterResourceLength: function () {
            return Math.ceil(
                this.institutionWaterResourcePagination.total / this.institutionWaterResourcePagination.per_page
            );
        },
        institutionWaterResourceLength: function () {
            return Math.ceil(
                this.institutionWaterResourcePagination.total / this.institutionWaterResourcePagination.per_page
            );
        },
        locationLocalityLength: function () {
            return Math.ceil(
                this.locationLocalityPagination.total / this.locationLocalityPagination.per_page
            );
        },
        professionalInstitutionLength: function () {
            return Math.ceil(
                this.professionalInstitutionPagination.total / this.professionalInstitutionPagination.per_page
            );
        },
        pteNgoTrainingLength: function () {
            return Math.ceil(
                this.pteNgoTrainingPagination.total / this.pteNgoTrainingPagination.per_page
            );
        },
        schoolCategoryLength: function () {
            return Math.ceil(
                this.schoolCategoryPagination.total / this.schoolCategoryPagination.per_page
            );
        },
        subCountyLength: function () {
            return Math.ceil(
                this.subCountyPagination.total / this.subCountyPagination.per_page
            );
        },
        teacherTypeLength: function () {
            return Math.ceil(
                this.teacherTypePagination.total / this.teacherTypePagination.per_page
            );
        },
        termsOfServiceLength: function () {
            return Math.ceil(
                this.termsOfServicePagination.total / this.termsOfServicePagination.per_page
            );
        },
        wardLength: function () {
            return Math.ceil(
                this.wardPagination.total / this.wardPagination.per_page
            );
        },
    },
};
</script>
