import apiCall from '@/utils/api'

const state = {
	institutionAccomodation: {},
	institutionAccomodations: [],
    institutionAccomodationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    institutionAccomodationLoader: false
};

const getters = {
    institutionAccomodationLoader: (state) => state.institutionAccomodationLoader,
	institutionAccomodations: (state) => state.institutionAccomodations,
	institutionAccomodation: (state) => state.institutionAccomodation,
    institutionAccomodationPagination: (state) => state.institutionAccomodationPagination
}

const actions = {
    async fetchInstitutionAccomodations({commit}, page) {
		const response = await apiCall({url: `/api/institution-accomodation?page=${page}`, method: 'GET' });
		commit('setInstitutionAccomodations', response)
        commit('stopInstitutionAccomodationLoader', response)
	},
    async fetchInstitutionAccomodation({commit}, itemId) {
		const response = await apiCall({url: `/api/institution-accomodation/${itemId}`, method: 'GET' });
		commit('setInstitutionAccomodation', response)
        commit('stopInstitutionAccomodationLoader', response)
	},
    async filterInstitutionAccomodations({commit, state},resp){
		commit('setInstitutionAccomodations', resp)
	},
	async startInstitutionAccomodationLoader({commit, state},resp){
		commit('startInstitutionAccomodationLoader', resp)
	},
	async stopInstitutionAccomodationLoader({commit, state},resp){
		commit('stopInstitutionAccomodationLoader', resp)
	},
};

const mutations = {
	setInstitutionAccomodations: (state, institutionAccomodations) => {
		state.institutionAccomodations = institutionAccomodations.data
		state.institutionAccomodationPagination.current_page = institutionAccomodations.current_page
		state.institutionAccomodationPagination.total = institutionAccomodations.total
		state.institutionAccomodationPagination.per_page = institutionAccomodations.per_page
	},
	setInstitutionAccomodation: (state, institutionAccomodation) => {
		state.institutionAccomodation = institutionAccomodation
	},
    startInstitutionAccomodationLoader: (state) => state.institutionAccomodationLoader = true,
	stopInstitutionAccomodationLoader: (state) => state.institutionAccomodationLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
