import apiCall from '@/utils/api'

const state = {
	attendancePeriod: {},
	attendancePeriods: [],
    allAttendancePeriods: [],
    attendancePeriodPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    attendancePeriodLoader: false
};

const getters = {
    attendancePeriodLoader: (state) => state.attendancePeriodLoader,
	attendancePeriods: (state) => state.attendancePeriods,
    allAttendancePeriods: (state) => state.allAttendancePeriods,
	attendancePeriod: (state) => state.attendancePeriod,
    attendancePeriodPagination: (state) => state.attendancePeriodPagination
}

const actions = {
    async fetchAttendancePeriods({commit}, page) {
		const response = await apiCall({url: `/api/attendance-period?page=${page}`, method: 'GET' });
		commit('setAttendancePeriods', response)
        commit('stopAttendancePeriodLoader', response)
	},
    async fetchAllAttendancePeriods({commit}) {
		const response = await apiCall({url: `/api/attendance-period?type=all`, method: 'GET' });
		commit('setAllAttendancePeriods', response)
        commit('stopAttendancePeriodLoader', response)
	},
    async fetchAttendancePeriod({commit}, itemId) {
		const response = await apiCall({url: `/api/attendance-period/${itemId}`, method: 'GET' });
		commit('setAttendancePeriod', response)
        commit('stopAttendancePeriodLoader', response)
	},
    async filterAttendancePeriods({commit, state},resp){
		commit('setAttendancePeriods', resp)
	},
	async startAttendancePeriodLoader({commit, state},resp){
		commit('startAttendancePeriodLoader', resp)
	},
	async stopAttendancePeriodLoader({commit, state},resp){
		commit('stopAttendancePeriodLoader', resp)
	},
};

const mutations = {
	setAttendancePeriods: (state, attendancePeriods) => {
		state.attendancePeriods = attendancePeriods.data
		state.attendancePeriodPagination.current_page = attendancePeriods.current_page
		state.attendancePeriodPagination.total = attendancePeriods.total
		state.attendancePeriodPagination.per_page = attendancePeriods.per_page
	},
	setAttendancePeriod: (state, attendancePeriod) => {
		state.attendancePeriod = attendancePeriod
	},
    setAllAttendancePeriods: (state, attendancePeriod) => {
		state.allAttendancePeriods = attendancePeriod
	},
    startAttendancePeriodLoader: (state) => state.attendancePeriodLoader = true,
	stopAttendancePeriodLoader: (state) => state.attendancePeriodLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
