import apiCall from '@/utils/api'

const state = {
	institutionProvider: {},
	institutionProviders: [],
    institutionProviderPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    institutionProviderLoader: false
};

const getters = {
    institutionProviderLoader: (state) => state.institutionProviderLoader,
	institutionProviders: (state) => state.institutionProviders,
	institutionProvider: (state) => state.institutionProvider,
    institutionProviderPagination: (state) => state.institutionProviderPagination
}

const actions = {
    async fetchInstitutionProviders({commit}, page) {
		const response = await apiCall({url: `/api/institution-provider?page=${page}`, method: 'GET' });
		commit('setInstitutionProviders', response)
        commit('stopInstitutionProviderLoader', response)
	},
    async fetchInstitutionProvider({commit}, itemId) {
		const response = await apiCall({url: `/api/institution-provider/${itemId}`, method: 'GET' });
		commit('setInstitutionProvider', response)
        commit('stopInstitutionProviderLoader', response)
	},
    async filterInstitutionProviders({commit, state},resp){
		commit('setInstitutionProviders', resp)
	},
	async startInstitutionProviderLoader({commit, state},resp){
		commit('startInstitutionProviderLoader', resp)
	},
	async stopInstitutionProviderLoader({commit, state},resp){
		commit('stopInstitutionProviderLoader', resp)
	},
};

const mutations = {
	setInstitutionProviders: (state, institutionProviders) => {
		state.institutionProviders = institutionProviders.data
		state.institutionProviderPagination.current_page = institutionProviders.current_page
		state.institutionProviderPagination.total = institutionProviders.total
		state.institutionProviderPagination.per_page = institutionProviders.per_page
	},
	setInstitutionProvider: (state, institutionProvider) => {
		state.institutionProvider = institutionProvider
	},
    startInstitutionProviderLoader: (state) => state.institutionProviderLoader = true,
	stopInstitutionProviderLoader: (state) => state.institutionProviderLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
