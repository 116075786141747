<template>
    <div class="reports" v-if="$can('reports_view')">
        <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
            <span>{{ $t(message) }}</span>
        </v-snackbar>
        <v-container>
            <v-layout column>
                <v-flex xs12>
                    <div class="title">Teacher Reports</div>
                </v-flex>
                <div v-if="view == 'default'">
                    <v-flex xs12 class="mt-5 mb-2">
                        <v-card elevation="0" class="header">
                            <v-layout column class="pa-5">
                                <v-flex xs12 class="mt-1">
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <div class="ml-1"><b>Location</b></div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap class="mt-2">
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>County</div>
                                                <v-select :items="formData.counties" class="text_field background"
                                                    item-value="id" item-text="name" outlined dense
                                                    v-model="reportData.county_id" @input="populateSubCounties">
                                                </v-select>
                                                </v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>Sub County</div>
                                                <v-select :items="subcounties" class="text_field background"
                                                    item-value="id" item-text="name" outlined dense
                                                    v-model="reportData.subcounty_id" @input="populateWards">
                                                </v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>Ward</div>
                                                <v-select :items="wards" class="text_field background" item-value="id"
                                                    item-text="name" outlined dense v-model="reportData.ward_id">
                                                </v-select>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-9">
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <div class="ml-1"><b>Filters</b></div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap class="mt-2">

                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>
                                                    Gender
                                                </div>
                                                <v-select :items="formData.gender" v-model="reportData.gender_id"
                                                    class="text_field background" item-value="id" item-text="name"
                                                    outlined dense></v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>
                                                    Institution Type
                                                </div>
                                                <v-select :items="formData.institutionTypes"
                                                    v-model="reportData.institution_type_id"
                                                    class="text_field background" item-value="id" item-text="name"
                                                    outlined dense></v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>
                                                    Nationality
                                                </div>
                                                <v-select :items="formData.country" v-model="reportData.country_id"
                                                    class="text_field background" item-value="id" item-text="name"
                                                    outlined dense></v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>
                                                    Institution Sectors
                                                </div>
                                                <v-select :items="formData.institutionSectors"
                                                    v-model="reportData.institution_sector_id"
                                                    class="text_field background" item-value="id" item-text="name"
                                                    outlined dense></v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>
                                                    Institution Accomodation
                                                </div>
                                                <v-select :items="formData.institutionAccomodations"
                                                    v-model="reportData.institution_accomodation_id"
                                                    class="text_field background" item-value="id" item-text="name"
                                                    outlined dense></v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>
                                                    Institution Gender
                                                </div>
                                                <v-select :items="formData.institutionGenders"
                                                    v-model="reportData.institution_gender_id"
                                                    class="text_field background" item-value="id" item-text="name"
                                                    outlined dense></v-select>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <div>
                                                    Academic Qualification
                                                </div>
                                                <v-select :items="formData.academicQualifications"
                                                    v-model="reportData.academic_qualification_id"
                                                    class="text_field background" item-value="id" item-text="name"
                                                    outlined dense></v-select>
                                            </div>
                                        </v-flex>
                                    </v-layout>

                                </v-flex>

                                <v-flex xs12 class="mt-9">
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <div class="ml-1"><b>Year</b></div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap class="mt-2">
                                        <v-flex xs12 md4>
                                            <div class="pa-1">
                                                <!-- <div>County</div> -->
                                                <v-select :items="formData.years" v-model="reportData.year"
                                                    class="text_field background" item-value="year" item-text="year"
                                                    outlined dense></v-select>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-8">
                                    <div align="right">
                                        <v-btn rounded depressed class="white--text universal text-none"
                                            :loading="loading" @click="generate()">
                                            Show Data
                                            <v-icon right>mdi-cogs</v-icon>
                                        </v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>

                        </v-card>
                    </v-flex>
                    <v-flex class="my-5">
                        <v-layout row wrap>
                            <v-flex xs6>
                                <!-- <v-btn rounded depressed class="white--text pdf text-none ml-3" :loading="pdfLoading" @click="pdfDownload()">
                                    Download PDF
                                    <v-icon right>mdi-microsoft-excel</v-icon>
                                </v-btn> -->
                            </v-flex>
                            <v-flex xs6>
                                <div align="right">
                                    <v-btn rounded depressed class="white--text excel text-none mr-3"
                                        :loading="csvLoading" @click="csvDownload()">
                                        Download CSV
                                        <v-icon right>mdi-microsoft-excel</v-icon>
                                    </v-btn>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="mt-5">
                        <div v-if="reports.length == 0">
                            <v-card elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md1>
                                        <v-container fill-height fluid>
                                            <v-row align="center" justify="center">
                                                <v-col class="text-center">
                                                    <v-icon large class="primary--text">
                                                        mdi-alert-circle-outline
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-flex>
                                    <v-flex xs12 md11>
                                        <v-container fill-height fluid>
                                            <v-row align="center" justify="center">
                                                <v-col class="text-center">
                                                    No Teacher Found
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </div>
                        <div v-else>
                            <div class="hidden-sm-and-down">
                                <v-progress-linear v-if="loading" height="1" indeterminate color="primary">
                                </v-progress-linear>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead class="header">
                                            <tr>
                                                <th class="text-left">Photo</th>
                                                <th class="text-left">Name</th>
                                                <th class="text-left">Gender</th>
                                                <th class="text-left">Nationality</th>
                                                <th class="text-left">Academic Qualification</th>
                                                <th class="text-left">Institution Type</th>
                                                <th class="text-left">Institution Sector</th>
                                                <th class="text-left">Institution Gender</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in reports" :key="item.id">
                                                <td class="pa-1">
                                                    <!-- <v-img class="elevation-0" :src="asset_url + item.profile_pic" max-height="140px"
                                                        max-width="200px"></v-img> -->
                                                    <div class="my-2">
                                                        <!-- <v-img :src="item.image" :alt="item.name" height="100px"></v-img> -->
                                                        <v-img src="img/badge.png" max-width="40"></v-img>
                                                    </div>
                                                </td>
                                                <td>{{ item.name }}</td>
                                                <td>
                                                    <div v-if="item.staff_detail != null">
                                                        {{ item.staff_detail.gender.name }}
                                                    </div>
                                                    <div v-else>
                                                        N/A
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="item.staff_detail != null">
                                                        {{ item.staff_detail.nationality.name }}
                                                    </div>
                                                    <div v-else>
                                                        N/A
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="item.staff_detail != null">
                                                        {{ item.staff_detail.academic_qualification.name }}
                                                    </div>
                                                    <div v-else>
                                                        N/A
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="item.staff_assignment != null">
                                                        {{ item.staff_assignment.institution.institution_type.name }}
                                                    </div>
                                                    <div v-else>
                                                        N/A
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="item.staff_assignment != null">
                                                        {{ item.staff_assignment.institution.institution_sector.name }}
                                                    </div>
                                                    <div v-else>
                                                        N/A
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="item.staff_assignment != null">
                                                        {{ item.staff_assignment.institution.institution_gender.name }}
                                                    </div>
                                                    <div v-else>
                                                        N/A
                                                    </div>
                                                </td>
                                                
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                            </div>
                            <div class="hidden-md-and-up">
                                <v-layout column>
                                    <template v-for="(report, index) in reports">
                                        <div :key="index">
                                            <v-flex xs12 class="mb-2">
                                                <v-card elevation="0">
                                                    <div class="pa-5">
                                                        <v-layout column>
                                                            <v-flex xs12>
                                                                <div class="title primary--text">
                                                                    <b>{{ report.name }}</b>
                                                                </div>
                                                            </v-flex>
                                                            <v-divider class="mt-2"></v-divider>
                                                            <v-flex xs12 class="mt-1">
                                                                <v-layout column>
                                                                    <v-flex xs12 class="mt-3">
                                                                        <v-layout row wrap>
                                                                            <v-flex xs4>
                                                                                <b>Gender</b>
                                                                            </v-flex>
                                                                            <v-flex xs8>
                                                                                <div v-if="report.staff_detail != null">
                                                                                    {{ report.staff_detail.gender.name }}
                                                                                </div>
                                                                                <div v-else>
                                                                                    N/A
                                                                                </div>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                    <v-flex xs12 class="mt-5">
                                                                        <v-layout row wrap>
                                                                            <v-flex xs4>
                                                                                <b>Nationality</b>
                                                                            </v-flex>
                                                                            <v-flex xs8>
                                                                                <div v-if="report.staff_detail != null">
                                                                                    {{ report.staff_detail.nationality.name }}
                                                                                </div>
                                                                                <div v-else>
                                                                                    N/A
                                                                                </div>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                    <v-flex xs12 class="mt-5">
                                                                        <v-layout row wrap>
                                                                            <v-flex xs4>
                                                                                <b>Academic Qualification</b>
                                                                            </v-flex>
                                                                            <v-flex xs8>
                                                                                <div v-if="report.staff_detail != null">
                                                                                    {{ report.staff_detail.academic_qualification.name }}
                                                                                </div>
                                                                                <div v-else>
                                                                                    N/A
                                                                                </div>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                    <v-flex xs12 class="mt-5">
                                                                        <v-layout row wrap>
                                                                            <v-flex xs4>
                                                                                <b>Institution Type</b>
                                                                            </v-flex>
                                                                            <v-flex xs8>
                                                                                <div v-if="report.staff_assignment != null">
                                                                                    {{ report.staff_assignment.institution.institution_type.name }}
                                                                                </div>
                                                                                <div v-else>
                                                                                    N/A
                                                                                </div>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                    <v-flex xs12 class="mt-5">
                                                                        <v-layout row wrap>
                                                                            <v-flex xs4>
                                                                                <b>Institution Sector</b>
                                                                            </v-flex>
                                                                            <v-flex xs8>
                                                                                <div v-if="report.staff_assignment != null">
                                                                                    {{ report.staff_assignment.institution.institution_sector.name }}
                                                                                </div>
                                                                                <div v-else>
                                                                                    N/A
                                                                                </div>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                    <v-flex xs12 class="mt-5">
                                                                        <v-layout row wrap>
                                                                            <v-flex xs4>
                                                                                <b>Institution Gender</b>
                                                                            </v-flex>
                                                                            <v-flex xs8>
                                                                                <div v-if="report.staff_assignment != null">
                                                                                    {{ report.staff_assignment.institution.institution_gender.name }}
                                                                                </div>
                                                                                <div v-else>
                                                                                    N/A
                                                                                </div>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                        </v-layout>
                                                    </div>
                                                </v-card>
                                            </v-flex>
                                        </div>
                                    </template>
                                </v-layout>
                            </div>
                        </div>
                    </v-flex>
                    <v-flex xs12 class="mt-10">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                                        v-model="reportPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="length != 0" align="right" class="mr-3">
                                    <b class="universal--text ml-4">{{ $t("totalMsg") }}: </b>{{ reportPagination.total
                                            |
                                            formatNumber
                                    }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </div>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import Vue from 'vue'
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
    components: {

    },
    data() {
        return {
            view: "default",
            loading: false,
            pdfLoading: false,
            csvLoading: false,
            valid: true,
            message: "",
            color: "",
            delete: false,
            confirm: false,
            snackbar: false,

            min: 0,
            max: 30,

            formData: null,
            reports: [],
            reportPagination: {
                search: ' ',
                current_page: 1,
                per_page: 0,
                total: 0,
                visible: 10
            },

            subcounties: [],
            wards: [],

            reportData: {
                id: null,
                county_id: null,
                subcounty_id: null,
                ward_id: null,
                gender_id: null,
                institution_type_id: null,
                country_id: null,
                institution_sector_id: null,
                institution_accomodation_id: null,
                academic_qualification_id: null,
                year: null,
            },
            monthNames: [
                { name: "January" },
                { name: "February" },
                { name: "March" },
                { name: "April" },
                { name: "May" },
                { name: "June" },
                { name: "July" },
                { name: "August" },
                { name: "September" },
                { name: "October" },
                { name: "November" },
                { name: "December" },
            ],
            months: [
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
            ],
        };
    },
    watch: {
        toDatePicker(val) {
            val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
        }
    },
    created() {
        if (this.$can("reports_view")) {
            this.getFormData();
            this.reportData.year = this.currentYear
        }
    },
    methods: {
        ...mapActions([

        ]),

        activateImportButton() {
            this.importButton = false
        },
        populateSubCounties() {
            this.reportData.subcounty_id = null;
            this.reportData.ward_id = null;
            let x = this.formData.counties.find((county) => county.id == this.reportData.county_id);
            this.subcounties = x.subcounties;
        },
        populateWards() {
            this.reportData.ward_id = null;
            let x = this.subcounties.find((subcounty) => subcounty.id == this.reportData.subcounty_id);
            this.wards = x.wards;
        },
        resetSearch() {
            this.searchTerm = null;
            this.filteredInstitutions = true;
            this.startInstitutionLoader();
            this.fetchInstitutions(1);
        },

        generate() {
            this.loading = true;
            apiCall({
                url: "/api/report?type=staffReport",
                data: this.reportData,
                method: "POST",
            })
                .then((resp) => {
                    this.reports = resp.data
                    this.reportPagination.current_page = resp.current_page
                    this.reportPagination.total = resp.total
                    this.reportPagination.per_page = resp.per_page

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error.response);
                    this.loading = false;
                    this.message = "anErrorOccurredMsg";
                    this.color = "error";
                    this.snackbar = true;
                });
        },
        csvDownload() {
            this.csvLoading = true;
            apiCall({
                url: "/api/report?type=csvStaffReport",
                data: this.reportData,
                method: "POST",
            })
                .then((resp) => {
                    this.csvLoading = false;
                    this.downloadFile(resp);
                })
                .catch((error) => {
                    console.log(error.response);
                    this.csvLoading = false;
                    this.message = "anErrorOccurredMsg";
                    this.color = "error";
                    this.snackbar = true;
                });
        },
        downloadFile(response, filename) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([response], { type: "application/csv" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = 'No of Teachers '  + this.dateToday +  ".csv";
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        },
        changePage() {
            this.loading = true;
            apiCall({
                url: "/api/report?type=staffReport&page=" + this.reportPagination.current_page,
                data: this.reportData,
                method: "POST",
            })
                .then((resp) => {
                    this.reports = resp.data
                    this.reportPagination.current_page = resp.current_page
                    this.reportPagination.total = resp.total
                    this.reportPagination.per_page = resp.per_page

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error.response);
                    this.loading = false;
                    this.message = "anErrorOccurredMsg";
                    this.color = "error";
                    this.snackbar = true;
                });
        },
        getFormData() {
            apiCall({
                url: "/api/report?type=staffFormData",
                method: "GET",
            })
                .then((resp) => {
                    this.formData = resp;
                })
                .catch((error) => {
                    this.message = "anErrorOccurredCouldntGetFormDataMsg";
                    this.color = "error";
                });
        },

        closeFromDate() {
            this.fromDatePicker = false;
        },
        closeToDate() {
            this.toDatePicker = false;
        },
        closeYearDate(date) {
            this.$refs.yearMenu.save(date);
            this.$refs.picker.activePicker = 'YEAR'
            this.toDatePicker = false;
        },

    },
    computed: {
        ...mapGetters([

        ]),
        length: function () {
            return Math.ceil(
                this.reportPagination.total / this.reportPagination.per_page
            );
        },
        currentYear() {
            var today = new Date();

            var timestamp = today.getFullYear()

            return timestamp;
        },
        dateToday() {
            var today = new Date();

            var timestamp =
                today.getFullYear() +
                "-" +
                this.months[today.getMonth()] +
                "-" +
                ("0" + today.getDate()).slice(-2);
            return timestamp;
        },
        formatFromDate() {
            if (this.reportData.date_opened != null) {
                const d = new Date(this.reportData.date_opened);
                const year = d.getFullYear();
                const date = d.getDate();
                const month = d.getMonth();

                return date + " " + this.monthNames[month].name + " " + year;
            }
        },
        formatToDate() {
            if (this.reportData.date_closed != null) {
                const d = new Date(this.reportData.date_closed);
                const year = d.getFullYear();
                const date = d.getDate();
                const month = d.getMonth();

                return date + " " + this.monthNames[month].name + " " + year;
            }
        },
        formatYear() {
            if (this.newResource.year != null) {
                const d = new Date(this.newResource.year);
                const year = d.getFullYear();

                this.newResource.year = year

                return year;
            }
        },
    },
};
</script>
