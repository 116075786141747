import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import dotenv from 'dotenv'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n';
import {ability} from '@/store'
import { abilitiesPlugin } from '@casl/vue'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false
Vue.use(abilitiesPlugin, ability)

dotenv.config()

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

document.addEventListener("deviceready", () => {
  StatusBar.backgroundColorByHexString('#EE7619');
  //NavigationBar.backgroundColorByHexString('#ffffff');

});
