import apiCall from '@/utils/api'

const state = {
	subCounty: {},
	subCounties: [],
    subCountyPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    subCountyLoader: false
};

const getters = {
    subCountyLoader: (state) => state.subCountyLoader,
	subCounties: (state) => state.subCounties,
	subCounty: (state) => state.subCounty,
    subCountyPagination: (state) => state.subCountyPagination
}

const actions = {
    async fetchSubCounties({commit}, page) {
		const response = await apiCall({url: `/api/subcounty?page=${page}`, method: 'GET' });
		commit('setSubCounties', response)
        commit('stopSubCountyLoader', response)
	},
    async fetchSubCounty({commit}, itemId) {
		const response = await apiCall({url: `/api/subcounty/${itemId}`, method: 'GET' });
		commit('setSubCounty', response)
        commit('stopSubCountyLoader', response)
	},
    async filterSubCounties({commit, state},resp){
		commit('setSubCounties', resp)
	},
	async startSubCountyLoader({commit, state},resp){
		commit('startSubCountyLoader', resp)
	},
	async stopSubCountyLoader({commit, state},resp){
		commit('stopSubCountyLoader', resp)
	},
};

const mutations = {
	setSubCounties: (state, subCounties) => {
		state.subCounties = subCounties.data
		state.subCountyPagination.current_page = subCounties.current_page
		state.subCountyPagination.total = subCounties.total
		state.subCountyPagination.per_page = subCounties.per_page
	},
	setSubCounty: (state, subCounty) => {
		state.subCounty = subCounty
	},
    startSubCountyLoader: (state) => state.subCountyLoader = true,
	stopSubCountyLoader: (state) => state.subCountyLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
