<template>
  <div class="staffs" v-if="$can('staff_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ $t(message) }}</span>
    </v-snackbar>
    <v-container fluid>
      <v-layout column>
        <v-flex xs12>
          <div class="title">Staff
            <!-- <span>
            <v-btn
              class="ma-2"
              color="primary"
              dark
              icon
              @click="changeView('create')"
              v-if="$can('staff_create')"
            >
              <v-icon> mdi-plus-box </v-icon>
            </v-btn>
            </span> -->
          </div>
        </v-flex>
        <div v-if="view == 'default'">
          <v-flex xs12 class="mt-5 mb-2">
            <v-card elevation="0" class="header">
              <v-layout row wrap>
                <v-flex xs12 md4 class="hidden-sm-and-down">
                  <!-- <v-text-field
                    type="date"
                    outlined
                    rounded
                    dense
                    label="Period"
                    @click:append="resetSearch()"
                    v-on:keyup.enter="search"
                    v-model="searchTerm"
                    @input="enableSearch()"
                    class="background search_field my-3 mx-5"
                  ></v-text-field> -->
                </v-flex>

                <v-flex xs12 md4>
                  <v-text-field outlined rounded dense label="Name" append-icon="mdi-undo-variant" @click:append="resetSearch()"
                    v-on:keyup.enter="search" v-model="searchTerm" @input="enableSearch()"
                    class="background search_field my-3 mx-5"></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <!-- <v-select
                    :items="formData.designations"
                    item-value="id"
                    item-text="name"
                    outlined
                    rounded
                    dense
                    label="Designation"
                    @click:append="resetSearch()"
                    v-on:keyup.enter="search"
                    v-model="searchTerm"
                    @input="enableSearch()"
                    class="background search_field my-3 mx-5"
                  ></v-select> -->
                  <div align="center" class="my-4">
                    <v-btn rounded depressed class="white--text universal text-none" @click="changeView('create')"
                      v-if="$can('staff_create')">
                      Add Staff
                      <v-icon right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>

                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <div v-if="staffs.length == 0">
              <v-card elevation="0">
                <v-layout row wrap>
                  <v-flex xs12 md1>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          <v-icon large class="primary--text">
                            mdi-alert-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md11>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          No Staff Found
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-card>
            </div>
            <div v-else>
              <v-progress-linear v-if="staffLoader" height="1" indeterminate color="primary"></v-progress-linear>
              <div class="hidden-sm-and-down">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="header">
                      <tr>
                        <th class="text-left">Photo</th>
                        <th class="text-left">UNEMIS Id</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">{{ $t("designationMsg") }}</th>
                        <th class="text-left">Start Date</th>
                        <th class="text-left">End Date</th>
                        <th class="text-right">{{ $t("actionsMsg") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in staffs" :key="item.id">
                        <td class="pa-1">
                          <div class="my-2">
                            <v-avatar size="56" v-if="item.profile_pic != null">
                            <img :src="path+'/profile_pics/'+item.profile_pic" alt="PPIC" />
                          </v-avatar>
                          <v-avatar v-else class="header">
                            <v-icon large> mdi-account-circle </v-icon>
                          </v-avatar>
                          </div>
                        </td>
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>

                        <td>
                          <div v-if="item.staff_detail != null">
                            {{ item.staff_detail.designation.name }}
                          </div>
                          <div v-else>
                            N/A
                          </div>
                        </td>
                        <td>
                          <div v-if="item.staff_detail != null">
                            {{ item.staff_detail.started_on }}
                          </div>
                          <div v-else>
                            N/A
                          </div>
                        </td>
                        <td>
                          <div v-if="item.staff_detail != null">
                            {{ item.staff_detail.ended_on }}
                          </div>
                          <div v-else>
                            N/A
                          </div>
                        </td>
                        <!-- <td><i v-if="item.stafflevelstreams.length > 0">Assigned</i><i
                            v-else-if="item.stafflevelstreams == undefined">Unassigned</i></td>
                        <td> -->
                        <td>
                          <div align="right">
                            <v-tooltip top v-if="$can('staff_view')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="showStaff(item)" class="button mr-1 universal--text" v-bind="attrs"
                                  v-on="on">
                                  <v-icon small> mdi-eye </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("viewMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('staff_edit')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="button mr-1 success--text" @click="editStaff(item)" v-bind="attrs"
                                  v-on="on">
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("editMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('staff_archive')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="button mr-1 error--text" @click="deleteStaff(item)"
                                  :loading="loading && staffIndex == item.id" v-bind="attrs" v-on="on">
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("deleteMsg") }}</span>
                            </v-tooltip>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="hidden-md-and-up">
                <v-layout column>
                  <template v-for="(staff, index) in staffs">
                    <div :key="index">
                      <v-flex xs12 class="mb-2">
                        <v-card elevation="0">
                          <div class="pa-5">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <div class="title primary--text">
                                      <b>{{ staff.name }}</b>
                                    </div>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn depressed class="red--text" :loading="loading && staffIndex == staff.id" icon
                                      @click="deleteStaff(staff)" v-if="$can('staff_archive')">
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-divider class="mt-2"></v-divider>
                              <v-flex xs12 class="mt-1">
                                <v-layout column>
                                  <v-flex xs12 class="mt-3">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>UNEMIS ID</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ staff.id }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>{{ $t("designationMsg") }}</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        <div v-if="staff.staff_detail != null">
                                          {{ staff.staff_detail.designation.name }}
                                        </div>
                                        <div v-else>
                                          N/A
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Start Date</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        <div v-if="staff.staff_detail != null">
                                          {{ staff.staff_detail.started_on }}
                                        </div>
                                        <div v-else>
                                          N/A
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>End Date</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        <div v-if="staff.staff_detail != null">
                                          {{ staff.staff_detail.ended_on }}
                                        </div>
                                        <div v-else>
                                          N/A
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs12>
                                        <div>
                                          <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                              mt-3
                                            " block @click="showStaff(staff)" v-if="$can('staff_view')">{{
                                                $t("viewMsg")
                                            }}
                                            <v-icon right> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div>
                                          <v-btn depressed class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                              mt-1
                                            " block @click="editStaff(staff)" v-if="$can('staff_edit')">{{
                                                $t("editMsg")
                                            }}
                                            <v-icon right>
                                              mdi-border-color
                                            </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <v-layout row wrap>
              <v-flex xs12 md9>
                <div align="left">
                  <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                    v-model="staffPagination.current_page" @input="changePage()" circle>
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div v-if="length != 0" align="right" class="mr-3">
                  <b class="universal--text mr-1">{{ $t("totalMsg") }}: </b>{{ staffPagination.total | formatNumber }}
                </div>
              </v-flex>

            </v-layout>
          </v-flex>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-2">
            <v-card elevation="0" class="background">
              <v-card-title class="header">
                <v-spacer></v-spacer>
                <div v-if="newStaff.id == null">Add Staff</div>
                <div v-else>Edit Staff</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5">
                    <!-- <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("institutionMsg") }}*</div>
                        <v-select :items="formData.institutions" class="text_field background" item-value="id"
                          item-text="name" outlined v-model="newStaff.institution_id" dense :rules="inputRules">
                        </v-select>
                      </div>
                    </v-flex> -->
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("nameMsg") }}<span class="error--text">*</span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newStaff.name"
                          :rules="inputRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("emailAddressMsg") }}<span class="error--text">*</span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newStaff.email"
                          :disabled="lockEmail" :rules="emailRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4 v-if="newStaff.id == null">
                      <div class="pa-3">
                        <div>{{ $t("passwordMsg") }}*</div>
                        <v-text-field class="text_field background" outlined dense v-model="newStaff.password"
                          :rules="passwordRules" append-icon="mdi-autorenew" @click:append="generate()"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4 v-if="newStaff.id != null">
                      <div class="pa-3">
                        <div>{{ $t("passwordMsg") }}</div>
                        <v-text-field class="text_field background" outlined dense v-model="newStaff.password"
                          append-icon="mdi-autorenew" @click:append="generate()"></v-text-field>
                        <div>
                          <i>**{{
                              $t(
                                "leaveBlankToAvoidUnintentionallyUpdatingTheUsersPasswordMsg"
                              )
                          }}</i>
                        </div>
                      </div>
                    </v-flex>
                    <!-- <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("userMsg") }}*</div>
                        <v-select :items="formData.users" class="text_field background" item-value="id" item-text="name"
                          outlined v-model="newStaff.user_id" dense :rules="inputRules"></v-select>
                      </div>
                    </v-flex> -->
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("nationalityMsg") }}*</div>
                        <v-select :items="formData.nationalities" class="text_field background" item-value="id"
                          item-text="name" outlined v-model="newStaff.nationality_id" dense :rules="inputRules">
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("identificationMsg") }}<span class="error--text">*</span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newStaff.identification"
                          :rules="inputRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("phoneNumberMsg") }}<span class="error--text">*</span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newStaff.phone_number"
                          :rules="inputRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("dateOfBirthMsg") }}<span class="error--text">*</span></div>
                        <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                          v-model="dobDatePicker" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense :value="formatDobDate" slot="activator" readonly v-bind="attrs"
                              v-on="on" class="text_field background" :rules="inputRules">
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="newStaff.date_of_birth" :max="minLegalAge" @change="closeDobDate">
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("genderMsg") }}<span class="error--text">*</span></div>
                        <v-select :items="formData.genders" class="text_field background" item-value="id"
                          item-text="name" outlined v-model="newStaff.gender_id" dense :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("teacherTypeMsg") }}<span class="error--text">*</span></div>
                        <v-select :items="formData.teacherTypes" class="text_field background" item-value="id"
                          item-text="name" outlined v-model="newStaff.teacher_type_id" dense :rules="inputRules">
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("designationMsg") }}<span class="error--text">*</span></div>
                        <v-select :items="formData.designations" class="text_field background" item-value="id"
                          item-text="name" outlined v-model="newStaff.designation_id" dense :rules="inputRules">
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Highest Academic Qualification<span class="error--text">*</span></div>
                        <v-select :items="formData.academicQualifications" class="text_field background" item-value="id"
                          item-text="name" outlined v-model="newStaff.academic_qualification_id" dense
                          :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("pteNgoTrainingMsg") }}<span class="error--text">*</span></div>
                        <v-select :items="formData.pteNgoTrainings" class="text_field background" item-value="id"
                          item-text="name" outlined v-model="newStaff.pte_ngo_training_id" dense :rules="inputRules">
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("salaryMsg") }}<span class="error--text">*</span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newStaff.salary"
                          :rules="inputRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Start Date<span class="error--text">*</span></div>
                        <!-- <v-text-field class="text_field background" outlined dense type="date"
                          v-model="newStaff.started_on" :rules="inputRules"></v-text-field> -->
                        <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                          v-model="startDatePicker" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense :value="formatStartDate" slot="activator" readonly
                              v-bind="attrs" v-on="on" class="text_field background" :rules="inputRules">
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="newStaff.started_on" :max="dateToday" @change="closeStartDate">
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>End Date</div>
                        <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                          v-model="endDatePicker" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense :value="formatEndDate" slot="activator" readonly v-bind="attrs"
                              v-on="on" class="text_field background">
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="newStaff.ended_on" :max="dateToday" @change="closeEndDate">
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>
                    <v-flex xs12>

                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <v-checkbox class="text_field background" outlined dense v-model="newStaff.moe_p1_training"
                          :label="$t('moeP1TrainingMsg')"></v-checkbox>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <v-checkbox class="text_field background" outlined dense v-model="newStaff.ecde_training"
                          :label="$t('ecdeTrainingMsg')"></v-checkbox>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <v-checkbox class="text_field background" outlined dense v-model="newStaff.sne_training"
                          :label="$t('sneTrainingMsg')"></v-checkbox>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <v-checkbox class="text_field background" outlined dense v-model="newStaff.sec_sub_training"
                          :label="$t('secSubTrainingMsg')"></v-checkbox>
                      </div>
                    </v-flex>
                    <!-- <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Avatar<span class="error--text">*</span></div>
                        <v-file-input class="text_field background" outlined dense
                          accept="image/png, image/jpeg, image/bmp" v-model="files" prepend-icon="mdi-camera">
                        </v-file-input>
                      </div>
                    </v-flex> -->
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded depressed class="universal white--text text-none mt-3" @click="save" :disabled="!valid"
                  :loading="loading">
                  {{ $t("saveMsg") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card elevation="0" class="pa-3">
            <v-card-title class="header">
              <v-spacer></v-spacer>
              <div>View Staff </div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-tabs show-arrows v-model="tab">
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab @click="activeTab = 'personal details'">
                <div class="text-none">Personal Details</div>
              </v-tab>
              <v-tab @click="activeTab = 'classes'" v-if="$can('staff_assignment_view')">
                <div class="text-none">Classes</div>
              </v-tab>
            </v-tabs>
            <div v-if="activeTab == 'personal details'">
              <v-card-text>
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap>
                      <v-flex xs12 md2>
                        <div class="ma-5">
                        <v-img
                          v-if="assignedStaff.profile_pic == null"
                          aspect-ratio="0.9"
                          contain
                          src="/img/default_pic.png"
                        ></v-img>
                        <v-img
                          v-else
                          aspect-ratio="0.9"
                          contain
                          :src="path+'/profile_pics/'+assignedStaff.profile_pic"
                        ></v-img>
                          
                      </div>
                      </v-flex>
                      <v-flex xs12 md10>
                        <div class="pa-3">
                          <v-layout column>
                            <v-flex xs12>
                              <v-layout row wrap>
                                <v-flex xs12 md6>
                                  <v-layout column>
                                    <v-flex xs12 class="mt-6">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>Name: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>{{ assignedStaff.name }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6">
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>Email: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>{{ assignedStaff.email }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>Gender: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>
                                            {{ assignedStaff.staff_detail.gender.name }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>Date of Birth: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>
                                            {{ assignedStaff.staff_detail.date_of_birth }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 md6>
                                  <v-layout column>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>Nationality: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>
                                            {{ assignedStaff.staff_detail.nationality.name }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>Identification: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>
                                            {{ assignedStaff.staff_detail.identification }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>Phone Number: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>{{ assignedStaff.staff_detail.phone_number }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b class="grey--text text--lighten-3"> .</b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div class="grey--text text--lighten-3">. </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-9">
                              <v-layout row wrap>
                                <v-flex xs12 md6>
                                  <v-layout column>
                                    <v-flex xs12 class="mt-6">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <b>Professional Details </b>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs12 md6>
                                  <v-layout column>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>Teacher Type: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>
                                            {{ assignedStaff.staff_detail.teacher_type.name }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>Designation: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>{{ assignedStaff.staff_detail.designation.name }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>Academic Qualification: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>{{ assignedStaff.staff_detail.academic_qualification.name }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>PTE NGO Training: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>{{ assignedStaff.staff_detail.pte_ngo_training.name }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>Salary: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>{{ assignedStaff.staff_detail.salary }}</div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 md6 v-if="assignedStaff.staff_detail != null">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-6">
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>MOE P1 Training: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div v-if="assignedStaff.staff_detail.moe_p1_training == 1">
                                            Yes
                                          </div>
                                          <div v-else>
                                            No
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>ECDE Training: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div v-if="assignedStaff.staff_detail.ecde_training == 1">
                                            Yes
                                          </div>
                                          <div v-else>
                                            No
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6">
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>SNE Training: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div v-if="assignedStaff.staff_detail.sne_training == 1">
                                            Yes
                                          </div>
                                          <div v-else>
                                            No
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>SEC Sub Training: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div v-if="assignedStaff.staff_detail.sec_sub_training == 1">
                                            Yes
                                          </div>
                                          <div v-else>
                                            No
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6" v-if="assignedStaff.staff_detail != null">
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>Start Date: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>
                                            {{ assignedStaff.staff_detail.started_on }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6"
                                      v-if="assignedStaff.staff_detail != null && assignedStaff.staff_detail.ended_on != null">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>End Date: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>
                                            {{ assignedStaff.staff_detail.ended_on }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    
                  </v-flex>
                </v-layout>

              </v-card-text>
              
              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Professional Qualifications</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeStaffProfView('create')" v-if="staffProfView == 'default'">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn icon @click="changeStaffProfView('default')" v-if="staffProfView == 'create'">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="staffProfView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedStaff.staff_prof_qualifications.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                No Professional Qualifications
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout row wrap>
                        <template v-for="(staff_prof_qualification, index) in assignedStaff.staff_prof_qualifications">
                          <v-flex xs12 class="mb-13" :key="index">
                            <v-layout row wrap>
                              <v-flex xs1>
                                {{ index + 1 }}.
                              </v-flex>
                              <v-flex xs10>
                                <div class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md6>
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>Institution:</b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          {{ staff_prof_qualification.prof_institution.name }}
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md6>
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>Start Date:</b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          {{ staff_prof_qualification.date_opened | moment("Do MMMM YYYY") }}
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                  
                                </div>
                                <div class="mt-9">
                                  <v-layout row wrap>
                                    <v-flex xs12 md6>
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>Course:</b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          {{ staff_prof_qualification.name }}
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md6>
                                      <v-layout row wrap class="grey lighten-3">
                                        <v-flex xs12 md4>
                                          <b>Finish Date:</b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          {{ staff_prof_qualification.date_closed | moment("Do MMMM YYYY") }}
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                  
                                </div>
                              </v-flex>
                              <v-flex xs1>
                                <v-btn icon @click="deleteStaffProfQualification(staff_prof_qualification)"
                                  :loading="loading && staffIndex == staff_prof_qualification.id">
                                  <v-icon small class="red--text">mdi-delete</v-icon>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="staffProfView == 'create'">
                  <div class="pa-3 mt-5">
                    <v-layout column>
                      <v-flex xs12>
                        <div align="right">
                          <v-btn rounded depressed class="primary text-none mb-5" @click="addProfQualiField()">
                            Add Qualification
                            <v-icon right> mdi-plus </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-form v-model="valid" ref="professionalQualificationsForm" lazy-validation></v-form>
                      <template v-for="(professionalQualification, index) in professionalQualifications">
                        <div :key="index">
                          <v-flex xs12 class="mb-7">
                            <v-layout row wrap>
                              <v-flex xs11>
                                <v-layout row wrap>
                                  <v-flex xs12 md3>
                                    <div class="pa-3">
                                      <div>Institution<span class="error--text">*</span></div>
                                      <v-select :items="professionalInstitutions" class="text_field background"
                                        item-value="id" item-text="name" outlined
                                        v-model="professionalQualifications[index].professional_institution_id" dense :rules="inputRules">
                                      </v-select>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 md3>
                                    <div class="pa-3">
                                      <div>Name<span class="error--text">*</span></div>
                                      <v-text-field class="text_field background" outlined dense
                                        v-model="professionalQualifications[index].name" :rules="inputRules"></v-text-field>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 md3>
                                    <div class="pa-3">
                                      <div>Start Date</div>
                                      <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                                        v-model="professionalQualifications[index].start_date_picker" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field outlined dense :value="formatProfStartDate(index)" slot="activator" readonly
                                            v-bind="attrs" v-on="on" class="text_field background"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="professionalQualifications[index].date_started" :max="professionalQualifications[index].date_finished"
                                          @change="closeProfStartDate(index)"></v-date-picker>
                                      </v-menu>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 md3>
                                    <div class="pa-3">
                                      <div>Finish Date</div>
                                      <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                                          v-model="professionalQualifications[index].finish_date_picker" offset-y>
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field outlined dense :value="formatProfFinishDate(index)" slot="activator" readonly v-bind="attrs"
                                              v-on="on" class="text_field background"></v-text-field>
                                          </template>
                                          <v-date-picker v-model="professionalQualifications[index].date_finished" :min="professionalQualifications[index].date_started"
                                            :max="dateToday" @change="closeProfFinishDate(index)"></v-date-picker>
                                        </v-menu>
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs1>
                                <div class="pa-3">
                                  <v-btn icon class="red--text mt-3" @click="removeProfQualiField(index)">
                                    <v-icon> mdi-close-circle </v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>

                          </v-flex>
                        </div>
                      </template>
                      <v-flex xs12>
                        <div align="right" class="mt-5">
                          <v-btn rounded depressed class="universal white--text text-none mt-3"
                            @click="saveProfessionalQualification()" :disabled="!valid" :loading="loading">
                            {{ $t("saveMsg") }}
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      </v-form>
                    </v-layout>
                  </div>
                </div>
              </v-card-text>
            </div>
            <div v-if="activeTab == 'classes'">
              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Class Assignments</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeClassView('create')" v-if="
                  $can('staff_assignment_create') &&
                  classView == 'default'
                ">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-btn icon @click="changeClassView('default')" v-if="classView != 'default'">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="classView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedStaff.staff_assignments.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                No Class Assigned
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout column>
                        <v-flex xs12 class="mt-7">
                          <div class="ml-3">
                            <v-layout column>
                              <template v-for="(assignment, index) in assignedStaff.staff_assignments">
                                <div :key="index">
                                  <v-flex xs12 class="mb-15">
                                    <v-layout row wrap>
                                      <v-flex xs1>
                                        {{ index + 1 }}.
                                      </v-flex>
                                      <v-flex xs10>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <v-layout row wrap>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Institution: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ assignment.institution.name }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Level: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ assignment.level.name }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Stream: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ assignment.level_stream.name }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Class Teacher: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div v-if="assignment.is_class_teacher == 1">
                                                          Yes
                                                        </div>
                                                        <div v-if="assignment.is_class_teacher == 0">
                                                          No
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Start Date: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ assignment.level.start_date }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>End Date: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ assignment.level.end_date }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="white lighten-3">
                                                      <v-flex xs12 md4>
                                                        <b class="white--text text--lighten-3"> .</b>
                                                      </v-flex>
                                                      <v-flex xs12 md8>
                                                        <div class="white--text text--lighten-3">. </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md4>
                                                        <b class="grey--text text--lighten-3"> .</b>
                                                      </v-flex>
                                                      <v-flex xs12 md8>
                                                        <div class="grey--text text--lighten-3">. </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>


                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs1>
                                        <!-- <v-btn icon @click="editResource(resource)"
                                          v-if="$can('institution_resource_edit')">
                                          <v-icon small class="green--text">mdi-pencil</v-icon>
                                        </v-btn> -->
                                        <v-btn icon @click="deleteAssignment(assignment)"
                                          v-if="$can('staff_assignment_archive')"
                                          :loading="loading && staffIndex == assignment.id">
                                          <v-icon small class="red--text">mdi-delete</v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </div>
                              </template>
                            </v-layout>

                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="classView == 'create'" class="mt-3">
                  <v-form v-model="valid" ref="assignForm" lazy-validation>
                    <v-layout row wrap>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Institution Type*</div>
                          <v-select :items="formData.institutionTypes" class="text_field background" item-value="id"
                            item-text="name" outlined v-model="assignStaff.institution_type_id" dense :rules="inputRules"
                            @input="populateInstitutions()">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Institution*</div>
                          <v-select :items="institutions" class="text_field background" item-value="id"
                            item-text="name" outlined v-model="assignStaff.institution_id" dense :rules="inputRules"
                            @input="populateYears()">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Year*</div>
                          <v-select :items="years" class="text_field background" item-value="year" item-text="year"
                            outlined v-model="assignStaff.year" dense :rules="inputRules" @input="populateClasses()">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Level*</div>
                          <v-select :items="classes" class="text_field background" item-value="id" item-text="name"
                            outlined v-model="assignStaff.level_id" dense :rules="inputRules"
                            @input="populateStreams()">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Stream*</div>
                          <v-select :items="streams" class="text_field background" item-value="id" item-text="name"
                            outlined v-model="assignStaff.level_stream_id" dense :rules="inputRules">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs3>
                        <div class="pa-3 mt-5">
                          <v-checkbox class="text_field background" outlined dense
                            v-model="assignStaff.is_class_teacher" label="Class Teacher"></v-checkbox>
                        </div>
                      </v-flex>
                      <v-flex xs6>
                        <div align="right">
                          <v-btn rounded depressed class="universal white--text text-none mt-8" @click="saveAssign"
                            :disabled="!valid" :loading="loading">
                            {{ $t("saveMsg") }}
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </div>

                      </v-flex>


                    </v-layout>
                  </v-form>
                </div>
              </v-card-text>
            </div>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      view: "default",
      staffProfView: 'default',
      activeTab: 'personal details',
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      lockEmail: false,
      size: 10,
      characters: "a-z,A-Z,0-9,#",
      dobDatePicker: false,
      startDatePicker: false,
      endDatePicker: false,

      staffIndex: null,
      snackbar: false,
      inputRules: [
        (v) => !!v || "Input is required"
      ],
      emailRules: [
        (v) => !!v || "Input is required",
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
            v
          ) ||
          "E-mail must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Minimum 8 characters",
        (v) =>
          /(?=.*[A-Z])/.test(v) || "Must have at least one uppercase character",
        (v) => /(?=.*\d)/.test(v) || "Must have at least one number",
        (v) =>
          /([!@$%{}<>~%#|.])/.test(v) ||
          "Must have at least one special character [!@#$%.]",
      ],
      asset_url: process.env.VUE_APP_API_URL,
      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      filteredStaffs: false,
      files: {},
      genderDistribution: [23, 77],
      chartOptions: {
        chart: {
          width: 150,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          customLegendItems: ['male', 'female'],
        },
        labels: ['male', 'female'],
        colors: ['#F1571E', '#1BE27F'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 100
            },
            legend: {
              position: 'bottom',
              customLegendItems: ['male', 'female'],
            }
          }
        }]
      },
      newStaff: {
        id: null,
        name: null,
        email: null,
        password: null,
        nationality_id: null,
        identification: null,
        phone_number: null,
        date_of_birth: null,
        designation_id: null,
        gender_id: null,
        teacher_type_id: null,
        academic_qualification_id: null,
        pte_ngo_training_id: null,
        moe_p1_training: false,
        ecde_training: false,
        sne_training: false,
        sec_sub_training: false,
        salary: null,
        institution_id: null,
        user_id: null,
        started_on: null,
        ended_on: null,
        profile_pic: null

      },
      assignStaff: {
        user_id: null,
        institution_type_id: null,
        institution_id: null,
        year: null,
        level_id: null,
        level_stream_id: null,
        is_class_teacher: null,
        starting_on: null,
      },
      assignedStaff: null,
      formData: null,
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      tab: null,
      classTab: null,
      classView: 'default',
      activeAssignments: null,
      institutions: [],
      years: [],
      classes: [],
      professionalQualifications: [
        {
          start_date_picker: false,
          finish_date_picker: false,
          professional_institution_id: null,
          name: null,
          date_started: null,
          date_finished: null,
        }
      ],
      professionalQualification: {
        id: null,
        user_id: null,
        professionalQualifications: null
      },
      professionalInstitutions: [],
      profStartDatePicker: false,
      profFinishDatePicker: false,
      toDatePicker: false,
    };
  },
  created() {
    if (this.$can("staff_view")) {
      this.startStaffLoader();
      this.fetchStaffs(this.staffPagination.current_page);
      this.getFormData();
    }
  },
  methods: {
    ...mapActions([
      "fetchStaffs",
      "startStaffLoader",
      "stopStaffLoader",
      "filterStaffs",
    ]),
    populateInstitutions(){
      let x = this.formData.institutionTypes.find(
        (institutionType) => institutionType.id == this.assignStaff.institution_type_id
      );

      this.institutions = x.institutions;
    },
    populateYears() {
      let x = this.institutions.find(
        (institution) => institution.id == this.assignStaff.institution_id
      );

      var keys = [];
      var values = [];

      for (let value of Object.keys(x.levels)) {
        keys.push(value);
      }

      for (let value of Object.values(x.levels)) {
        values.push(value);
      }

      for (var i = 0; i < keys.length; i++) {
        var obj = {
          "year": keys[i],
          "data": values[i]
        }
        this.years.push(obj);
      }
      console.log("this.years", this.years)
      //this.years = result;
    },
    populateClasses() {
      let x = this.years.find(
        (year) => year.year == this.assignStaff.year
      );

      this.classes = x.data;
    },
    populateStreams() {
      let x = this.classes.find(
        (level) => level.id == this.assignStaff.level_id
      );

      this.streams = x.level_streams;
    },
    changeStaffProfView(view) {
      if (view == "default") {

      } else if (view == "create") {
        apiCall({
          url: "/api/professional-institution?type=formData",
          method: "GET",
        })
          .then((resp) => {
            this.professionalInstitutions = resp;
          })
          .catch((error) => {
            this.message = "anErrorOccurredCouldntGetFormDataMsg";
            this.color = "error";
          });
        
      } else if (view == "show") {

      }
      this.staffProfView = view;
    },
    assignAssignment(assignment) {
      this.activeAssignments = assignment
    },
    closeProfStartDate(index) {
      this.professionalQualifications[index].start_date_picker = false;
    },
    closeProfFinishDate(index) {
      this.professionalQualifications[index].finish_date_picker = false;
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredStaffs = true;
      this.startStaffLoader();
      this.fetchStaffs(1);
    },
    showStaff(staff) {
      this.assignedStaff = staff;
      this.changeView("show");
    },
    addProfQualiField(){
      let obj = {}
      obj['professional_institution_id'] = null
      obj['name'] = null
      obj['date_started'] = null
      obj['date_finished'] = null

      this.professionalQualifications.push(obj)
    },
    removeProfQualiField(index) {
      if (this.professionalQualifications.length != 0) {
        this.professionalQualifications.splice(index, 1);
      }

    },
    editStaff(staff) {
      console.log("staff", staff)
      this.newStaff.id = staff.id;
      this.newStaff.name = staff.name
      this.newStaff.email = staff.email
      this.newStaff.user_id = staff.id;

      if (staff.staff_detail != null) {
        this.newStaff.nationality_id = staff.staff_detail.nationality_id;
        this.newStaff.identification = staff.staff_detail.identification;
        this.newStaff.phone_number = staff.staff_detail.phone_number;
        this.newStaff.date_of_birth = staff.staff_detail.date_of_birth;
        this.newStaff.gender_id = staff.staff_detail.gender_id;
        this.newStaff.designation_id = staff.staff_detail.designation_id;
        this.newStaff.teacher_type_id = staff.staff_detail.teacher_type_id;
        this.newStaff.academic_qualification_id = staff.staff_detail.academic_qualification_id;
        this.newStaff.pte_ngo_training_id = staff.staff_detail.pte_ngo_training_id;
        this.newStaff.moe_p1_training = staff.staff_detail.moe_p1_training;
        this.newStaff.ecde_training = staff.staff_detail.ecde_training;
        this.newStaff.sne_training = staff.staff_detail.sne_training;
        this.newStaff.sec_sub_training = staff.staff_detail.sec_sub_training;
        this.newStaff.salary = staff.staff_detail.salary;
        this.newStaff.institution_id = staff.staff_detail.institution_id;
        this.newStaff.started_on = staff.staff_detail.started_on;
        this.newStaff.profile_pic = staff.staff_detail.profile_pic;
      }
      this.changeView("create");

    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.newStaff.id != null) {
          confirm('Are you sure you want to update staff details?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/staff/" + this.newStaff.id,
              data: this.newStaff,
              method: "PUT"
            })
              .then((resp) => {
                this.message = "Staff Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchStaffs(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm('Are you sure you want to create staff details?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/staff",
              data: this.newStaff,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Staff Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchStaffs(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      }
    },
    saveProfessionalQualification(){
      if (this.$refs.professionalQualificationsForm.validate()) {
        if (this.professionalQualification.id != null) {
          confirm('Are you sure you want to update Professional Qualification?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/staff-prof-qualification/" + this.professionalQualification.id,
              data: this.professionalQualification,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Professional Qualification Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeStaffProfView("default");
                this.fetchStaffs(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm('Are you sure you want to add Professional Qualification(s)?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.professionalQualification.user_id = this.assignedStaff.id
            this.professionalQualification.professionalQualifications = this.professionalQualifications
            apiCall({
              url: "/api/staff-prof-qualification",
              data: this.professionalQualification,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Professional Qualification(s) Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.assignedStaff.staff_prof_qualifications = resp;
                this.staffProfView = "default"
                this.professionalQualifications = []
                let obj = {}
                obj['professional_institution_id'] = null
                obj['name'] = null
                obj['date_started'] = null
                obj['date_finished'] = null

                this.professionalQualifications.push(obj)
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchStaffs(1);
              });
          }
        }
      }
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startStaffLoader();
        apiCall({
          url: "/api/staff?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterStaffs(resp);
            this.searchLoader = false;
            this.stopStaffLoader();
            this.filteredStaffs = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopStaffLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    saveAssign() {
      if (this.$refs.assignForm.validate()) {
        confirm('Are you sure you want to assign class?') && (this.confirm = true);
        if (this.confirm) {
          this.assignStaff.user_id = this.assignedStaff.id
          this.loading = true;
          apiCall({
            url: "/api/staff-assignment",
            data: this.assignStaff,
            method: "POST",
            multipart: true
          })
            .then((resp) => {
              this.message = "Assignment Successful";
              this.color = "orange";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.assignedStaff.staff_assignments = resp
              this.changeClassView("default");

              this.fetchStaffs(this.staffPagination.current_page);
            })
            .catch((error) => {
              this.message = "anErrorOccurredMsg";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
            });
        }

      }
    },
    changeView(view) {
      if (view == "default") {
        this.newStaff.id = null
        this.newStaff.name = null
        this.newStaff.email = null
        this.newStaff.password = null
        this.newStaff.nationality_id = null;
        this.newStaff.identification = null;
        this.newStaff.phone_number = null;
        this.newStaff.date_of_birth = null;
        this.newStaff.gender_id = null;
        this.newStaff.designation_id = null;
        this.newStaff.teacher_type_id = null;
        this.newStaff.academic_qualification_id = null;
        this.newStaff.pte_ngo_training_id = null;
        this.newStaff.moe_p1_training = false;
        this.newStaff.ecde_training = false;
        this.newStaff.sne_training = false;
        this.newStaff.sec_sub_training = false;
        this.newStaff.salary = null;
        this.newStaff.institution_id = null;
        this.newStaff.user_id = null;
        this.newStaff.started_on = null;
        this.newStaff.ended_on = null;
        this.newStaff.profile_pic = null;

      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {

      }
      this.view = view;
    },
    changeClassView(view) {
      if (view == "default") {
        this.assignStaff.id = null;
        this.assignStaff.institution_type_id = null;
        this.assignStaff.institution_id = null;
        this.assignStaff.year = null;
        this.assignStaff.level_id = null;
        this.assignStaff.level_stream_id = null;
        this.assignStaff.is_class_teacher = null;
        this.assignStaff.starting_on = null;

      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {

      }
      this.classView = view;
    },
    changePage() {
      this.startStaffLoader();
      if (this.filteredStaffs == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/staff?page=" +
            this.staffPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterStaffs(resp);
            this.stopStaffLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopStaffLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchStaffs(this.staffPagination.current_page);
      }
    },
    deleteStaff(item) {
      confirm("Are You Sure You Want to Delete Staff") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.staffIndex = item.id;
        apiCall({ url: "/api/user/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Staff Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.staffIndex = null;
            this.fetchStaffs(this.staffPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.staffIndex = null;
            console.log(error.response);
          });
      }
    },
    deleteAssignment(item) {
      confirm("Are You Sure You Want to Delete Assignment") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.staffIndex = item.id;
        apiCall({ url: "/api/staff-assignment/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Assignment Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.staffIndex = null;

            const index = this.assignedStaff.staff_assignments.indexOf(item);
            if (index > -1) {
              this.assignedStaff.staff_assignments.splice(index, 1);
            }
            this.fetchStaffs(this.staffPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.staffIndex = null;
            console.log(error.response);
          });
      }
    },
    deleteStaffProfQualification(item) {
      confirm("Are You Sure You Want to Delete Professional Qualification") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.staffIndex = item.id;
        apiCall({ url: "/api/staff-prof-qualification/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Professional Qualification Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.staffIndex = null;

            const index = this.assignedStaff.staff_prof_qualifications.indexOf(item);
            if (index > -1) {
              this.assignedStaff.staff_prof_qualifications.splice(index, 1);
            }
            this.fetchStaffs(this.staffPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.staffIndex = null;
            console.log(error.response);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/staff?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    generate() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.newStaff.password = password;
    },
    closeDobDate() {
      this.dobDatePicker = false;
    },
    closeStartDate() {
      this.startDatePicker = false;
    },
    closeEndDate() {
      this.endDatePicker = false;
    },
    formatProfStartDate(index) {
      if (this.professionalQualifications[index].date_started != null) {
        const d = new Date(this.professionalQualifications[index].date_started);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatProfFinishDate(index) {
      if (this.professionalQualifications[index].date_finished != null) {
        const d = new Date(this.professionalQualifications[index].date_finished);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
  },
  computed: {
    ...mapGetters([
      "staffs",
      "staffPagination",
      "staffLoader",
    ]),
    length: function () {
      return Math.ceil(
        this.staffPagination.total / this.staffPagination.per_page
      );
    },
    minLegalAge() {
      var today = new Date();

      var timestamp =
        (today.getFullYear() - 16) +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        (today.getFullYear()) +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
    formatDobDate() {
      if (this.newStaff.date_of_birth != null) {
        const d = new Date(this.newStaff.date_of_birth);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatStartDate() {
      if (this.newStaff.started_on != null) {
        const d = new Date(this.newStaff.started_on);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatEndDate() {
      if (this.newStaff.ended_on != null) {
        const d = new Date(this.newStaff.ended_on);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    
  },
};
</script>
