import Vue from 'vue'
import Vuex from 'vuex'

import language from './modules/language'

import role from './modules/role'
import users from './modules/users'
import permission from './modules/permission'
import user from './modules/user'
import auth from './modules/auth'

import status from './modules/status'

import abilities from './modules/abilities'
import loginCard from './modules/loginCard'
import darkMode from './modules/darkMode'

import location from './modules/location'
import institution from './modules/institution'
import staffInstitution from './modules/staffInstitution'
import level from './modules/level'
import levelStream from './modules/levelStream'
import staffLevelStream from './modules/staffLevelStream'
import staff from './modules/staff'
import schoolAdmin from './modules/schoolAdmin'
import student from './modules/students'
import calendar from './modules/calendars'
import attendance from './modules/attendance'

import academicQualification from './modules/academicQualification'
import absenceReason from './modules/absenceReason'
import attendancePeriod from './modules/attendancePeriod'
import country from './modules/country'
import county from './modules/county'
import designation from './modules/designation'
import gender from './modules/gender'
import institutionAccomodation from './modules/institutionAccomodation'
import institutionClassification from './modules/institutionClassification'
import studentIdentifier from './modules/studentIdentifier'
import institutionElectricityResource from './modules/institutionElectricityResource'
import institutionGender from './modules/institutionGender'

import institutionOwnership from './modules/institutionOwnership'
import institutionProvider from './modules/institutionProvider'
import institutionSector from './modules/institutionSector'
import institutionType from './modules/institutionType'
import institutionWaterResource from './modules/institutionWaterResource'
import locationLocality from './modules/locationLocality'
import professionalInstitution from './modules/professionalInstitution'
import pteNgoTraining from './modules/pteNgoTraining'
import schoolCategory from './modules/schoolCategory'
import subCounty from './modules/subCounty'
import teacherType from './modules/teacherType'
import termsOfService from './modules/termsOfService'
import ward from './modules/ward'

import VuexPersist from 'vuex-persist'
import { abilityPlugin, ability as appAbility } from './ability'

Vue.use(Vuex)

export const ability = appAbility

const vuexLocalStorage = new VuexPersist({
  key: 'unhcr_app', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: mutation => (true)
})

export default new Vuex.Store({
  plugins: [
    vuexLocalStorage.plugin,
    abilityPlugin
  ],

  modules: {
    language,

    status,

    role,
    users,
    user,
    auth,
    permission,
    abilities,
    loginCard,
    darkMode,

    location,
    institution,
    staffInstitution,
    level,
    levelStream,
    staffLevelStream,
    staff,
    schoolAdmin,
    student,
    calendar,
    attendance,

    academicQualification,
    absenceReason,
    attendancePeriod,
    country,
    county,
    designation,
    gender,
    institutionAccomodation,
    institutionClassification,
    institutionElectricityResource,
    institutionGender,
    institutionOwnership,
    institutionProvider,
    institutionSector,
    institutionType,
    institutionWaterResource,
    locationLocality,
    professionalInstitution,
    pteNgoTraining,
    schoolCategory,
    studentIdentifier,
    subCounty,
    teacherType,
    termsOfService,
    ward
  },
  strict: false,
})