<template>
  <div class="Profile">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ $t(message) }}</span>
    </v-snackbar>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="imageDialog"
    >
      <v-card max-width="600">
        <v-toolbar color="primary" dark flat>
          {{ $t("uploadProfilePictureMsg") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="imageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="imageForm" v-model="imageValid" lazy-validation>
          <v-layout column>
            <v-flex xs12>
              <v-file-input
                class="mx-2 mt-3"
                :loading="imageLoading"
                :rules="fileRules"
                filled
                dense
                show-size
                chips
                accept="image/*"
                v-model="imageFile"
                :label="$t('selectImageMsg')"
              ></v-file-input>
            </v-flex>
            <v-flex xs12>
              <div align="right" class="pa-3">
                <v-btn
                  depressed
                  block
                  class="secondary text-none mb-3"
                  @click="uploadImage"
                  :loading="imageLoading"
                  :disabled="!imageValid"
                >
                  {{ $t("submitMsg") }} <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="passwordDialog"
    >
      <v-card max-width="600">
        <v-toolbar flat class="primary white--text">
          {{ $t("changeAccountPasswordMsg") }}
          <v-spacer></v-spacer>
          <v-btn class="white--text" icon @click="passwordDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="passwordForm" v-model="valid" lazy-validation>
          <v-layout column>
              <v-flex xs12>
                <div class="mx-2 mt-5">
                  <div>{{ $t("currentPasswordMsg") }}*</div>
                  <v-text-field
                    outlined
                    dense
                    v-model="passwordData.current_password"
                    :rules="inputRules"
                    type="password"
                    class="text_field background"
                  ></v-text-field>
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="mx-2 mt-5">
                  <div>{{ $t("newPasswordMsg") }}*</div>
                  <v-text-field
                    outlined
                    dense
                    v-model="passwordData.new_password"
                    :rules="passwordRules"
                    type="password"
                    class="text_field background"
                  ></v-text-field>
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="mx-2 mt-5">
                  <div>{{ $t("confirmPasswordMsg") }}*</div>
                  <v-text-field
                    outlined
                    dense
                    v-model="confirmpassword"
                    :rules="inputRules"
                    type="password"
                    class="text_field background"
                  ></v-text-field>
                </div>
              </v-flex>
            <v-flex xs12>
              <div align="right" class="mx-2">
                <v-btn
                  depressed
                  class="secondary text-none mb-3 mt-5"
                  @click="savePassword"
                  :loading="loading"
                  :disabled="!valid"
                >
                  {{ $t("updateMsg") }} <v-icon right dark>mdi-upload</v-icon> 
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <v-card elevation="0" class="header pa-3">
            <div class="title">{{ $t("profileMsg") }}</div>
          </v-card>
        </v-flex>
        <div v-if="view == 'default'">
          <v-flex class="mt-5">
                <div>
                  <v-layout row wrap>
                    <v-flex xs12 md4 class="my-2">
                      <div class="ma-2">
                        <v-img
                          v-if="getProfile.profile_pic == null"
                          aspect-ratio="0.9"
                          contain
                          src="/img/default_pic.png"
                        ></v-img>
                        <v-img
                          v-else
                          aspect-ratio="0.9"
                          contain
                          :src="path+'/profile_pics/'+getProfile.profile_pic"
                        ></v-img>
                        <div align="center">
                          <v-btn
                            depressed  
                            class="secondary white--text text-none"
                            @click="imageDialog = true"
                            >
                            {{ $t("changeImageMsg") }}
                            <v-icon right>mdi-image-filter-center-focus-weak</v-icon>
                          </v-btn>
                        </div>
                          
                      </div>
                    </v-flex>
                    <v-flex xs12 md8>
                      <div class="ma-5">
                        <v-layout row wrap>
                            <v-flex xs12>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <div class="display-1 primary--text ml-2">{{getProfile.name}}</div>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs4>
                                  <div class="ml-3">
                                    <b>{{ $t("emailAddressMsg") }}:</b>
                                  </div>
                                </v-flex>
                                <v-flex xs8>
                                  <div>{{ getProfile.email }}</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs4>
                                  <div class="ml-3">
                                    <b>Creation Date:</b>
                                  </div>
                                </v-flex>
                                <v-flex xs8>
                                  <div>{{ getProfile.created_at | moment("MMMM Do, YYYY") }}</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs4>
                                  <div class="ml-3">
                                    <b>{{ $t("roleMsg") }}:</b>
                                  </div>
                                </v-flex>
                                <v-flex xs8>
                                  <div v-if="getProfile.roles.length!=0">
                                    {{getProfile.roles[0].name}}
                                  </div>
                                  <div v-else>
                                    {{ $t("noneMsg") }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap class="mb-5">
                                <v-flex xs4>
                                  <div class="ml-3">
                                    <b>{{ $t("accountStatusMsg") }}:</b>
                                  </div>
                                </v-flex>
                                <v-flex xs8>
                                  <div v-if="getProfile.active==1">
                                    {{ $t("activeMsg") }}
                                  </div>
                                  <div v-else>
                                    {{ $t("inactiveMsg") }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-2">
                                <v-divider class="mx-3"></v-divider>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                              <div align="right">
                                <v-btn dark depressed class="green text-none mt-2" @click="passwordDialog = true">
                                  {{ $t("changePasswordMsg") }}
                                  <v-icon right>mdi-lock</v-icon>  
                                </v-btn>
                              </div>
                            </v-flex>
                        </v-layout>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>           
            
          </v-flex>
        </div>
        <div v-if="view == 'create'">
          <v-flex xs12 class="mt-5">
            
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          
        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import { USER_REQUEST } from "@/store/actions/user";
export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      dashBreadcrumb: true,
      showBreadcrumb: false,

      view: "default",
      loading: false,
      valid: true,
      message: "",
      color: "",
      confirm: false,
      snackbar: false,
      inputRules: [
        v => !!v || 'Input is required'
      ],

      imageDialog: false,
      passwordDialog: false,
      imageLoading: false,
      fileRules: [(v) => !!v || this.$t("kindlySelectAFileMsg")],
      imageFile: null,
      imageValid: true,

      confirmpassword: '',
      passwordRules: [
          v => !!v || this.$t("passwordIsRequiredMsg"),
          v => (v && v.length >= 8) || this.$t("minimum8CharactersMsg"),
          v => /(?=.*[A-Z])/.test(v) || this.$t("mustHaveAtLeastOneUppercaseCharacterMsg"),
          v => /(?=.*\d)/.test(v) || this.$t("mustHaveAtLeastOneNumberMsg"),
          v => /([!@$%.])/.test(v) || this.$t("mustHaveAtLeastOneSpecialCharacterMsg")
      ],

      passwordData: {
        current_password: null,
        new_password: null
      }

    };
  },
  created() {
      
  },
  methods: {
    ...mapActions([
      
      
    ]),
    changeView(view) {
      if (view == "default") {
          
        this.dashBreadcrumb = true;
        this.showBreadcrumb = false;
      } else if (view == "create") {
        
      } else if (view == "show") {
        this.dashBreadcrumb = false;
        this.showBreadcrumb = true;
      }
      this.view = view;
    },
    savePassword(){
      
        if (this.$refs.passwordForm.validate()) {

          if(this.passwordData.new_password != this.confirmpassword){
              this.message = "newPasswordAndConfirmPasswordDoNotMatchMsg"
              this.color = 'error'
              this.snackbar = true;
          }else{
            confirm(this.$t("areYouSureYouWantToChangeYourAccountPasswordMsg")) &&
        (this.confirm = true);
      if (this.confirm) {
            this.loading = true;
            apiCall({
                url: "/api/user?type=password",
                data: this.passwordData,
                method: "POST",
              })
                .then((resp) => {
                  this.snackbar = true;
                  this.message = "passwordUpdatedSuccessfullyMsg";
                  this.color = "success";
                  this.confirm = false;
                  this.loading = false;
                  this.passwordDialog = false;
                })
                .catch((error) => { 
                  this.snackbar = true;
                  this.confirm = false;
                  this.message = "currentPasswordDoesNotMatchWithRecordsMsg";
                  this.color = "error";
                  this.loading = false;
                });
            }
          }
        }
      
    },
    uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);

        apiCall({
          url: "/api/user?type=image",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "profilePictureUploadedSuccessfullyMsg";
            this.color = "success";
            this.$store.dispatch(USER_REQUEST);
            this.imageLoading = false;
            this.imageDialog = false;
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "getProfile",
    ]),
    
  },
};
</script>