import apiCall from '@/utils/api'

const state = {
	institutionClassification: {},
	institutionClassifications: [],
    institutionClassificationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    institutionClassificationLoader: false
};

const getters = {
    institutionClassificationLoader: (state) => state.institutionClassificationLoader,
	institutionClassifications: (state) => state.institutionClassifications,
	institutionClassification: (state) => state.institutionClassification,
    institutionClassificationPagination: (state) => state.institutionClassificationPagination
}

const actions = {
    async fetchInstitutionClassifications({commit}, page) {
		const response = await apiCall({url: `/api/institution-classification?page=${page}`, method: 'GET' });
		commit('setInstitutionClassifications', response)
        commit('stopInstitutionClassificationLoader', response)
	},
    async fetchInstitutionClassification({commit}, itemId) {
		const response = await apiCall({url: `/api/institution-classification/${itemId}`, method: 'GET' });
		commit('setInstitutionClassification', response)
        commit('stopInstitutionClassificationLoader', response)
	},
    async filterInstitutionClassifications({commit, state},resp){
		commit('setInstitutionClassifications', resp)
	},
	async startInstitutionClassificationLoader({commit, state},resp){
		commit('startInstitutionClassificationLoader', resp)
	},
	async stopInstitutionClassificationLoader({commit, state},resp){
		commit('stopInstitutionClassificationLoader', resp)
	},
};

const mutations = {
	setInstitutionClassifications: (state, institutionClassifications) => {
		state.institutionClassifications = institutionClassifications.data
		state.institutionClassificationPagination.current_page = institutionClassifications.current_page
		state.institutionClassificationPagination.total = institutionClassifications.total
		state.institutionClassificationPagination.per_page = institutionClassifications.per_page
	},
	setInstitutionClassification: (state, institutionClassification) => {
		state.institutionClassification = institutionClassification
	},
    startInstitutionClassificationLoader: (state) => state.institutionClassificationLoader = true,
	stopInstitutionClassificationLoader: (state) => state.institutionClassificationLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
