<template>
    <div class="reports" v-if="$can('reports_view')">
        <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
            <span>{{ $t(message) }}</span>
        </v-snackbar>
        <v-container>
            <v-layout column>
                <v-flex xs12>
                    <div class="title">Attendance Reports</div>
                </v-flex>
                <div v-if="view == 'default'">
                    <v-flex xs12 class="mt-5 mb-2">
                        <v-card elevation="0" class="header">
                            <v-form v-model="valid" ref="reportForm" lazy-validation>
                                <v-layout column class="pa-5">
                                    <v-flex xs12 class="mt-1">
                                        <v-layout row wrap>
                                            <v-flex xs12>
                                                <div class="ml-1"><b>Location</b></div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap class="mt-2">
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>County</div>
                                                    <v-select :items="formData.counties" class="text_field background"
                                                        item-value="id" item-text="name" outlined dense
                                                        v-model="reportData.county_id" @input="populateSubCounties">
                                                    </v-select>
                                                    </v-select>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>Sub County</div>
                                                    <v-select :items="subcounties" class="text_field background"
                                                        item-value="id" item-text="name" outlined dense
                                                        v-model="reportData.subcounty_id" @input="populateWards">
                                                    </v-select>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>Ward</div>
                                                    <v-select :items="wards" class="text_field background" item-value="id"
                                                        item-text="name" outlined dense v-model="reportData.ward_id">
                                                    </v-select>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-9">
                                        <v-layout row wrap>
                                            <v-flex xs12>
                                                <div class="ml-1"><b>Filters</b></div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap class="mt-2">

                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>
                                                        Gender
                                                    </div>
                                                    <v-select :items="formData.gender" v-model="reportData.gender_id"
                                                        class="text_field background" item-value="id" item-text="name"
                                                        outlined dense></v-select>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>
                                                        Institution Type
                                                    </div>
                                                    <v-select :items="formData.institutionTypes"
                                                        v-model="reportData.institution_type_id"
                                                        class="text_field background" item-value="id" item-text="name"
                                                        outlined dense></v-select>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>
                                                        Nationality
                                                    </div>
                                                    <v-select :items="formData.country" v-model="reportData.country_id"
                                                        class="text_field background" item-value="id" item-text="name"
                                                        outlined dense></v-select>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>
                                                        Institution Sectors
                                                    </div>
                                                    <v-select :items="formData.institutionSectors"
                                                        v-model="reportData.institution_sector_id"
                                                        class="text_field background" item-value="id" item-text="name"
                                                        outlined dense></v-select>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>
                                                        Institution Accomodation
                                                    </div>
                                                    <v-select :items="formData.institutionAccomodations"
                                                        v-model="reportData.institution_accomodation_id"
                                                        class="text_field background" item-value="id" item-text="name"
                                                        outlined dense></v-select>
                                                </div>
                                            </v-flex>

                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>
                                                        Institution Gender
                                                    </div>
                                                    <v-select :items="formData.institutionGenders"
                                                        v-model="reportData.institution_gender_id"
                                                        class="text_field background" item-value="id" item-text="name"
                                                        outlined dense></v-select>
                                                </div>
                                            </v-flex>
                                        </v-layout>

                                    </v-flex>
                                    <v-flex xs12 class="mt-9">
                                        <v-layout row wrap>
                                            <v-flex xs12>
                                                <div class="ml-1"><b>Age</b></div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap class="mt-2">
                                            <v-flex xs12>
                                                <div class="pa-1">
                                                    <!-- <div>County</div> -->
                                                    <v-range-slider v-model="reportData.age_range" :max="max" :min="min"
                                                        hide-details class="align-center">
                                                        <template v-slot:prepend>
                                                            <v-text-field :value="reportData.age_range[0]"
                                                                class="text_field background" outlined dense type="number"
                                                                style="width: 60px" @change="$set(range, 0, $event)">
                                                            </v-text-field>
                                                        </template>
                                                        <template v-slot:append>
                                                            <v-text-field :value="reportData.age_range[1]"
                                                                class="text_field background" outlined dense type="number"
                                                                style="width: 60px" @change="$set(range, 1, $event)">
                                                            </v-text-field>
                                                        </template>
                                                    </v-range-slider>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-9">
                                        <v-layout row wrap>
                                            <v-flex xs12>
                                                <div class="ml-1"><b>Status</b></div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap class="mt-2">
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>
                                                        Status
                                                    </div>
                                                    <v-select :items="attendanceStatus"
                                                        v-model="reportData.status"
                                                        class="text_field background" item-value="id" item-text="name"
                                                        outlined dense @input="updateAbsenceReason()"></v-select>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 md4 v-if="reportData.status==0">
                                                <div class="pa-1">
                                                    <div>
                                                        Absence Reason
                                                    </div>
                                                    <v-select :items="formData.absenceReasons"
                                                        v-model="reportData.absence_reason_id"
                                                        class="text_field background" item-value="id" item-text="name"
                                                        outlined dense></v-select>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-9">
                                        <v-layout row wrap>
                                            <v-flex xs12>
                                                <div class="ml-1"><b>Time Period</b></div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap class="mt-2">
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>
                                                        Attendance Period
                                                    </div>
                                                    <v-select :items="formData.attendancePeriods" :rules="inputRules"
                                                        v-model="reportData.attendance_period_id"
                                                        class="text_field background" item-value="id" item-text="name"
                                                        outlined dense></v-select>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>Start</div>
                                                    <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                                                        v-model="fromDatePicker" offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field outlined dense :value="formatFromDate" slot="activator" readonly v-bind="attrs"
                                                            v-on="on" class="text_field background" :rules="inputRules"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="reportData.start_date"
                                                            :max="reportData.end_date" @change="closeFromDate"></v-date-picker>
                                                        </v-menu>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <div>End</div>
                                                    <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                                                        v-model="toDatePicker" offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field outlined dense :value="formatToDate" slot="activator" readonly v-bind="attrs"
                                                            v-on="on" class="text_field background" :rules="inputRules"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="reportData.end_date" :min="reportData.start_date"
                                                            :max="dateToday" @change="closeToDate"></v-date-picker>
                                                        </v-menu>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-9">
                                        <v-layout row wrap>
                                            <v-flex xs12>
                                                <div class="ml-1"><b>Special Needs</b></div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap class="mt-2">
                                            <v-flex xs12 md4>
                                                <div class="pa-1">
                                                    <v-checkbox class="text_field" outlined dense
                                                        v-model="reportData.disability" label="Have Special Needs">
                                                    </v-checkbox>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap v-if="reportData.disability == 1">
                                                <template v-for="(item, index) in formData.disabilities">
                                                    <v-flex xs12 md4 :key="index">
                                                        <v-checkbox v-model="disabilities" :value="item.id"
                                                            :label="item.name" class="mx-1 text_field"></v-checkbox>
                                                    </v-flex>
                                                </template>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-8">
                                        <div align="right">
                                            <v-btn rounded depressed class="white--text universal text-none"
                                                :loading="loading" :disabled="!valid" @click="generate()">
                                                Show Data
                                                <v-icon right>mdi-cogs</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-form>
                        </v-card>
                    </v-flex>
                    <v-flex class="my-5">
                        <v-layout row wrap>
                            <v-flex xs6>
                                <!-- <v-btn rounded depressed class="white--text pdf text-none ml-3" :loading="pdfLoading" @click="pdfDownload()">
                                    Download PDF
                                    <v-icon right>mdi-microsoft-excel</v-icon>
                                </v-btn> -->
                            </v-flex>
                            <v-flex xs6>
                                <div align="right">
                                    <v-btn rounded depressed class="white--text excel text-none mr-3"
                                        :loading="csvLoading" @click="csvDownload()">
                                        Download CSV
                                        <v-icon right>mdi-microsoft-excel</v-icon>
                                    </v-btn>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="mt-5">
                        <div v-if="reports.length == 0">
                            <v-card elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md1>
                                        <v-container fill-height fluid>
                                            <v-row align="center" justify="center">
                                                <v-col class="text-center">
                                                    <v-icon large class="primary--text">
                                                        mdi-alert-circle-outline
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-flex>
                                    <v-flex xs12 md11>
                                        <v-container fill-height fluid>
                                            <v-row align="center" justify="center">
                                                <v-col class="text-center">
                                                    No Attendance Records
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </div>
                        <div v-else>
                            <div class="hidden-sm-and-down">
                                <v-progress-linear v-if="loading" height="1" indeterminate color="primary">
                                </v-progress-linear>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead class="header">
                                            <tr>
                                                <th class="text-left">Date</th>
                                                <th class="text-left">Total Students</th>
                                                <th class="text-left"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in reports" :key="item.id">
                                                <td>{{index | moment("DD/MM/YYYY") }}</td>
                                                <td>{{ item.total_students }}
                                                </td>
                                                <td>
                                                    <div align="right">
                                                        <v-btn rounded depressed class="white--text excel text-none mr-3"
                                                            :loading="studentLoading && studentIndex == index" @click="downloadStudents(index)">
                                                            Download
                                                            <v-icon right>mdi-microsoft-excel</v-icon>
                                                        </v-btn>
                                                    </div>
                                                    
                                                </td>
                                                
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                            </div>
                            <div class="hidden-md-and-up">
                                <v-layout column>
                                    <template v-for="(report, index) in reports">
                                        <div :key="index">
                                            <v-flex xs12 class="mb-2">
                                                <v-card elevation="0">
                                                    <div class="pa-5">
                                                        <v-layout column>
                                                            <v-flex xs12>
                                                                <div class="title primary--text">
                                                                    <b>{{ index | moment("DD/MM/YYYY") }}</b>
                                                                </div>
                                                            </v-flex>
                                                            <v-divider class="mt-2"></v-divider>
                                                            <v-flex xs12 class="mt-1">
                                                                <v-layout column>
                                                                    <v-flex xs12 class="mt-3">
                                                                        <v-layout row wrap>
                                                                            <v-flex xs8>
                                                                                <b>Total Students</b>
                                                                            </v-flex>
                                                                            <v-flex xs4>
                                                                                {{ report.total_students }}
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                        </v-layout>
                                                    </div>
                                                </v-card>
                                            </v-flex>
                                        </div>
                                    </template>
                                </v-layout>
                            </div>
                        </div>
                    </v-flex>
                    <!-- <v-flex xs12 class="mt-10">
                        <v-layout row wrap>
                            <v-flex xs12 md9>
                                <div align="left">
                                    <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                                        v-model="reportPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12 md3>
                                <div v-if="length != 0" align="right" class="mr-3">
                                    <b class="universal--text ml-4">{{ $t("totalMsg") }}: </b>{{ reportPagination.total
                                            |
                                            formatNumber
                                    }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-flex> -->
                </div>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import Vue from 'vue'
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
    components: {

    },
    data() {
        return {
            view: "default",
            loading: false,
            pdfLoading: false,
            csvLoading: false,
            studentLoading: false,
            studentIndex: null,
            valid: true,
            message: "",
            color: "",
            delete: false,
            confirm: false,
            snackbar: false,

            inputRules: [(v) => !!v || "Input is required"],

            fromDatePicker: false,
            toDatePicker: false,

            min: 0,
            max: 30,

            formData: null,
            reports: [],
            reportPagination: {
                search: ' ',
                current_page: 1,
                per_page: 0,
                total: 0,
                visible: 10
            },

            subcounties: [],
            wards: [],

            reportData: {
                id: null,
                county_id: null,
                subcounty_id: null,
                ward_id: null,
                gender_id: null,
                institution_type_id: null,
                country_id: null,
                institution_sector_id: null,
                institution_accomodation_id: null,
                institution_gender_id: null,
                min_age: null,
                max_age: null,
                age_range: [4, 17],
                year: null,
                status: 1,
                attendance_period_id: null,
                absence_reason_id: null,
                start_date: null,
                end_date: null,
                disability: 0,
                disabilities: null,
                date: null
            },
            attendanceStatus: [
                {id: 0, name: 'Absent'},
                {id: 1, name: 'Present'}
            ],
            disabilities: [],
            monthNames: [
                { name: "January" },
                { name: "February" },
                { name: "March" },
                { name: "April" },
                { name: "May" },
                { name: "June" },
                { name: "July" },
                { name: "August" },
                { name: "September" },
                { name: "October" },
                { name: "November" },
                { name: "December" },
            ],
            months: [
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
            ],
        };
    },
    watch: {
        toDatePicker(val) {
            val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
        }
    },
    created() {
        if (this.$can("reports_view")) {
            this.getFormData();
            //this.reportData.year = this.currentYear
        }
    },
    methods: {
        ...mapActions([

        ]),
        updateAbsenceReason(){
            this.reportData.absence_reason_id = null
        },
        activateImportButton() {
            this.importButton = false
        },
        populateSubCounties() {
            this.reportData.subcounty_id = null;
            this.reportData.ward_id = null;
            let x = this.formData.counties.find((county) => county.id == this.reportData.county_id);
            this.subcounties = x.subcounties;
        },
        populateWards() {
            this.reportData.ward_id = null;
            let x = this.subcounties.find((subcounty) => subcounty.id == this.reportData.subcounty_id);
            this.wards = x.wards;
        },
        resetSearch() {
            this.searchTerm = null;
            this.filteredInstitutions = true;
            this.startInstitutionLoader();
            this.fetchInstitutions(1);
        },

        generate() {
            if (this.$refs.reportForm.validate()) {
                this.loading = true;
                this.reportData.disabilities = this.disabilities
                apiCall({
                    url: "/api/report?type=attendanceReport",
                    data: this.reportData,
                    method: "POST",
                })
                    .then((resp) => {
                        this.reports = resp

                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error.response);
                        this.loading = false;
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                    });
            }
        },
        downloadStudents(date){
            this.studentLoading = true;
            this.studentIndex = date
            this.reportData.disabilities = this.disabilities
            this.reportData.date = date

            apiCall({
                url: "/api/report?type=csvStudentAttendanceReport",
                data: this.reportData,
                method: "POST",
            })
                .then((resp) => {
                    this.studentLoading = false;
                    this.studentIndex = null
                    this.downloadFile(resp);
                })
                .catch((error) => {
                    this.studentLoading = false;
                    this.studentIndex = null
                    this.message = "anErrorOccurredMsg";
                    this.color = "error";
                    this.snackbar = true;
                });
        },
        csvDownload() {
            this.csvLoading = true;
            this.reportData.disabilities = this.disabilities
            apiCall({
                url: "/api/report?type=csvAttendanceReport",
                data: this.reportData,
                method: "POST",
            })
                .then((resp) => {
                    this.csvLoading = false;
                    this.downloadFile(resp);
                })
                .catch((error) => {
                    console.log(error.response);
                    this.csvLoading = false;
                    this.message = "anErrorOccurredMsg";
                    this.color = "error";
                    this.snackbar = true;
                });
        },
        downloadFile(response, filename) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([response], { type: "application/csv" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = 'Attendance per Date '  + this.dateToday +  ".csv";
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        },
        changePage() {
            this.loading = true;
            apiCall({
                url: "/api/report?type=studentReport&page=" + this.reportPagination.current_page,
                data: this.reportData,
                method: "POST",
            })
                .then((resp) => {
                    this.reports = resp.data
                    this.reportPagination.current_page = resp.current_page
                    this.reportPagination.total = resp.total
                    this.reportPagination.per_page = resp.per_page

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error.response);
                    this.loading = false;
                    this.message = "anErrorOccurredMsg";
                    this.color = "error";
                    this.snackbar = true;
                });
        },
        getFormData() {
            apiCall({
                url: "/api/report?type=attendanceFormData",
                method: "GET",
            })
                .then((resp) => {
                    this.formData = resp;
                })
                .catch((error) => {
                    this.message = "anErrorOccurredCouldntGetFormDataMsg";
                    this.color = "error";
                });
        },

        closeFromDate() {
            this.fromDatePicker = false;
        },
        closeToDate() {
            this.toDatePicker = false;
        },
        closeYearDate(date) {
            this.$refs.yearMenu.save(date);
            this.$refs.picker.activePicker = 'YEAR'
            this.toDatePicker = false;
        },

    },
    computed: {
        ...mapGetters([

        ]),
        length: function () {
            return Math.ceil(
                this.reportPagination.total / this.reportPagination.per_page
            );
        },
        currentYear() {
            var today = new Date();

            var timestamp = today.getFullYear()

            return timestamp;
        },
        dateToday() {
            var today = new Date();

            var timestamp =
                today.getFullYear() +
                "-" +
                this.months[today.getMonth()] +
                "-" +
                ("0" + today.getDate()).slice(-2);
            return timestamp;
        },
        formatFromDate() {
            if (this.reportData.start_date != null) {
                const d = new Date(this.reportData.start_date);
                const year = d.getFullYear();
                const date = d.getDate();
                const month = d.getMonth();

                return date + " " + this.monthNames[month].name + " " + year;
            }
        },
        formatToDate() {
            if (this.reportData.end_date != null) {
                const d = new Date(this.reportData.end_date);
                const year = d.getFullYear();
                const date = d.getDate();
                const month = d.getMonth();

                return date + " " + this.monthNames[month].name + " " + year;
            }
        },
        formatYear() {
            if (this.newResource.year != null) {
                const d = new Date(this.newResource.year);
                const year = d.getFullYear();

                this.newResource.year = year

                return year;
            }
        },
    },
};
</script>
