import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        //dark: true,
        themes: {
            light: {
                primary: '#1e85f1',
                secondary: '#0f1e3d',
                loginCard: '#0f1e3d',
                accent: '#D21F26',
                error: '#b71c1c',
                background: "#FFFFFF",
                header: "#f2f8fe",
                button: "EEEEEE",
                universal: '#1e85f1',
                nav: '#1e85f1',
                excel: '#1D6F42',
                pdf: "#f40f02"
            },
            dark: {
                primary: '#1e85f1',
                secondary: '#1e85f1',
                loginCard: '#0f1e3d',
                accent: '#D21F26',
                error: '#b71c1c',
                background: "#121212",
                header: "#272727",
                button: "292929",
                universal: '#1e85f1',
                nav: '#0f1e3d',
                excel: '#1D6F42',
                pdf: "#f40f02"
            },
        },
    },
});
