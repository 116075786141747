import apiCall from '@/utils/api'

const state = {
	institutionWaterResource: {},
	institutionWaterResources: [],
    institutionWaterResourcePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    institutionWaterResourceLoader: false
};

const getters = {
    institutionWaterResourceLoader: (state) => state.institutionWaterResourceLoader,
	institutionWaterResources: (state) => state.institutionWaterResources,
	institutionWaterResource: (state) => state.institutionWaterResource,
    institutionWaterResourcePagination: (state) => state.institutionWaterResourcePagination
}

const actions = {
    async fetchInstitutionWaterResources({commit}, page) {
		const response = await apiCall({url: `/api/institution-water-resource?page=${page}`, method: 'GET' });
		commit('setInstitutionWaterResources', response)
        commit('stopInstitutionWaterResourceLoader', response)
	},
    async fetchInstitutionWaterResource({commit}, itemId) {
		const response = await apiCall({url: `/api/institution-water-resource/${itemId}`, method: 'GET' });
		commit('setInstitutionWaterResource', response)
        commit('stopInstitutionWaterResourceLoader', response)
	},
    async filterInstitutionWaterResources({commit, state},resp){
		commit('setInstitutionWaterResources', resp)
	},
	async startInstitutionWaterResourceLoader({commit, state},resp){
		commit('startInstitutionWaterResourceLoader', resp)
	},
	async stopInstitutionWaterResourceLoader({commit, state},resp){
		commit('stopInstitutionWaterResourceLoader', resp)
	},
};

const mutations = {
	setInstitutionWaterResources: (state, institutionWaterResources) => {
		state.institutionWaterResources = institutionWaterResources.data
		state.institutionWaterResourcePagination.current_page = institutionWaterResources.current_page
		state.institutionWaterResourcePagination.total = institutionWaterResources.total
		state.institutionWaterResourcePagination.per_page = institutionWaterResources.per_page
	},
	setInstitutionWaterResource: (state, institutionWaterResource) => {
		state.institutionWaterResource = institutionWaterResource
	},
    startInstitutionWaterResourceLoader: (state) => state.institutionWaterResourceLoader = true,
	stopInstitutionWaterResourceLoader: (state) => state.institutionWaterResourceLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
