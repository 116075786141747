<template>
  <div class="attendance" v-if="$can('teacher_attendance_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ $t(message) }}</span>
    </v-snackbar>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <div class="title">Attendance</div>
        </v-flex>
        <div v-if="view == 'default'">
          <v-flex xs12 class="mt-5 mb-2">
            <v-card elevation="0" class="header">
              <v-layout row wrap>
                <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                <v-flex xs12 md4>
                  <v-text-field outlined rounded dense :label="$t('searchMsg')" append-icon="mdi-undo-variant"
                    @click:append="resetSearch()" v-on:keyup.enter="search()" v-model="searchTerm"
                    @input="enableSearch()" class="background search_field my-3 mx-5"></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <div align="center" class="my-4">
                    <v-btn rounded depressed class="wite--text primary text-none" @click="changeView('create')"
                      v-if="$can('teacher_attendance_create')">
                      Mark Attendance
                      <v-icon right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <div v-if="attendance_list.length == 0">
              <v-card elevation="0">
                <v-layout row wrap>
                  <v-flex xs12 md1>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          <v-icon large class="primary--text">
                            mdi-alert-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md11>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          No Record Found
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-card>
            </div>
            <div v-else>
              <v-progress-linear v-if="attendanceLoader" height="1" indeterminate color="primary"></v-progress-linear>
              <div class="hidden-sm-and-down">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="header">
                      <tr>
                        <th class="text-left">Date </th>
                        <th class="text-left">Class </th>
                        <th class="text-left">Class Stream </th>
                        <th class="text-left">Period </th>
                        <th class="text-left">No of Students</th>

                        <th class="text-right">{{ $t("actionsMsg") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in attendance_list" :key="item.id">
                        <td>{{ item.attendance_date | moment("Do MMM YYYY") }}</td>
                        <td>{{ item.level_stream.level.name }}</td>
                        <td>{{ item.level_stream.name }}</td>
                        <td>{{ item.attendance_period.name }}</td>
                        <td>{{ item.attendance_students.length }}</td>

                        <td>
                          <div align="right">
                            <v-tooltip top v-if="$can('teacher_attendance_view')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="showAttendance(item)" class="button mr-1 primary--text"
                                  v-bind="attrs" v-on="on">
                                  <v-icon small> mdi-eye </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("viewMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('teacher_attendance_edit')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="button mr-1 success--text" @click="editAttendance(item)"
                                  v-bind="attrs" v-on="on">
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("editMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('teacher_attendance_archive')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="button mr-1 error--text" @click="deleteAttendance(item)"
                                  :loading="loading && attendanceIndex == item.id" v-bind="attrs" v-on="on">
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("deleteMsg") }}</span>
                            </v-tooltip>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="hidden-md-and-up">
                <v-layout column>
                  <template v-for="(attendance, index) in attendance_list">
                    <div :key="index">
                      <v-flex xs12 class="mb-2">
                        <v-card elevation="0">
                          <div class="pa-5">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <div class="title primary--text">
                                      <b>{{ attendance.name }}</b>
                                    </div>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn depressed class="red--text"
                                      :loading="loading && attendanceIndex == attendance.id" icon
                                      @click="deleteAttendance(attendance)" v-if="$can('teacher_attendance_archive')">
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-divider class="mt-2"></v-divider>
                              <v-flex xs12 class="mt-1">
                                <v-layout column>
                                  <v-flex xs12 class="mt-1">
                                    <div>
                                      <b>{{ $t("dateAddedMsg") }}</b>
                                    </div>
                                    <div>
                                      {{
                                          attendance.created_at
                                          | moment("DD/MM/YYYY - hh:m a")
                                      }}
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="pa-1">
                                          <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                            " block @click="showAttendance(attendance)"
                                            v-if="$can('teacher_attendance_view')">{{ $t("viewMsg") }}
                                            <v-icon right> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div class="pa-1">
                                          <v-btn depressed class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                            " block @click="editAttendance(attendance)"
                                            v-if="$can('teacher_attendance_edit')">{{ $t("editMsg") }}
                                            <v-icon right>
                                              mdi-border-color
                                            </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <v-layout row wrap>
              <v-flex xs12 md9>
                <div align="left">
                  <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                    v-model="attendancePagination.current_page" @input="changePage()" circle>
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div v-if="length != 0" align="right" class="mr-3">
                  <b class="primary--text ml-4">{{ $t("totalMsg") }}: </b>{{ attendancePagination.total | formatNumber
                  }}
                </div>
              </v-flex>

            </v-layout>
          </v-flex>
        </div>
        <!--Add or Edit -->
        <div v-if="view == 'create'">
          <v-flex class="mt-2">
            <v-card elevation="0">
              <v-card-title class="header">
                <v-spacer></v-spacer>
                <div v-if="newAttendance.id == null">Mark Attendance</div>
                <div v-else>Edit Attendance</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5" v-if="newAttendance.id==null">
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>Institution<span class="error--text">*</span></div>
                        <v-select :items="formData.institutions" class="text_field background" item-value="id"
                          item-text="name" outlined v-model="newAttendance.school_id" dense :rules="inputRules"
                          @input="populateClasses"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>Class/Grade<span class="error--text">*</span></div>
                        <v-select :items="classes" class="text_field background" item-value="id" item-text="name"
                          outlined v-model="newAttendance.level_id" dense :rules="inputRules"
                          @input="populateClassStreams"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <div>Stream<span class="error--text">*</span></div>
                        <v-select :items="classStreams" class="text_field background" item-value="id" item-text="name"
                          outlined v-model="newAttendance.level_stream_id" dense :rules="inputRules"
                          @input="activateFetchButton()"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="pa-3">
                        <v-btn rounded depressed class="wite--text primary text-none mt-6"
                          @click="fetchStudents(newAttendance.level_stream_id)" :loading="getStudentsLoader"
                          :disabled="getStudentsDisabled">
                          Get Students
                          <v-icon right>mdi-plus-circle-outline</v-icon>
                        </v-btn>

                      </div>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap class="mt-2" v-if="studentList.length != 0">
                    <v-flex xs12 md3 v-if="newAttendance.id == null">
                      <div class="pa-3">
                        <div>Term/Semester<span class="error--text">*</span></div>
                        <v-select :items="calendars" class="text_field background" item-value="id" item-text="name"
                          outlined v-model="newAttendance.calendar_id" dense :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3 v-if="newAttendance.id == null">
                      <div class="pa-3">
                        <div>Attendance Date<span class="error--text">*</span></div>
                        <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                          v-model="attendanceDate" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense :value="newAttendance.attendance_date" slot="activator"
                              readonly v-bind="attrs" v-on="on" class="text_field background"></v-text-field>
                          </template>
                          <v-date-picker v-model="newAttendance.attendance_date" :max="nowDate"
                            @change="closeAttendanceDate"></v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3 v-if="newAttendance.id == null">
                      <div class="pa-3">
                        <div>Attendance Period<span class="error--text">*</span></div>
                        <v-select :items="formData.attendance_periods" class="text_field background" item-value="id" item-text="name"
                          outlined v-model="newAttendance.attendance_period_id" dense :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                  </v-layout>

                  <div class="mt-2" v-if="studentList.length != 0">

                    <div class="pa-3">
                      <div>Students<span class="error--text"></span></div>
                      <v-layout column>
                        <template v-for="(item, index) in studentList">
                          <v-flex xs12 :key="index" class="mb-8">
                            <v-layout row wrap>
                              <v-flex xs12 md3>
                                <div class="subtitle-1 mt-5"><b>{{ index + 1 }}. {{ item.first_name + ' ' + item.last_name }}</b></div>
                              </v-flex>
                              <v-flex xs12 md9>
                                <v-layout column>
                                  <v-flex xs12 class="mt-4">
                                    <v-layout row wrap>
                                      <v-flex xs12 md2>
                                        <div class="mt-5">Status</div>
                                      </v-flex>
                                      <v-flex xs12 md10>
                                        <v-select :items="attendanceStatus" class="text_field background mt-3" item-value="id"
                                          item-text="name" outlined dense v-model="newAttendance.checkedStudents[item.id]" @input="changeReasonField(item.id)"></v-select>
                                      </v-flex>
                                    </v-layout>
                                    
                                  </v-flex>
                                  <v-flex xs12 v-if="absenceReasons[item.id]" class="mt-4">
                                    <v-layout row wrap>
                                        <v-flex xs12 md2>
                                          <div class="mt-5">Reason (Optional)</div>
                                        </v-flex>
                                        <v-flex xs12 md10>
                                          <v-select :items="formData.absence_reasons" class="text_field background mt-3" item-value="id"
                                            item-text="name" outlined dense v-model="newAttendance.attendanceReason[item.id]"></v-select>
                                        </v-flex>
                                      </v-layout>
                                    
                                  </v-flex>
                                  <v-flex xs12 v-if="absenceReasons[item.id]" class="mt-4 mb-12">
                                    <v-layout row wrap>
                                        <v-flex xs12 md2>
                                          <div class="mt-5">Comments (Optional)</div>
                                        </v-flex>
                                        <v-flex xs12 md10>
                                          <v-textarea class="text_field background mt-3 mb-15" outlined dense v-model="newAttendance.attendanceComment[item.id]"></v-textarea>
                                        </v-flex>
                                      </v-layout>
                                    
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>


                          </v-flex>

                        </template>
                      </v-layout>

                    </div>
                  </div>
                </v-form>
              </v-card-text>
              <v-card-actions v-if="studentList.length != 0">
                <v-spacer></v-spacer>
                <v-btn rounded depressed class="primary text-none mt-3" @click="save" :disabled="!valid"
                  :loading="loading">
                  {{ $t("saveMsg") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <!-- View -->
        <div v-if="view == 'show'">

          <v-card elevation="0">
            <v-card-title class="header">
              <v-spacer></v-spacer>
              <div>Attendance Details</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="pa-3 mt-5">
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Class: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>{{ assignedAttendance.level_stream.level.name }}</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Class Stream: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>{{ assignedAttendance.level_stream.name }}</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Period: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>{{ assignedAttendance.attendance_period.name }}</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Date: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>{{ assignedAttendance.attendance_date }}</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Term/Semester: </b>
                              </v-flex>
                              <v-flex xs12 md8>
                                <div>{{ assignedAttendance.calendar.name }}</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 class="mt-10">
                    <div class="mb-3"><b>Class List</b></div>
                      <v-layout column>
                        <template v-for="(attendance, index) in assignedAttendance.attendance_students">
                        <div :key=""index>
                          <v-flex xs12 class="mb-5">
                            <v-layout row wrap>
                              <v-flex xs12 md3>
                                {{index+1}}. {{ attendance.student.first_name }} {{ attendance.student.last_name }} {{ attendance.student.other_name }}
                              </v-flex>
                              <v-flex xs12 md9>
                                <v-layout column>
                                  <v-flex xs12>
                                    <div v-if="attendance.status == 1">Present</div>
                                    <div v-else>Absent</div>
                                  </v-flex>
                                  <v-flex xs12 v-if="attendance.attendance_absence_reason!=null" class="mb-3 mt-1">
                                    <v-layout row wrap>
                                      <v-flex xs12 md2>
                                        <div class="ml-3"><b>Reason:</b></div>
                                      </v-flex>
                                      <v-flex xs12 md10>
                                        {{attendance.attendance_absence_reason.absence_reason.name}}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 v-if="attendance.attendance_absence_comment!=null" class="mb-3 mt-1">
                                    <v-layout row wrap>
                                      <v-flex xs12 md2>
                                        <div class="ml-3"><b>Comment:</b></div>
                                      </v-flex>
                                      <v-flex xs12 md10>
                                        {{attendance.attendance_absence_comment.comment}}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                            
                          </v-flex>
                        </div>
                      </template>
                      </v-layout>
                      
                  </v-flex>
                </v-layout>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      view: "default",
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      institutions: [],
      classes: [],
      classStreams: [],
      studentList: [],
      attendanceIndex: null,
      snackbar: false,
      getStudentsLoader: false,
      getStudentsDisabled: false,

      inputRules: [
        (v) => !!v || "Input is required"
      ],

      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      filterAttendanceList: false,
      attendanceDate: false,
      nowDate: new Date().toISOString().slice(0, 10),
      newAttendance: {
        id: null,
        calendar_id: null,
        level_id: null,
        level_stream_id: null,
        school_id: null,
        checkedStudents: [],
        attendanceReason: [],
        attendanceComment: [],
        attendance_date: null,
        attendance_period_id: null
      },
      assignedAttendance: null,
      formData: null,
      calendars: [],
      attendanceStatus: [
        {
          id: 1,
          name: 'Present'
        },
        {
          id: 0,
          name: 'Absent'
        }
      ],
      absenceReasons: []
    };
  },
  created() {
    if (this.$can("teacher_attendance_view")) {
      this.startAttendanceLoader();
      this.fetchAttendanceList(this.attendancePagination.current_page);
      this.getFormData();
    }
  },
  methods: {
    ...mapActions([
      "fetchAttendanceList",
      "startAttendanceLoader",
      "stopAttendanceLoader",
      "filteredAttendanceList",
    ]),
    activateFetchButton() {
      this.getStudentsDisabled = false
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filterAttendanceList = true;
      this.startAttendanceLoader();
      this.fetchAttendanceList(1);
    },
    showAttendance(attendance) {
      this.assignedAttendance = attendance;
      this.changeView("show");
      console.log(attendance)
    },
    editAttendance(attendance) {
      this.newAttendance.id = attendance.id
      this.newAttendance.school_id = attendance.level_stream.level.institution_id;
      this.newAttendance.level_id = attendance.level_stream.level.id;
      this.newAttendance.level_stream_id = attendance.level_stream.id;
      this.newAttendance.attendance_date = attendance.attendance_date
      this.newAttendance.attendance_period_id = attendance.attendance_period_id

      for (var i = 0; i < attendance.attendance_students.length; i++) {
        this.studentList.push(attendance.attendance_students[i].student);

        this.newAttendance.checkedStudents[attendance.attendance_students[i].student.id] = attendance.attendance_students[i].status;
        this.absenceReasons[attendance.attendance_students[i].student.id] = attendance.attendance_students[i].attendance_absence_reason;

        if(attendance.attendance_students[i].attendance_absence_reason!=null){
          this.newAttendance.attendanceReason[attendance.attendance_students[i].student.id] = attendance.attendance_students[i].attendance_absence_reason.absence_reason_id;
        }
        if(attendance.attendance_students[i].attendance_absence_comment!=null){
          this.newAttendance.attendanceComment[attendance.attendance_students[i].student.id] = attendance.attendance_students[i].attendance_absence_comment.comment;
        }
      }
      

      this.changeView("create");
    },
    populateClasses() {
      this.getStudentsDisabled = false
      this.newAttendance.level_id = null;
      let x = this.formData.institutions.find(
        (institution) => institution.id == this.newAttendance.school_id
      );
      this.classes = x.levels;
      this.calendars = x.calendars
    },
    populateClassStreams() {
      this.getStudentsDisabled = false
      this.newAttendance.level_stream_id = null;
      let x = this.classes.find(
        (l) => l.id == this.newAttendance.level_id
      );
      this.classStreams = x.level_streams;
    },
    fetchStudents(level_stream_id) {
      if (this.newAttendance.level_id == null || this.newAttendance.level_stream_id == null || this.newAttendance.school_id == null) {
        this.getStudentsDisabled = true
      } else {
        this.getStudentsLoader = true
        apiCall({
          url: "/api/attendance?type=classList&stream_id=" + level_stream_id,
          method: "GET",
        })
          .then((resp) => {
            this.getStudentsLoader = false
            this.message = "Class List retrieved Successfully";
            this.color = "orange";
            this.loading = false;
            this.snackbar = true;
            this.confirm = false;
            this.studentList = resp;
            
            for (var i = 0; i < resp.length; i++) {
              this.newAttendance.checkedStudents[resp[i].id] = 1;
              this.absenceReasons[resp[i].id] = false;
            }
            console.log("this.newAttendance.checkedStudents", this.newAttendance.checkedStudents)
          })
          .catch((error) => {
            this.getStudentsLoader = false
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.loading = false;
            this.snackbar = true;
            console.log(error);

          });
      }
    },
    changeReasonField(id){
      if(this.newAttendance.checkedStudents[id]==1){
        this.absenceReasons[id] = false
      }else{
        this.absenceReasons[id] = true
      }
       
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.newAttendance.id != null) {
          confirm('Are you sure you want to update Attendance List?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/attendance/" + this.newAttendance.id,
              data: this.newAttendance,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Attendance Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchAttendanceList(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm('Are you sure you want to save Attendance List?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/attendance",
              data: this.newAttendance,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Attendance Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchAttendanceList(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
              });
          }
        }
      }
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startStudentLoader();
        apiCall({
          url: "/api/attendance?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filteredAttendanceList(resp);
            this.searchLoader = false;
            this.stopAttendanceLoader();
            this.filterAttendanceList = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopAttendanceLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.newAttendance.id = null;
        this.newAttendance.calendar_id = null;
        this.newAttendance.level_id = null;
        this.newAttendance.level_stream_id = null;
        this.newAttendance.school_id = null;
        this.newAttendance.checkedStudents = [];
        this.newAttendance.attendanceReason = [];
        this.newAttendance.attendanceComment = [];
        this.newAttendance.attendance_date = null;
        this.studentList = []
      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {

      }
      this.view = view;
    },
    changePage() {
      this.startAttendanceLoader();
      if (this.filterAttendanceList == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/attendance?page=" +
            this.attendancePagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filteredAttendanceList(resp);
            this.stopAttendanceLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopAttendanceLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchAttendanceList(this.attendancePagination.current_page);
      }
    },
    deleteAttendance(item) {
      confirm("Are You Sure You Want to Delete Record") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.attendanceIndex = item.id;
        apiCall({ url: "/api/attendance/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Record Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.attendanceIndex = null;
            this.fetchAttendanceList(this.attendancePagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.attendanceIndex = null;
            console.log(error.response);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/attendance?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    closeAttendanceDate() {
      this.attendanceDate = false;
    },

  },
  computed: {
    ...mapGetters([
      "attendance_list",
      "attendancePagination",
      "attendanceLoader",
    ]),
    length: function () {
      return Math.ceil(
        this.attendancePagination.total / this.attendancePagination.per_page
      );
    }
  },
};
</script>
