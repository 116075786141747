<template>
  <v-app>
    <Navbar v-if="['ResetPass', 'Dashboard', 'PageNotFound', 'Redirector'].indexOf($route.name) == -1" />
      <v-main class="background">
        <router-view></router-view>
      </v-main>
    <Footer v-if="$route.path === '/'"/>
  </v-app>
</template>

<script>
import Navbar from "@/components/layout/navbar";
import Footer from '@/components/layout/footer'
import { mapGetters, mapActions } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  name: 'App',

  components: {
    Navbar,
    Footer
  },
  created() {
    this.setTheme()
    i18n.locale = this.language;
    if (this.darkState == true) {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }
  },
  data: () => ({
    //
  }),
  methods: {
    setTheme(){
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
  },
  computed: {
    ...mapGetters(["darkState", "language"]),
  },
};
</script>