import apiCall from '@/utils/api'

const state = {
	county: {},
	counties: [],
    countyPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    countyLoader: false
};

const getters = {
    countyLoader: (state) => state.countyLoader,
	counties: (state) => state.counties,
	county: (state) => state.county,
    countyPagination: (state) => state.countyPagination
}

const actions = {
    async fetchCounties({commit}, page) {
		const response = await apiCall({url: `/api/county?page=${page}`, method: 'GET' });
		commit('setCounties', response)
        commit('stopCountyLoader', response)
	},
    async fetchCounty({commit}, itemId) {
		const response = await apiCall({url: `/api/county/${itemId}`, method: 'GET' });
		commit('setCounty', response)
        commit('stopCountyLoader', response)
	},
    async filterCounties({commit, state},resp){
		commit('setCounties', resp)
	},
	async startCountyLoader({commit, state},resp){
		commit('startCountyLoader', resp)
	},
	async stopCountyLoader({commit, state},resp){
		commit('stopCountyLoader', resp)
	},
};

const mutations = {
	setCounties: (state, counties) => {
		state.counties = counties.data
		state.countyPagination.current_page = counties.current_page
		state.countyPagination.total = counties.total
		state.countyPagination.per_page = counties.per_page
	},
	setCounty: (state, county) => {
		state.county = county
	},
    startCountyLoader: (state) => state.countyLoader = true,
	stopCountyLoader: (state) => state.countyLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
