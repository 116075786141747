import apiCall from '@/utils/api'

const state = {
	studentIdentifier: {},
	studentIdentifiers: [],
    studentIdentifierPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    studentIdentifierLoader: false
};

const getters = {
    studentIdentifierLoader: (state) => state.studentIdentifierLoader,
	studentIdentifiers: (state) => state.studentIdentifiers,
	studentIdentifier: (state) => state.studentIdentifier,
    studentIdentifierPagination: (state) => state.studentIdentifierPagination
}

const actions = {
    async fetchStudentIdentifiers({commit}, page) {
		const response = await apiCall({url: `/api/student-identifier?page=${page}`, method: 'GET' });
		commit('setStudentIdentifiers', response)
        commit('stopStudentIdentifierLoader', response)
	},
    async fetchStudentIdentifier({commit}, itemId) {
		const response = await apiCall({url: `/api/student-identifier/${itemId}`, method: 'GET' });
		commit('setStudentIdentifier', response)
        commit('stopStudentIdentifierLoader', response)
	},
    async filterStudentIdentifiers({commit, state},resp){
		commit('setStudentIdentifiers', resp)
	},
	async startStudentIdentifierLoader({commit, state},resp){
		commit('startStudentIdentifierLoader', resp)
	},
	async stopStudentIdentifierLoader({commit, state},resp){
		commit('stopStudentIdentifierLoader', resp)
	},
};

const mutations = {
	setStudentIdentifiers: (state, studentIdentifiers) => {
		state.studentIdentifiers = studentIdentifiers.data
		state.studentIdentifierPagination.current_page = studentIdentifiers.current_page
		state.studentIdentifierPagination.total = studentIdentifiers.total
		state.studentIdentifierPagination.per_page = studentIdentifiers.per_page
	},
	setStudentIdentifier: (state, studentIdentifier) => {
		state.studentIdentifier = studentIdentifier
	},
    startStudentIdentifierLoader: (state) => state.studentIdentifierLoader = true,
	stopStudentIdentifierLoader: (state) => state.studentIdentifierLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
