import apiCall from '@/utils/api'

const state = {
	staffInstitution: {},
	staffInstitutions: [],
    staffInstitutionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    staffInstitutionLoader: false
};

const getters = {
    staffInstitutionLoader: (state) => state.staffInstitutionLoader,
	staffInstitutions: (state) => state.staffInstitutions,
	staffInstitution: (state) => state.staffInstitution,
    staffInstitutionPagination: (state) => state.staffInstitutionPagination
}

const actions = {
    async fetchStaffInstitutions({commit}, page) {
		const response = await apiCall({url: `/api/staff-institution?page=${page}`, method: 'GET' });
		commit('setStaffInstitutions', response)
        commit('stopLoader', response)
	},
    async fetchStaffInstitution({commit}, itemId) {
		const response = await apiCall({url: `/api/staff-institution/${itemId}`, method: 'GET' });
		commit('setStaffInstitution', response)
        commit('stopLoader', response)
	},
    async filterStaffInstitutions({commit, state},resp){
		commit('setStaffInstitutions', resp)
	},
	async startStaffInstitutionLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopStaffInstitutionLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setStaffInstitutions: (state, staffInstitutions) => {
		state.staffInstitutions = staffInstitutions.data
		state.staffInstitutionPagination.current_page = staffInstitutions.current_page
		state.staffInstitutionPagination.total = staffInstitutions.total
		state.staffInstitutionPagination.per_page = staffInstitutions.per_page
	},
	setStaffInstitution: (state, staffInstitution) => {
		state.staffInstitution = staffInstitution
	},
    startLoader: (state) => state.staffInstitutionLoader = true,
	stopLoader: (state) => state.staffInstitutionLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
