<template>
  <div class="students" v-if="$can('student_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ $t(message) }}</span>
    </v-snackbar>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="imageDialog"
    >
      <v-card max-width="600">
        <v-toolbar color="primary" dark flat>
          {{ $t("uploadProfilePictureMsg") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="imageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="imageForm" v-model="imageValid" lazy-validation>
          <v-layout column>
            <v-flex xs12>
              <v-file-input
                class="mx-2 mt-3"
                :loading="imageLoading"
                :rules="fileRules"
                filled
                dense
                show-size
                chips
                accept="image/*"
                v-model="imageFile"
                :label="$t('selectImageMsg')"
              ></v-file-input>
            </v-flex>
            <v-flex xs12>
              <div align="right" class="pa-3">
                <v-btn
                  depressed
                  block
                  class="secondary text-none mb-3"
                  @click="uploadImage"
                  :loading="imageLoading"
                  :disabled="!imageValid"
                >
                  {{ $t("submitMsg") }} <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="700" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="excel white--text">
          Upload Excel
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false" class="white--text">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="my-7">

          <v-form ref="excelForm" v-model="excelValid" lazy-validation>
            <v-layout row wrap>
              <v-flex xs10>
                <v-file-input :loading="excelLoading" :rules="fileRules" outlined dense show-size chips
                  accept=".xlsx, .xls, .csv" v-model="excelFile" label="Excel File" class="text_field background">
                </v-file-input>
              </v-flex>
              <v-flex xs2>
                <div align="right">
                  <v-btn rounded depressed class="primary text-none" :disabled="!excelValid" @click="saveExcel"
                    :loading="excelLoading">
                    Upload
                    <v-icon right>
                      mdi-cloud-upload
                    </v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <div class="title">Students</div>
        </v-flex>
        <div v-if="view == 'default'">
          <v-flex xs12 class="mt-5 mb-2">
            <v-card elevation="0" class="header">
              <v-layout row wrap>
                <v-flex xs12 md4 class="hidden-sm-and-down"> </v-flex>

                <v-flex xs12 md4>
                  <v-text-field outlined rounded dense :label="$t('searchMsg')" append-icon="mdi-undo-variant"
                    @click:append="resetSearch()" v-on:keyup.enter="search()" v-model="searchTerm"
                    @input="enableSearch()" class="background search_field my-3 mx-5"></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <!-- <div class="pa-1">
                    <v-btn
                      class="secondary text-none"
                      rounded
                      depressed
                      @click="search"
                      :loading="searchLoader"
                      :disabled="searchButtonDisabled"
                    >
                      {{ $t("searchMsg") }}
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </div> -->
                  <div align="center" class="my-4">
                    <v-btn rounded depressed class="primary text-none" @click="changeView('create')"
                      v-if="$can('student_create')">
                      Add Student
                      <v-icon right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                    <v-btn rounded depressed class="excel text-none white--text ml-1" @click="dialog = true"
                      v-if="$can('student_create')">
                      Import Students
                      <v-icon right>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <div v-if="students.length == 0">
              <v-card elevation="0">
                <v-layout row wrap>
                  <v-flex xs12 md1>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          <v-icon large class="primary--text">
                            mdi-alert-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md11>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          No student Found
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-card>
            </div>
            <div v-else>
              <v-progress-linear v-if="studentLoader" height="1" indeterminate color="primary"></v-progress-linear>
              <div class="hidden-sm-and-down">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="header">
                      <tr>
                        <th class="text-left">Photo</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Gender </th>
                        <th class="text-left">Date of Birth </th>
                        <th class="text-left">Nationality </th>
                        <th class="text-left">Status </th>
                        <th class="text-right">{{ $t("actionsMsg") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in students" :key="item.id">
                        <td class="pa-1">
                          <div class="my-2">
                            <v-avatar size="56" v-if="item.profile_picture != null">
                            <img :src="path+'/student_pics/'+item.profile_picture" alt="PPIC" />
                          </v-avatar>
                          <v-avatar v-else class="header">
                            <v-icon large> mdi-account-circle </v-icon>
                          </v-avatar>
                          </div>
                        </td>
                        <td>{{ item.first_name }} {{ item.last_name }} {{ item.other_names }}</td>
                        <td>{{ item.gender.name }}</td>
                        <td>{{ item.dob }}</td>
                        <td>{{ item.nationality.name }}</td>
                        <td>
                          <div v-if="item.status = 1">Active</div>
                          <div v-else>Inactive </div>
                        </td>
                        <td>
                          <div align="right">
                            <v-tooltip top v-if="$can('student_view')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="showStudent(item)" class="button mr-1 primary--text" v-bind="attrs"
                                  v-on="on">
                                  <v-icon small> mdi-eye </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("viewMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('student_edit')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="button mr-1 success--text" @click="editstudent(item)" v-bind="attrs"
                                  v-on="on">
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("editMsg") }}</span>
                            </v-tooltip>
                            <v-tooltip top v-if="$can('student_archive')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="button mr-1 error--text" @click="deleteStudent(item)"
                                  :loading="loading && studentIndex == item.id" v-bind="attrs" v-on="on">
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("deleteMsg") }}</span>
                            </v-tooltip>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="hidden-md-and-up">
                <v-layout column>
                  <template v-for="(student, index) in students">
                    <div :key="index">
                      <v-flex xs12 class="mb-2">
                        <v-card elevation="0">
                          <div class="pa-5">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <div class="title primary--text">
                                      <b>{{ student.first_name }} {{ student.last_name }} {{ student.other_names }}</b>
                                    </div>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn depressed class="red--text" :loading="loading && studentIndex == student.id"
                                      icon @click="deleteStudent(student)" v-if="$can('student_archive')">
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-divider class="mt-2"></v-divider>
                              <v-flex xs12>
                                <v-layout column>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Gender</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ student.gender.name }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Date of Birth</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ student.dob }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <b>Nationality</b>
                                      </v-flex>
                                      <v-flex xs8>
                                        {{ student.nationality.name }}
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-5">
                                    <v-layout row wrap class="mt-1">
                                      <v-flex xs12>
                                        <div>
                                          <v-btn depressed class="
                                              primary
                                              text-none
                                              white--text
                                            " block @click="showStudent(student)" v-if="$can('student_view')">{{
                                                $t("viewMsg")
                                            }}
                                            <v-icon right> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div>
                                          <v-btn depressed class="
                                              green
                                              darken-1
                                              text-none
                                              white--text
                                              mt-1
                                            " block @click="editstudent(student)" v-if="$can('student_edit')">{{
                                                $t("editMsg")
                                            }}
                                            <v-icon right>
                                              mdi-border-color
                                            </v-icon>
                                          </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-10">
            <v-layout row wrap>
              <v-flex xs12 md9>
                <div align="left">
                  <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                    v-model="studentPagination.current_page" @input="changePage()" circle>
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div v-if="length != 0" align="right" class="mr-3">
                  <b class="primary--text ml-4">{{ $t("totalMsg") }}: </b>{{ studentPagination.total | formatNumber }}
                </div>
              </v-flex>

            </v-layout>
          </v-flex>
        </div>
        <!--Add or Edit -->
        <div v-if="view == 'create'">
          <v-flex class="mt-2">
            <v-card elevation="0">
              <v-card-title class="header">
                <v-spacer></v-spacer>
                <div v-if="newStudent.id == null">Add Student</div>
                <div v-else>Edit Student</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5">
                    <div class="ml-3">Student Details</div>
                  </v-layout>
                  <!-- <v-layout row wrap class="mt-5" v-if="newStudent.id != null">
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Status<span class="error--text"></span></div>
                        <v-radio-group v-model="newStudent.status" row>
                            <v-radio
                                label="Active"
                                value="1"
                            ></v-radio>
                            <v-radio
                                label="Inactive"
                                value="0"
                            ></v-radio>
                            </v-radio-group>
                      </div>
                    </v-flex>
                  </v-layout> -->
                  <v-layout row wrap class="mt-5">
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>First Name<span class="error--text">*</span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newStudent.first_name"
                          :rules="inputRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Last Name<span class="error--text">*</span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newStudent.last_name"
                          :rules="inputRules"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Other Names<span class="error--text"></span></div>
                        <v-text-field class="text_field background" outlined dense v-model="newStudent.other_names">
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Date of Birth<span class="error--text">*</span></div>
                        <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                          v-model="dateOfBirth" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense :value="formatDobDate" slot="activator" readonly v-bind="attrs"
                              v-on="on" class="text_field background"></v-text-field>
                          </template>
                          <v-date-picker v-model="newStudent.dob" :max="nowDate" @change="closeDateOfBirth">
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("genderMsg") }}*</div>
                        <v-select :items="formData.genders" class="text_field background" item-value="id"
                          item-text="name" outlined v-model="newStudent.gender_id" dense :rules="inputRules"></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>{{ $t("nationalityMsg") }}*</div>
                        <v-select :items="formData.nationalities" class="text_field background" item-value="id"
                          item-text="name" outlined v-model="newStudent.country_id" dense :rules="inputRules">
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <v-checkbox class="text_field background" outlined dense v-model="newStudent.disability"
                          label="Special Needs"></v-checkbox>
                      </div>
                    </v-flex>

                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded depressed class="primary text-none mt-3" @click="save" :disabled="!valid"
                  :loading="loading">
                  {{ $t("saveMsg") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <!-- View -->
        <div v-if="view == 'show'">

          <v-card elevation="0">
            <v-card-title class="header">
              <v-spacer></v-spacer>
              <div>View Student</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-tabs show-arrows v-model="tab">
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab @click="activeTab = 'personal details'">
                <div class="text-none">Personal Details</div>
              </v-tab>
              <v-tab @click="activeTab = 'classes'" v-if="$can('student_registration_view')">
                <div class="text-none">Classes</div>
              </v-tab>
            </v-tabs>
            <div v-if="activeTab == 'personal details'">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 md2>
                    <div class="ma-2">
                        <v-img
                          v-if="assignedStudent.profile_picture == null"
                          aspect-ratio="0.9"
                          contain
                          src="/img/default_pic.png"
                        ></v-img>
                        <v-img
                          v-else
                          aspect-ratio="0.9"
                          contain
                          :src="path+'/student_pics/'+assignedStudent.profile_picture"
                        ></v-img>
                        <div align="center">
                          <v-btn
                            depressed  
                            class="secondary white--text text-none"
                            @click="imageDialog = true"
                            v-if="$can('student_add_photo')"
                            >
                            {{ $t("changeImageMsg") }}
                            <v-icon right>mdi-image-filter-center-focus-weak</v-icon>
                          </v-btn>
                        </div>
                          
                      </div>
                  </v-flex>
                  <v-flex xs12 md10>
                    <div class="pa-3">
                      <v-layout column>
                        <v-flex xs12>
                          <v-layout row wrap>
                            <v-flex xs12 md6>
                              <v-layout column>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>First Name: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>{{ assignedStudent.first_name }}</div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap class="grey lighten-3">
                                    <v-flex xs12 md4>
                                      <b>Last Name: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>{{ assignedStudent.last_name }}</div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Other Name(s): </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>{{ assignedStudent.other_name }}</div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap class="grey lighten-3 mr-3">
                                    <v-flex xs12 md4>
                                      <b>Special Needs: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div v-if="assignedStudent.disability == 0">No</div>
                                      <div v-else>Yes</div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 md6>
                              <v-layout column>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Gender: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>
                                        {{ assignedStudent.gender.name }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap class="grey lighten-3">
                                    <v-flex xs12 md4>
                                      <b>Date of Birth: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>
                                        {{ assignedStudent.dob }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Nationality: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>
                                        {{ assignedStudent.nationality.name }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>

              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Guardians</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeGuardianView('create')" v-if="guardianView == 'default'">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn icon @click="changeGuardianView('default')" v-if="guardianView == 'create'">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="guardianView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedStudent.guardians.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                No Guardian Added
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout row wrap>
                        <template v-for="(guardian, index) in assignedStudent.guardians">
                          <v-flex xs12 class="mb-15" :key="index">
                            <v-layout row wrap>
                              <v-flex xs1>
                                {{ index + 1 }}.
                              </v-flex>
                              <v-flex xs11>
                                <v-layout row wrap>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs12 md4>
                                              <b>Name:</b>
                                            </v-flex>
                                            <v-flex xs12 md8>
                                              {{ guardian.name }}
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div class="mt-5">
                                          <v-layout row wrap class="grey lighten-3">
                                            <v-flex xs12 md4>
                                              <b>Relationship:</b>
                                            </v-flex>
                                            <v-flex xs12 md8>
                                              {{ guardian.relationship.name }}
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs12 md4>
                                              <b>Lives With?:</b>
                                            </v-flex>
                                            <v-flex xs12 md8>
                                              <div v-if="guardian.living_with==1">
                                                Yes
                                              </div>
                                              <div v-else>
                                                No
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs12 md4>
                                              <b>Primary Phone No.:</b>
                                            </v-flex>
                                            <v-flex xs12 md8>
                                              {{ guardian.primary_phone }}
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div class="mt-5">
                                          <v-layout row wrap class="grey lighten-3">
                                            <v-flex xs12 md4>
                                              <b>Secondary Phone No:</b>
                                            </v-flex>
                                            <v-flex xs12 md8>
                                              {{ guardian.secondary_phone }}
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                                
                              </v-flex>
                            </v-layout>
                          </v-flex>

                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="guardianView == 'create'">
                  <div class="pa-3 mt-5">
                    <v-layout column>
                      <v-flex xs12>
                        <div align="right">
                          <v-btn rounded depressed class="primary text-none" @click="addGuardianField()">
                            Add Guardian
                            <v-icon right> mdi-plus </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-form v-model="valid" ref="guardianForm" lazy-validation></v-form>
                      <template v-for="(guardian, index) in guardians">
                        <div :key="index">
                          <v-flex xs12 class="mb-7">
                            <v-layout row wrap>
                              <v-flex xs11>
                                <v-layout row wrap>
                                  <v-flex xs12 md4>
                                    <div class="pa-3">
                                      <div>Name<span class="error--text">*</span></div>
                                      <v-text-field class="text_field background" outlined dense
                                        v-model="guardians[index].name" :rules="inputRules"></v-text-field>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 md4>
                                    <div class="pa-3">
                                      <div>Relationship<span class="error--text">*</span></div>
                                      <v-select :items="formData.relationships" class="text_field background"
                                        item-value="id" item-text="name" outlined
                                        v-model="guardians[index].relationship_id" dense :rules="inputRules">
                                      </v-select>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 md4>
                                    <div class="pa-3">
                                      <div>Primary Phone #<span class="error--text">*</span></div>
                                      <v-text-field class="text_field background" outlined dense
                                        v-model="guardians[index].primary_phone" :rules="inputRules"></v-text-field>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 md4>
                                    <div class="pa-3">
                                      <div>Secondary Phone #</div>
                                      <v-text-field class="text_field background" outlined dense
                                        v-model="guardians[index].secondary_phone"></v-text-field>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 md4>
                                    <div class="pa-3 mt-5">
                                      <v-checkbox class="text_field background" outlined dense v-model="guardians[index].living_with"
                                        label="Living With?" :rules="inputRules"></v-checkbox>
                                    </div>
                                  </v-flex>
                                  
                                </v-layout>
                              </v-flex>
                              <v-flex xs1>
                                <div class="pa-3">
                                  <v-btn icon class="red--text mt-3" @click="removeGuardian(index)">
                                    <v-icon> mdi-close-circle </v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-divider class="mx-3 my-10 mr-5"></v-divider>
                        </div>
                      </template>
                      <v-flex xs12>
                        <div align="right" class="mt-5">
                          <v-btn rounded depressed class="universal white--text text-none mt-3"
                            @click="saveGuardians()" :disabled="!valid" :loading="loading">
                            {{ $t("saveMsg") }}
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      </v-form>
                    </v-layout>
                  </div>
                </div>
              </v-card-text>

              <v-card-title class="header mt-5" v-if="assignedStudent.disability == 1">
                <v-spacer></v-spacer>
                <div>Disabilities</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeDisabilityView('create')" v-if="disabilityView == 'default'">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn icon @click="changeDisabilityView('default')" v-if="disabilityView == 'create'">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="assignedStudent.disability == 1">
                <div v-if="disabilityView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedStudent.disabilities.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                No Special Need
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout row wrap>
                        <template v-for="(disability, index) in assignedStudent.disabilities">
                          <v-flex xs12 class="mb-5" :key="index">
                            <v-layout row wrap>
                              <v-flex xs1>
                                {{ index + 1 }}.
                              </v-flex>
                              <v-flex xs11>
                                <v-layout row wrap>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-6">
                                          <v-layout row wrap>
                                            <v-flex xs12 md4>
                                              <b>Name:</b>
                                            </v-flex>
                                            <v-flex xs12 md8>
                                              {{ disability.disability.name }}
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-5">
                                          <!-- <v-layout row wrap>
                                            <v-flex xs12 md4>
                                              <b>Primary Phone No.:</b>
                                            </v-flex>
                                            <v-flex xs12 md8>
                                              {{ guardian.primary_phone }}
                                            </v-flex>
                                          </v-layout> -->
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                                
                              </v-flex>
                            </v-layout>
                          </v-flex>

                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="disabilityView == 'create'">
                  <div class="pa-3 mt-5">
                    <v-layout column>
                      <v-flex xs12>
                        <div align="right">
                          <v-btn rounded depressed class="primary text-none" @click="addDisabilityField()">
                            Add Disability
                            <v-icon right> mdi-plus </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-form v-model="valid" ref="disabilityForm" lazy-validation></v-form>
                      <template v-for="(disability, index) in disabilities">
                        <div :key="index">
                          <v-flex xs12 class="mb-7">
                            <v-layout row wrap>
                              <v-flex xs11>
                                <v-layout row wrap>
                                  <v-flex xs12 md4>
                                    <div class="pa-3">
                                      <div>Disability<span class="error--text">*</span></div>
                                      <v-select :items="formData.disabilities" class="text_field background"
                                        item-value="id" item-text="name" outlined
                                        v-model="disabilities[index].disability_id" dense :rules="inputRules">
                                      </v-select>
                                    </div>
                                  </v-flex>
                                  
                                </v-layout>
                              </v-flex>
                              <v-flex xs1>
                                <div class="pa-3">
                                  <v-btn icon class="red--text mt-3" @click="removeDisability(index)">
                                    <v-icon> mdi-close-circle </v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-divider class="mx-3 my-10 mr-5"></v-divider>
                        </div>
                      </template>
                      <v-flex xs12>
                        <div align="right" class="mt-5">
                          <v-btn rounded depressed class="universal white--text text-none mt-3"
                            @click="saveDisabilities()" :disabled="!valid" :loading="loading">
                            {{ $t("saveMsg") }}
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      </v-form>
                    </v-layout>
                  </div>
                </div>
              </v-card-text>

              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Student Identifiers</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeStudentIdentifierView('create')" v-if="studentIdentifierView == 'default'">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn icon @click="changeStudentIdentifierView('default')" v-if="studentIdentifierView == 'create'">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="studentIdentifierView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedStudent.student_identifier_links.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                Identifier Not Added
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout row wrap>
                        <template v-for="(student_identifier_link, index) in assignedStudent.student_identifier_links">

                          <v-flex xs12 md6 class="mb-5" :key="index">
                            <v-layout row wrap>
                              <v-flex xs1>
                                {{ index + 1 }}.
                              </v-flex>
                              <v-flex xs11>
                                <div class="mt-3">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>{{ student_identifier_link.student_identifier.name }}:</b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      {{ student_identifier_link.value }}
                                    </v-flex>
                                  </v-layout>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="studentIdentifierView == 'create'">
                  <div class="pa-3 mt-5">
                    <v-layout column>
                      <v-flex xs12>
                        <div align="right">
                          <v-btn rounded depressed class="primary text-none" @click="addIdentifierField()">
                            Add Identifier
                            <v-icon right> mdi-plus </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-form v-model="valid" ref="studentIdentifierForm" lazy-validation></v-form>
                      <template v-for="(studentIdentifier, index) in studentIdentifiers">
                        <div :key="index">
                          <v-flex xs12 class="mb-7">
                            <v-layout row wrap>
                              <v-flex xs11>
                                <v-layout row wrap>
                                  <v-flex xs12 md4>
                                    <div class="pa-3">
                                      <div>Student Identifier<span class="error--text">*</span></div>
                                      <v-select :items="formData.student_identifiers" class="text_field background"
                                        item-value="id" item-text="name" outlined
                                        v-model="studentIdentifiers[index].identifier_id" dense :rules="inputRules">
                                      </v-select>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 md8>
                                    <div class="pa-3">
                                      <div>Value<span class="error--text">*</span></div>
                                      <v-text-field class="text_field background" outlined dense
                                        v-model="studentIdentifiers[index].value" :rules="inputRules"></v-text-field>
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs1>
                                <div class="pa-3">
                                  <v-btn icon class="red--text mt-3" @click="removeStudentIdentifier(index)">
                                    <v-icon> mdi-close-circle </v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>

                          </v-flex>
                        </div>
                      </template>
                      <v-flex xs12>
                        <div align="right" class="mt-5">
                          <v-btn rounded depressed class="universal white--text text-none mt-3"
                            @click="saveIdentifiers()" :disabled="!valid" :loading="loading">
                            {{ $t("saveMsg") }}
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      </v-form>
                    </v-layout>
                  </div>
                </div>
              </v-card-text>
            </div>
            <div v-if="activeTab == 'classes'">
              <v-card-title class="header mt-5">
                <v-spacer></v-spacer>
                <div>Class Registrations</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeClassView('create')" v-if="
                  $can('student_registration_create') &&
                  classView == 'default'
                ">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-btn icon @click="changeClassView('default')" v-if="classView != 'default'">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div v-if="classView == 'default'">
                  <div class="pa-3 mt-5">
                    <div v-if="assignedStudent.student_registration.length == 0">
                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="primary--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs12 md11>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                Student not Registered in any class
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-tabs show-arrows v-model="levelTab" class="mb-3">
                        <v-tabs-slider color="primary"></v-tabs-slider>

                        <v-tab v-for="(level, index) in assignedStudent.institution_levels" :key="index"
                          @click="assignLevel(level)">
                          <div class="text-none">{{ level }}</div>
                        </v-tab>
                      </v-tabs>
                      <v-layout column>
                        <v-flex xs12 class="mt-7">
                          <div class="ml-3">
                            <v-layout column>
                              <template v-for="(registration, index) in studentClasses">
                                <div :key="index">
                                  <v-flex xs12 class="mb-15">
                                    <v-layout row wrap>
                                      <v-flex xs1>
                                        {{ index + 1 }}.
                                      </v-flex>
                                      <v-flex xs10>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <v-layout row wrap>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Institution: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ registration.institution.name }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Level: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>{{ registration.level.name }}</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Stream: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ registration.level_stream.name }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 md6>
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                      <v-flex xs12 md6>
                                                        <b>Registration Number: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ registration.registration_number }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="grey lighten-3">
                                                      <v-flex xs12 md6>
                                                        <b>Registration Date: </b>
                                                      </v-flex>
                                                      <v-flex xs12 md6>
                                                        <div>
                                                          {{ registration.registration_date }}
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap class="white lighten-3">
                                                      <v-flex xs12 md4>
                                                        <b class="white--text text--lighten-3"> .</b>
                                                      </v-flex>
                                                      <v-flex xs12 md8>
                                                        <div class="white--text text--lighten-3">. </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn icon @click="deleteAssignment(registration)"
                                          v-if="$can('student_registration_archive')"
                                          :loading="loading && assignmentIndex == registration.id">
                                          <v-icon small class="red--text">mdi-delete</v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </div>
                              </template>
                            </v-layout>
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="classView == 'create'" class="mt-3">
                  <v-form v-model="valid" ref="assignForm" lazy-validation>
                    <v-layout row wrap>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Institution Type*</div>
                          <v-select :items="formData.institutionTypes" class="text_field background" item-value="id"
                            item-text="name" outlined v-model="assignStudent.institution_type_id" dense :rules="inputRules"
                            @input="populateInstitutions()">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Institution*</div>
                          <v-select :items="institutions" class="text_field background" item-value="id"
                            item-text="name" outlined v-model="assignStudent.institution_id" dense :rules="inputRules"
                            @input="populateYears()">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Year*</div>
                          <v-select :items="years" class="text_field background" item-value="year" item-text="year"
                            outlined v-model="assignStudent.year" dense :rules="inputRules" @input="populateClasses()">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Level*</div>
                          <v-select :items="classes" class="text_field background" item-value="id" item-text="name"
                            outlined v-model="assignStudent.level_id" dense :rules="inputRules"
                            @input="populateStreams()">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Stream*</div>
                          <v-select :items="streams" class="text_field background" item-value="id" item-text="name"
                            outlined v-model="assignStudent.level_stream_id" dense :rules="inputRules">
                          </v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Enrolment Date</div>
                          <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                            v-model="dateEnrolled" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatEnrolmentDate" slot="activator" readonly
                                v-bind="attrs" v-on="on" class="text_field background"></v-text-field>
                            </template>
                            <v-date-picker v-model="assignStudent.registration_date" :max="nowDate"
                              @change="closeDateEnrolled">
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Registration Number<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense
                            v-model="assignStudent.registration_number" :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>

                      <v-flex xs12 md3>
                        <div align="center" class="mt-5">
                          <v-btn rounded depressed class="universal white--text text-none mt-3" @click="saveAssign"
                            :disabled="!valid" :loading="loading">
                            {{ $t("saveMsg") }}
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </div>

                      </v-flex>


                    </v-layout>
                  </v-form>
                </div>
              </v-card-text>
            </div>

          </v-card>

        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      view: "default",
      classView: "default",
      studentIdentifierView: "default",
      guardianView: "default",
      disabilityView: "default",
      activeTab: 'personal details',
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      dialog: false,

      nowDate: new Date().toISOString().slice(0, 10),
      phone_number: '',
      dateOfBirth: false,
      dateEnrolled: false,

      studentIndex: null,
      assignmentIndex: null,
      snackbar: false,
      inputRules: [
        (v) => !!v || "Input is required"
      ],
      phoneRules: [
        (v) => !!v || "Input is required",
        (v) =>
          !v ||
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(
            v
          ) ||
          "Enter valid phone",
        (v) => !v || (v && v.length >= 9) && (v && v.length <= 12) || "Maximum 12 digits",
      ],

      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      filteredstudents: false,
      newStudent: {
        id: null,
        first_name: null,
        last_name: null,
        other_names: null,
        dob: null,
        gender_id: null,
        country_id: null,
        disability: false,
      },
      assignStudent: {
        id: null,
        student_id: null,
        institution_type_id: null,
        institution_id: null,
        year: null,
        level_id: null,
        level_stream_id: null,
        registration_date: null,
        registration_number: null,
      },
      assignedStudent: null,
      formData: null,

      tab: null,
      levelTab: null,
      institutions: [],
      years: [],
      classes: [],
      streams: [],
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      studentClasses: [],

      studentIdentifiers: [
        {
          identifier_id: null,
          value: null
        }
      ],
      guardians: [
        {
          relationship_id: null,
          name: null,
          primary_phone: null,
          secondary_phone: null,
          living_with: false
        }
      ],
      disabilities: [
        {
          disability_id: null,
        }
      ],
      studentIdentifier: {
        id: null,
        identifier_id: null,
        value: null,
        student_id: null,
        studentIdentifiers: null
      },
      guardian: {
        id: null,
        relationship_id: null,
        name: null,
        primary_phone: null,
        secondary_phone: null,
        living_with: null,
        guardians: null
      },
      disability: {
        id: null,
        disabilities: null
      },

      excelValid: true,
      excelLoading: false,
      fileRules: [(v) => !!v || "Kindly Select a File"],
      excelFile: null,

      imageDialog: false,
      imageLoading: false,
      fileRules: [(v) => !!v || this.$t("kindlySelectAFileMsg")],
      imageFile: null,
      imageValid: true,
    };
  },
  created() {
    if (this.$can("student_view")) {
      this.startStudentLoader();
      this.fetchStudents(this.studentPagination.current_page);
      this.getFormData();
    }
  },
  methods: {
    ...mapActions([
      "fetchStudents",
      "startStudentLoader",
      "stopStudentLoader",
      "filterStudents",
    ]),
    assignLevel(level) {
      this.studentClasses = []
      var l = this.assignedStudent.student_registration.length, i;
      for (i = 0; i < l; i++) {
        if (this.assignedStudent.student_registration[i].institution.institution_type.name == level) {
          this.studentClasses.push(this.assignedStudent.student_registration[i]);
        }
      }
    },
    populateInstitutions(){
      let x = this.formData.institutionTypes.find(
        (institutionType) => institutionType.id == this.assignStudent.institution_type_id
      );

      this.institutions = x.institutions;
    },
    populateYears() {
      let x = this.institutions.find(
        (institution) => institution.id == this.assignStudent.institution_id
      );

      var keys = [];
      var values = [];

      for (let value of Object.keys(x.levels)) {
        keys.push(value);
      }

      for (let value of Object.values(x.levels)) {
        values.push(value);
      }

      for (var i = 0; i < keys.length; i++) {
        var obj = {
          "year": keys[i],
          "data": values[i]
        }
        this.years.push(obj);
      }
      console.log("this.years", this.years)
      //this.years = result;
    },
    populateClasses() {
      let x = this.years.find(
        (year) => year.year == this.assignStudent.year
      );

      this.classes = x.data;
    },
    populateStreams() {
      let x = this.classes.find(
        (level) => level.id == this.assignStudent.level_id
      );

      this.streams = x.level_streams;
    },
    addIdentifierField() {
      let obj = {}
      obj['identifier_id'] = null
      obj['value'] = null

      this.studentIdentifiers.push(obj)
    },
    addGuardianField() {
      let obj = {}
      obj['relationship_id'] = null
      obj['name'] = null
      obj['primary_phone'] = null
      obj['secondary_phone'] = null
      obj['living_with'] = null

      this.guardians.push(obj)
    },
    addDisabilityField() {
      let obj = {}
      obj['disability_id'] = null

      this.disabilities.push(obj)
    },
    removeStudentIdentifier(index) {
      if (this.studentIdentifiers.length != 0) {
        this.studentIdentifiers.splice(index, 1);
      }

    },
    removeGuardian(index) {
      if (this.guardians.length != 0) {
        this.guardians.splice(index, 1);
      }

    },
    removeDisability(index){
      if (this.disabilities.length != 0) {
        this.disabilities.splice(index, 1);
      }
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredStudents = true;
      this.startStudentLoader();
      this.fetchStudents(1);
    },
    showStudent(student) {
      this.assignedStudent = student;
      this.changeView("show");
      this.assignLevel(this.assignedStudent.student_registration[0].institution.institution_type.name)
    },
    editstudent(student) {
      this.newStudent.id = student.id
      this.newStudent.first_name = student.first_name
      this.newStudent.last_name = student.last_name
      this.newStudent.other_names = student.other_names
      this.newStudent.dob = student.dob
      this.newStudent.gender_id = student.gender_id
      this.newStudent.country_id = student.country_id
      this.newStudent.disability = student.disability

      this.changeView("create");
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.newStudent.id != null) {
          confirm('Are you sure you want to update Student?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/student/" + this.newStudent.id,
              data: this.newStudent,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Student Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchStudents(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm('Are you sure you want to create Student?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/student",
              data: this.newStudent,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Student Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchStudents(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchStudents(1);
              });
          }
        }
      }
    },
    saveAssign() {
      if (this.$refs.assignForm.validate()) {
        confirm('Are you sure you want to assign class?') && (this.confirm = true);
        if (this.confirm) {
          this.assignStudent.student_id = this.assignedStudent.id
          this.loading = true;
          apiCall({
            url: "/api/student-registration",
            data: this.assignStudent,
            method: "POST",
            multipart: true
          })
            .then((resp) => {
              this.message = "Assignment Successful";
              this.color = "orange";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.assignedStudent = resp
              this.changeClassView("default");

              this.fetchStudents(this.studentPagination.current_page);
            })
            .catch((error) => {
              this.message = "anErrorOccurredMsg";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
            });
        }

      }
    },
    saveIdentifiers() {
      if (this.$refs.studentIdentifierForm.validate()) {
        if (this.studentIdentifier.id != null) {
          confirm('Are you sure you want to update Student Identifier?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/student-identifier-link/" + this.newStudent.id,
              data: this.newStudent,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Student Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchStudents(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm('Are you sure you want to add Student Identifier(s)?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.studentIdentifier.student_id = this.assignedStudent.id
            this.studentIdentifier.studentIdentifiers = this.studentIdentifiers
            apiCall({
              url: "/api/student-identifier-link",
              data: this.studentIdentifier,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Student Identifiers Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.assignedStudent.student_identifier_links = resp;
                this.studentIdentifierView = "default"
                this.studentIdentifiers = []
                let obj = {}
                obj['identifier_id'] = null
                obj['value'] = null

                this.studentIdentifiers.push(obj)
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchStudents(1);
              });
          }
        }
      }
    },
    saveGuardians() {
      if (this.$refs.guardianForm.validate()) {
        if (this.guardian.id != null) {
          confirm('Are you sure you want to update Guardian?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/guardian-link/" + this.guardian.id,
              data: this.guardian,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Guardian Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeGuardianView("default");
                this.fetchStudents(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm('Are you sure you want to add Guardian(s)?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.guardian.student_id = this.assignedStudent.id
            this.guardian.guardians = this.guardians
            apiCall({
              url: "/api/guardian-link",
              data: this.guardian,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Guardian(s) Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.assignedStudent.guardians = resp;
                this.guardianView = "default"
                this.guardians = []
                let obj = {}
                obj['relationship_id'] = null
                obj['name'] = null
                obj['primary_phone'] = null
                obj['secondary_phone'] = null
                obj['living_with'] = null

                this.guardians.push(obj)

                this.changeGuardianView("default");
                this.fetchStudents(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeGuardianView("default");
                this.fetchStudents(1);
              });
          }
        }
      }
    },
    saveDisabilities() {
      if (this.$refs.disabilityForm.validate()) {
        if (this.disability.id != null) {
          confirm('Are you sure you want to update Disability?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/disability-link/" + this.disability.id,
              data: this.disability,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Disability Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.disabilityView = "default";
                this.fetchStudents(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm('Are you sure you want to add Disability(s)?') && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.disability.student_id = this.assignedStudent.id
            this.disability.disabilities = this.disabilities
            apiCall({
              url: "/api/disability-link",
              data: this.disability,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Disability(s) Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.assignedStudent.disabilities = resp;
                this.disabilityView = "default"
                this.disabilities = []
                let obj = {}
                obj['relationship_id'] = null
                obj['name'] = null
                obj['primary_phone'] = null
                obj['secondary_phone'] = null
                obj['living_with'] = null

                this.disabilities.push(obj)
                this.fetchStudents(1);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchStudents(1);
              });
          }
        }
      }
    },
    saveExcel() {
      if (this.$refs.excelForm.validate()) {
        confirm("Are you sure you want to upload this excel?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.excelLoading = true;
          let formData = new FormData();

          // files
          formData.append(
            "files",
            this.excelFile,
            this.excelFile.name
          );

          apiCall({
            url: "/api/student?type=excel",
            data: formData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Excel Uploaded Successfully";
              this.color = "orange";
              this.excelLoading = false;
              this.snackbar = true;
              this.confirm = false;
              this.dialog = false;
              this.fetchStudents(1);
            })
            .catch((error) => {
              this.excelLoading = false;
              this.confirm = false;
            });
        }
      }
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startStudentLoader();
        apiCall({
          url: "/api/student?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterStudents(resp);
            this.searchLoader = false;
            this.stopStudentLoader();
            this.filteredStudents = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopStudentLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.newStudent.id = null;
        this.newStudent.first_name = null;
        this.newStudent.last_name = null;
        this.newStudent.other_names = null;
        this.newStudent.dob = null;
        this.newStudent.gender_id = null;
        this.newStudent.country_id = null;
        this.newStudent.disability = false;
      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {

      }
      this.view = view;
    },
    changeClassView(view) {
      if (view == "default") {
        this.assignStudent.id = null;
        this.assignStudent.institution_id = null;
        this.assignStudent.year = null;
        this.assignStudent.level_id = null;
        this.assignStudent.level_stream_id = null;
        this.assignStudent.registration_date = null;
        this.assignStudent.registration_number = null;

      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {

      }
      this.classView = view;
    },
    changeStudentIdentifierView(view) {
      if (view == "default") {

      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {

      }
      this.studentIdentifierView = view;
    },
    changeGuardianView(view) {
      if (view == "default") {

      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {

      }
      this.guardianView = view;
    },
    changeDisabilityView(view) {
      if (view == "default") {

      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {

      }
      this.disabilityView = view;
    },
    changePage() {
      this.startStudentLoader();
      if (this.filteredStudents == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/student?page=" +
            this.studentPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterStudents(resp);
            this.stopstudentLoader();
          })
          .catch((error) => {
            console.log(error);
            this.stopStudentLoader();
            // this.message = "anErrorOccurredMsg";
            // this.color = "error";
            // this.snackbar = true;
          });
      } else {
        this.fetchStudents(this.studentPagination.current_page);
      }
    },
    deleteStudent(item) {
      confirm("Are You Sure You Want to Delete student") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.studentIndex = item.id;
        apiCall({ url: "/api/student/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "student Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.studentIndex = null;
            this.fetchStudents(this.studentPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.studentIndex = null;
            console.log(error.response);
          });
      }
    },
    deleteAssignment(item){
      confirm("Are You Sure You Want to Delete Class Assignment?") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.assignmentIndex = item.id;
        apiCall({ url: "/api/student-registration/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Class Assignment Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.assignmentIndex = null;

            const index = this.studentClasses.indexOf(item);
            if (index > -1) {
              this.studentClasses.splice(index, 1);
            }

            this.fetchStudents(this.studentPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.assignmentIndex = null;
            console.log(error.response);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/student?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedStudent.id);

        apiCall({
          url: "/api/student?type=image",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "profilePictureUploadedSuccessfullyMsg";
            this.color = "success";
            this.assignedStudent.profile_picture = resp.profile_picture
            this.imageLoading = false;
            this.imageDialog = false;
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
    closeDateOfBirth() {
      this.dateOfBirth = false;
    },
    closeDateEnrolled() {
      this.dateEnrolled = false;
    },

  },
  computed: {
    ...mapGetters([
      "students",
      "studentPagination",
      "studentLoader",
    ]),
    length: function () {
      return Math.ceil(
        this.studentPagination.total / this.studentPagination.per_page
      );
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        (today.getFullYear()) +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
    formatDobDate() {
      if (this.newStudent.dob != null) {
        const d = new Date(this.newStudent.dob);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatEnrolmentDate() {
      if (this.assignStudent.registration_date != null) {
        const d = new Date(this.assignStudent.registration_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
  },
};
</script>
