import apiCall from '@/utils/api'

const state = {
	institutionType: {},
	institutionTypes: [],
    institutionTypePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    institutionTypeLoader: false
};

const getters = {
    institutionTypeLoader: (state) => state.institutionTypeLoader,
	institutionTypes: (state) => state.institutionTypes,
	institutionType: (state) => state.institutionType,
    institutionTypePagination: (state) => state.institutionTypePagination
}

const actions = {
    async fetchInstitutionTypes({commit}, page) {
		const response = await apiCall({url: `/api/institution-type?page=${page}`, method: 'GET' });
		commit('setInstitutionTypes', response)
        commit('stopInstitutionTypeLoader', response)
	},
    async fetchInstitutionType({commit}, itemId) {
		const response = await apiCall({url: `/api/institution-type/${itemId}`, method: 'GET' });
		commit('setInstitutionType', response)
        commit('stopInstitutionTypeLoader', response)
	},
    async filterInstitutionTypes({commit, state},resp){
		commit('setInstitutionTypes', resp)
	},
	async startInstitutionTypeLoader({commit, state},resp){
		commit('startInstitutionTypeLoader', resp)
	},
	async stopInstitutionTypeLoader({commit, state},resp){
		commit('stopInstitutionTypeLoader', resp)
	},
};

const mutations = {
	setInstitutionTypes: (state, institutionTypes) => {
		state.institutionTypes = institutionTypes.data
		state.institutionTypePagination.current_page = institutionTypes.current_page
		state.institutionTypePagination.total = institutionTypes.total
		state.institutionTypePagination.per_page = institutionTypes.per_page
	},
	setInstitutionType: (state, institutionType) => {
		state.institutionType = institutionType
	},
    startInstitutionTypeLoader: (state) => state.institutionTypeLoader = true,
	stopInstitutionTypeLoader: (state) => state.institutionTypeLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
