const state = {
	dark: false,
};

const getters = {
	darkState: (state) => state.dark,
};

const actions = {
	async changeDarkState({commit}) {
		commit('updateDarkState')
	}
};

const mutations = {
	updateDarkState: (state) => (state.dark = !state.dark)
};

export default {
	state,
	getters,
	actions,
	mutations
}