import apiCall from '@/utils/api'

const state = {
	gender: {},
	genders: [],
    genderPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    genderLoader: false
};

const getters = {
    genderLoader: (state) => state.genderLoader,
	genders: (state) => state.genders,
	gender: (state) => state.gender,
    genderPagination: (state) => state.genderPagination
}

const actions = {
    async fetchGenders({commit}, page) {
		const response = await apiCall({url: `/api/gender?page=${page}`, method: 'GET' });
		commit('setGenders', response)
        commit('stopGenderLoader', response)
	},
    async fetchGender({commit}, itemId) {
		const response = await apiCall({url: `/api/gender/${itemId}`, method: 'GET' });
		commit('setGender', response)
        commit('stopGenderLoader', response)
	},
    async filterGenders({commit, state},resp){
		commit('setGenders', resp)
	},
	async startGenderLoader({commit, state},resp){
		commit('startGenderLoader', resp)
	},
	async stopGenderLoader({commit, state},resp){
		commit('stopGenderLoader', resp)
	},
};

const mutations = {
	setGenders: (state, genders) => {
		state.genders = genders.data
		state.genderPagination.current_page = genders.current_page
		state.genderPagination.total = genders.total
		state.genderPagination.per_page = genders.per_page
	},
	setGender: (state, gender) => {
		state.gender = gender
	},
    startGenderLoader: (state) => state.genderLoader = true,
	stopGenderLoader: (state) => state.genderLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
