import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/redirector')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: '/roles',
    name: 'Roles',
    component: require('@/views/accessControl/role.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users',
    name: 'Users',
    component: require('@/views/accessControl/user.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: require('@/views/Profile.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: require("@/views/Dashboard.vue").default,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/redirector",
    name: "Redirector",
    component: require("@/views/Redirector.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/school-admin-home',
    name: 'SchoolAdminHome',
    component: require("@/views/schoolAdminHome.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/admin-home',
    name: 'AdminHome',
    component: require("@/views/adminHome.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/staff-home',
    name: 'StaffHome',
    component: require("@/views/staffHome.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/staff-students',
    name: 'StaffStudent',
    component: require("@/views/staff/student.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/school-calendar',
    name: 'SchoolCalendar',
    component: require("@/views/calendar/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/table-data',
    name: 'TableData',
    component: require("@/views/tableData/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/locations',
    name: 'Location',
    component: require("@/views/location/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/institutions',
    name: 'Institution',
    component: require("@/views/institution/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/classes',
    name: 'Class',
    component: require("@/views/level/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/class-streams',
    name: 'Class Stream',
    component: require("@/views/levelStream/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/school-admins',
    name: 'School Admins',
    component: require("@/views/schoolAdmin/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/staff',
    name: 'Staff',
    component: require("@/views/staff/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/students',
    name: 'Student',
    component: require("@/views/students/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/attendance',
    name: 'Attendance',
    component: require("@/views/attendance/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/attendance',
    name: 'AttendanceReport',
    component: require("@/views/reports/attendance.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/schools',
    name: 'SchoolReport',
    component: require("@/views/reports/school.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/students',
    name: 'StudentReport',
    component: require("@/views/reports/student.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/teachers',
    name: 'TeacherReport',
    component: require("@/views/reports/teacher.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/classrooms',
    name: 'ClassroomReport',
    component: require("@/views/reports/classroom.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/toilets',
    name: 'ToiletReport',
    component: require("@/views/reports/toilet.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/electricity-internet',
    name: 'ElectricityReport',
    component: require("@/views/reports/electricity.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/computed-reports',
    name: 'ComputedReport',
    component: require("@/views/computedReport/index.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPass',
    component: require("@/components/auth/resetPass.vue").default,
    beforeEnter: ifNotAuthenticated,
  },
  { path: "*", 
    name: 'PageNotFound',
    component: require("@/views/404.vue").default, 
  }
]

const router = new VueRouter({
  routes
})

export default router
